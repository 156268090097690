import React, { Component } from "react";
import { Empty, Menu, Layout, Row, Col, Divider, PageHeader, Form, message, Modal, Pagination, List, Input, Checkbox, Spin } from "antd";

import { CardTaskClient, CardTaskStep } from '../../Widgets/Cards';
import { Redirect } from "react-router-dom";



import { ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons'

import axios from 'axios'

import Avatar from '../../Widgets/Avatar/Avatar'


import { User } from '../../../Hooks/Logged'
//css
import "../../../Styles/Modules/ProjectManager/ProjectManager.scss";


const { Content } = Layout;


/**
 *
 *
 * @export
 * @class ProjectManager
 * @extends {Component}
 * @description Vista de Project Manager
 */
class ProjectManager extends Component {

    static contextType = User;

    constructor(props) {

        super(props)
        // let { page, limit } = Object.fromEntries(new URLSearchParams(this.props.history?.location?.search?.replace("?", "")))
        this.state = {
            step1: [
                {
                    title: 'Formulario de Cliente',
                    value: 1
                },
                {
                    title: 'Cotización Preeliminar',
                    value: 2
                },
                {
                    title: 'Matriz de Costos',
                    value: 3
                },
                {
                    title: 'Envio de Cotización Final',
                    value: 4
                },
                {
                    title: 'Aprobación o Rechazo',
                    value: 5
                }
            ],
            step2: [
                {
                    title: 'Anticipo de Cliente',
                    value: 6
                },
                {
                    title: 'Generación de Orden de Compra',
                    value: 7
                },
                {
                    title: 'Pago de Orden de Compra',
                    value: 8
                },

            ],
            step3: [
                {
                    title: 'Liquidación del Cliente',
                    value: true
                },
                {
                    title: 'Programación de Envio',
                    value: 10
                },
                {
                    title: 'Recepción del Cliente',
                    value: 11
                }
            ],
            step4: [
                {
                    title: 'Liquidación del Cliente',
                    value: true
                },
            ],
            filtros: {
                page: 1,
                total: 0,
                limit: 10
            },
            ordenes: {
                cotizacion: [],
                compras: [],
                logistica: [],
                completas: []
            },
            itemHeight: 641,
            listLen: 0,
            redirect: false,
            loading: false,
            usuarios: []
        }
    }

    // scrollHandler = () => {
    //     let height = document.documentElement.scrollHeight - document.documentElement.offsetHeight;
    //     if (document.documentElement.scrollTop > height && !this.state.loading) {
    //         console.log("A", "cargando")
    //         // let filtros = this.state.filtros
    //         // filtros.page = filtros.page + 1
    //         // console.log(filtros)

    //         // this.setState({
    //         //     filtros
    //         // }, () => this.getOrdenes(filtros.page))
    //     }
    // }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenes();
        this.getUsuariosList()


        window.addEventListener('scroll', this.handleScroll)
        // window.addEventListener('scroll', this.scrollHandler, true)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.search !== this.props.search) {
            this.getOrdenes(1)
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll, true)
    }

    handleScroll = () => {
        if (
            (window.scrollY + window.innerHeight) / document.querySelector("body").scrollHeight > 0.97 &&
            !this.state.loading &&
            this.state.pages >= this.state.filtros.page
        )
            this.getOrdenes(this.state.filtros.page + 1)
    }

    /**
    * @memberof Cuentas
    * @method getOrdenes
    * @description Trae las cuentas
    *
    */
    getOrdenes = (page = this.state.filtros.page, canceladas) => {

        this.setState({ loading: true, filtros: { ...this.state.filtros, page } }, () => {


            // let urlParams = { page, }

            // if (this.props.search)
            //     urlParams["search"] = this.props.search      

            // this.props.history.replace({ pathname: this.props.location.pathname, search: (new URLSearchParams(urlParams)).toString() });  
            axios.post('/project-manager/dashboard', {
                page: page,
                limit: 10,
                search: this.props.search,
                canceladas: (canceladas === true) ? true : undefined,
            }).then(({ data }) => {
                data = data.data
                console.log(data)

                
                let ordenes = {}

                if (page === 1)
                    ordenes = {
                        cotizacion: [...data.data.cotizacion],
                        compras: [...data.data.compras],
                        logistica: [...data.data.logistica],
                        completas: [...data.data.completadas]
                    }
                else
                    ordenes = {
                        cotizacion: [...this.state.ordenes.cotizacion, ...data.data.cotizacion],
                        compras: [...this.state.ordenes.compras, ...data.data.compras],
                        logistica: [...this.state.ordenes.logistica, ...data.data.logistica],
                        completas: [...this.state.ordenes.completas, ...data.data.completadas]
                    }

                this.setState({
                    ordenes,
                    pages: data.pages
                })
            })
                .catch(error => {
                    console.log(error)
                    message.error('Error al traer la información')
                })
                .finally(() => {
                    this.setState({ loading: false })
                })
        })


    }


    getUsuariosList = (search) => {

        //this.setState({ loading: true })

        axios.get('/usuarios', {
            params: {

                responsables: true,
                search
            }
        }).then(response => {
            console.log('responsables', response.data.data)
            this.setState({
                usuarios: response.data.data.itemsList,
            })


        }).catch(error => {
            message.error('Error al traer usuarios')
            console.log(error)
        })
        //.finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberof Cuentas
    * @method asignarAseser
    * @description Trae las cuentas
    *
    */
    asignarAsesor = (orden) => {

        Modal.confirm({
            title: '¿Quieres ser el Asesor de esta Orden?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Cancelar',
            okText: 'Si',
            onOk: () => {
                axios.post('/project-manager/asesor', {
                    orden_id: orden._id,
                }).then(response => {
                    message.success('Asesor Asignado')
                    this.redirect(orden)
                }).catch(error => {
                    console.log(error)
                    message.error('Error al asignar el Asesor')
                })
            },
        });
    }

    /**
    * @memberof ProjectManager
    * @method redirect
    * @description Redirige a la vista para visualizar los datos de la orden
    *
    */
    redirect = ({ _id, estado, tipo_orden, orden_detalles }) => {

        let section = ""
        // let faltante = orden_detalles?.find(item => item.cantidad_faltante > 0) ? true : false


        if (tipo_orden === 1) {
            switch (estado) {
                case 1:
                    section = "supplychain"
                    break
                case 2:
                case 3:
                case 4:
                    section = "matriz-costos"
                    break
                case 5:
                    section = "supplychain"
                    break;
                case 6:
                case 7:
                    section = "cuenta-ventas"
                    break
                case 8:
                case 9:
                case 10:
                case 11:
                    section = "logistica"
                    break
                default:
                    section = "supplychain"
                    break
            }
        }

        if (tipo_orden === 2) {
            if (estado <= 5) {
                section = "matriz-costos"
            } else if (estado <= 7) {
                section = "cuenta-ventas"
            } else if (estado > 7) {
                section = "logistica"
            }
        }

        this.setState({ redirect: `/admin/project-manager/${section}/${_id}` })
        // this.props.history.push();
    }


    onScroll = e => {
        //console.log(`${e.target.scrollHeight} - ${e.target.scrollTop} de ${this.state.listLen * this.state.itemHeight}`)
        if (e.target.scrollHeight - e.target.scrollTop >= (this.state.listLen * this.state.itemHeight)) {
            this.setState(prevState => ({ page: parseInt(prevState.page) + 1 }), () => this.getOrdenes())
        }
    }

    /**
    * @memberof ProjectManager
    * @method goOrden
    * @description Redirige a las vistas del flujo de la orden o asigna un asesor a la orden
    *
    */
    goOrden = (orden) => {
        console.log("orden", orden);
        if (this.context.tipo === 1 ||  //si soy admin
            this.context._id === orden?.agente_compras_id?._id   //y estoy logeado con el ususario asignado
        ) {
            this.redirect(orden)
        } else {
            this.asignarAsesor(orden)
        }
    }


    getUsuarios = (orden, tipo, index) => {

        console.log("tipo", tipo)

        return <Menu>
            <div style={{ margin: '5px' }}>
                <Input.Search
                    id="buscar-input"
                    size="small"
                    placeholder="Buscar Persona..."
                    onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    // console.log("e", e.target.value)
                    onChange={(e) => this.getUsuariosList(e.target.value)}
                ></Input.Search>
            </div>

            {(Array.isArray(this.state.usuarios) && this.state.usuarios.length > 0) ?

                this.state.usuarios.map(e => <Menu.Item icon={<Avatar size="small" name={[e.nombre, e.apellido]} image={e.avatar} />}
                    onClick={() => {
                        this.setState(state => {

                            state.ordenes[tipo][index].loading = true
                            return state
                        },
                            () => axios.post('/project-manager/asesor', {
                                orden_id: orden._id,
                                usuario_id: e._id
                            }).then(response => {
                                message.success('Asesor Asignado')
                                this.setState(state => {
                                    state.ordenes[tipo][index].agente_compras_id = e
                                    state.ordenes[tipo][index].loading = false
                                    return state
                                })
                            }).catch(error => {
                                console.log(error)
                                message.error('Error al asignar el Asesor')
                            })

                        )
                    }}
                >
                    {e.nombre} {e.apellido}
                </Menu.Item>)
                :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No hay usuarios coincidentes" />
            }
        </Menu>
    }

    /**
     *
     *
     * @memberof ProjectManager
     * @method cancelOrden
     * @param {string} id - Identificador de la orden
     * @description Cambia el estatus de la orden a cancelado
     */
    cancelOrden = (id) => {
        if (this.state.loading) return
        this.setState({ loading: true }, () => {
            axios.post("/ordenes/update", {
                id,
                estatus: 2
            })
                .then(response => {
                    if (response.data.success) {
                        message.success('Se ha cancelado la orden');
                        this.getOrdenes(1, false)
                    }
                    else {
                        message.error('Error al cancelar la orden');
                    }
                })
                .catch(err => {
                    console.log(err)
                    message.error('Error al cancelar la orden');
                })
                .finally(() => {
                    this.setState({ loading: false })
                })
        })
    }

    render() {

        const { step1, step2, step3, step4, redirect } = this.state;


        return (
            <>
                {(typeof this.state.redirect === "string") ? <Redirect to={this.state.redirect} push /> : null}
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={
                        <Form
                            noStyle
                            className="form-filters"
                            onValuesChange={this.filter}
                        >
                            <Row className="width-100" justify="space-between">
                                <Col xxl={{ span: 8 }} xl={{ span: 8 }} lg={{ span: 17 }} md={{ span: 24 }} sm={{ span: 4 }} xs={{ span: 14 }}  >
                                    <span className="ant-page-header-heading-title" >
                                        Project Manager
                                    </span>
                                </Col>
                                <Col span={3} style={{ alignSelf: "center" }} >
                                    <Checkbox onChange={e => this.getOrdenes(1, e.target.checked)} style={{ color: "white" }} >Ver Canceladas</Checkbox>
                                </Col>

                            </Row>
                        </Form>
                    }
                />
                <Content className="content-bg pd-1">
                    <Row gutter={[8, 8]} className="witdh-100" align="start" justify="space-between">
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <List>
                                    <CardTaskStep
                                        color={'#0089ED'}
                                        title={"Cotización"}
                                        position={"Primer"}
                                    />
                                    <Divider className="border-task" />
                                    {
                                        this.state.ordenes.cotizacion.map((orden, index) => {

                                            let vencido = false

                                            if (orden.cotizacion_id) {
                                                let vigencia = new Date(orden.cotizacion_id.fecha_respuesta)
                                                vigencia.setDate(vigencia.getDate() + orden.cotizacion_id.vigencia)
                                                let hoy = new Date()
                                                vencido = (vigencia < hoy) && (orden.cotizacion_id.status === 2)
                                            }

                                            return <CardTaskClient
                                                onCancel={() => this.cancelOrden(orden._id)}
                                                key={orden._id}
                                                task={step1}
                                                color={
                                                    (orden.estado === 5) ? "#f50" : (vencido ? '#EFF00C' : '#0089ED')
                                                }
                                                vencido={vencido}
                                                orden={orden}
                                                onClick={() => this.goOrden(orden)}
                                                cliente={orden.cliente_id}

                                                sinAsesor={this.getUsuarios(orden, "cotizacion", index)}
                                            />
                                        }
                                        )
                                    }
                                </List>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <List>
                                    <CardTaskStep
                                        color={'#DAC401'}
                                        title={"Compras"}
                                        position={"Segundo"}
                                    />
                                    <Divider className="border-task" />
                                    {
                                        this.state.ordenes.compras.map((orden, index) => <CardTaskClient
                                            onCancel={() => this.cancelOrden(orden._id)}
                                            key={orden._id}
                                            task={step2}
                                            color={(orden.estado === 5) ? "#f50" : '#DAC401'}
                                            orden={orden}
                                            onClick={() => this.goOrden(orden)}
                                            cliente={orden.cliente_id}

                                            sinAsesor={this.getUsuarios(orden, "compras", index)}
                                        />)
                                    }
                                </List>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <List>
                                    <CardTaskStep
                                        color={'#B354E0'}
                                        title={"Logística"}
                                        position={"Tercer"}
                                    />
                                    <Divider className="border-task" />
                                    {
                                        this.state.ordenes.logistica.map((orden, index) => <CardTaskClient
                                            onCancel={() => this.cancelOrden(orden._id)}
                                            key={orden._id}
                                            task={step3}
                                            color={(orden.estado === 5) ? "#f50" : '#B354E0'}
                                            orden={orden}
                                            onClick={() => this.goOrden(orden)}
                                            cliente={orden.cliente_id}

                                            sinAsesor={this.getUsuarios(orden, "logistica", index)}
                                        />)
                                    }
                                </List>
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} >
                            <div className="col-list-task-margin col-list-task-bg">
                                <List>
                                    <CardTaskStep
                                        color={'#00DB30'}
                                        title={"Ordenes Completadas"}
                                        position={"Cuarto"}
                                    />
                                    <Divider className="border-task" />
                                    {
                                        this.state.ordenes.completas.map((orden, index) => <CardTaskClient
                                            onCancel={() => this.cancelOrden(orden._id)}

                                            complete={true}

                                            key={orden._id}
                                            task={step4}
                                            color={(orden.estado === 5) ? "#f50" : '#00DB30'}
                                            orden={orden}
                                            onClick={() => this.goOrden(orden)}
                                            cliente={orden.cliente_id}

                                            sinAsesor={this.getUsuarios(orden, "completas", index)}
                                        />)
                                    }
                                </List>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        {<Spin indicator={<SyncOutlined spin style={{ display: "block", margin: "auto", fontSize: 30 }} />} spinning={this.state.loading} size={"large"} style={{ display: "block", margin: "auto" }} />}
                    </Row>


                </Content>
            </>
        )
    }
}


export default function (props) {

    // let history = useHistory();

    return <ProjectManager {...props}
    // history={history} 

    />
}
