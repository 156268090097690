import {
    Button,
    Col, Divider, Form, Input, List, Modal, Row, Typography, message
} from 'antd'
import '../../../Styles/Modules/Public/quickquote.scss'
import ModalAgregarProducto from './ModalAgregarProducto'
import { CardProducto } from '../../Widgets/Cards'
import Carrito, { SetCarrito } from '../../../Hooks/Carrito'
import { useContext } from 'react'
import axios from 'axios'
import { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from 'antd/lib/form/Form'

const { Text, Paragraph, Title } = Typography


const ModalForm = (props) => {
    const {
        visible = false,
        onCancel = () => { }
    } = props

    const [form] = useForm()
    const [loading, setLoading] = useState(false)

    const carrito = useContext(Carrito)
    const setCarrito = useContext(SetCarrito)

    const handleOk = e => {
        console.log(e);
        form.submit()
    };

    const handleCancel = e => {
        onCancel(false)
    }

    const onFinish = values => {
        setLoading(true)

        axios.post('/mini-cotizador', {
            values,
            carrito: carrito
        })
            .then(e => {
                Modal.success({
                    title: ("Enviado exitosamente"),
                })
                form.resetFields()
                onCancel()
                console.log('e', e)
                setCarrito([])

            })
            .catch(e => {
                message.error("No fue posible enviar")
                console.log('e', e)
            })
            .finally(e => setLoading(false))
        // onCancel(false)
    }


    return (
        <Modal
            title={<Typography>
                <Title level={5}>¡Nos pondremos en contacto!</Title>
                <Paragraph style={{ paddingBottom: 0 }}>Envianos tu infomración y ponte en contacto con nosotros.</Paragraph>
            </Typography>}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <Form
                form={form}
                layout='vertical'
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Nombre Completo"
                    name="nombre"
                    rules={[{ required: true, message: 'Por favor ingresa tu nombre completo!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Correo Electrónico"
                    name="correo"
                    rules={[{ required: true, message: 'Por favor ingresa tu correo electrónico!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Teléfono"
                    name="telefono"
                    rules={[{ required: true, message: 'Por favor ingresa tu número de teléfono!' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );

}


const QuickQuote = (props) => {

    const carrito = useContext(Carrito)
    const setCarrito = useContext(SetCarrito)
    const history = useHistory()

    const [modalVisible, setModalVisible] = useState(false)
    const [modalVisibleForm, setModalVisibleForm] = useState(false)
    const [productoIdx, setProductoIdx] = useState(null)

    const remove = (index) => {
        const _carrito = carrito
        const producto = _carrito.splice(index, 1)

        setCarrito(_carrito)
    }

    const deleteFile = (filename) => {
        axios.post("/upload/delete", {
            filename
        })
            .then(res => {
                console.log("Archivo/Imagen removida con éxito", res);
            })
            .catch(res => {
                console.log("Archivo/Imagen no se puedo remover", res);
            });
    }

    return (
        <section className="quick-quote">
            <Row className="quick-quote-content" justify={"center"} gutter={[40, 40]}>
                <Col xs={24} className="quick-quote-header">
                    <Title level={2} className='header-title'>
                        QuickQuote
                    </Title>
                    <Paragraph className="header-descripcion">
                        ¡Cotiza de manera rapida y sencilla los productos que requieras!
                    </Paragraph>
                </Col>
                <Col xs={24} md={12} className='quick-quote-subtitle'>
                    <Title level={4}>Lista de Productos</Title>
                </Col>
                <Col xs={24} md={12} className='quick-quote-actions'>
                    <Button className="action-add" onClick={() => setModalVisible(true)}>
                        Agregar Producto <PlusOutlined />
                    </Button>
                </Col>
                <Col xs={24}>
                    <Divider className="quick-quote-divider" />
                </Col>
                {
                    carrito.length < 1 ? (<Col xs={24} className='quick-quote-empty'>
                        <Paragraph >
                            No hay ningún producto a cotizar
                        </Paragraph>
                        <Button
                            className="quick-quote-text-add"
                            onClick={() => setModalVisible(true)}
                        >
                            ¡Agregar Uno!
                        </Button>
                    </Col>
                    ) : (
                        <Col xs={24}>
                            <List
                                className='quick-quote-productos'
                                //pagination={{}}
                                dataSource={carrito}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <CardProducto
                                            producto={item}
                                            onEditClick={() => {
                                                setProductoIdx(index)
                                                setModalVisible(true)
                                            }}
                                            onDeleteClick={() => remove(index)}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    )
                }
                <Col xs={24}>
                    <Row justify={"center"}>
                        <Button
                            className="quick-quote-cotizar"
                            disabled={false}
                            onClick={() => {
                                if (carrito?.length > 0)
                                    setModalVisibleForm(true)
                                else
                                    message.error("Agrege algunos productos")
                            }
                            }
                        >
                            Cotizar
                        </Button>
                    </Row>
                </Col>
            </Row>

            <ModalAgregarProducto
                productIdx={productoIdx}
                visible={modalVisible}
                deleteFile={deleteFile}
                onClose={() => {
                    setModalVisible(false)
                    setProductoIdx(null)
                }}
            />
            <ModalForm
                visible={modalVisibleForm}
                onCancel={(value) => setModalVisibleForm(value ?? false)}
            />
        </section>
    )
}

export default QuickQuote
