import React, { createRef, useEffect, useState } from 'react'
import { useContext } from 'react';
import { Button, Carousel, Col, Layout, Row, Steps, Typography, message } from "antd"

// Contextos
import Carrito, { SetCarrito } from '../../../Hooks/Carrito';
import User from '../../../Hooks/Logged';

// Steps
import StepCotizador from './StepCotizador';
import StepCuenta from './StepCuenta';
import StepInfo from './StepInfo';
import StepReview from './StepReview';
import StepSuccess from './StepSuccess';

// Styles
import '../../../Styles/Modules/Public/marketplace.scss';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

// AntD
const { Content } = Layout
const { Title, Paragraph } = Typography

const Marketplace = () => {

    // Context
    const carrito = useContext(Carrito)
    const setCarrito = useContext(SetCarrito)
    const user = useContext(User)
    // States
    const [currentStep, setCurrentStep] = useState(0)
    const [contactData, setContactData] = useState(null);
    const [folio, setFolio] = useState(null)
    const [loading, setLoading] = useState(false)
    const [captcha, setCaptcha] = useState(false)

    // Refs
    let carouselRef = createRef()
    let refStepInfo = createRef()

    // Effects
    useEffect(() => {
        if(folio && currentStep === 3) next() 
    }, [folio])

    useEffect(() => {
        if(contactData && currentStep === 2) next() 
    }, [contactData])

    // Variables
    const steps = [
        {
            title: 'Cotización de Productos',
            content: (
                <StepCotizador/>
            )
        },
        {
            title: 'Requisición de Material - Spot Buy',
            content: (
                <StepCuenta
                    onSuccess={() => {
                        next()
                    }}
                />
            )
        },
        {
            title: 'Requisición de Material - Spot Buy',
            content: (
                <StepInfo
                    ref={ref => refStepInfo = ref}
                    onSuccess={(data) => {
                        setContactData(data)
                    }}
                />
            )
        },
        {
            title: 'Confirma tu cotización',
            content: (
                <StepReview/>
            )
        },
        {
            title: 'Cotización realizada',
            content: (
                <StepSuccess folio={folio} nombre={`${user?.nombre} ${user?.apellido}`}/>
            )
        }
    ]


    const prev = () => {
        console.log(currentStep)

        if(currentStep === 3) {
            setContactData(null)
            refStepInfo?.formRef?.current?.resetFields()
            refStepInfo?.formRef?.current?.setFieldsValue(user?.cliente_id)
        }

        if(carouselRef){
            setCurrentStep(step => step - 1)
            carouselRef?.prev()
        }
    }
    /**
     * Verifica que los los productos en el carrito si tengan ciertos campos
     * */
    const faltanCampos = () => {
        for(let item of carrito){
            if(!item.cantidad) {
                message.info("Falta asignar una cantidad a algún producto")
                return true
            }
        }
        return false
    }

    const next = async () => {

        // Revisar que haya productos a cotizar
        if(currentStep === 0 && carrito.length < 1) {
            message.info("No hay productos a cotizar")
            return
        }

        // Revisar que haya productos con cantidad
        if(currentStep === 0 && carrito.length > 0) {
            if(faltanCampos()){
                return;
            }
        }

        // Revisar que haya una cuenta en uso
        if(currentStep === 1 && !user){
            message.info("Inicia sesión o registrate para continuar")
            return
        }

        // Checar que la info de contacto este cargado antes de proseguir
        if(currentStep === 2 && !contactData){
            
            try{
                await refStepInfo.formRef.current?.validateFields()
                refStepInfo?.formRef?.current?.submit()
                setLoading(true)
            }catch(error){
                message.error("Faltan datos necesarios para continuar")
            }
            
            return
        }

        // Confirmar cotizacion sin folio registrado
        if(currentStep === 3 && !folio){
            if(!captcha){
                message.info("Realiza el Captcha para proseguir")
                return
            }
            axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
            cotizar()
            return
        }

        if(carouselRef){

            if(currentStep === 1 && user?.cliente_id) refStepInfo?.formRef?.current?.setFieldsValue(user?.cliente_id)

            setLoading(false)
            carouselRef.next()
            setCurrentStep(step => step + 1)
        }
    }

    const reset = () => {
        if(!carouselRef) return

        setFolio(null)
        setContactData(null)
        setCarrito([])
        setCurrentStep(0)
        carouselRef.goTo(0)
    }

    const cotizar = () => {
        setLoading(true)
        const cotizacion = 
        {
            // nombre: contactData?.nombre,
            // apellido: contactData?.apellido,
            // email: user?.email,
            // razon_social: contactData?.razon_social,
            // telefono: contactData?.telefono,
            // departamento: contactData?.departamento,
            // direccion: contactData?.direccion,

            ...contactData,
            detalles: carrito,

        }

        // Enviar peticion
        axios.post("/rfq/cotizacion", { ...cotizacion })
            .then(({ data }) => {
                setFolio(data.data.orden.folio);
                setCarrito([])
            })
            .catch(err => {
                console.log("err", err);
            }).finally(()=>{
                //setLoading(false)
            })
    }

    return (
        <Content className="marketplace">
            <Row className="marketplace-content" justify={"center"} gutter={[40, 40]}>
                <Col xs={24} className="marketplace-brand">
                    <img
                        src={"/images/logo.png"}
                        alt="Nexus Cotizador"
                    />
                </Col>
                {
                    currentStep < 4 && <>
                        <Col xs={24} className="marketplace-header">
                            <Title level={3} className='header-title'>
                                {steps[currentStep].title}
                            </Title>
                            <Paragraph className="header-descripcion">
                                En un plazo de 48 horas, recibirás la cotización en tu email.
                            </Paragraph>
                        </Col>
                        <Col xs={24} className="marketplace-steps">
                            <Steps
                                progressDot={(dot, { status, index }) => (
                                    <div className={`steps-dot ${status !== "wait" ? 'active' : ''}`}>
                                        <Paragraph>
                                            {index + 1}
                                        </Paragraph>
                                    </div>
                                )}
                                current={currentStep}
                            >
                                <Steps.Step title="Cuenta Nexus" />
                                <Steps.Step title="Información de Contacto" />
                                <Steps.Step title="Creación de Cotización" />
                                <Steps.Step title="Confirmar Cotización" />
                            </Steps>
                        </Col>
                    </>
                }
                <Col xs={24}>
                    <Carousel
                        ref={ref => carouselRef = window.carousel = ref}
                        adaptiveHeight={true}
                        initialSlide={currentStep}
                        draggable={false}
                        dots={false}
                        infinite={false}
                    >
                        {steps.map(({ content }) => content)}
                    </Carousel>
                </Col>
                <Col xs={24}>
                    <Row gutter={[16, 16]} className='marketplace-actions' justify={currentStep <= 1 ? 'end' : currentStep === 4 ? 'center' : 'space-between'} align={"middle"}>
                        {
                            (currentStep > 1 && currentStep < 4) && (
                                <Button
                                    className="marketplace-steps-button"
                                    disabled={loading}
                                    onClick={() => prev()}
                                >
                                    ANTERIOR
                                </Button>
                            )
                        }
                          {
                            (currentStep === 4) && (
                                <Button
                                    className="marketplace-steps-button"
                                    disabled={false}
                                    onClick={() => reset()}
                                >
                                    NUEVA COTIZACION
                                </Button>
                            )
                        }
                        {
                            (currentStep === 3) && (
                                <ReCAPTCHA
                                    onChange={(value) => setCaptcha(value)}
                                    size="normal"
                                    sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA}
                                />
                            )
                        }
                        {
                            (currentStep !== 1 && currentStep !== 4) && (
                                <Button
                                    className="marketplace-steps-button"
                                    disabled={false}
                                    loading={loading}
                                    onClick={() => next()}
                                >
                                    {currentStep === 3 ? 'CONFIRMAR' : 'SIGUIENTE'}
                                </Button>
                            )
                        }
                    </Row>
                </Col>
            </Row>
        </Content>
    )
}

export default Marketplace