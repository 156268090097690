
import React, { Component, createRef } from 'react';
import { Button, Space, Image, Typography, InputNumber, Select, Table, Form, message } from 'antd';
import axios from 'axios';
import { IconEdit } from '../../../Widgets/Iconos';
import ModalCambiarProveedor from './ModalCambiarProveedor';

const { Text } = Typography;
const { Option } = Select;


/**
 *
 *
 * @export
 * @class TableReporteProductos
 * @extends {Component}
 */
export default class TableReporteProductos extends Component {

    formTableReporteProductos = React.createRef()
    refTableReporteProductos = React.createRef()
    state = {
        productos: {},
        productos_proveedor: [],
        proveedor_productos: [],
        dataSource: [],
        modal_visible: false,
        proveedor_id: null,
        producto_selected: null
    }

    componentDidMount() {
        this.getProductosReporte();
    }

    /**
     *
     *
     * @memberof TableReporteProductos
     */
    setDataArray = (arreglo = []) => {
        let values = {}
        for (let index = 0; index < arreglo.length; index++) {
            const data_productos = arreglo[index]
            values[index] = data_productos
        }

        return values
    }

    /**
     *
     *
     * @memberof TableReporteProductos
     * @description Obtiene los productos agregados en el reporte del almacen
     */
    getProductosReporte = ({ reporte_almacen_id = this.props.reporte_id } = this.props) => {
        axios.get('/reportes/productos', {
            params: {
                id: reporte_almacen_id
            }
        })
            .then(({ data }) => {
                console.log('data', data.data)
                this.setState({
                    dataSource: data.data.map(producto => {
                        return producto
                    }),
                    productos: data.data
                }, () => {
                    console.log('formTableReporteProductos', this.formTableReporteProductos)
                    this.formTableReporteProductos.current.setFieldsValue(this.setDataArray(data.data))
                })
                this.props.setReporte(reporte_almacen_id)
            })
            .catch(e => {
                console.log('datadatadata', e)
            })
    }

    updateProducto = values => {
        axios.put('/producto/update', {
            ...values,
        })
            .then(({ data }) => {
                console.log('data',data)
                message.success('Se actualizo Proveedor')
                this.getProductosReporte()
                this.setState({ visibleModalAgregarProductos: false })
            })
            .catch(response => {
                message.error('No se pudo actualizar el  Proveedor')
                console.log('response', response)
            })
    }


    handleSave = (row) => {
        let suma = (parseFloat(row.producto.costo) * row.cantidad_pendiente)
        let costo_total = suma.toMoney();
        row.costo_total = costo_total;
        console.log('row', row)
        return row;
    }

    onSave = (values) => {
        console.log('values', values)
        axios.post('/reportes/ordenes/compras/add/ordenes-compra', {
            reporte_id: this.props.reporte_id,
            productos: this.state.dataSource
        })
            .then(({ data }) => {
                message.success("Las ordenes de compras han sido generadas exitosamente.")
                this.props.onSave()
            })
            .catch(e => {
                console.log('datadatadata', e)
            })
    }


    columns = [
        {
            title: 'Producto',
            dataIndex: ['producto_id', 'nombre'],
            key: 'name',
            width: "25%",
            render: (value, item, index) => {
                let producto = item?.producto;
                return (
                    <Space>
                        <Image
                            width={80}
                            src={
                                producto?.imagenes?.length > 0
                                    ? `${axios.defaults.baseURL}/upload/${producto?.imagenes[0]?.filename}`
                                    : `/images/nofile.png`
                            }
                            className="product-image"
                            preview={true}
                        />
                        <Space direction="vertical" size={0} className="width-100">
                            <Text className="product-text-name">
                                {producto ? producto.modelo : ""}
                            </Text>
                            <Text className="product-text">
                                {producto ? producto.marca : ""}
                            </Text>

                            <Text className="product-text">
                                {
                                    producto?.descripcion?.length > 82 ?
                                        producto?.descripcion?.slice(0, 78) + "..." :
                                        producto?.descripcion
                                }
                            </Text>
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: "Cantidad a Pedir",
            key: 'cantidad_pendiente',
            dataIndex: "cantidad_pendiente",
            width: "20%",
            shouldCellUpdate: () => true,
            render: (value, record, index) => (
                <Form.Item
                    className="form-item-orden-compra"
                    name={[index, "cantidad_pendiente"]}
                    rules={[
                        {
                            required: true,
                            message: "Ingrese la cantidad de producto a pedir",
                        },
                    ]}
                >
                    <InputNumber
                        controls={false}
                        max={(record.inv_max - record.cantidad_actual)}
                        min={1}
                        className="width-100" />
                </Form.Item>

            )
        },
        {
            title: "Costo Uni. Proveedor",
            dataIndex: ['producto', 'costo'],
            key: "costo",
            shouldCellUpdate: () => true,
            render: (value, record, index) => {
                // return (
                //     <span>
                //         $ 
                //     </span>
                // );

                return (
                    <Form.Item
                        className="form-item-orden-compra"
                        name={[index, "producto","costo"]}
                        rules={[
                            {
                                required: true,
                                message: "Ingrese el precio unitario del proveedor",
                            },
                        ]}
                    >
                        <InputNumber
                            controls={false}
                            defaultValue={(this.state.dataSource[index]?.producto?.costo) ? this.state.dataSource[index]?.producto?.costo.toMoney() : 0}
                            decimalSeparator="."
                            min={1}
                            className="width-100" />
                    </Form.Item>
                )
            }

        },
        {
            title: " Proveedor",
            key: "proveedor",
            dataIndex: ['producto', 'proveedor_id', 'nombre'],
            shouldCellUpdate: () => true,
        },
        {
            title: "Costo total",
            key: "costo_total",
            dataIndex: 'costo_total',
            shouldCellUpdate: () => true,
            render: (value, record, index) => {
                return (
                    <span>
                        $ {(this.state.dataSource[index]?.costo_total) ? this.state.dataSource[index]?.costo_total.toMoney(true) : null}
                    </span>
                )
            }
        },
        {
            key: "acciones",
            render: (value, record, index) => {
                console.log('value', value)
                return (
                    <Space>
                        <Button
                            onClick={() => this.setState({
                                proveedor_id: record.producto.proveedor_id._id,
                                producto_selected: record.producto, 
                                modal_visible: true
                            })} danger type="primary" size='small' icon={<IconEdit style={{ color: "white", fontSize: '10px' }} />} />
                    </Space>
                );
            },
        }

    ];



    render() {
        const { dataSource, productos, modal_visible } = this.state;
        const { columns, formTableReporteProductos, refTableReporteProductos } = this
        window.a = refTableReporteProductos
        return (
            <>
                <Form
                    ref={formTableReporteProductos}
                    rowKey={record => record._id}
                    onFinish={this.onSave}
                    onValuesChange={(values, record) => {
                        
                        let index = Object.entries(values)[0][0];
                        let recordTemp = this.formTableReporteProductos.current?.getFieldValue(index);
                        console.log(record)
                        this.setState(state => {
                            state.dataSource[index].cantidad_pendiente = recordTemp.cantidad_pendiente;
                            state.dataSource[index].producto.costo = recordTemp.producto.costo;
                            state.dataSource[index].costo_total = recordTemp.producto.costo * recordTemp.cantidad_pendiente;
                            state.dataSource[index] = { ...state.dataSource[index], ...recordTemp }
                            state.dataSource[index] = this.handleSave(state.dataSource[index])
                            state.dataSource = [...state.dataSource]
                            return state;
                        }, () => this.formTableReporteProductos.current?.setFieldsValue(this.state.dataSource))
                        
                    }}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                    />
                </Form>
                <ModalCambiarProveedor
                    visible={modal_visible}
                    onCancel={() => this.setState({ modal_visible: false })}
                    producto={this.state.producto_selected}
                    proveedor_id={this.state.proveedor_id}
                    onSave={this.updateProducto}
                />

            </>
        )
    }
}