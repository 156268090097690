import React, { Component } from "react";

import {
    Layout, Row, Col, PageHeader, Space, Button, List,
    Tag, Pagination, Typography, Card, message, Spin,
    Tooltip, Select,
    Modal
} from "antd";

//Componentes

import { WarningOutlined, EditFilled, FilePdfFilled, MailOutlined, DeleteFilled } from "@ant-design/icons";
import { IconAceptarOrden, IconProveedores } from '../../Widgets/Iconos';

import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
//Modales

import ModalAlmacen from "../ProjectManager/AccountPurchase/ModalAlmacen"
import DrawerOrdenCompra from "./DrawerOrdenCompra"
import ModalOrdenCompra from "./ModalOrdenCompra"


import { User } from '../../../Hooks/Logged';
import { SocketContext } from '../../../Hooks/Socket';
import ModalNotificar from "./ModalNotificar";
import ModalProveedor from "./ModalProveedor";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;

const axios = require('axios')
const moment = require('moment')

/**
 *
 *
 * @export
 * @class ListaCompras
 * @extends {Component}
 * @description Vista del listado de ListaCompras
 */
class ListaCompras extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalAlmacenVisible: false,

            modalNotificarVisible: false,
            modalProveedorVisible: false,
            proveedor_id: undefined,
            orden_compra_id: undefined,
            cliente_id: null,
            admin: undefined,
            folio: undefined,

            compras: {

                data: [],

                limit: 20,
                page: 1,

                total: 0,
                pages: 0,

                search: '',

                filtros: {
                    orden_compra_id: null
                },
            },
            asignadas: 'todas'
        }
    }

    filterForms = React.createRef();
    componentDidMount() {
        this.props.updateFilterSearch(false);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getCompras()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.compras.search !== this.props.search && this.props.search  !== null && this.props.search !== undefined) {
            this.getCompras({ search: this.props.search, page: 1 })
        }
    }


    /**
    *
    *
    * @memberof ListaCompras
    * @description Obtiene todas todas las ListaCompras
    * @param values pagina
    */
    getCompras = (
        {
            page = this.state.compras.page,
            limit = this.state.compras.limit,
            filtros = this.state.compras.filtros,
            search = this.state.compras.search
        } = this.state.compras,
        { compras } = this.state

    ) => {
        this.setState({
            loading:true,
            compras: { ...compras, page, limit, search }
        })
        axios.get('/ordenesCompra/list', {
            params: {
                page,
                limit,
                simplified: true,
                asignadas: this.state.asignadas,
                search,
                ...filtros
            }
        })
            .then(({ data }) => {
                data = data.data;

                console.log('data recibida',data)
                this.setState({
                    compras: {
                        ...this.state.compras,
                        ...data,
                    }
                })
            })
            .catch(err => {
                console.log("error", err);
                message.error('No se pudieron cargar las ordenes de compra.')
            })
            .finally(() => this.setState({ loading: false }))

    }


    toogleFilters = (value) => {
        this.setState({ showFiltro: value });
        this.getListaCompras()
        this.renderFiltros()
    }

    renderFiltros = () => {
        let tags = []
        let { showFiltro } = this.state;
        for (const filtro in showFiltro) {
            let array_filtro = showFiltro[filtro];
            for (const key in array_filtro) {
                tags.push(<Tag closable className="tag-filtro" closeIcon={<div className="point"></div>}>{array_filtro[key]}</Tag>)
            }

        }
        return tags;
    }



    /**
     *
     *
     * @memberof ListaCompras
     * @method renderEstatus
     * @description Obtiene las ordenes de compra de la orden correspondiente
     */
    renderEstatus = (estatus) => {
        let estatus_label = {
            0: <Tag color="#facc15">Creada</Tag>,
            1: <Tag color="#1a91ff">Aceptada</Tag>,
            2: <Tag color="#1d4ed8">Confirmada</Tag>,
            3: <Tag color="#facc15">Incompleta</Tag>,
            4: <Tag color="#bef264">Completa</Tag>,
            5: <Tag color="#eab308">En entrega</Tag>,
            6: <Tag color="#eab308">Entregada</Tag>,
            7: <Tag color="#22c55e">Completa</Tag>,
            8: <Tag color="#15803d">Enviada</Tag>
        }
        return (estatus !== null || estatus !== undefined) ? estatus_label[estatus] : 'N/A'
    }


    /**
     *
     *
     * @memberof ListaCompras
     * @method aceptarOrden
     * @description Actualiza una orden de compra
     */
    aceptarOrden = (id) => {
        this.setState({ loading: true })
        return axios.post("/ordenesCompra/update",
            {
                id,
                estatus: 1
            })
            .then(response => {
                if (response.data.success) {
                    message.success('Se ha actualizado el estatus de orden de compra.');
                    this.getCompras();
                }
                else throw ""
            })
            .catch(err => {
                console.log(err)
                message.error('Error al actualizar estatus de orden de compra.');
                this.setState({ loading: false })

            })
    }


    /**
 *
 *
 * @memberof ListaCompras
 * @method sendEmail
 * @description Envia una orden de compra por email
 */
    sendEmail = (id) => {
        return axios.get('/ordenesCompra/purchase-order', {
            params: {
                orden_compra_id: id,
                enviar: true
            }
        }).then(response => {
            message.success('Orden Enviada')
            this.getCompras();
        }).catch(error => {
            console.log(error)
            message.error('Error al enviar  orden')
        })

    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}  >
                                    <span className="ant-page-header-heading-title">Ordenes de Compra</span>
                                </Col>
                                <Col xs={24} md={12} className="flex-right">
                                    <Select defaultValue={'todas'} style={{ width: '200px' }} onChange={(e) => this.setState({ asignadas: e }, () => this.getCompras())}>
                                        <Option value={'todas'}>Todas</Option>
                                        <Option value={'asignadas'}>Asignadas</Option>
                                        <Option value={'no_asignadas'}>No Asignadas</Option>
                                    </Select>
                                </Col>
                            </Row>
                        }
                    />
                    <Content className="admin-content content-bg pd-1 ">
                        <List
                            loading={this.state.loading}
                            className="component-list "
                            size="large"
                            itemLayout="horizontal"
                            dataSource={this.state.compras.data}
                            header={<Row className="header-list width-100 pl-1 pr-1 ">
                                <Col xs={24} md={3} lg={2} xl={2} xxl={2} className=" center">
                                    <Text strong>fecha</Text>
                                </Col>
                                <Col xs={24} md={4} lg={4} xl={4} xxl={4} className="center">
                                    <Text strong>proveedor</Text>
                                </Col>
                                <Col xs={24} md={3} lg={4} xl={4} xxl={4} className="center">
                                    <Text strong>folio</Text>
                                </Col>
                                <Col xs={24} md={3} lg={4} xl={4} xxl={4} className="center">
                                    <Text strong>productos</Text>
                                </Col>
                                <Col xs={24} md={3} lg={4} xl={4} xxl={4} className="center">
                                    <Text strong>monto</Text>
                                </Col>
                                <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                    <Text strong>estatus</Text>
                                </Col>
                                <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                    <Text strong>acciones</Text>
                                </Col>
                            </Row>}
                            renderItem={item => (
                                <List.Item className="component-list-item ">
                                    <Card className="width-100">
                                        <Row className="width-100">
                                            <Col xs={24} md={3} lg={2} xl={2} xxl={2} className="center">
                                                <Text ellipsis strong>{moment(item?.createdAt).format("DD-MMM-YYYY HH:mm")}</Text>
                                            </Col>
                                            <Col xs={24} md={4} lg={4} xl={4} xxl={4} className="center">
                                                <Text ellipsis strong>{item?.proveedor_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={24} md={4} lg={4} xl={4} xxl={4} className="center">
                                                <Text ellipsis strong>{item?.folio}</Text>
                                            </Col>
                                            <Col xs={24} md={3} lg={4} xl={4} xxl={4} className="center">
                                                <Text ellipsis strong>{(item?.orden_detalles_count) ? item?.orden_detalles_count : null}</Text>
                                            </Col>
                                            <Col xs={24} md={3} lg={4} xl={4} xxl={4} className="center">
                                                <Text ellipsis strong>$ {item?.monto_total ? item?.monto_total.toMoney(true) : '0.00'} MXN</Text>
                                            </Col>
                                            <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                                {this.renderEstatus(item?.estatus)}
                                            </Col>
                                            <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                                <Space wrap >
                                                    <Tooltip title="Editar Orden de Compra" >
                                                        <Button
                                                            icon={<EditFilled style={{ color: "currentColor" }} />}
                                                            type="primary"
                                                            title="Enviar"
                                                            onClick={() => this.setState({ drawerOrdenCompraVisible: true, orden_compra_id: item._id })}
                                                            size="small"
                                                        ></Button>
                                                    </Tooltip>
                                                    <Tooltip title="Editar Proveedor">
                                                        <Button
                                                            icon={<IconProveedores style={{ width: 14, heigth: 14 }} />}
                                                            type="primary"
                                                            title="IVA"
                                                            onClick={() => this.setState({ modalProveedorVisible: true, proveedor_id: item.proveedor_id._id })}
                                                            size="small"
                                                        >
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title={"Enviar la orden de Compra al proveedor"} >
                                                        <Button
                                                            icon={<MailOutlined style={{ color: "currentColor" }} />}
                                                            size="small"
                                                            type="primary"
                                                            title="Enviar"
                                                            onClick={() => this.sendEmail(item._id)}
                                                            style={{
                                                                backgroundColor: "#B845FF",
                                                                borderColor: "#B845FF"
                                                            }}
                                                        ></Button>
                                                    </Tooltip>
                                                    {
                                                        this.props.user?._id === item?.orden?.agente_compras_id ? (
                                                            <Tooltip title="Notificar la orden" >
                                                                <Button
                                                                    size="small"
                                                                    disabled={!(item.estatus === 0)}
                                                                    onClick={() => this.setState({ modalNotificarVisible: true, orden_compra_id: item._id, admin: this.props.user, folio: item.folio })}
                                                                    icon={<IconAceptarOrden color="#FFF" style={{ position: "relative", top: 2, left: '0.014em' }} />}
                                                                    type="primary"
                                                                    title="Aceptar"
                                                                    style={{
                                                                        backgroundColor: "#000",
                                                                        borderColor: "#000",//"#",
                                                                    }}
                                                                ></Button>
                                                            </Tooltip>
                                                        ) : this.props.user?.tipo === 1 ? (
                                                            <Tooltip title="Aceptar la orden" >
                                                                <Button
                                                                    size="small"
                                                                    disabled={!(item.estatus === 0)}
                                                                    onClick={() => this.aceptarOrden(item._id)}
                                                                    icon={<IconAceptarOrden color="currentColor" style={{ position: "relative", top: 2, left: '0.014em' }} />}
                                                                    type="primary"
                                                                    title="Aceptar"
                                                                    style={{
                                                                        backgroundColor: ((item.estatus === 0)) ? "rgb(108 230 52 / 40%)" : "#6CE634",
                                                                        borderColor: ((item.estatus === 0)) ? "rgb(108 230 52 / 40%)" : "#6CE634",//"#",
                                                                    }}
                                                                ></Button>
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title="Aceptar Orden " >
                                                                <Button
                                                                    size="small"
                                                                    disabled={true}
                                                                    icon={<IconAceptarOrden color="currentColor" style={{ position: "relative", top: 2, left: '0.014em' }} />}
                                                                    type="primary"
                                                                    title="Aceptar"
                                                                    style={{
                                                                        backgroundColor: ((item?.estatus === 0)) ? "rgb(108 230 52 / 40%)" : "#6CE634",
                                                                        borderColor: ((item?.estatus === 0)) ? "rgb(108 230 52 / 40%)" : "#6CE634",//"#",
                                                                    }}
                                                                ></Button>
                                                            </Tooltip>
                                                        )
                                                    }
                                                    <Tooltip title="Ver el PDF">
                                                        <Button
                                                            size="small"
                                                            icon={<FilePdfFilled style={{ color: "currentColor" }} />}
                                                            type="primary"
                                                            title="PDF"
                                                            onClick={() => {
                                                                let url = new URL(axios.defaults.baseURL + '/ordenesCompra/purchase-order')
                                                                url.searchParams.append('orden_compra_id', item?._id)
                                                                url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                                                                window.open(url, '_blank')
                                                            }}
                                                            style={{
                                                                backgroundColor: "rgb(68 210 178)",
                                                                borderColor: "rgb(68 210 178)",
                                                            }}
                                                        ></Button>
                                                    </Tooltip>
                                                    <Tooltip title="Asignar el almacén a donde llegará el producto" position="left">
                                                        <Button
                                                            size="small"
                                                            disabled={!(item?.orden?.tipo_orden === 2)}
                                                            icon={<WarningOutlined style={{ color: "currentColor" }} />}
                                                            type="primary"
                                                            title="Agregar Almacén"
                                                            onClick={() => {
                                                                this.setState({
                                                                    modalAlmacenVisible: true,
                                                                    orden_compra_id: item?._id,
                                                                    cliente_id: item?.orden?.cliente_id,
                                                                    almacen_id: item.almacen_id,
                                                                })
                                                            }}
                                                        ></Button>
                                                    </Tooltip>

                                                    <Button
                                                        size="small"
                                                        icon={<DeleteFilled style={{ color: "currentColor" }} />}
                                                        type="primary"
                                                        danger
                                                        title="Eliminar Orden"
                                                        onClick={() => {
                                                            Modal.confirm({
                                                                title: "¿Deseas eliminar esta Orden de Compra?",
                                                                content: "Si no hay una orden o un reporte relacionado, se eliminarán los registros de los productos",
                                                                okText: "Aceptar", cancelText: "Cancelar",
                                                                onOk: () => {


                                                                    return axios.post('/ordenesCompra/delete', {
                                                                        id: item._id
                                                                    })
                                                                        .then(() => {
                                                                            message.success("Eliminado correctamente")
                                                                            this.getCompras()
                                                                        })
                                                                }
                                                            })
                                                        }}
                                                    ></Button>
                                                </Space>

                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}

                        />
                        <Row className="mt-2 pb-3">
                            <Pagination
                                current={this.state.compras.page}
                                total={this.state.compras.total}
                                pageSize={this.state.compras.limit}
                                onChange={(page) => this.getCompras({ page })}

                            />
                        </Row>
                        <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalOrdenCompraVisible: true })} />
                    </Content>
                </Spin>
                <ModalAlmacen
                    visible={this.state.modalAlmacenVisible}
                    cliente_id={this.state.cliente_id}
                    orden_compra_id={this.state.orden_compra_id}
                    onClose={() => {
                        this.setState({ modalAlmacenVisible: false, orden_compra_id: undefined, almacen: undefined })
                        this.getCompras();
                    }}
                    almacen_id={this.state.almacen_id}
                />
                <DrawerOrdenCompra
                    orden_compra_id={this.state.orden_compra_id}
                    visible={this.state.drawerOrdenCompraVisible}
                    onSave={() => {
                        this.setState({ drawerOrdenCompraVisible: false, orden_compra_id: null })
                        this.getCompras()
                    }}
                    onClose={() => this.setState({ drawerOrdenCompraVisible: false, orden_compra_id: null })}
                />


                <ModalOrdenCompra
                    orden_compra_id={this.state.orden_compra_id}
                    visible={this.state.modalOrdenCompraVisible}
                    openDrawer={id => {
                        this.setState({
                            modalOrdenCompraVisible: false,
                            drawerOrdenCompraVisible: true,
                            orden_compra_id: id
                        })
                    }}
                    onClose={() => this.setState({ modalOrdenCompraVisible: false, orden_compra_id: null })}
                />

                <ModalNotificar
                    visible={this.state.modalNotificarVisible}
                    path={`${process.env.REACT_APP_PUBLIC_URL}${this.props.location?.pathname}`}
                    admin={this.state.admin}
                    folio={this.state.folio}
                    orden_compra_id={this.state.orden_compra_id}
                    onClose={() => {
                        this.setState({ modalNotificarVisible: false, orden_compra_id: undefined, admin: undefined })
                        this.getCompras();
                    }}
                />
                <ModalProveedor
                    visible={this.state.modalProveedorVisible}
                    proveedor_id={this.state.proveedor_id}
                    onClose={() => {
                        this.setState({ modalProveedorVisible: false, proveedor_id: undefined })
                        this.getCompras();
                    }}
                />
            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {

    const user = React.useContext(User)
    const socket = React.useContext(SocketContext)

    return (<ListaCompras {...props} user={user} socket={socket} />)
}