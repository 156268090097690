import React, { Component } from "react";

import {
    Layout, Row, Col, PageHeader, Space, Button, List,
    Tag, Pagination, Typography, Card, message, Spin, Popconfirm, Statistic
} from "antd";

//Componentes
import { IconEdit, IconDelete, IconDetails } from '../../../Widgets/Iconos';
import { PlusOutlined } from "@ant-design/icons";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
//Modales
import ModalTransacciones from './ModalTransacciones'
import ModalTransaccionesDetalle from './ModalTransaccionesDetalle'
import ModalTransaccionMultiple from './ModalTransaccionMultiple'

import DrawerFiltrosTransacciones from './DrawerFiltrosTransacciones'



const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')





/**
 *
 *
 * @export
 * @class Transacciones
 * @extends {Component}
 * @description Vista del listado de transacciones
 */
export default class Transacciones extends Component {

    constructor(props) {
        super(props)

        let { page, limit } = Object.fromEntries(new URLSearchParams(this.props.history?.location?.search?.replace("?","")))
        this.state = {
            loading: false,
            transacciones: [],
            transaccion_id: undefined,

            currentPage: page ?? 1,
            perPage: limit ?? 100,
            itemCount: 0,

            filtroSearch: '',
            searching: true,
            showFiltro: {},

            filtros: [],

            visibleFilters: false,
        }
    }

    filterForms = React.createRef();
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getTransacciones(1);
    }

    /**
     *
     *
     * @memberof Transacciones
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getTransacciones();
            this.state.searching = false;
        }
    }

    /**
    *
    *
    * @memberof Transacciones
    * @description Obtiene todas todas las transacciones
    * @param values pagina
    */
    getTransacciones = (page = this.state.currentPage, filtros = [], limit = this.state.perPage,) => {
        this.setState({ loading: true });
        let search = this.props.search;

        let urlParams = { page, limit }

        if (Array.isArray(filtros) && filtros.length > 0)
            urlParams["filtros"] = search

        if (search)
            urlParams["search"] = search        
            
        this.props.history.replace({ pathname: this.props.location.pathname, search: (new URLSearchParams(urlParams)).toString() });  


        console.log('filtros transacciones',filtros)
        axios.post('/transacciones/list', {
            page: page,
            limit,
            filtros,
            search
        })
            .then(res => {
                const data = res.data.data
                // console.log('res', res)
                this.setState({
                    transacciones: data.itemsList,
                    currentPage: data.currentPage,
                    itemCount: data.itemCount,
                    perPage: data.perPage,
                    pageCount: data.pageCount,

                    filtroSearch: search,
                    searching: true,
                    loading: false,
                    filtros

                })
            })
            .catch(res => {
                console.log("error", res);
                message.error('No se pudieron cargar las transacciones')
            }).finally(() => this.setState({ loading: false }))
    }

    renderMonto = (tipo, monto) => {
        let colors = ['#00FF19', '#FF0000', '#000000', '#000000'];
        return <Statistic value={monto.toMoney()} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    toogleFilters = (value) => {
        // console.log('value recibido', value);
        this.setState({ showFiltro: value });
        this.getTransacciones()
        this.renderFiltros()
    }



    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Transacciones
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        const { objectName } = filtro
        try{
            let values = this?.drawerFiltros?.formFiltros.getFieldValue(objectName)
            console.log('values', values)
            let index = values.findIndex(e => e === filtro._id)                    
            values.splice(index, 1)
            
            let newValues = {}
            newValues[objectName] = values
            this?.drawerFiltros?.formFiltros?.setFieldsValue(newValues)
            this?.drawerFiltros?.formFiltros?.submit()
        } catch (error) {
            console.log('error', error)
            this.setState({ visibleFilters: true })
        }
    }

    
    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Transacciones
     * @description Renderizamos los filtros que estén en el arreglo.
     */
    renderFiltros = ({ filtros } = this.state) => {
        return filtros.map( filtro => {
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{  lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px'}}
    
            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.nombre || filtro?.filter?.razon_social || filtro?.filter?.email || filtro?.filter?.folio}
            </Tag>
        } );
    }

    
    /**
     *
     *
     * @param {*} tipo
     * @param {*} monto
     * @memberof Transacciones
     * @description renderMonto renderizamos los montos.
     */
    renderMonto = (tipo, monto) => {
        let colors = ['#000000', '#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }


    render() {
        // console.log('state', this.state)

        const contentMenuPlus = (
            <div className="flex-column">
                <Button type="primary" className="width-100 mb-05" onClick={() => this.setState({ modalTransacVisible: true })}>
                    Añadir Transaccion
                </Button>
                <Button type="primary" className="width-100" onClick={() => this.setState({ modalVisibleCSV: true })}>
                    Añadir Múltiples
                </Button>
            </div>
        );

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                                    <span className="ant-page-header-heading-title" >
                                        Transacciones
                                    </span>
                                </Col>
                                <Col span={24}>
                                    {this.renderFiltros()}
                                </Col>
                            </Row>
                        }
                        extra={

                            // <Form
                            //     className="form-filters"
                            //     ref={ref => this.filterForms = ref}
                            //     // this.getTransacciones({ page: 1, filters: allValues })
                            //     onValuesChange={(changedValues, allValues) => this.toogleFilters(allValues)}
                            // >
                            //     <Dropdown overlay={<FilterSelect />} trigger="hover">
                            <Button className="button-plus" icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />} onClick={() => this.setState({visibleFilters: true})} />
                            //     </Dropdown>
                            // </Form>
                            // 
                        }
                    />
                    <Content className="admin-content content-bg pd-1 ">
                        <List
                            loading={this.state.loading}
                            className="component-list "
                            size="large"
                            itemLayout="horizontal"
                            dataSource={this.state.transacciones}
                            header={<Row className="header-list width-100 pl-1 pr-1 ">
                                <Col xs={24} md={2} lg={2} xl={2} xxl={2} className=" center">
                                    <Text strong>fecha</Text>
                                </Col>
                                <Col xs={24} md={3} lg={4} xl={4} xxl={4} className="center">
                                    <Text strong>concepto</Text>
                                </Col>
                                <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                    <Text strong>área</Text>
                                </Col>
                                <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                    <Text strong>rubro</Text>
                                </Col>
                                <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                    <Text strong>folio</Text>
                                </Col>
                                <Col xs={24} md={3} lg={3} xl={1} xxl={1} className="center">
                                    <Text strong>cliente</Text>
                                </Col>
                                <Col xs={24} md={3} lg={3} xl={3} xxl={3} className=" center">
                                    <Text strong>cuenta</Text>
                                </Col>
                                <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                    <Text strong>monto</Text>
                                </Col>
                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item ">
                                    <Card className="card-list">
                                        <div className={`badge-card tipo-${item.tipo}`}>
                                        </div>
                                        <Row className="width-100">
                                            <Col xs={24} md={2} lg={2} xl={2} xxl={2} className="center">
                                                <Text ellipsis strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                            </Col>
                                            <Col xs={24} md={4} lg={4} xl={4} xxl={4} className="center">
                                                <Text ellipsis className="text-gray-dark">{item.concepto}</Text>
                                            </Col>
                                            <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                                <Text ellipsis className="text-gray-dark">{item.area_id?.nombre ? item.area_id?.nombre : '-'}</Text>
                                            </Col>
                                            <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                                <Text ellipsis className="text-gray-dark">{item.rubro_id?.nombre ? item.rubro_id?.nombre : '-' }</Text>
                                            </Col>
                                            <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                                <Text ellipsis className="text-gray-dark">{item.orden_id?.folio ? item.orden_id?.folio : '-'}</Text>
                                            </Col>
                                            <Col xs={24} md={3} lg={1} xl={1} xxl={1} className="center">
                                                <CustomAvatar name={item.cliente_id?.razon_social?.split(' ')} />
                                            </Col>
                                            <Col xs={24} md={3} lg={3} xl={3} xxl={3} className=" center">
                                                <Text ellipsis className="text-gray-dark">{item.cuenta_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                                <Text ellipsis>{this.renderMonto(item.tipo, item.monto)} </Text>
                                            </Col>
                                            <Col xs={24} md={6} lg={2} xl={2} xxl={2} className="center">
                                                <Space direction="horizontal">
                                                    <Button
                                                        icon={<IconDetails />}
                                                        type="primary"
                                                        title="Ver Detalle"
                                                        onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}>
                                                    </Button>
                                                    <Button
                                                        icon={<IconEdit />}
                                                        type="primary"
                                                        title="Editar"
                                                        onClick={() => this.setState({ modalTransacVisible: true, transaccion_id: item._id })}>
                                                    </Button>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta Transacción?"
                                                        onConfirm={() => axios.post('/transacciones/delete', { id: item._id }).then((response) => {
                                                            message.success('Transaccion Eliminada')
                                                            this.getTransacciones()
                                                        }).catch((error) => {
                                                            message.error('Transacción NO Eliminada')
                                                            this.getTransacciones();
                                                        })
                                                        }
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            danger
                                                            icon={<IconDelete />} title="Eliminar" />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}

                        />
                        <Row className="mt-2 pb-3">
                            <Pagination
                                current={this.state.currentPage}
                                total={this.state.itemCount}
                                pageSize={this.state.perPage}
                                showSizeChanger={true}
                                onChange={(page, limit) => this.getTransacciones(page, this.filterForms?.current?.getFieldsValue(), limit)}

                            />
                        </Row>
                        <FloatingButton title="Nuevo registro" content={contentMenuPlus}  />
                    </Content>
                </Spin>

                <ModalTransacciones
                    visible={this.state.modalTransacVisible}
                    // visible={true}
                    transaccion_id={this.state.transaccion_id}
                    onClose={() => {
                        this.setState({ modalTransacVisible: false, transaccion_id: undefined })
                        this.getTransacciones(this.state.currentPage)
                    }}
                />
                <ModalTransaccionesDetalle
                    visible={this.state.modalTransacDetalleVisible}
                    onClose={() => this.setState({
                        modalTransacDetalleVisible: false,
                        transaccion_id: undefined
                    })}
                    id={this.state.transaccion_id}
                />
                <DrawerFiltrosTransacciones
                    ref={ref => this.drawerFiltros = ref}
                    updateFilters={filtros => this.getTransacciones(1, filtros)}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                />
                <ModalTransaccionMultiple 
                    visible={this.state.modalVisibleCSV}
                    onClose={(flag)=>{
                        this.setState({modalVisibleCSV: false})
                        this.getTransacciones(this.state.currentPage)
                    }}
                />
            </>
        )
    }
}