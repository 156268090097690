import React, { useState, useEffect, } from "react";
import { Link } from "react-router-dom";
import { Steps, Card, List, Tabs, message } from "antd";
import { FaCheckCircle, FaTimesCircle, FaClock, FaRegCircle } from 'react-icons/fa';
import { LoadingOutlined } from '@ant-design/icons';
import axios from "axios";

//componentes
import ChatCustomer from './ChatCustomer'

//css
import '../../../Styles/Modules/Customer/StepTracker.css';

const { Step } = Steps;
const { TabPane } = Tabs

/**
 * @const StepTracker
 * @extends {Component}
 * @description StepTracker del Cliente
 */
const StepTracker = React.forwardRef((props, ref) => {

    let { orden_id, orden_compra_id } = props

    /** @type Number Step actual activo */
    const [loading, setLoading] = useState(false);

    /** @type {*} Información de la orden */
    const [orden, setOrden] = useState({});
    const [ordenDetalles, setOrdenDetalles] = useState({});



    /**
     *
     * @const StepTracker
     * @description Actualiza el StepTracker segun la orden cargada
     */
    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        getOrden()
    }, [orden_id])

    const getOrden = () => {
        setLoading(true)
        axios.get('/ordenes/get', {
            params: {
                id: orden_id,
                detalles: true
            }
        })
            .then(({ data }) => {
                setOrden(data.data)
                setOrdenDetalles(data.detalles)
            })
            .catch((error) => {
                console.log(error.request)
                message.error("No fue posible obtener la orden")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const renderStatus = (key = 1) => {

        const style = {
            fontSize: 25,
            position: 'relative',
            bottom: 3
        }

        if (typeof key === "boolean")
            return (key) ? <FaCheckCircle style={style} className="step-icon" /> : <FaRegCircle style={{ ...style, color: "#2C3E50" }} />//circulo sin relleno 

        switch (key) {
            case 0:
                //Non started
                return <FaRegCircle style={{ ...style, color: "#2C3E50" }} />//circulo sin relleno

            case 1:
                //Success
                return <FaCheckCircle style={style} className="step-icon" />//check

            case 2:
                //In progress
                return <FaClock style={{ ...style, color: "#FFC107" }} />//reloj
            case 3:
                //Error
                return <FaTimesCircle style={{ ...style, color: "#DC3545" }} />//X

            case 4:
                //LOADING
                return <LoadingOutlined style={style} spin />

        }
    }

    // 0 - Sin iniciar
    // 1 - Completado
    // 2 - Progreso
    // 3 - Error

    const getSteps = () => {
        let steps = []
        if (orden.tipo_orden === 1) {
            steps = [
                {
                    title: "Cotización",
                    steps: [
                        {

                            check: () => !!(orden.rfq_id),
                            task: 'Formulario de Cliente',
                        },
                        {
                            check: () => orden.cotizacion_id?.status > 2 ? true : false,
                            task: <Link to={"/customer/misPedidos/"+ orden?._id + "/cotizaciones"}>Aprobación o Rechazo</Link>,
                        },
                    ],
                    className: "step-color-blue",
                },
                {

                    title: "Accounting",
                    steps: [
                        {
                            check: e => orden.pago_recibido,
                            task: <Link to={`/customer/misPedidos/${orden._id}/accounting`} >Orden de Compra</Link>,
                        },

                    ],
                    className: "step-color-yellow",
                },
                {
                    title: "Logística",
                    steps: [
                        {
                            check: e => {
                                if (orden.logistica_estatus == 0) return 1
                                if (orden.montos?.costo_total > 0 && orden.montos?.costo_total === orden.montos?.monto_pagado_total) return 2
                                return 0
                            },
                            task: <Link to={`/customer/misPedidos/${orden._id}/produccion`} >Producción</Link>,
                        },
                        {
                            check: e => {
                                if (orden.logistica_estatus >= 1) return 1
                                if (orden.montos?.costo_total > 0 && orden.montos?.costo_total === orden.montos?.monto_pagado_total) return 2
                                return 0
                            },
                            task: <Link to={`/customer/misPedidos/${orden._id}/transito`} >Orden de Embarque</Link>,
                        },
                        {
                            check: e => {
                                if (orden.logistica_estatus >= 2) return 1
                                if (orden.montos?.costo_total > 0 && orden.montos?.costo_total === orden.montos?.monto_pagado_total) return 2
                                return 0
                            },
                            task: <Link to={`/customer/misPedidos/${orden._id}/entrega`} >Entrega</Link>,
                        },
                    ],
                    className: "step-color-pink",
                },
                {
                    title: "Orden Completa",
                    className: "step-color-green",
                },
            ]
        }
        if (orden.tipo_orden === 2) {
            steps = [
                {
                    title: "Logística",
                    steps: [
                        {
                            check: e => {
                                if (orden.logistica_estatus > 2) return 1
                                if (orden.montos?.costo_total > 0 && orden.montos?.costo_total === orden.montos?.monto_pagado_total) return 2
                                return 0
                            },
                            task: <Link to={`/customer/misPedidos/${orden._id}/produccion`} >Producción</Link>,
                        },
                        {
                            check: e => {
                                if (orden.logistica_estatus >= 1) return 1
                                if (orden.montos?.costo_total > 0 && orden.montos?.costo_total === orden.montos?.monto_pagado_total) return 2
                                return 0
                            },
                            task: <Link to={`/customer/misPedidos/${orden._id}/transito`} >Orden de Embarque</Link>,
                        },
                        {
                            check: e => {
                                if (orden.logistica_estatus >= 2) return 1
                                if (orden.montos?.costo_total > 0 && orden.montos?.costo_total === orden.montos?.monto_pagado_total) return 2
                                return 0
                            },
                            task: <Link to={`/customer/misPedidos/${orden._id}/entrega`} >Entrega</Link>,
                        },
                    ],
                    className: "step-color-pink",
                },
                {
                    title: "Orden Completa",
                    className: "step-color-green",
                },
            ]
        }
        return steps
    }


    const renderList = (list = []) => <List
        itemLayout="horizontal"
        dataSource={list}
        bordered={false}
        className="width-100 card-task-list list-border-none"
        locale={{ emptyText: ' ' }}
        renderItem={({ check, task }) => (
            <List.Item >
                <List.Item.Meta
                    className="card-task-list-meta"
                    avatar={renderStatus((loading === true) ? 4 : check())}
                    title={task}
                />
            </List.Item>
        )}
    />

    React.useImperativeHandle(ref, () => ({ getOrden }));


    return <Card className="card-shadow step-card-customer" >

        <Tabs defaultActiveKey="1">
            <TabPane tab="Project Tracker" key="1">
                <Steps direction="vertical" className="step-tracker">
                    {getSteps().map(({ title, steps, className }, index) => <Step
                        key={'step-' + index}
                        title={title}
                        description={renderList(steps)}
                        className={className}
                    />)}
                </Steps>
            </TabPane>

            <TabPane tab="Chat" key="2">
                <ChatCustomer orden_id={orden._id} orden={orden} />
            </TabPane>
        </Tabs>

    </Card>
})


export default StepTracker