import React, { Component } from "react";
import { Row, Col, Modal, Typography, Spin, Image, message } from 'antd';


const { Title, Text } = Typography;
const axios = require('axios').default;
const moment = require('moment');



/**
 *
 *
 * @class ModalDetalleOrdenCompra
 * @extends {Component}
 */
class ModalDetalleOrdenCompra extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            recepcion_entrega: {
                orden_detalles: []
            }

        }
    }

    ModalDetalleOrdenCompra = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getRecepcion()
    }

    /**
     *
     *
     * @memberof ModalDetalleOrdenCompra
     * @method getRecepcion
     * @description Obtiene la recepcion
     */
    getRecepcion = () => {
        axios.get('/ordenesCompra/recepcion', {
            params: {recepcion_entrega_id: this.props.recepcion_entrega_id}
        })
            .then(({data}) => {

                const recepcion_entrega = data.data
                let existencias = 0
                let cantidad = 0

                for(const existencia of recepcion_entrega.ordenes_detalles){
                    existencias += existencia.recepciones.cantidad
                    cantidad += (this.props.recepcion ? (existencia.cantidad - existencia.cantidad_existente) : (existencia.cantidad))
                }

                this.setState({
                    recepcion_entrega,
                    existencias,
                    cantidad
                })
            })
            .catch(err => {
                message.error('Error al crear la recepcion')
            })
    }

    render() {
        const { fecha, ordenes_detalles, nombre, firma, tipo } = this.state.recepcion_entrega || {}
        const { disabled, existencias, cantidad } = this.state;
        return (
            <Col xs={24}>
                <div className="center">
                    <Title level={3} className="text-center">{tipo === 0 ? "Recepción" : "Entrega"}</Title>
                </div>
                <Spin spinning={this.state.loading}>
                    <Row justify="space-between" style={{margin: "1rem 0"}}>
                        <Col xs={24} lg={10} style={{textAlign: "center"}}>
                            <Text className="text-gray">Creación de Folio</Text><br/>
                            <Text>{moment(fecha).format('DD-MM-YYYY')}</Text>
                        </Col>
                        <Col xs={24} lg={10} style={{textAlign: "center"}}>
                            <Text className="text-gray">Productos {tipo === 0 ? "Recibidos" : "Entregados"}</Text><br/>
                            <Text>{`${existencias}/${cantidad}`}</Text>
                        </Col>
                    </Row>
                    <Row style={{margin: "1rem 0"}}>
                        <Col xs={24} style={{textAlign: "center"}}>
                            <Text className="text-gray">Productos {tipo === 0 ? "Recibidos" : "Entregados"}</Text>
                        </Col>
                    </Row>
                    <Row justify="center" gutter={[0, 12]} style={{margin: "1rem 0"}}>
                       {
                           ordenes_detalles?.map(producto => (
                            <Col xs={24} lg={14}>
                                <Row justify="space-between">
                                    <Col><Text>{producto?.producto_id?.sku}</Text></Col>
                                    <Col><Text>{producto?.recepciones?.cantidad}</Text></Col>
                                </Row>
                            </Col>
                           ))
                       }
                    </Row>
                    <Row>
                        <Col xs={24} style={{textAlign: "center", margin: "1.5rem 0 0 0"}} >
                            <Text className="text-gray">{tipo === 0 ? "Recepción" : "Entrega"}</Text>
                        </Col>
                    </Row>
                    <Row justify="space-between" style={{margin: "1rem 0"}}>
                        <Col xs={24} lg={10} style={{textAlign: "center"}}>
                            <Text className="text-gray">Firmó</Text><br/>
                            <Text>{nombre}</Text>
                        </Col>
                        <Col xs={24} lg={10} style={{textAlign: "center"}}>
                            <Text className="text-gray">Firma</Text><br/>
                           {
                               firma ? (
                                <Image
                                    height={100}
                                    src={axios.defaults.baseURL + "/upload/" + firma}
                                />
                               ) : null
                           }
                        </Col>
                    </Row>
                </Spin>
            </Col>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, factura_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        
        <ModalDetalleOrdenCompra {...props} />
    </Modal>

}