import React, { Component } from 'react';
import { Button, Popconfirm, Tooltip, Space, Card, Row, List, Col, Spin, PageHeader, Layout, message, Typography } from 'antd'
import axios from 'axios';

//Componentes
import { IconDelete, IconEdit } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalOrdenes from './ModalOrdenes'


const { Content } = Layout;
const { Text } = Typography

/**
 * @export
 * @class Ordenes
 * @extends {Component}
 * @description Vista de Ordenes 
 */
export default class Ordenes extends Component {

    volver = () => {
       this.props.history.goBack();
    }

    constructor(props) {
        super(props)
        this.state = {
            modalOrdenes: false,

            ordenes: {
                data: [],
                
                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
                search: null
            },

            almacen_id: this.props.match.params.almacen_id,
            inventario_id: this.props.match.params.inventario_id,
            almacen_producto_id: undefined,
            almacen: {
                direccion: ''
            },
 
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenes()
    }

    /**
    * @memberof ModalTransacciones
    * @method getOrdenes
    * @description Trae los negocios
    * 
    */
    getOrdenes = (
        {            
            page = this.state.ordenes.page,
            limit = this.state.ordenes.limit,
            search = this.state.ordenes.search
        } = this.state.ordenes
    ) => {

        this.setState({ ordenes: {...this.state.ordenes, page, limit, search} })
        axios.get('/ordenes', {
            params: {
                page, limit, search
            }
        }).then(response => {
            this.setState({

                ordenes: {
                    ...this.state.ordenes,
                    ...response.data.data, 
                }
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Ordenes')
        })
    }



    render() {
        const { ordenes } = this.state
        return (
            <Spin spinning={false}>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={`Ordenes`}
                />
                <Content className="admin-content content-bg pd-1">
                    <List
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Ordenes " }}
                        dataSource={ordenes.data}
                        pagination={{
                            onChange: (page, limit) => this.getOrdenes({ page }),
                            current: ordenes.page,
                            pageSize: ordenes.limit,
                            total: ordenes.total,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className: "flex-left"
                        }}
                        header={<Row className="header-list width-100 pr-1 pl-1" >
                            <Col lg={8} className="center">
                                <Text strong>Folio</Text>
                            </Col>
                            <Col lg={6} className="center">
                                <Text strong>Cliente</Text>
                            </Col>
                            <Col lg={6} className="center">
                                <Text strong>Agente</Text>
                            </Col>
                            <Col lg={4} className="center">
                                <Text strong>Acciones</Text>
                            </Col>
                        </Row>
                        }
                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">
                                        <Col lg={8} className="center">
                                            <Text>{item.folio}</Text>
                                        </Col>
                                        <Col lg={6} className="center">
                                            <Text>{item.cliente_id?.nombre}</Text>
                                        </Col>
                                        <Col lg={6} className="center">
                                            <Text>{item.agente_compras_id?.nombre}</Text>
                                        </Col>
                                        <Col lg={4} className="center">
                                            <Space>
                                                <Tooltip title="Editar">
                                                    <Button type="primary" icon={<IconEdit />} onClick={() => this.setState({
                                                        modalOrdenes: true,
                                                        orden_id: item._id,
                                                    })} />
                                                </Tooltip>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar este Producto  del Almacen?"
                                                    onConfirm={() => axios.post('/ordenes/delete', { id: item._id })
                                                        .then((response) => {
                                                            message.success("Eliminado Correctamente")
                                                            this.getOrdenes()
                                                        })
                                                        .catch((error) => {
                                                            console.log()
                                                            message.error(error.response?.data?.message ? error.response.data.message : "No es posible eliminar")
                                                            this.getOrdenes();
                                                        })
                                                    }
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button type="normal" danger icon={<IconDelete />} title="Eliminar" />
                                                </Popconfirm>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalOrdenes: true })} />
                </Content>
                <ModalOrdenes
                    visible={this.state.modalOrdenes}
                    onCancel={() => {
                        this.setState({ modalOrdenes: false, orden_id: undefined })
                        this.getOrdenes()
                    }}
                    orden_id={this.state.orden_id}
                />
            </Spin>
        )
    }
}