
import React from 'react';
import { Drawer,Form, Select, Cascader, message, Badge } from 'antd';
import axios from 'axios';
import CAvatar from '../../../Widgets/Avatar/Avatar';


const { Option } = Select

/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de transacciones
 */
export default class FiltrosDrawer extends React.Component {

    /**
     *
     *
     * @export
     * @class FiltrosDrawer
     * @extends {React.Component}
     * 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state ordenes Objeto para paginar y buscar en el select de ordenes
     * @state ordenes_compras Objeto para paginar y buscar en el select de ordenes de compra
     * @state areas Objeto para paginar y buscar en el select de areas  
     * @state areas_dictionary Diccionario de Areas. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state rubros_dictionary Diccionario de Rubros. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state razones_sociales Objeto para paginar y buscar en el select de Razones Sociales
     * @state clasificadores Objeto para paginar y buscar en el select de Clasificadores 
     * 
     * @state paginator.data Lista de elementos para paginar
     * @state paginator.dictionary Ya que los selects funcionan solo con ID´s, utilizamos el diccionario para obtener el objeto al que el ID hace referencia
     * @state paginator.page Pagina actual
     * @state paginator.limit Limit de elementos por página
     * @state paginator.pages Total de paginas
     * @state paginator.total Total de Elementos en la lista
     * @state paginator.search Para buscar
     * @state paginator.search Loading, para declarar el actualizado.
     */
    state = {

        cuentas: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },
        clientes: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        ordenes: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        ordenes_compras: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },


        areas: [],
        areas_dictionary: {},
        rubros_dictionary: {},

        razones_sociales: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        clasificadores: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },
    }


    /**
     *
     *
     * @memberof FiltrosDrawer
     * @methodof componentDidMount
     * @description Obtenemos todas las listas
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCuentas()

        this.getClientes()
        this.getOrdenes()
        this.getOrdenesCompras()

        this.getAreas();
        this.getRazonesSociales()
        this.getClasificadores()

    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * 
     * @description Para obtener las cuentas en la lista
     */
    getCuentas = ({ page, limit, search } = this.state.cuentas, { cuentas } = this.state) => {

        cuentas.loading = true;
        cuentas.page = page;
        cuentas.limit = limit;

        this.setState({ cuentas })

        axios.get('/cuentas/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                // console.log('response', response.data.data)
                cuentas.data = (page === 1) ? response.data.data.itemsList : [...cuentas.data, ...response.data.data.itemsList];

                cuentas.dictionary = {}
                cuentas.data.map(d => cuentas.dictionary[d._id] = d)

                cuentas.total = response.data.data.paginator.itemCount
                cuentas.pages = response.data.data.paginator.pageCount
                cuentas.loading = false;

                this.setState({ cuentas })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.cuentas.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method getClientes
     * 
     * @description Para obtener los clientes en la lista
     */
    getClientes = ({ page, limit, search } = this.state.clientes, { clientes } = this.state) => {

        clientes.loading = true;
        clientes.page = page;
        clientes.limit = limit;

        this.setState({ clientes })

        axios.get('/clientes/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                // console.log('response', response.data.data)
                clientes.data = (page === 1) ? response.data.data.itemsList : [...clientes.data, ...response.data.data.itemsList];

                clientes.dictionary = {}
                clientes.data.map(d => clientes.dictionary[d._id] = d)

                clientes.total = response.data.data.paginator.itemCount
                clientes.pages = response.data.data.paginator.pageCount
                clientes.loading = false;

                this.setState({ clientes })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.clientes.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method getOrdenes
     * 
     * @description Para obtener las ordenes en la lista
     */
    getOrdenes = ({ page, limit, search, clientes_ids } = this.state.ordenes, { ordenes } = this.state) => {

        ordenes.loading = true;
        ordenes.page = page;
        ordenes.limit = limit;
        ordenes.clientes_ids = clientes_ids

        this.setState({ ordenes })

        // console.log('clientes_ids', clientes_ids)
        axios.get('/ordenes', {
            params: {
                page, limit, search, cliente_id: clientes_ids
            }
        })
            .then(response => {

                // console.log('response', response.data.data)
                ordenes.data = (page === 1) ? response.data.data.itemsList : [...ordenes.data, ...response.data.data.itemsList];

                ordenes.dictionary = {}
                ordenes.data.map(d => ordenes.dictionary[d._id] = d)

                ordenes.total = response.data.data.paginator.itemCount
                ordenes.pages = response.data.data.paginator.pageCount
                ordenes.loading = false;

                this.setState({ ordenes })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.ordenes.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method getOrdenesCompras
     * 
     * @description Para obtener las ordenes de compra en la lista
     */
    getOrdenesCompras = ({ page, limit, search, orden_id } = this.state.ordenes_compras, { ordenes_compras } = this.state) => {

        ordenes_compras.loading = true;
        ordenes_compras.page = page;
        ordenes_compras.limit = limit;

        this.setState({ ordenes_compras })

        axios.get('/ordenesCompra/list', {
            params: {
                page, limit, search, orden_id
            }
        })
            .then(response => {

                // console.log('response', response.data.data)
                ordenes_compras.data = (page === 1) ? response.data.data.itemsList : [...ordenes_compras.data, ...response.data.data.itemsList];

                ordenes_compras.dictionary = {}
                ordenes_compras.data.map(d => ordenes_compras.dictionary[d._id] = d)

                ordenes_compras.total = response.data.data.paginator.itemCount
                ordenes_compras.pages = response.data.data.paginator.pageCount
                ordenes_compras.loading = false;

                this.setState({ ordenes_compras })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.ordenes_compras.loading = false
                    return state
                })
            })
    }


    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method getAreas
     * 
     * @description Para obtener las areas en la lista
     */
    getAreas = (search) => {
        return axios.get('/areas/list', {
            params: {
                search,
                paginate: false
            }
        }).then(response => {

            let areas_dictionary = {}
            let areas = response.data.data.map(area => {

                areas_dictionary[area._id] = area
                return ({
                    value: area._id, label: area.nombre, isLeaf: false
                })
            })

            this.setState({
                areas,
                areas_dictionary
            })
        }).catch(error => {
            console.log('error al traer areas', error)
        })
    }

    
    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method loadRubros
     * 
     * @description Cargamos los rubros
     */
    loadRubros = selectedOptions => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        // console.log(targetOption)
        targetOption.loading = true;

        let areas = this.state.areas

        return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: targetOption.value
            }
        })
            .then(response => {
                targetOption.loading = false;

                let index = areas.findIndex(area => area.value.toString() === targetOption.value.toString())
                let rubros = response.data.data;

                if (index !== -1 && rubros.length > 0) {


                    let rubros_dictionary = {}
                    let areas_temp = rubros.map(rubro => {
                        // areas_dictionary._id
                        rubros_dictionary[rubro._id] = rubro
                        return ({ label: rubro.nombre, value: rubro._id })
                    })

                    areas[index].children = areas_temp
                    this.setState({ areas: [...areas], rubros_dictionary: { ...this.state.rubros_dictionary, ...rubros_dictionary } });

                    // areas[index].children = rubros.map(rubro => ({ label: rubro.nombre, value: rubro._id }))
                    // this.setState({ areas: [...areas] });
                } else {
                    message.error('Rubros no encontrados')
                }


            })
            .catch(error => {
                console.log(error)
                message.error('Error al cargar los rubros')
            })

    };

      
    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method getRazonesSociales
     * 
     * @description Obtenemos las razones sociales
     */
    getRazonesSociales = ({ page, limit, search } = this.state.razones_sociales, { razones_sociales } = this.state) => {

        razones_sociales.loading = true;
        razones_sociales.page = page;
        razones_sociales.limit = limit;

        this.setState({ razones_sociales })

        axios.get('/razones-sociales', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                // console.log('response', response.data.data)
                razones_sociales.data = (page === 1) ? response.data.data.itemsList : [...razones_sociales.data, ...response.data.data.itemsList];

                razones_sociales.dictionary = {}
                razones_sociales.data.map(d => razones_sociales.dictionary[d._id] = d)

                razones_sociales.total = response.data.data.paginator.itemCount
                razones_sociales.pages = response.data.data.paginator.pageCount
                razones_sociales.loading = false;

                this.setState({ razones_sociales })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.razones_sociales.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method getClasificadores
     * 
     * @description Obtenemos los clasificadores
     */
    getClasificadores = ({ page, limit, search } = this.state.clasificadores, { clasificadores } = this.state) => {

        clasificadores.loading = true;
        clasificadores.page = page;
        clasificadores.limit = limit;
        // clasificadores
        this.setState({ clasificadores })

        axios.get('/clasificadores/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                // console.log('response', response.data.data)
                clasificadores.data = (page === 1) ? response.data.data.itemsList : [...clasificadores.data, ...response.data.data.itemsList];


                clasificadores.dictionary = {}
                clasificadores.data.map(d => clasificadores.dictionary[d._id] = d)

                clasificadores.total = response.data.data.paginator.itemCount
                clasificadores.pages = response.data.data.paginator.pageCount
                clasificadores.loading = false;

                this.setState({ clasificadores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.clasificadores.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method getClasificadores
     * 
     * @description Permite obtener el arreglo para poder mostrarlo en la lista de guardados. 
     */
    submit = ({ area_rubro, clasificadores, clientes, cuentas, ordenes, ordenes_compras, razones_sociales }) => {

        let filtros = []
        // clasificadores, clientes, cuentas, ordenes, ordenes_compras, razones_sociales

        console.log('trans submit filter',clientes, cuentas,)
        //Metodo para añadir al areglo de filtros.
        let addToFilter = (name, objectName, array) => array.map(element => filtros.push({
            _id: element,//clasificador,
            name: name,
            objectName,
            filter: this.state[objectName].dictionary[element]
        }))

        if (clasificadores) addToFilter("clasificadores", "clasificadores", clasificadores)
        if (clientes) addToFilter("cliente_id", "clientes", clientes)
        if (cuentas) addToFilter("cuenta_id", "cuentas", cuentas)
        if (ordenes) addToFilter(["ordenes.orden_id", "ordenes.orden_id"], "ordenes", ordenes)
        if (ordenes_compras) addToFilter("ordenes_compra.orden_compra_id", "ordenes_compras", ordenes_compras)
        if (razones_sociales) addToFilter("razon_social_id", "razones_sociales", razones_sociales)

        if (area_rubro)
            for (const ar of area_rubro) {

                if (ar.length === 1) {
                    filtros.push({
                        _id: ar[0],//clasificador,
                        name: "area_id",
                        objectName: "areas",
                        filter: this.state.areas_dictionary[ar[0]]
                    })
                } else {
                    for (let index = 0; index < ar.length; index++) {
                        if (index === 0) continue;
                        const element = ar[index];
                        filtros.push({
                            _id: element,//clasificador,
                            name: "rubro_id",
                            objectName: "rubros",
                            filter: this.state.rubros_dictionary[element]
                        })
                    }
                }
            }


        if (this.props.updateFilters)
            this.props.updateFilters(filtros)
    }

    render() {

        const { onClose, visible } = this.props

        const { clientes, cuentas, razones_sociales, clasificadores, ordenes, ordenes_compras } = this.state

        return (<Drawer title="Filtrar Transacciones" placement="right" onClose={onClose} visible={visible}>
            <Form
                ref={ref => this.formFiltros = ref}
                layout='vertical'
                onValuesChange={(x, values) => this.submit(values)}
                onFinish={this.submit}
            >
                <Form.Item label="Cuentas" name="cuentas">
                    <Select
                        allowClear
                        mode="multiple"
                        showSearch
                        filterOption={false}
                        onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !cuentas?.loading && cuentas.page < clientes.pages) ? this.getCuentas({ page: clientes.page + 1 }) : null}
                        onSearch={search => this.getCuentas({ search, page: 1 })}
                    >
                        {cuentas.data.map(cuenta => <Option key={cuenta._id} value={cuenta._id}>
                            <CAvatar
                                size='small'
                                image={cuenta.logo}
                                name={cuenta.nombre}
                                color={cuenta.color}
                            /> &nbsp;{cuenta.nombre}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Clientes" name="clientes">
                    <Select
                        allowClear
                        mode="multiple"
                        showSearch
                        filterOption={false}

                        onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !clientes?.loading && clientes.page < clientes.pages) ? this.getClientes({ page: clientes.page + 1 }) : null}
                        onSearch={search => this.getClientes({ search, page: 1 })}

                        onSelect={() => this.getOrdenes({ clientes_ids: this.formFiltros.getFieldValue('clientes'), page: 1 })}
                    >
                        {clientes.data.map(cliente => <Option key={cliente._id} value={cliente._id}>{cliente.razon_social || cliente.nombre || cliente.email}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Ventas / Ordenes" name="ordenes">
                    <Select
                        allowClear
                        mode="multiple"
                        showSearch
                        filterOption={false}

                        onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !ordenes?.loading && ordenes.page < ordenes.pages) ? this.getOrdenes({ page: ordenes.page + 1 }) : null}
                        onSearch={search => this.getOrdenes({ search, page: 1 })}

                        onSelect={() => this.getOrdenesCompras({ orden_id: this.formFiltros.getFieldValue('ordenes'), page: 1 })}
                    >
                        {ordenes.data.map(orden => <Option key={orden._id} value={orden._id}>{orden.folio}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Ordenes de Compra" name="ordenes_compras">
                    <Select
                        allowClear
                        mode="multiple"
                        showSearch
                        filterOption={false}

                        onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !ordenes_compras?.loading && ordenes_compras.page < ordenes_compras.pages) ? this.getOrdenesCompras({ page: ordenes_compras.page + 1 }) : null}
                        onSearch={search => this.getOrdenesCompras({ search, page: 1 })}
                    >
                        {ordenes_compras.data.map(orden_compra => <Option key={orden_compra._id} value={orden_compra._id}>{orden_compra.folio}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Areas / Rubros" name="area_rubro">
                    <Cascader
                        multiple={true}
                        placeholder="Seleccione el Area y Rubro"
                        options={this.state.areas}
                        loadData={this.loadRubros}
                        changeOnSelect
                    />
                </Form.Item>
                <Form.Item label="Razones Sociales" name="razones_sociales">
                    <Select
                        allowClear
                        mode="multiple"
                        showSearch
                        filterOption={false}
                        onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !razones_sociales?.loading && razones_sociales.page < razones_sociales.pages) ? this.getRazonesSociales({ page: razones_sociales.page + 1 }) : null}
                        onSearch={search => this.getClientes({ search, page: 1 })}
                    >
                        {razones_sociales.data.map(razon_social => <Option key={razon_social._id} value={razon_social._id}>        <CAvatar
                            size='small'
                            image={razon_social.logo}
                            name={razon_social.razon_social}
                            color={razon_social.color}
                        /> &nbsp; {razon_social.razon_social}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Categorias" name="clasificadores">
                    <Select
                        allowClear
                        mode="multiple"
                        showSearch
                        filterOption={false}
                        onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !clientes?.loading && clientes.page < clientes.pages) ? this.getClientes({ page: clientes.page + 1 }) : null}
                        onSearch={search => this.getClientes({ search, page: 1 })}
                    >
                        {clasificadores.data.map(clasificador => <Option key={clasificador._id} value={clasificador._id}><Badge color={clasificador.color} />{clasificador.nombre}</Option>)}
                    </Select>
                </Form.Item>
            </Form>
        </Drawer>)
    }
};
