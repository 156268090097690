import React from 'react'
import { Route, Switch } from "react-router-dom";


// componentes
import VMI from '../../components/Customer/VMI/VMIDashboard'
import Comprar from '../../components/Customer/VMI/Comprar'
import Productos from '../../components/Customer/VMI/Productos'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterVMI(props) {

    return (
        <Switch>
            <Route exact path="/customer/VMI" render={() => <VMI {...props} />} />
            <Route path="/customer/VMI/comprar" render={() => <Comprar {...props} />} />
            <Route path="/customer/VMI/productos" render={() => <Productos {...props} />} />
        </Switch>
    )
}

export default RouterVMI;
