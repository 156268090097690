
import React, { Component } from "react";
import { message, Button, Col, Space, List, Row, Typography, Dropdown, Menu, Tag } from "antd";
import { EllipsisOutlined, FlagFilled, PaperClipOutlined } from "@ant-design/icons";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import ModalOrdenEmbarque from '../OrdenEmbarque/ModalOrdenEmbarque';

import "../../../../Styles/Modules/ProjectManager/ProjectManager.scss"

const { Title, Text } = Typography;
const axios = require('axios').default;
const moment = require('moment')

/**
 *
 *
 * @export
 * @class OrdenEmbarque
 * @extends {Component}
 */
export default class OrdenEmbarque extends Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 0,
            orden: {},
            ordenes_compra: [],
            orden_compra_id: undefined,
            visible: false
        }
    }

    componentDidMount = () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrden();
    }

    componentDidUpdate(prevProps) {

        if(this.props.match.params.orden_id && this.props.match.params.orden_id !== this.state.orden_id){
            this.setState({orden_id: this.props.match.params.orden_id},()=>{
                this.getOrden();
            })
        }
    }

    getOrden = () => {
        this.setState({ loading: true })
        axios.get('/project-manager/orden', {
            params: {
                id: this.props.match.params.orden_id
            }
        }).then(response => {

            let ingresos = response.data.data.margen?.ingresos ? response.data.data.margen.ingresos : 0
            let costos = response.data.data.margen?.costos ? response.data.data.margen?.costos : 0

            let margen = (ingresos - costos) / ingresos * 100
            this.setState({
                orden: response.data.data,
                percentage: response.data.data.percentage,
                productos: response.data.data.orden_detalles,
                margen: margen.toMoney()
            }, () => {
                this.getOrdenesCompra();
            })
        })
            .catch(error => {
                console.log(error)
                message.error('Error al cargar la informacion')
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     * 
     * @method getOrdenesCompra
     * @memberof OrdenEmbarque
     * @description Obtener las ordenes de compra de la orden
     */
    getOrdenesCompra = () => {
        this.setState({loading: true})
        axios.get('/ordenesCompra/list', {
            params: {
                orden_id: this.props.match?.params?.orden_id
            }
        })
            .then(({ data }) => {
                console.log(data)
                const ordenes_compra = data.data.itemsList
                const orden = this.state.orden

                let recepciones = false
                let entregas = false

                if (ordenes_compra.length > 0) {
                    recepciones = true
                    entregas = true

                    for (const orden_compra of ordenes_compra) {
                        if (orden_compra.estatus < 4) {
                            recepciones = false
                            entregas = false
                        } else if (orden_compra.estatus < 6) {
                            entregas = false
                        }
                    }
                }

                if (recepciones && this.state.orden?.logistica_estatus === 0) {
                    this.updateLogisticaEstatus(1)
                }

                if (recepciones && entregas && this.state.orden?.logistica_estatus === 1) {
                    this.updateLogisticaEstatus(2)
                }

                if (orden.tipo_orden === 2 && orden.logistica_estatus < 2 && orden.orden_detalles.length === 1 && orden.orden_detalles[0].cantidad_entregada === orden.orden_detalles[0].cantidad){
                    this.updateLogisticaEstatus(2)
                }

                this.setState({
                    ordenes_compra
                })
            })
            .catch(error => {
                message.error("Error al obtener Ordenes de Compras")
                console.log(error)
            }).finally(()=>this.setState({loading: true}))
    }
    /**
     *
     *
     * @param String id 
     * @memberof OrdenEmbarque
     * @method updateCotizacionEstatus
     * @description Actualiza la cotizacion para que tenga el estatus de completado
     */
    updateCotizacionEstatus = (id) => {
        axios.put('/cotizacion/update', {
            id,
            status: 6
        })
            .then(success => {
                message.success('Cotización actualizada')
            })
            .catch(error => {
                message.error('Error al actualizar cotización')
            })
    }

    /**
     *
     *
     * @param Number estatus
     * @memberof OrdenEmbarque
     * @method updateLogisticaEstatus
     * @description Actualiza el estatus de logistica en la orden
     */
    updateLogisticaEstatus = (estatus) => {
        axios.post('/ordenes/update', {
            id: this.props.match?.params?.orden_id,
            logistica_estatus: estatus
        })
            .then(success => {
                message.success('Orden actualizada')
                window.location.replace('')

                if(estatus === 3 && this.state.orden?.cotizacion_id){
                    this.updateCotizacionEstatus(this.state.orden.cotizacion_id)
                }
            })
            .catch(error => {
                message.error('Error al actualizar orden')
            })
    }

    /**
     *
     * @method openModal
     * @param {*} id string con el identificador de la orden
     * @memberof OrdenEmbarque
     * @description Abre el formulario para la edicion de las ordenes de compra
     */
    openModal = (id) => {

        this.setState({
            visible: true,
            orden_compra_id: id
        })
    }

    /**
     *
     * @method getMenu
     * @param {*} id string con el identificador de la orden
     * @memberof OrdenEmbarque
     * @description Obtiene un Menu para el overlay del Dropdown
     */
    getMenu = (id) => {

        const faltante = this.state.productos.find(producto => producto.orden_compra_id === id && producto.cantidad_faltante > 0)
        const hasRecepciones = (faltante && this.props.vmi) || !this.props.vmi
        
        return(
            <Menu>
                <Menu.Item onClick={() => this.openModal(id)}>
                    Editar Orden
                </Menu.Item>
                {
                      hasRecepciones ? (
                        <Menu.Item onClick={() => { this.redirect(id, 'recepcion') }}>
                            Ver Recepciones
                        </Menu.Item>
                    ) : null
                }
                <Menu.Item onClick={() => this.redirect(id, 'entrega')}>
                    Ver Entregas
                </Menu.Item>
            </Menu>
        )
    }

    /**
     *
     *
     * @memberof OrdenEmbarque
     * @method finishOrden
     * @description Finaliza con la orden embarque
     */
    finishOrden = () => {
        this.updateLogisticaEstatus(3)
    }

    /**
     *
     *
     * @param String orden_compra_id
     * @param String section
     * @memberof OrdenEmbarque
     * @method redirect
     * @description Redirige a otra seccion de logistica
     */
    redirect = (orden_compra_id, section) => {
        let orden_id = this.props.match?.params?.orden_id

        this.props.history.push(`/admin/project-manager/logistica/${orden_id}/${orden_compra_id}?tab=${section}`);
    }

    render() {

        const { agente_compras_id } = this.state.orden

        return (
            <>
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.ordenes_compra}
                    header={
                        <Row justify="space-between">
                            <Col span={18} md={24} lg={14} xxl={{ span: 18 }}><Title level={3}>Orden de Embarque </Title></Col>
                            <Col span={5} md={20} lg={7} xxl={{ span: 5 }} >
                                <Button
                                    type="primary" 
                                    // purple
                                onClick={this.finishOrden} size="large" className={"btn" + ((this.state.orden?.logistica_estatus !== 2) ? "-success" : "-purple")} block>{(this.state.orden?.logistica_estatus !== 2) ? "¡Orden Completada!" : "Completar Orden"}</Button>
                            </Col>
                        </Row>
                    }
                    renderItem={({ _id, folio, descripcion, estatus, archivos = [], createdAt }) => (
                        <List.Item className="order-list-item bg-white">
                            <Row className="width-100" style={{ padding: "0.5rem 1rem 0.5rem 1rem" }} align="middle" justify="start" >
                                <Col span={24}  >
                                    <Row className="width-100" align="middle" justify="start" >
                                        <Col md={{ span: 22, order: 1 }} xl={{ span: 22, order: 1 }} xxl={{ span: 23, order: 1 }}>
                                            <Row className="width-100" align="middle" justify="start" >
                                                <Col md={{ span: 24, order: 1 }} >
                                                    <Text ellipsis strong className="tag-card-pink"> {folio} </Text>
                                                </Col>
                                                <Col md={{ span: 8, order: 2 }} xl={{ span: 8, order: 2 }} xxl={{ span: 8, order: 2 }} >
                                                    <Text ellipsis className="text-gray">{descripcion}</Text>
                                                </Col>
                                                <Col md={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }} xxl={{ span: 8, order: 3 }} >
                                                    <Row>
                                                        {
                                                            archivos.map(archivo => (
                                                                <Col >
                                                                    <a href={`${axios.defaults.baseURL}/upload/${archivo}`} download target="_blank">
                                                                        <Button
                                                                            type="link"
                                                                            target="_blank"
                                                                            icon={<PaperClipOutlined />}
                                                                        >
                                                                            {archivo}
                                                                        </Button>
                                                                    </a>
                                                                </Col>
                                                            ))
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={{ span: 8, order: 4 }}>
                                                    <Tag color={
                                                        estatus < 3 ? "#1778B0" :
                                                        estatus === 3 ? "#cc6302" :
                                                        estatus === 4 ? "#d41585" :
                                                        estatus === 5 ? "#cc6302" : 
                                                        "#B354E0"
                                                    }
                                                        style={{
                                                            fontWeight: 600
                                                        }}
                                                    >{
                                                        estatus < 3 ? "Sin acciones" :
                                                        estatus === 3 ? "Se esta recibiendo mercancia" :
                                                        estatus === 4 ? "Se recibio la mercancia" :
                                                        estatus === 5 ? "Se esta entregando mercancia" :
                                                        "Completado"
                                                    }</Tag>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={{ span: 2, order: 2 }} xl={{ span: 2, order: 2 }} xxl={{ span: 1, order: 2 }} >
                                            <Space>
                                                <Dropdown
                                                    overlay={this.getMenu(_id)}
                                                    placement="bottomRight"
                                                    trigger={['click']}
                                                >
                                                    <Button
                                                        icon={<EllipsisOutlined className="orden-embarque-elipsis" />}
                                                        type="ghost"
                                                        className="no-border orden-embarque-button"
                                                    />
                                                </Dropdown>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24} style={{ marginTop: "1rem" }}>
                                    <Row align="middle" justify="start" >
                                        <Col span={4} >
                                            <Text ellipsis className="text-gray"><FlagFilled /> <span>{moment(createdAt).format('L')}</span></Text>
                                        </Col>
                                        {/* <Col span={4} >
                                            <Text ellipsis className="text-gray">20 días</Text>
                                        </Col> */}
                                        <Col span={4} >
                                            <Text ellipsis className="text-gray">
                                                <CustomAvatar
                                                    image={agente_compras_id?.avatar}
                                                    name={`${agente_compras_id?.nombre || "N"} ${agente_compras_id?.apellido || "A"}`}
                                                />
                                                &nbsp;&nbsp;&nbsp;{`${agente_compras_id?.nombre || "No"} ${agente_compras_id?.apellido || "Asignado"}`}
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </List.Item>
                    )}

                />

                <ModalOrdenEmbarque
                    visible={this.state.visible}
                    ordenCompraId={this.state.orden_compra_id}
                    onSuccess={() => {
                        this.setState({
                            visible: false
                        }, () => this.getOrdenesCompra())
                    }}
                    onCancel={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                />
            </>
        )
    }
}