import React, { Component } from "react";
import {
    Layout, Row, Col, Divider, Tag, Button, PageHeader, Form, Radio, Dropdown,
    Typography
} from "antd";
import { CardClientProductos, CardTaskStep } from '../../Widgets/Cards';
import { Link, } from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons'

import axios from 'axios'
import FilterSelect from "../../Widgets/Filtros/FilterSelect";

import "../../../Styles/Modules/ProjectManager/ProjectManager.scss";

const { Content } = Layout;
const { Title } = Typography;



/**
 *
 *
 * @export
 * @class ManagerInventarios
 * @extends {Component}
 * @description Vista de Project Manager
 */
class ManagerInventarios extends Component {


    /**
     *Creates an instance of ManagerInventarios.
     * @param {*} props
     * @memberof ManagerInventarios
     */
    constructor(props) {
        super(props)
        this.state = {
            modo: "vmi",
            buscar: undefined,
            dataClientes: [],
            clientes1: [],
            clientes2: [],
            clientes3: [],

            step1: [
                {
                    cliente: 'Cliente',
                    productos: {
                        nombre: 'producto'
                    }
                },
                {
                    cliente: 'Cliente',
                    productos: {
                        nombre: 'producto'
                    }
                },
                {
                    cliente: 'Cliente',
                    productos: {
                        nombre: 'producto'
                    }
                },
                {
                    cliente: 'Cliente',
                    productos: {
                        nombre: 'producto'
                    }
                },
            ],
            step2: [
                {
                    title: 'Anticipo de Cliente',
                    value: 6
                },
                {
                    title: 'Generación de Orden de Compra',
                    value: 7
                },
                {
                    title: 'Pago de Orden de Compra',
                    value: 8
                },

            ],
            step3: [
                {
                    title: 'Liquidación del Cliente',
                    value: 9
                },
                {
                    title: 'Programación de Envio',
                    value: 10
                },
                {
                    title: 'Recepción del Cliente',
                    value: 11
                }
            ],

            showFiltro: {
            }
        }
    }



    /**
     *
     *
     * @memberof ManagerInventarios
     */
    componentDidMount = () => {
        this.consultaDashboard();
    }


    /**
     *
     *
     * @memberof ManagerInventarios
     */
    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.search !== this.props.search){
            this.consultaDashboard()
        }
    }


    /**
     *
     *
     * @memberof ManagerInventarios
     */
    consultaDashboard = () => {
        axios.get('/vmi/dashboard', {
            params: {
                search: this.props.search
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        })
            .then((res) => {
                if (res.data.success) {

                    var array = res.data.data, array1 = [], array2 = [], array3 = [];
                    for (let index = 0; index < array.length; index++) {
                        const cl = array[index];

                        if (!cl) continue

                        switch (cl.tipo) {
                            case 1:
                                array1.push({ cl });
                                break;

                            case 2:
                                array2.push({ cl });
                                break;

                            case 3:
                                array3.push({ cl });
                                break;

                            default:
                                break;
                        }

                    }


                    this.setState({
                        dataClientes: res.data.data,
                        clientes1: array1,
                        clientes2: array2,
                        clientes3: array3
                    })
                }
            })
            .catch((error) => {
                console.log('error', error)
            })
    }

    onChange = e => {
        this.setState({ modo: e.target.value });
    };
    render() {
        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                                <span className="ant-page-header-heading-title" >
                                    Inventarios
                                </span>
                            </Col>
                            
                        </Row>
                    }
                />
                <Content className="content-bg pd-1">
                    <Row>
                        <Col xs={24}>
                            <Radio.Group  size="small" className="radio-blue-switch"
                                defaultValue={this.state.modo} onChange={this.onChange}>
                                <Radio.Button key={1} value={"spot"} disabled>Spot</Radio.Button>
                                <Radio.Button key={2} value={"vmi"}>VMI</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>


                    {(this.state.modo === "spot") ?
                        <section className="sec-spot" >

                            <Row className="width-100 mt-2" align="center" justify="center">
                                <Title className="center mt-3 text-gray">{"<< Modo Spot >>"}</Title>
                            </Row>
                        </section>
                        :
                        <section className="sec-vmi" >
                            <Row gutter={[8, 8]} className="width-100" align="start" justify="start">
                                <Col xs={24} sm={24} md={12} lg={12} xl={7} xxl={6} >
                                    <div className="col-list-task-margin col-list-task-bg">
                                        <CardTaskStep
                                            color={'#ED0000'}
                                            title={"Debajo del Mínimo"}
                                            position={"Cantidad de productos en el almacén"}
                                            almacen={true}
                                        />
                                        <Divider className="border-task" />
                                        {
                                            this.state.clientes1.map(cliente => {
                                                var cl = cliente.cl;
                                                return <Link to={`/admin/manager-inventarios/vendedor/${cl._id}`}>
                                                    <CardClientProductos
                                                        nombre={cl.razon_social || cl.nombre}
                                                        productos={cl.art1}
                                                        almacenes={cl.almacenes}
                                                        className="check-red"
                                                        color={'#ed0000'} />
                                                </Link>
                                            })
                                        }
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={7} xxl={6} >
                                    <div className="col-list-task-margin col-list-task-bg">
                                        <CardTaskStep
                                            color={'#DAC401'}
                                            title={"Mínimo"}
                                            position={"Cantidad de productos en el almacén"}
                                            almacen={true}
                                        />
                                        <Divider className="border-task" />
                                        {
                                            this.state.clientes2.map(cliente => {
                                                var cl = cliente.cl;
                                                return <Link to={`/admin/manager-inventarios/vendedor/${cl._id}`}>
                                                    <CardClientProductos
                                                        nombre={cl.razon_social || cl.nombre}
                                                        productos={cl.art2}
                                                        almacenes={cl.almacenes}
                                                        className="check-yellow"
                                                        color={'#DAC401'} />
                                                </Link>
                                            })
                                        }
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={7} xxl={6} >
                                    <div className="col-list-task-margin col-list-task-bg">
                                        <CardTaskStep
                                            color={'#1DD667'}
                                            title={"Arriba del Mínimo "}
                                            position={"Cantidad de productos en el almacén"}
                                            almacen={true}
                                        />
                                        <Divider className="border-task" />
                                        {
                                            this.state.clientes3.map(cliente => {
                                                var cl = cliente.cl;
                                                return <Link to={`/admin/manager-inventarios/vendedor/${cl._id}`}>
                                                    <CardClientProductos
                                                        nombre={cl.razon_social || cl.nombre}
                                                        productos={cl.art3}
                                                        almacenes={cl.almacenes}
                                                        className="check-green"
                                                        color={'#1DD667'} />
                                                </Link>
                                            })
                                        }
                                    </div>
                                </Col>
                            </Row>

                        </section>
                    }


                </Content>
            </>
        )
    }
}


export default function (props) {

    // let history = useHistory();

    return <ManagerInventarios {...props} history={null} />
}
