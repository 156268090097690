import React, { Component } from 'react';
import { Button, Popconfirm, Spin, List, Row, Col, Card, PageHeader, Layout, message, Tooltip, Typography, Space } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom";
import { BsBox } from 'react-icons/bs'
//Componentes
import { IconDelete, IconEdit, IconArrowBack } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalAlmacen from "./ModalAlmacen"

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class InventariosAlmacenes
 * @extends {Component}
 * @description Vista de InventariosAlmacenes
 */
export default class InventariosAlmacenes extends Component {

    volver = () => {
        this.props.history.goBack();
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            almacenes: [],
            inventario_id: this.props.match.params.inventario_id,
            inventario: {},
            page: 1,
            total: 0,
            filtroSearch: '',
            searching: true,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getInventario()
        this.getAlmacenes();
    }


    /**
     * @memberof ModalAreas
     * @method getInventario
     * @description Obtiene una area de la DB
     */
    getInventario = () => {
        this.setState({ loading: true })
        axios.get('/inventarios/get', {
            params: {
                id: this.props.match.params.inventario_id,
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState({ inventario: response.data.data })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información.')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalAreas
     * @method getAlmacenes
     * @description Obtiene una area de la DB
     */
    getAlmacenes = (page) => {
        this.setState({ loading: true })
        axios.get('/almacenes/list', {
            params: {
                inventario_id: this.props.match.params.inventario_id,
                page: page
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState({
                almacenes: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información.')
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <div className="flex-left">
                                <Button ghost onClick={this.volver} style={{ border: "none" }}>
                                    <IconArrowBack />
                                </Button>
                                {`Almacenes de ${this.state.inventario.cliente_id?.razon_social || this.state.inventario.cliente_id?.nombre}`}
                            </div>
                        }

                    />

                    <Content className="admin-content content-bg pd-1">
                        <Row>
                            <Col span={24}>
                                <List
                                    className="component-list"
                                    itemLayout="horizontal"
                                    locale={{ emptyText: "Sin Almacenes" }}
                                    dataSource={this.state.almacenes}
                                    pagination={{
                                        onChange: this.getAlmacenes,
                                        current: this.state.page,
                                        pageSize: 10,
                                        total: this.state.total,
                                        position: ['bottomLeft'],
                                    }}
                                    header={<Row className="header-list width-100 pr-1 pl-1" >
                                        <Col lg={20}>
                                            <Text>Dirección</Text>
                                        </Col>
                                        <Col lg={4} className="center">
                                            <Text></Text>
                                        </Col>
                                    </Row>}

                                    renderItem={item => (
                                        <List.Item className="component-list-item">
                                            <Card className="card-list">
                                                <Row className="width-100 ">
                                                    <Col xs={24} sm={18} lg={20} className="flex-left">
                                                        <Text>{item.direccion}</Text>
                                                    </Col>
                                                    <Col xs={24} sm={6} lg={4} className="center">
                                                        <Space>

                                                            <Link to={`/admin/inventarios/${this.state.inventario_id}/almacenes/${item._id}`}>
                                                                <Tooltip title="Inventario del almacen">
                                                                    <Button
                                                                        type="primary"
                                                                        icon={<BsBox style={{ position: 'relative', top: 2 }} />}
                                                                    />
                                                                </Tooltip>
                                                            </Link>

                                                            <Tooltip title="Editar">
                                                                <Button

                                                                    type="primary"
                                                                    icon={<IconEdit />}
                                                                    title="Editar"
                                                                    onClick={() => { this.setState({ modalVisible: true, almacen_id: item._id }) }}
                                                                />
                                                            </Tooltip>
                                                            <Tooltip title="Eliminar">
                                                                <Popconfirm
                                                                    placement="topRight"
                                                                    title="¿Deseas eliminar este Almacen?"
                                                                    onConfirm={() => axios.post('/almacenes/delete',
                                                                        { id: item._id }).then(() => {
                                                                            this.getAlmacenes()
                                                                            message.success('Almacen eliminado')
                                                                        }).catch(error => console.log(error))}
                                                                    okText="Si"
                                                                    cancelText="No"
                                                                >
                                                                    <Button
                                                                        type="primary"
                                                                        icon={<IconDelete />}
                                                                        title="Eliminar"
                                                                        danger
                                                                    />
                                                                </Popconfirm>
                                                            </Tooltip>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>



                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />

                    <ModalAlmacen
                        visible={this.state.modalVisible}
                        inventario_id={this.state.inventario_id}
                        almacen_id={this.state.almacen_id}
                        onClose={() => {
                            this.setState({ modalVisible: false, almacen_id: undefined })
                            this.getAlmacenes()
                        }}
                    />

                </Spin>
            </>
        )
    }
}