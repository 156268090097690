import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin } from 'antd';

const { Title } = Typography;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalAreas
 * @extends {Component}
 */
class ModalAreas  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    ModalAreas = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.area_id !== undefined) {
            this.getArea()
        }
    }


    /**
     * @memberof ModalAreas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if (this.props.area_id) {
            this.updateArea(values)
        } else {
            this.addArea(values)
        }
    }


    /**
     * @memberof ModalAreas
     * @method addArea
     * @description Añade una area a la BD
     */
    addArea = (values) => {

        if(this.state.loading) return

        this.setState({ loading: true }, () => {

            axios.post('/areas/add', {
                ...values
            }).then(response => {
            
                if (response.data.success) {
                    message.success('Area creada')
                    this.setState({loading: false}, () => this.props.onClose())
                }
                else if (response.data.message !== undefined) {
                    message.error(response.data.message.title + "  " + response.data.message.content)
                }
                else {
                    message.error('Error al crear area, verifique los datos ingresados.')
                }
            }).catch(error => {
                console.log(error)
                message.error('Error al crear area.')
            })
            .finally(() => {
                this.setState({loading: false})
            })
        })

    }


    /**
     * @memberof ModalAreas
     * @method getArea
     * @description Obtiene una area de la DB
     */
    getArea = () => {

        this.setState({ loading: true })

        axios.get('/areas/get', {
            params: {
                id: this.props.area_id
            }
        }).then(response => {

            let area = response.data.data
            this.setState({
                area: response.data.data,
            })

            this.ModalAreas.current.setFieldsValue({
                nombre: area.nombre,
                descripcion: area.descripcion,
            })

        }).catch(error => {
            message.error('Error al traer area')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalAreas
     * @method updateArea
     * @description Actualiza la información de una area
     */
    updateArea = (values) => {
        this.setState({ loading: true })
        axios.post('/areas/update', {
            ...values,
            id: this.props.area_id,
        }).then(response => {

            if (response.data.success) {
                message.success('Area Actualizada')
                this.props.onClose()
            }
            else if (response.data.message !== undefined) {
                message.error(response.data.message.title + "  " + response.data.message.content)
            }
            else {
                message.error('Error al editar area, verifique los datos ingresados.')
            }

        }).catch(error => {
            message.error('Error al actualizar area')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalAreas}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input placeholder="Descripción" ></Input>

                            </Form.Item>
                        </Col>

                    </Row>

                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Área</Title>
        </div>
        <ModalAreas {...props} />
    </Modal>

}