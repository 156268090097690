import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, PageHeader, Button, Col, Row, message, Dropdown, Form, Typography, Tabs, Space } from "antd";
import { CardClientInfo, CardProductEstatus, CardProductsVentas, CardVMIInfo } from "../../Widgets/Cards";
import { IconArrowBack, } from '../../Widgets/Iconos';
import FilterSelect from "../../Widgets/Filtros/FilterSelect";
import { PlusOutlined, BarChartOutlined } from '@ant-design/icons'
import '../../../Styles/Modules/ManagerInventarios/ManagerInventarios.css'

import ProductosList from './Productos/ProductosList'
import AlmacenesList from './Almacenes/AlmacenesList'

import IngresosList from './IngresosList'
import VentasList from './VentasList'
import SolicitudesList from './SolicitudesList'
import FacturasList from "./FacturasList";

const { Content } = Layout;
const { Title } = Typography
const { TabPane } = Tabs;
const axios = require('axios')
/**
 *
 *
 * @export
 * @class InventariosVendedor
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class InventariosVendedor extends Component {

    back = () => {
        this.props.history.goBack();
    }

    constructor(props) {
        super(props)
        this.state = {
            current: 0,
            productos: [],
            list: 5,
            cliente: undefined,
            tabStatus: "Productos",


            art1: 0,
            art2: 0,
            art3: 0,

            facturas_cobradas: 0,
            facturas_emitidas: 0,
            facturas_emitidas_monto: 0,
            monto_venta: 0,
            pendiente_facturar: 0,
            pendiente_facturar_monto: 0,
            tipo: 0,
            venta_dia: 0,
            venta_mensual: 0



        }
    }

    componentDidMount = () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getCliente();
        this.getClienteInfo();
    }



    /**
     * @memberof  
     * @method getCliente
     * @description Obtiene un cliente de la DB
     */
    getCliente = () => {

        axios.get('/clientes/get', {
            params: { id: this.props.match?.params?.cliente_id }
        })
            .then(({ data }) => {
                this.setState({
                    cliente: data.data
                })
            })
            .catch(error => {
                message.error('No se obtuvo el cliente')
                console.log(error)
            })
    }




    /**
   * @memberof  
   * @method getCliente
   * @description Obtiene un cliente de la DB
   */
    getClienteInfo = () => {

        axios.get('/vmi/getInfoClienteVMI', {
            params: { id: this.props.match?.params?.cliente_id }
        })
            .then(({ data }) => {

                if (data.success) {
                    data = data.data;
                    this.setState({

                        //  cliente: data.cliente,
                        art1: data.art1.length,
                        art2: data.art2.length,
                        art3: data.art3.length,

                        facturas_cobradas: data.facturas_cobradas,
                        facturas_emitidas: data.facturas_emitidas,
                        facturas_emitidas_monto: data.facturas_emitidas_monto,
                        monto_venta: data.monto_venta,
                        pendiente_facturar: data.pendiente_facturar,
                        pendiente_facturar_monto: data.pendiente_facturar_monto,
                        tipo: data.tipo,
                        venta_dia: data.venta_dia,
                        venta_mensual: data.venta_mensual,
                        actualizarTabla: 1
                    })
                }

            })
            .catch(error => {
                message.error('No se obtuvo la info cliente')
                console.log(error)
            })
    }


    render() {
        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={<Row className=" width-100 " justify="space-between" align="middle">
                        <Col span={2}>
                            <Link to={"/admin/manager-inventarios"}>
                                <Button ghost onClick={this.back} className="no-border" title="Volver a VMI">
                                    <IconArrowBack />
                                </Button>
                            </Link>
                        </Col>
                        <Col span={22}>
                            <span className="ant-page-header-heading-title" >
                                Vendor-Managed Inventory
                            </span>
                        </Col>
                    </Row>
                    }
                  
                />
                <Content className="content-bg pd-1">
                    <Row gutter={[16, 16]} className="mb-1">
                        <Col xs={24} md={8}>
                            <CardClientInfo cliente={this.state.cliente} />
                        </Col>
                        <Col xs={24} md={8}>
                            <CardProductEstatus
                                art1={this.state.art1}
                                art2={this.state.art2}
                                art3={this.state.art3}
                            />
                        </Col>
                        <Col xs={24} md={8}>
                            <CardProductsVentas
                                venta_dia={this.state.venta_dia}
                                venta_mes={this.state.venta_mensual}

                            />
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Title level={2} className=" p-0 mb-1">VMI</Title>
                        </Col>
                        <Col span={24} className="mb-1">
                            <CardVMIInfo
                                monto_venta={this.state.monto_venta}
                                facturas_emitidas={this.state.facturas_emitidas_monto}
                                facturas_cobradas={this.state.facturas_cobradas}
                                facturas_pendientes={this.state.pendiente_facturar_monto}
                                dias_credito={this.state.cliente?.dias_credito}
                                responsable={this.state.cliente?.responsable_id}
                            />
                        </Col>

                        <Col span={24}>
                            <Tabs defaultActiveKey="Productos" destroyInactiveTabPane={true} className="tabs-inventario" id="tab-inventario"

                                onChange={tabStatus => this.setState({ tabStatus })}
                                tabBarExtraContent={<Space>
                                    {
                                        (this.state.tabStatus === "Productos" || this.state.tabStatus === "Almacenes") ? <Button
                                            onClick={() => {
                                                let url = new URL(axios.defaults.baseURL + '/vmi/list')
                                                url.searchParams.set('csv', true)

                                                url.searchParams.set(((this.state.tabStatus === "Productos") ? "productos" : "almacenes"), true)

                                                url.searchParams.set("cliente_id", this.state.cliente?._id)

                                                url.searchParams.set('Authorization', sessionStorage.getItem('token'))
                                                window.open(url, '_blank');
                                            }}
                                            icon={<BarChartOutlined style={{ color: "currentColor" }} />}>
                                            Descargar Reporte
                                        </Button> : null
                                    }
                                </Space>}
                            >
                                <TabPane tab="Productos" key="Productos">
                                    <ProductosList
                                        cliente_id={this.state.cliente?._id}
                                        search={this.props.search} />
                                </TabPane>

                                <TabPane tab="Almacenes" key="Almacenes">
                                    <AlmacenesList
                                        cliente_id={this.state.cliente?._id}
                                        search={this.props.search} />
                                </TabPane>

                                <TabPane tab="Ingresos" key="Ingresos">
                                    <IngresosList
                                        clienteId={this.state.cliente?._id}
                                        search={this.props.search} />
                                </TabPane>

                                <TabPane tab="Facturas" key="Facturas">
                                    <FacturasList
                                        clienteId={this.state.cliente?._id}
                                        search={this.props.search} />
                                </TabPane>

                                <TabPane tab="Ventas" key="Pedidos">
                                    <VentasList
                                        cliente_id={this.state.cliente?._id}
                                        search={this.props.search} />
                                </TabPane>

                                <TabPane tab="Solicitudes" key="Solicitudes">
                                    <SolicitudesList
                                        cliente_id={this.state.cliente?._id}
                                        search={this.props.search} 
                                        responsable={this.state.cliente?.responsable_id}
                                    />
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Content>
            </>
        )
    }
}