import React, { Component, useContext, useRef, useState } from 'react';
import { default as axios } from 'axios';
import { Row, Col, message, Spin, Button, Modal } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';


//css
import './Stripe.css';

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#c4f0ff',
            color: 'black',
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': { color: '#e5edf5' },
            '::placeholder': { color: '#bfc0d2' },
        },
        invalid: {
            iconColor: '#bfc0d2',
            color: 'black',
        },
    },
};



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, { locale: 'en' });


/**
 * @const CheckoutForm
 * @description Formulario para realizar un pago por medio de Stripe
 */
const CheckoutForm = (props) => {


    const [loading, setLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    /**
     * @const handleSubmit
     * @description Se ejecuta al realizar el pago correspondiente
     */
    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
        })

        //Si no hay error
        if (!error) {
            console.log(paymentMethod)

            const { id } = paymentMethod

            try {

                axios.post('/pagar', {
                    total: props.total,
                    orden_id: props.orden_id,
                    facturado: props.facturado,
                    id: id,
                }).then(response => {
                    Modal.success({
                        title: "Pago realizado exitosamente.",
                        content: "Recibirás un correo de confirmación, revisa tu bandeja de entrada."
                    })
                    props.onClose();
                }).catch(err => {
                    console.log(err.response)
                    let msg = 'Error al realizar el pago'
                    if (err.response.data.message)
                        msg = err.response.data.message
                    message.error(msg)
                }).finally(() => {
                    setLoading(false)
                })
            } catch (e) {
                console.log(e)
            }
        } else {
            message.error('Error producido por Stripe')
            console.log(error)
        }
    }

    return (
        <Spin spinning={loading}>
            <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: '2em' }}>
                    Ingresa tu informacion de pago.
                </div>
                <fieldset className="FormGroup">
                    <div className="FormRow">
                        <CardElement
                            options={CARD_OPTIONS}
                            id="card-element"
                        //onChange={handleChange}
                        />
                    </div>
                </fieldset>
                <div className="flex-right">
                    <img src="/images/stripe-logo.png" width="120"/>
                </div>
                {props.extra ? <fieldset className='mb-1'>
                    <div >
                        {props.extra}
                    </div>
                </fieldset> : null}

                {/* extra */}
                <Row>
                    <Col span={24} className="center">
                        <Button htmlType='submit' block className="ant-btn" >
                            Pagar
                        </Button>
                    </Col>
                </Row>
            </form>
        </Spin>
    );
}

export { CheckoutForm }


