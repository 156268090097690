import React, { Component } from "react";
import { Button, Row, Radio, Space, Col, Modal, Typography, Form, Input, InputNumber, Select, Spin, Image, Avatar, message, Drawer, List, Pagination } from 'antd';
import { PlusOutlined, SaveOutlined, UploadOutlined, LoadingOutlined, } from '@ant-design/icons';


import ModalProductos from '../../Productos/ModalProductos';


import '../../../../Styles/Modules/Productos/DrawerProductos.scss';


const { Title, Paragraph, Text } = Typography;
const { Option } = Select
const axios = require('axios').default;


/**
 *
 *
 * @class ModalRFQ
 * @extends {Component}
 */
class DrawerSeleccionarProducto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            productos: {
                data: [],

                page: 1,
                limit: 25,

                total: 0,
                search: null
            },
            isSticky: false,


            producto: null,

            rfq_detalle_id: this.props.rfq_detalle_id,
            rfq_detalle_proveedor_id: this.props.rfq_detalle_proveedor_id,
            visibleModalProductos: false
        }
    }

    modalRFQ = React.createRef()
    modalFormulario = React.createRef()


    /**
     *
     *
     * @memberof DrawerSeleccionarProducto
     * @description Iniciamos el drawer de seleccion de producto.
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProductos()
        if (this.props.rfq_detalle_id && this.props.rfq_detalle_proveedor_id) {
            this.getProducto()
        }

        setTimeout(() => {
            try {
                this.drawerBody = document.querySelector(".drawer-seleccionar-productos .ant-drawer-body")
                console.log("drawerBody",  this.drawerBody )
                this.drawerBody.addEventListener("scroll", this.onListScroll)
            } catch (error) {
                console.log("error", error)
            }
        }, 300)

        console.log("A X F", )

    }

    /**
     *
     *
     * @memberof DrawerSeleccionarProducto
     * @description Removeos el evento que agrega la sombra
     */
    componentWillUnmount() {
        // try {
        //     this.drawerBody.removeEventListener("scroll", this.onListScroll)
        // } catch (error) {
        //     console.log("error", error)
        // }
    }

    /**
     *
     *
     * @memberof DrawerSeleccionarProducto
     * @description Si el SCROLL es 0.
     */
    onListScroll = () => {
        if ((this.state.isSticky == false) && this.drawerBody.scrollTop > 0)
            return this.setState({ isSticky: true })

        if ((this.state.isSticky == true) && this.drawerBody.scrollTop == 0)
            return this.setState({ isSticky: false })
    }

    /**
     *
     *
     * @memberof DrawerSeleccionarProducto
     * @description Obtenemos la opción del producto
     */
    getProducto = () => {
        axios.get('/productos/opcion', {
            params: {
                rfq_detalle_id: this.props.rfq_detalle_id,
                rfq_detalle_proveedor_id: this.props.rfq_detalle_proveedor_id
            }
        })
            .then(({ data }) => {
                this.setProducto(data)
            })
            .catch(({ error }) => {
                console.log("er", error)
            })
    }

    /**
     *
     *
     * @memberof DrawerSeleccionarProducto
     * @description Declaramos la opción de producto
     */
    setProducto = ({ proveedor_id, producto_id, precio, dias, _id }, call = () => {}) => {

        let valuesForm = {}
        let valuesState = {}

        if (proveedor_id?._id) {
            valuesForm['proveedor_id'] = (proveedor_id?._id) ? proveedor_id?._id : proveedor_id
            valuesState['proveedor_id'] = proveedor_id?._id
        }

        if (producto_id?._id) {
            valuesForm['producto'] = producto_id?.np_nexus
            valuesForm['producto_id'] = producto_id?._id
            valuesState['producto_id'] = producto_id?._id
        }

        if (precio)
            valuesForm['precio'] = precio

        if (dias)
            valuesForm['dias'] = dias

        this.setState(valuesState)
        this.modalRFQ.current.setFieldsValue(valuesForm)
    }

    /**
     *
     *
     * @memberof DrawerSeleccionarProducto
     * @description Obtenemos la información de los productos
     */
    getProductos = ({

        page = this.state.productos.page,
        limit = this.state.productos.limit,

        search = this.state.search,

        rfq_detalle_id = this.state.rfq_detalle_id,
        rfq_detalle_proveedor_id = this.state.rfq_detalle_proveedor_id

    } = {}) => {

        axios.post("/productos", {
            page, limit, search, rfq_detalle_id, rfq_detalle_proveedor_id
        })
            .then(({ data }) => {
                this.setState({ productos: { ...this.state.productos, ...data }, rfq_detalle: data.rfq_detalle })
            })
            .catch(e => {

            })
    }


    /**
     *
     *
     * @memberof DrawerSeleccionarProducto
     * @method update
     * @description Actualizamos un proveedor.
     */
    update = (values) => axios.post('/rfq/proveedor/edit', {
        ...values,
        rfq_detalle_id: this.state.rfq_detalle_id,
        rfq_detalle_proveedor_id: this.state.rfq_detalle_proveedor_id
    })
        .then(() => {

            message.success("Se ha actualizado la información del producto")
            this.props.onClose()
        })
        .catch(() => {
            message.error("No se puedo actualizar")


        })

    /**
     *
     *
     * @memberof DrawerSeleccionarProducto
     * @method create
     * @description Creamos un proveedor.
     */
    create = (values) => axios.post('/rfq/proveedor/add', {
        ...values,
        rfq_detalle_id: this.state.rfq_detalle_id
    })
        .then(() => {
            // console.log('FormProducto')
            message.success("¡Se ha guardado correctamente el producto!")
            this.props.onClose();
        })
        .catch((e) => {
            message.error('No se pudo guardar el producto')
            console.log('e', e)
        })

    /**
     *
     *
     * @memberof DrawerSeleccionarProducto
     * @method onFinish
     * @description Creamos un proveedor.
     */        
    onFinish = (values) => {

        values.proveedor_id = this.state.proveedor_id
        values.producto_id = this.state.producto_id

        if (this.props.rfq_detalle_id && this.props.rfq_detalle_proveedor_id)
            this.update(values)
        else
            this.create(values)

    }

    
    /**
     *
     *
     * @memberof DrawerSeleccionarProducto
     * @method onFinish
     * @description Abrimos el modal de NUEVO PRODUCTO y le declaramos los valores
     */ 
    openModalProducto = () => {
        let values = {}
    
        values.costo = this.state?.rfq_detalle?.precio_ref;
        values.descripcion = this.state?.rfq_detalle?.descripcion;
        values.imagenes = this.state?.rfq_detalle?.imagenes?.map((imagen, index) => ({
            uid: -index,
            name: imagen,
            filename: imagen,
            status: 'done',
            url: axios.defaults.baseURL + '/upload/th_' + imagen
        }))
        values.marca = this.state?.rfq_detalle?.marca;
        values.np_fabricante = this.state?.rfq_detalle?.modelo;
        values.sku = this.state?.rfq_detalle?.sku || this.state?.rfq_detalle?.modelo;
        this.setState({ visibleModalProductos: true, productoValues: values })

    }

    render() {


        return (
            <Form
                layout="vertical"

                name="form-producto-proveedor"
                id="form-producto-proveedor"

                ref={this.modalRFQ}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center">
                        <Col className="drawer-section" span={24}>
                            <Form.Item
                                className="agregar-producto-col-input"
                                label="Costo del Producto"
                                name="precio"
                                rules={[
                                    {
                                        required: true, message: "Se require el costo del producto"
                                    }
                                ]}
                            >
                                <InputNumber placeholder="Ingrese el costo del producto" maxLength={100} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className="drawer-section" span={24}>
                            <Form.Item
                                className="agregar-producto-col-input"
                                label="Número de Días"
                                name="dias"
                                rules={[
                                    {
                                        required: true, message: "Se require la cantidad de días"
                                    }
                                ]}
                            >
                                <InputNumber placeholder="Ingresa la cantidad de días" maxLength={100} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className={"drawer-section sticky " + (this.state.isSticky ? "is-sticky" : "")} span={24} >
                            <Form.Item
                                className="agregar-producto-col-input"
                                label="Busque y seleccione el producto a Buscar"
                                name="producto"

                                rules={[

                                    {
                                        validator: (_, value) => {
                                            console.log("this.state.producto_id", this.state.producto_id)
                                            if (this.state.producto_id == undefined || this.state.producto_id == null) {
                                                return Promise.reject(new Error('Debe de seleccionar un producto'));
                                            }
                                            return Promise.resolve();
                                        },
                                    }

                                ]}
                            >
                                <Input
                                    onChange={event => {
                                        this.getProductos({ search: event.target.value, page: 1 })
                                    }}
                                    placeholder="Ingrese el nombre del Producto" maxLength={2000} />
                            </Form.Item>
                            <Pagination
                                style={{ float: "right", marginBottom: "1em" }}
                                size="small"
                                simple
                                pageSize={this.state.productos.limit}
                                current={this.state.productos.page}
                                total={this.state.productos.total}

                                onChange={(page, limit) => this.getProductos({ page, limit })}
                            />
                        </Col>
                        <Col className="drawer-section" span={24}>
                            <Radio.Group
                                className="width-100"
                                optionType="button"
                                buttonStyle="solid"
                                size="small"
                                shape="round"

                                name="producto_id"

                                value={this.state.producto_id}
                            >
                                <List
                                    itemLayout="vertical"
                                    size="large"
                                    dataSource={this.state.productos.data}
                                    renderItem={(producto) => {

                                        const { _id, np_fabricante, imagenes, sku, np_nexus, descripcion, marca, proveedor_id, costo } = producto

                                        return (
                                            <List.Item
                                                style={{ position: "relative" }}
                                                key={_id}
                                            >
                                                <List.Item.Meta
                                                    avatar={(Array.isArray(imagenes) && (imagenes[0] !== undefined)) ?
                                                        <Image
                                                            width={64}
                                                            src={axios.defaults.baseURL + '/upload/' + imagenes[0].filename}
                                                        /> : <Avatar shape="square" size={64} ></Avatar>}
                                                    title={<Typography style={{ lineHeight: 0.5 }}>
                                                        <Paragraph style={{ paddingBottom: 10 }}><span style={{ marginTop: 3, backgroundColor: "lightgray", borderRadius: 5, border: "1px solid gray", padding: "2px 5px", marginRight: "4px", fontSize: 12 }}>{proveedor_id.nombre}</span></Paragraph>
                                                        <Paragraph><Text strong  >{sku}</Text> &nbsp;<Text italic style={{ fontSize: "0.765em" }}>{np_nexus}</Text></Paragraph>
                                                        <Paragraph><Text italic style={{ fontSize: "0.8em" }}>{marca}</Text>&nbsp;<Text strong style={{ fontSize: "0.8em" }}>{np_fabricante}</Text></Paragraph>

                                                    </Typography>}
                                                    description={descripcion}
                                                />
                                                <div style={{ width: "100%", justifyContent: "end", display: "flex" }} >
                                                    <Radio value={_id}
                                                        onChange={e => {
                                                            this.setProducto({
                                                                proveedor_id,
                                                                producto_id: producto,
                                                                precio: costo
                                                            })
                                                        }}
                                                    >{(this.state.producto_id?.toString() === _id?.toString()) ? "Seleccionado" : "Seleccionar"}</Radio>
                                                </div>
                                            </List.Item>
                                        )
                                    }}
                                />
                            </Radio.Group>
                        </Col>
                    </Row>
                </Spin>
                <ModalProductos
                    ref={this.modalFormulario}
                    productoValues={this.state.productoValues}
                    visible={this.state.visibleModalProductos}
                    onCancel={(producto) => {
                        this.setState({ visibleModalProductos: false })
                        this.getProductos()
                        if (producto?._id) {
                            this.setProducto({
                                proveedor_id: producto.proveedor_id,
                                producto_id: producto,
                                precio: producto.costo,
                                rfq_detalle_proveedor_id: this.props.rfq_detalle_proveedor_id,
                            })
                            this.getProductos({page: 1, search: producto.np_nexus })
                        }

                    }}
                />
            </Form>
        )
    }
}



export default function (props) {
    const {
        visible = false,
        onClose = () => { }
    } = props

    let drawerFormRef = React.createRef();

    return <Drawer
        visible={visible}
        onCancel={onClose}
        onClose={onClose}
        className="drawer-seleccionar-productos"
        title={"Agregar o Editar Proveedor"}
        destroyOnClose={true}
        zIndex={1000}
        width="500"
        footer={
            <Button  type="ghost" icon={<PlusOutlined style={{ color: "currentcolor" }} />} onClick={() => drawerFormRef.current.openModalProducto()}>Agregar Producto</Button>
        }
        extra={
            <Space>
                <Button icon={<SaveOutlined style={{ color: "currentcolor" }} />} form='form-producto-proveedor' key='submit' htmlType='submit' type="primary" >
                    Guardar
                </Button>
            </Space>
        }>
        <DrawerSeleccionarProducto ref={drawerFormRef} {...props} />

    </Drawer>

}