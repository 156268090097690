import React, { Component } from 'react';
import { Button, Col, Row, Spin, PageHeader, Layout, message, DatePicker, Typography, Statistic, Card, List, Pagination, Space, Progress, Divider, Tooltip } from 'antd'
import axios from 'axios';
import * as echarts from 'echarts';

import { CardPersonal, CardPromedios, CardVentas } from "../../Widgets/Cards";

import Avatar from "../../Widgets/Avatar/Avatar";


import "../../../Styles/Modules/KPIs/kpis.scss"

import { FileExcelOutlined, DollarOutlined, FileProtectOutlined, MessageOutlined, ClockCircleOutlined } from "@ant-design/icons"

import ReactECharts from 'echarts-for-react';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
const { Content, } = Layout;
const moment = require('moment')
const { Title, Text } = Typography

const IconText = (props) => {

    const { icon, children } = props

    return <Space size={2} {...props}>
        {React.createElement(icon)}
        <Text>{children}</Text>
    </Space>
}

/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Vista del Dashboard de Finanzas
 */
export default class KPIS extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [],
            // tiempo_promedio: {
            //     hours: 0,
            //     minutes: 0,
            //     days: 0
            // },
            // ranking: {
            //     primero: {},
            //     ultimo: {}
            // },
            // ventas: {},
            // kpis: [],
            // clientes: {
            //     nombres: [],
            //     data: [
            //         ['Nombre', 'Fecha', 'Ordenes']
            //     ]
            // },
            // options: {},

            contrateCotizacionesVentas: {

                data: [],

                page: 1,
                limit: 12,

                total: 0,
                pages: 0,
                loading: false

            },

            comprasRealizadas: {
                cotizadas: 0,
                realizadas: 0,
                tiempo_respuesta: 0,
                vendidas: 0,
                loading: false
            },

            promedioTiempo: {
                tiempo_respuesta: 0,
                tiempo_respuesta_dias: 0,
                tiempo_respuesta_horas: 0,
                tiempo_respuesta_minutos: 0,
                loading: false
            },

            eficienciaPersonal: {

                data: [],

                page: 1,
                limit: 3,

                total: 0,
                pages: 0,
                loading: false

            },

            cotizacionesCliente: {

                data: [],

                page: 1,
                limit: 12,

                total: 0,
                pages: 0,
                loading: false

            },
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        this.onFiltersChange()
    }

    getComprasRealizadas = (
        {
            mes = this.state.mes,
            ano = this.state.ano
        } = this.state.contrateCotizacionesVentas
    ) => {
        this.setState({ comprasRealizadas: { ...this.state.comprasRealizadas, loading: true } })
        axios.get('/kpis/compras-realizadas', {
            params: { mes, ano }
        })
            .then(({ data }) => {
                this.setState({ comprasRealizadas: { ...this.state.comprasRealizadas, ...data, loading: false } })

            })
            .catch(error => {
                this.setState({ comprasRealizadas: { ...this.state.comprasRealizadas, loading: true } })
            })
    }

    getContrasteCotizacionesVentas = (
        {
            page = this.state.contrateCotizacionesVentas.page,
            limit = this.state.contrateCotizacionesVentas.limit,

            mes = this.state.mes,
            ano = this.state.ano
        } = this.state.contrateCotizacionesVentas
    ) => {
        this.setState({ contrateCotizacionesVentas: { ...this.state.contrateCotizacionesVentas, loading: true } })
        axios.get('/kpis/contrate-cotizaciones-ventas', {
            params: {
                page, limit, mes, ano
            }
        })
            .then(({ data }) => {
                this.setState({ contrateCotizacionesVentas: { ...data, loading: false } })
            })
            .catch(error => {
                this.setState({ contrateCotizacionesVentas: { ...this.state.contrateCotizacionesVentas, loading: false } })
            })
    }

    getTiempoPromedio = (
        {
            mes = this.state.mes,
            ano = this.state.ano
        } = this.state
    ) => {
        this.setState({ promedioTiempo: { ...this.state.promedioTiempo, loading: true } })
        axios.get('/kpis/promedio-tiempo', {
            params: {
                mes, ano
            }
        })
            .then(({ data }) => {
                this.setState({ promedioTiempo: { ...this.state.promedioTiempo, ...data, loading: false } })
            })
            .catch(error => {
                this.setState({ promedioTiempo: { ...this.state.promedioTiempo, loading: false } })
            })
    }

    getEficienciaPersonal = (
        {

            page = this.state.eficienciaPersonal.page,
            limit = this.state.eficienciaPersonal.limit,

            mes = this.state.mes,
            ano = this.state.ano
        } = this.state
    ) => {
        this.setState({ eficienciaPersonal: { ...this.state.eficienciaPersonal, loading: true } })
        axios.get('/kpis/eficiencia-personal', {
            params: {
                page, limit,
                mes, ano
            }
        })
            .then(({ data }) => {
                this.setState({ eficienciaPersonal: { ...this.state.eficienciaPersonal, ...data, loading: false } })
            })
            .catch(error => {
                this.setState({ eficienciaPersonal: { ...this.state.eficienciaPersonal, loading: false } })
            })
    }


    getClientesCotizaciones = (
        {

            page = this.state.cotizacionesCliente.page,
            limit = this.state.cotizacionesCliente.limit,

            mes = this.state.mes,
            ano = this.state.ano
        } = this.state
    ) => {
        this.setState({ cotizacionesCliente: { ...this.state.cotizacionesCliente, loading: true } })
        axios.get('/kpis/cotizaciones-cliente', {
            params: {
                page, limit,
                mes, ano
            }
        })
            .then(({ data }) => {
                this.setState({ cotizacionesCliente: { ...this.state.cotizacionesCliente, ...data, loading: false } })
            })
            .catch(error => {
                this.setState({ cotizacionesCliente: { ...this.state.cotizacionesCliente, loading: false } })
            })
    }


    onFiltersChange = ({ ano = this.state.ano, mes = this.state.mes } = {}) => {
        this.setState({ mes, ano }, () => {
            this.getComprasRealizadas()
            this.getContrasteCotizacionesVentas()
            this.getTiempoPromedio()
            this.getEficienciaPersonal()
            this.getClientesCotizaciones()
        })
    }

    render() {

        let percentage = this.state.comprasRealizadas.vendidas / this.state.comprasRealizadas.realizadas * 100

        if (isNaN(percentage))
            percentage = 0

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Analytics"
                        extra={[
                            <DatePicker allowClear onClear onChange={value => this.onFiltersChange({ ano: value ? value.format("YYYY") : null })} picker="year" style={{ width: 150 }} />,
                            <DatePicker allowClear onChange={value => this.onFiltersChange({ mes: value ? value.format("MM") : null })} picker="month" format="MMMM" style={{ width: 150 }} />,
                            <a
                                target={"_blank"}
                                href={axios.defaults.baseURL + "/kpis/reporte-ventas?" + encodeURI(new URLSearchParams({
                                    ano: this.state.ano ?? "",
                                    mes: this.state.mes ?? "",
                                    Authorization: sessionStorage.getItem("token")
                                }))}>
                                <Button icon={<FileExcelOutlined />} />
                            </a>
                        ]}
                    />
                    <Content className="admin-content content-bg pd-1">
                        <Row gutter={[16, 16]}>
                            <Col xs={24} lg={24} xl={24} xxl={24}>
                                <Row gutter={[16, 16]}>
                                    <Col span={8} md={8} sm={24}>
                                        <Spin spinning={this.state.comprasRealizadas.loading}>



                                            <Card className=' card-shadow' style={{ minHeight: 216, height: '100%' }}>
                                                <Card.Meta
                                                    title={<Title level={4} className="element-title ">Compras Realizadas</Title>}
                                                />
                                                <Row justifyContent="center">
                                                    <Col span={12}>
                                                        <Progress type="dashboard" percent={percentage.toFixed(2)} />
                                                    </Col>
                                                    <Col span={12} className='flex-left-column'>
                                                        <Statistic className='flex' value={this.state.comprasRealizadas.vendidas} precision={0} suffix=" Ventas " />
                                                        <Text className='card-text-blue' style={{ fontSize: 18 }}>de {this.state.comprasRealizadas.realizadas} Cotizaciones</Text>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Spin>
                                    </Col>
                                    <Col span={8} md={8} sm={12}>
                                        <Spin spinning={this.state.promedioTiempo.loading}>
                                            <Card className='card-shadow ' style={{ minHeight: 216, height: '100%' }}>
                                                <Card.Meta
                                                    title={<Title level={4} className="element-title ">Tiempo Promedio de Cotización</Title>}
                                                />
                                                <Row justifyContent="center" className='flex' style={{ height: '130px', paddingTop: "3em" }}>
                                                    <Col span={24} style={{ width: "100%", justifyContent: "space-between", display: "flex", }}>
                                                        <Text strong className='card-text-blue card-text-only'>{this.state.promedioTiempo.tiempo_respuesta_dias} {(this.state.promedioTiempo.tiempo_respuesta_dias > 1) ? 'días' : 'día'}</Text>
                                                        <Text strong className='card-text-blue card-text-only'>{this.state.promedioTiempo.tiempo_respuesta_horas} {(this.state.promedioTiempo.tiempo_respuesta_horas > 1) ? 'horas' : 'hora'}</Text>
                                                        <Text strong className='card-text-blue card-text-only'>{this.state.promedioTiempo.tiempo_respuesta_minutos} {(this.state.promedioTiempo.tiempo_respuesta_minutos > 1) ? 'minutos' : 'minuto'}</Text>
                                                    </Col>

                                                </Row>
                                            </Card>
                                        </Spin>
                                    </Col>
                                    <Col span={8} md={8} sm={12}>
                                        <Spin spinning={this.state.eficienciaPersonal.loading}>


                                            <Card className=" card-shadow " style={{ minHeight: 216, height: '100%' }}>
                                                <Card.Meta
                                                    title={<Title level={4} className="element-title ">Eficiencia de Personal</Title>}
                                                />
                                                <List
                                                    className='list-eficiencia-clientes'
                                                    itemLayout="vertical"
                                                    size="small"
                                                    dataSource={this.state.eficienciaPersonal.data}
                                                    renderItem={item => (
                                                        <List.Item
                                                            key={item.title}
                                                            extra={[
                                                                <Space split={<Divider type="vertical" />} size={1}>
                                                                    <Tooltip title={<>{item.agente_compras_id.nombre} ha cotizado {item.cotizadas} ordenes</>}>
                                                                        <IconText icon={FileProtectOutlined} key="list-vertical-star-o" >{(item.cotizadas ?? "").toString().padStart(2, '0')}</IconText>
                                                                    </Tooltip>
                                                                    <Tooltip title={<>{item.agente_compras_id.nombre} ha concluido {item.vendidas} ordenes</>}>
                                                                        <IconText icon={DollarOutlined} key="list-vertical-star-o" >{(item.vendidas ?? "").toString().padStart(2, '0')}</IconText>
                                                                    </Tooltip>
                                                                    {/* <><Text> días, {(item.tiempo_respuesta_horas ?? "").toString().padStart(2, '0')} horas, {(item.tiempo_respuesta_minutos ?? "").toString().padStart(2, '0')} minutos</Text></> */}
                                                                    <Tooltip title={`${item.agente_compras_id.nombre} tiene un tiempo promedio de respuesta: ${(item.tiempo_respuesta_dias ?? "").toString().padStart(2, '0')} días, ${(item.tiempo_respuesta_horas ?? "").toString().padStart(2, '0')} horas, ${(item.tiempo_respuesta_minutos ?? "").toString().padStart(2, '0')}, minutos.`}>
                                                                        <IconText icon={ClockCircleOutlined} key="list-vertical-message" >
                                                                            <Text>{(item.tiempo_respuesta_dias ?? "").toString().padStart(2, '0')} {(item.tiempo_respuesta_horas ?? "").toString().padStart(2, '0')}:{(item.tiempo_respuesta_minutos ?? "").toString().padStart(2, '0')}</Text>
                                                                        </IconText>
                                                                    </Tooltip>
                                                                </Space>
                                                            ]}
                                                        >
                                                            <List.Item.Meta
                                                                style={{ margin: 0 }}
                                                                avatar={<Avatar size='small' image={item.agente_compras_id.avatar} name={<>{item.agente_compras_id.nombre} {item.agente_compras_id.apellido} </>} />}
                                                                title={<>{item.agente_compras_id.nombre} {item.agente_compras_id.apellido}</>}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                                <Pagination
                                                    style={{ float: "right" }}
                                                    size='small'

                                                    pageSize={this.state.eficienciaPersonal.limit}
                                                    current={this.state.eficienciaPersonal.page}
                                                    total={this.state.eficienciaPersonal.total}

                                                    showSizeChanger={false}

                                                    onChange={(page, limit) => {
                                                        this.getEficienciaPersonal({ page, limit })
                                                    }}
                                                />
                                            </Card>
                                        </Spin>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Spin spinning={this.state.contrateCotizacionesVentas.loading}>
                                    <Card className='card-shadow' style={{ justifyContent: "center" }}>
                                        <Card.Meta
                                            title={<Title level={4} className="element-title">Contraste de Cotizaciones y Ventas Realizadas por Mes</Title>}
                                        />
                                        <ReactECharts
                                            option={{
                                                tooltip: {
                                                    trigger: 'axis',
                                                    axisPointer: {
                                                        // Use axis to trigger tooltip
                                                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                                                    },
                                                    formatter: (args) => {


                                                        console.log("args", args)

                                                        let contrasteCotizaciones = this.state.contrateCotizacionesVentas.data[args[0].dataIndex]
                                                        let x = ""

                                                        for (const serie of args) {

                                                            x += `
                                                        <span >
                                                            <span style="
                                                                display: inline-block;
                                                                background: ${serie.color};
                                                                width: 10px;
                                                                height: 10px;
                                                                border-radius: 100%;
                                                            "></span>
                                                                ${serie.seriesName}:  
                                                                <strong>${serie.value}</strong>
                                                                <i>(${serie?.data?.cast} %) </i>
                                                        </span>
                                                        <br />`
                                                        }

                                                        return `
                                                        Resumen del mes de <strong>${moment(contrasteCotizaciones.fecha, "YYYY-MM").format("MMMM YYYY ")}</strong>
                                                        <br />
                                                        <br />
                                                        ${x}
                                                        <span>Total de Cotizaciones Solicitadas</span>: <strong>${contrasteCotizaciones.realizadas}</strong> <br />
                                                        <br />
                                                        <br />
                                                        Tiempo Promedio de Respuesta: <strong>${contrasteCotizaciones.tiempo_respuesta_dias} días, ${contrasteCotizaciones.tiempo_respuesta_horas} horas, ${contrasteCotizaciones.tiempo_respuesta_minutos} minutos</strong>

                                                    `
                                                    }
                                                    // formatter: '{a} <br/>{b}: {c} ({d}%)'
                                                },
                                                legend: {},
                                                grid: {
                                                    left: '3%',
                                                    right: '4%',
                                                    bottom: '3%',
                                                    containLabel: true
                                                },
                                                xAxis: {
                                                    type: 'category',
                                                    data: this.state.contrateCotizacionesVentas.data.map(e => moment(e.fecha, "YYYY-MM").format("MMMM YYYY "))
                                                },
                                                yAxis: {
                                                    type: 'value'
                                                },
                                                series: [
                                                    {
                                                        name: 'Solicitudes Cotizadas',
                                                        type: 'bar',
                                                        stack: 'total',
                                                        label: {
                                                            show: true
                                                        },
                                                        emphasis: {
                                                            focus: 'series'
                                                        },
                                                        data: this.state.contrateCotizacionesVentas.data.map(e => ({
                                                            value: e.cotizadas,
                                                            cast: e.cotizadas_porcentaje
                                                        }))
                                                    },
                                                    {
                                                        name: 'Solicitudes Vendidas',
                                                        type: 'bar',
                                                        stack: 'total',
                                                        label: {
                                                            show: true
                                                        },
                                                        emphasis: {
                                                            focus: 'series'
                                                        },
                                                        data: this.state.contrateCotizacionesVentas.data.map(e => ({

                                                            value: e.vendidas,
                                                            cast: e.vendidas_porcentaje,

                                                        })),

                                                    },
                                                ]
                                            }}
                                            style={{ minHeight: 300 }}
                                        />
                                        <Pagination
                                            style={{ float: "right" }}
                                            size='small'

                                            pageSize={this.state.contrateCotizacionesVentas.limit}
                                            current={this.state.contrateCotizacionesVentas.page}
                                            total={this.state.contrateCotizacionesVentas.total}

                                            showSizeChanger={true}
                                            pageSizeOptions={[6, 12, 18, 24]}

                                            onChange={(page, limit) => {
                                                this.getContrasteCotizacionesVentas({ page, limit })
                                            }}
                                        />
                                    </Card>
                                </Spin>
                            </Col>

                            <Col xs={24} lg={24} xl={24} xxl={24}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} lg={24} xl={24} xxl={24} >
                                        <Spin spinning={this.state.cotizacionesCliente.loading}>
                                            <Card className='card-shadow' >
                                                <Card.Meta
                                                    title={<Title level={4} className="element-title ">Cotizaciones por Persona al Mes</Title>}
                                                />
                                                <ReactECharts option={{
                                                    grid: {
                                                        top: 24,
                                                        left: 40,
                                                        right: 30,
                                                        bottom: 24,
                                                        // width: 875
                                                    },
                                                    tooltip: {
                                                        trigger: 'axis',
                                                        axisPointer: {
                                                            // Use axis to trigger tooltip
                                                            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                                                        },

                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: this.state.cotizacionesCliente.data.map(cliente => cliente?.razon_social || cliente?.nombre)
                                                    },
                                                    yAxis: {
                                                        type: 'value'
                                                    },
                                                    series: [
                                                        {
                                                            data: this.state.cotizacionesCliente.data.map(cliente => cliente?.cotizadas),
                                                            type: 'bar',
                                                            itemStyle: {
                                                                color: '#1890ff'
                                                            }
                                                        }
                                                    ]
                                                }}
                                                    style={{ minHeight: 300 }}
                                                />
                                                <Pagination
                                                    style={{ float: "right" }}
                                                    size='small'

                                                    pageSize={this.state.cotizacionesCliente.limit}
                                                    current={this.state.cotizacionesCliente.page}
                                                    total={this.state.cotizacionesCliente.total}

                                                    showSizeChanger={false}

                                                    onChange={(page, limit) => {
                                                        this.getClientesCotizaciones({ page, limit })
                                                    }}
                                                />
                                            </Card>
                                        </Spin>
                                    </Col>

                                </Row>
                            </Col>

                        </Row>
                    </Content>
                </Spin>
            </>
        )
    }
}