import React, { Component } from 'react';
import { Layout, Button, Col, Row, message, PageHeader, List, Spin, Typography, Card } from 'antd'
import { FlagFilled } from "@ant-design/icons";
import axios from 'axios'

const moment = require('moment');
const { Text, Title } = Typography;
const { Content } = Layout;


/**
 * @export
 * @class Entrega
 * @extends {Component}
 */
export default class Entrega extends Component {

    constructor(props) {
        super(props)
        this.state = {
            orden_id: this.props.match.params.orden_id,
            items: [],
            loading: false,
            cliente: {},
            orden: {},
        }
    }

    /**
     * @memberof Entrega
     * @method componentDidMount 
     * @description Cargamos la informacion inicial
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getEntregas()
    }

    /**
        *
        *
        * @memberof EntregaMercancia
        * @method getEntregas
        * @description Obtener la lista de entregas
        */
    getEntregas = () => {
        console.log('props', this.props)
        axios.get('/customer/recepciones', {
            params: {
                orden_compra_id: this.props.match.params.orden_compra_id,
                orden_id: this.props.match.params.orden_id,
                tipo: 1
            }
        }).then(({ data }) => {
            const entregas = data.data.data
            const detalles = entregas[0]?.orden_detalles

            this.setState({
                entregas,
                detalles
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la orden compra')
        })
    }
    render() {
        const { orden } = this.state
        return (
            <>
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.entregas}
                    header={
                        <Row gutter={[0, 8]}>
                            <Col span={18} md={24} lg={15} xxl={{ span: 18 }} ><Title level={3}>Entrega</Title></Col>
                        </Row>
                    }
                    renderItem={item => {

                        return (
                            <List.Item className="order-list-item bg-white">
                                <Row className="width-100 text-center" align="middle" justify="space-around" >
                                    <Col span={8} lg={{ span: 6, order: 1 }} md={{ span: 8, order: 1 }} >
                                        <Text ellipsis strong style={{ width: 'auto', padding: "4px 1rem" }} className="tag-card-pink"> {item.folio} </Text>
                                    </Col>
                                    <Col span={6} lg={{ span: 6, order: 2 }} md={{ span: 12, order: 2 }}>
                                        {/* <Text ellipsis className="text-gray">{orden_detalles.length > 0 ? moment(orden_detalles[-1]?.createdAt).format('L') : 'Sin Fecha'}</Text> */}
                                    </Col>
                                    <Col span={2} lg={{ span: 2, order: 3 }} md={{ span: 12, order: 4 }}>
                                        <Row>
                                            <Col span={24}><Text className="text-gray">Firma</Text></Col>
                                            {/* <Col span={24}>{
                                                firma ? <IconCheck /> : <Text className="text-gray">Pendiente</Text>
                                            }</Col> */}
                                        </Row>
                                    </Col>
                                </Row>
                            </List.Item>
                        )
                    }
                    }
                />
            </>
        )
    }
}