import React, { useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { Table, InputNumber, Typography, Space, Image, Form, message, Select } from 'antd';
import { EditableProTable } from "@ant-design/pro-table";

const { Text } = Typography;
const { Option } = Select
const EditableContext = React.createContext(null);

export default function TableMatrizCostos(props) {

    const { productos } = props;
    let [editableKeys, setEditableKeys] = useState([]);
    let [dataSource, setDataSource] = useState([]);

    const [form] = Form.useForm();




    let columns = [
        {
            title: "ClaveProdServ",
            dataIndex: "ClaveProdServ",
            key: "ClaveProdServ",
            width: "180px",
            renderFormItem: (item, { record }, extra) => {
                return (
                    <span className="text-center">
                        27112102
                    </span>
                );
            },
        },{
            title: "No Identificacion (sku)",
            dataIndex: "NoIdentificacion",
            key: "NoIdentificacion",
            width: "180px",
            renderFormItem: (item, { record }, extra) => {
                return (
                    <span className="text-center">
                        {record.producto_id?.sku}
                    </span>
                );
            },
        },{
            title: "Cantidad",
            dataIndex: "Cantidad",
            key: "Cantidad",
            width: "100px",
            renderFormItem: (item, { record }, extra) => {
                return (
                    <span className="text-center">
                        {record.cantidad}
                    </span>
                );
            },
        },{
            title: "Unidad",
            dataIndex: "Unidad",
            key: "Unidad",
            width: "130px",
            renderFormItem: (item, { record }, extra) => {
                return (
                    <Select>
                        <Option value={'H87'}>Pieza</Option>
                        <Option value={'EA'}>Elemento</Option>
                        <Option value={'KGM'}>Kilogramo</Option>
                        <Option value={'MTR'}>Metro</Option>
                        <Option value={'KT'}>Kit</Option>
                        <Option value={'SET'}>Conjunto</Option>
                        <Option value={'LTR'}>Litro</Option>
                        <Option value={'XBX'}>Caja</Option>
                        <Option value={'xun'}>Unidad</Option>
                        <Option value={'GRM'}>Gramo</Option>
                    </Select>
                );
            },
        },{
            title: "Descripcion",
            dataIndex: "Descripcion",
            key: "Descripcion",
            width: "280px",
            renderFormItem: (item, { record }, extra) => {
                return (
                    <span className="text-center">
                        {record.producto_id?.descripcion}
                    </span>
                );
            },
        },{
            title: "Valor Unitario",
            dataIndex: "ValorUnitario",
            key: "ValorUnitario",
            width: "120px",
            renderFormItem: (item, { record }, extra) => {
                return (
                    <span className="text-center">
                       $ {record.info_matriz.precio_venta_unitario.toMoney(true)}
                    </span>
                );
            },
        },{
            title: "Importe ",
            dataIndex: "Importe ",
            key: "Importe   ",
            width: "130px",
            renderFormItem: (item, { record }, extra) => {
                console.log("record", record);
                return (
                    <span className="text-center">
                        $ {record.info_matriz.total_venta.toMoney(true)} 
                    </span>
                );
            },
        }
    ]

    useEffect(() => {
        if (productos !== dataSource) {
            console.log("productos", productos);
            setEditableKeys(productos.map((p) => p._id));
            setDataSource(productos);
        }

    });

    return (
        <EditableProTable
            headerTitle=""
            columns={columns}
            rowKey="_id"
            value={dataSource}
            recordCreatorProps={{
                style: { display: "none" },
            }}
            scroll={{ x: 1050 }}
            editable={{
                form,
                type: "multiple",
                onValuesChange: props.onChange,
                editableKeys: editableKeys,
            }}
        />
    );
}
