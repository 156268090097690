import React, { Component } from "react";
import {
    Layout,
    Typography,
    Drawer,
    Space,
    Button,
    Spin,
    Select,
} from "antd";

import "../../../../Styles/Modules/Finanzas/DrawerTimbrado.scss";
import { CloseOutlined, } from "@ant-design/icons";

import { IconSAT } from "../../../Widgets/Iconos";

import { c_TipoDeComprobante, c_RegimenFiscal, c_UsoCFDI, c_Exportacion, c_FormaPago, c_TipoDeRelación } from "../../../SATCatalogos/index";
import { FaRegHandScissors } from "react-icons/fa";
import TimbradoOrden from "./DrawerTimbradoFrom";

/**
 *
 *
 * @export
 * @param {*} props
 * @return {*} 
 */
export default function (props) {

    let { onClose, visible } = props
    let [loading, setLoading] = React.useState(false)
    let [disabled, setDisabled] = React.useState(false)

    const timbradoOrden = React.createRef()



    /**
     *
     *
     * @export
     * @param {*} props
     * @return {*} 
     * 
     * @description Cerramos y limpiamos el DRAWER de facturación.
     */
    let onCloseEvent = () => {
        onClose();
        setLoading(false)
        setDisabled(false)
    }

    return <Drawer
        title={<span className="title">Generación del Comprobante Fiscal Digital</span>}
        placement="right"
        onClose={onCloseEvent}
        visible={visible}
        width={1250}
        style={{ maxWidth: "100%" }}
        destroyOnClose
        extra={
            <Space>
                <Button loading={loading} disabled={disabled} icon={<IconSAT size={14} color="currentColor" style={{ position: "relative", top: 2, right: 3 }} />} type="primary" onClick={() => timbradoOrden.current.formTimbrado.current.submit()}>Timbrar</Button>
                <Button loading={loading} danger icon={<CloseOutlined />} onClick={onClose}>Cancelar</Button>
            </Space>
        }
    >
        <Spin spinning={loading}>
            <TimbradoOrden
                loading={loading}
                disabled={disabled}
                setLoading={setLoading}
                setDisabled={setDisabled}
                ref={timbradoOrden}
                {...props}
                onClose={onCloseEvent} />
        </Spin>
    </Drawer>
}