import React, { Component } from "react";
import { Layout, Menu, Button, Typography } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    BarChartOutlined,
    CommentOutlined
} from '@ant-design/icons';
import '../../Styles/Global/sider.css';
import { Link, useHistory } from "react-router-dom"
// import { useHistory } from "react-router-dom";

import { FaFileInvoiceDollar, FaBuilding, FaBoxes } from "react-icons/fa"
// 
import {
    IconProducts,
    IconCuentas,
    IconFinanzas,
    IconDashboard,

    IconUsuarios,
    IconRubros,
    IconProveedores,
    IconPM,
    IconContabilidad,
    IconVentas,
    IconCategorias,
    IconDetails,
    IconVMI,
    IconCarrito

} from '../Widgets/Iconos.js'
const { SubMenu } = Menu;
const { Sider } = Layout;
const { Text } = Typography
let roles = {
    finanzas: false,
    crm: false,
    p_manager: false,
    legal: false,
    cuentas: false,
    clasificadores: false,
    rubros: false,
    proyectos: false,
    usuarios: false,

    manager: false,

    estatus_avance: false,
    bitacora_estatus_avance: false,
    contabilidad: true,
    kpis: false,
};




/**
 *
 *
 * @export
 * @class Admin_Sidebar
 * @extends {Component}
 */
class Admin_Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }
    }


    /**
     *
     *
     * @memberof Admin_Sidebar
     */
    toogle = collapsed => {
        this.setState({collapsed});
        this.props.onCollapse(collapsed)
    }
    
    render() {
        const { user } = this.props;
        let owner = false;
        if (typeof user == "object") {
            if (user.roles !== undefined)
                roles = user.roles;

            switch (user.tipo) {
                case 1:
                    owner = true;
                    break
                default:
                    break
            }
        }
        return (
            <Sider
                theme="light"
                width={300}
                collapsedWidth={0}
                // collapsible
                onCollapse={(collapsed) => this.props.setCollapse(collapsed)}
                className="custom-sider sider-width"
                collapsed={this.props.collapsed}
                
                breakpoint="md">
                <Menu
                    mode="inline"
                    theme="light"
                    className="heigth-100 "
                    style={{ borderRight: 0 }}
                >
                    <Button ghost onClick={() => this.props.setCollapse(!this.props.collapsed)} className="no-border">
                        {this.props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                    <Menu.ItemGroup key="g1" title={this.state.collapsed ? <Text className="text-white">.</Text> : "NAVEGACIÓN"}>

                        <Menu.Item key="1" icon={<IconDashboard />}
                            onClick={() => this.props.history.replace('/admin/dashboard', [])}
                        >
                            {/* <Link to={'/admin/dashboard'}> */}
                            Dashboard
                            {/* </Link> */}
                        </Menu.Item>

                        {(owner || roles.finanzas) ?
                            <SubMenu key="2" title={<Link to={'/admin/finanzas'}>Finanzas</Link>} icon={<IconFinanzas />}>
                                <Menu.Item key="2-1" icon={<IconFinanzas />} onClick={() => this.props.history.replace('/admin/finanzas/transacciones', [])}>
                                    Transacciones
                                </Menu.Item>
                                <Menu.Item key="2-2" icon={<IconDetails />} onClick={() => this.props.history.replace('/admin/finanzas/facturas', [])}>
                                    Facturas
                                </Menu.Item>
                            </SubMenu> : null}

                        {(owner || roles.clientes) ? <Menu.Item key="clientes" icon={<IconVentas />}
                            onClick={() => this.props.history.replace('/admin/clientes', [])}
                        >
                            {/* <Link to={'/admin/clientes'}> */}
                            Clientes
                            {/* </Link> */}
                        </Menu.Item> : null}

                        {owner ? <Menu.Item key="mensajes" icon={<CommentOutlined />} onClick={() => this.props.history.replace('/admin/chat', [])}>
                            {/* <Link to={'/'}> */}
                            Chat
                            {/* </Link> */}
                        </Menu.Item> : null}


                        {(owner || roles.orden_compras) ?
                            <SubMenu key="compras" title={<Link to={'/admin/compras'}>Compras</Link>}  icon={<IconCarrito />}>
                                <Menu.Item key="compras-ordenes" icon={<FaFileInvoiceDollar style={{ margin: 0 }} />} 
                                    onClick={() => {
                                        this.props.history.push('/admin/compras/ordenes', [])
                                    }}
                                    >
                                    Ordenes de Compra
                                </Menu.Item>

                                <Menu.Item key="compras-reportes" icon={<IconDashboard />}
                                    onClick={() => this.props.history.replace('/admin/compras/reportes', [])}
                                    // onClick={() => this.props.history.replace('/admin/compras/reportes', [])}
                                    >
                                    Reportes
                                </Menu.Item>
                            </SubMenu> : null}

                        {(owner || roles.project_manager) ?
                            <Menu.Item key="pm" icon={<IconPM style={{ margin: 0 }} />} onClick={() => this.props.history.replace('/admin/project-manager', [])}>
                                Project Manager
                            </Menu.Item>
                            : null}

                        {(owner || roles.contabilidad) ?
                            <Menu.Item key="conta" icon={<IconContabilidad style={{ margin: 0 }} />} onClick={() => this.props.history.replace('/admin/contabilidad', [])}>
                                Contabilidad
                            </Menu.Item>
                            : null}

                        {(owner || roles.inventarios) ?
                            <Menu.Item key="mi" icon={<IconVMI style={{ margin: 0 }} />} onClick={() => this.props.history.replace('/admin/manager-inventarios', [])}>
                                VMI
                            </Menu.Item>
                            : null}
                        {(owner || roles.kpis) ?
                            <Menu.Item key="kpi" icon={<BarChartOutlined className="sidebar-icon" />} onClick={() => this.props.history.replace('/admin/kpis', [])}>
                                KPIs
                            </Menu.Item>
                            : null}
                    </Menu.ItemGroup>
                    <Menu.Divider className="group-sider-divider " />
                    <Menu.ItemGroup key="g2" title={this.state.collapsed ? <Text className="text-white">.</Text> : "AJUSTES"}>
                        {(owner || roles.usuarios) ?
                            <Menu.Item key="4" icon={<IconUsuarios />} onClick={() => this.props.history.replace('/admin/usuarios', [])}>
                                Usuarios
                            </Menu.Item> : null}


                        {(owner || roles.proveedores) ?
                            <Menu.Item key="5" icon={<IconProveedores />} onClick={() => this.props.history.replace('/admin/proveedores', [])}>
                                Proveedores
                            </Menu.Item>
                            : null}

                        {(owner || roles.cuentas) ?
                            <Menu.Item key="6" icon={<IconCuentas />} onClick={() => this.props.history.replace('/admin/cuentas', [])}>
                                Cuentas
                            </Menu.Item> : null}

                        {(owner || roles.areas) ?
                            <Menu.Item key="7" icon={<IconRubros />} onClick={() => this.props.history.replace('/admin/areas', [])}>
                                Áreas
                            </Menu.Item>
                            : null}

                        {(owner || roles.clasificadores) ?
                            <Menu.Item key="8" icon={<IconCategorias />} onClick={() => this.props.history.replace('/admin/clasificadores', [])}>
                                Clasificadores
                            </Menu.Item>
                            : null}

                        {(owner || roles.razones_sociales) ?
                            <Menu.Item key="9" icon={<FaBuilding style={{fontSize: '20px', margin: '0'}} />} onClick={() => this.props.history.replace('/admin/razones-sociales', [])}>
                                Razones Sociales
                            </Menu.Item>
                            : null}


                        {(owner || roles.productos) ?

                            <Menu.Item key="10" icon={<IconProducts color="#4D4D4D" />} onClick={() => this.props.history.replace('/admin/productos', [])}>
                                Productos
                            </Menu.Item>
                            : null}


                        {(owner || roles.inventarios) ?
                            <Menu.Item
                                onClick={() => this.props.history.replace('/admin/inventarios', [])}
                                key="11" icon={<FaBoxes style={{fontSize: '20px', margin: '0'}} />}>
                                Inventarios
                            </Menu.Item> : null}

                            <Menu.Item key="12" icon={<IconCategorias />} onClick={() => this.props.history.replace('/admin/categorias', [])}>
                                Categorias Catalogos
                            </Menu.Item>


                    </Menu.ItemGroup>
                </Menu>

            </Sider>
        )
    }
}

export default (props) => <Admin_Sidebar history={useHistory()}  {...props} />
