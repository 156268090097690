import React, { Component } from "react";
import { Layout, Spin } from 'antd';
import axios from "axios";


/**
 *
 *
 * @export
 * @class CatalogoViewer
 * @extends {Component}
 * @description Visor de PDF del catalogo
 */export default class CatalogoViewer extends Component {


    /**
     *
     *
     * @memberof CatalogoViewer
     * @state catalogo Objeto catalogo
     * @state catalogo_url Nombre URL del catalogo
    */
    state = {
        catalogo: null,
        loading: false,
        catalogo_url: this.props.match.params.catalogo_url,
        proveedor_url: this.props.match.params.proveedor_url
    }

    /**
     *
     *
     * @memberof CatalogoViewer
     * @method componentDidMount
     * @description Al montar el catalogo
    */
    componentDidMount() {
        this.getCatalogo()
    }

    /**
     *
     *
     * @memberof CatalogoViewer
     * @method componentDidUpdate
     * @description Al cambiar el proveedor o el catalogo, actualizamos el cataloogo
    */
    componentDidUpdate() {
        if (this.state.catalogo_url !== this.props.match.params.catalogo_url && this.state.proveedor_url !== this.props.match.params.proveedor_url) {
            this.setState({
                catalogo_url: this.props.match.params.catalogo_url,
                proveedor_url: this.props.match.params.proveedor_url
            }, () => this.getCatalogo())
        }
    }

    /**
     *
     *
     * @memberof CatalogoViewer
     * @method componentWillUnmount
     * @description Quitamos el proveedor cuando nos vamos, esto para que no te permita agregar elementos.
    */
    componentWillUnmount() {
        this.props.setProveedorId(null)
    }

    /**
     *
     *
     * @memberof CatalogoViewer
     * @method getCatalogo
     * @description Obtenemos el catalogo y lo ponemos en el state
    */
    getCatalogo = (url = this.props.match.params.catalogo_url) => {
        this.setState({ loading: true })
        axios.get('/catalogos/get', {
            params: {
                url
            }
        })
            .then(({ data }) => {
                this.props.setProveedorId(data.data.proveedor_id)
                this.setState({ catalogo: data.data })
            })
            .catch(error => {
                console.log('error', error)

            })
            .finally(e => {
                this.setState({ loading: false })
            })

    }


    render() {
        return (
            <Layout>
                {this.state.loading ?
                    <Spin spinning={true} size="large" style={{ width: '100%', minHeight: 'calc(100vh - 203.8px)', paddingTop: 100 }} />
                    :
                   <iframe style={{ width: '100%', minHeight: 'calc(100vh - 203.8px)' }} src={axios.defaults.baseURL + '/voucher/' + this.state.catalogo?.archivo}></iframe>
                }
            </Layout>
        )
    }

}