import React, { Component } from "react";
import { Layout, Button, Col, Row, Space, Typography, List, message, Card, Statistic } from "antd";

import { IconEye } from '../../Widgets/Iconos';
import ModalCompraDetalle from './ModalCompraDetalle'

import '../../../Styles/Modules/ManagerInventarios/ManagerInventarios.css'

const { Content } = Layout;
const { Text } = Typography
const axios = require('axios')
const moment = require('moment')

/**
 * @export
 * @class ComprasList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class ComprasList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            compras: [],
            loading: false,
            page: 1,
            limit: 10,
            total: 0,

            searching: true,
            filtroSearch: '',

        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCompras();
    }



    /**
    * @method componentDidUpdate
    * @description Actualiza la consulta si se buscan datos
    */
    componentDidUpdate() {
        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getCompras();
            this.state.searching = false;
        }
    }


    /**
     * @memberof Productos
     * @method getCompras
     * @description Obtiene los compras del cliente y enque almacenes se encuantran
     */
    getCompras = (page = this.state.page) => {
        let search = this.props.search;

        this.setState({ loading: true })
        axios.get('/vmi/ventas', {
            params: {
                cliente: true,
                search,
                page
            }
        }).then(response => {
            this.setState({
                compras: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount,


                filtroSearch: search,
                searching: true,
                loading: false

            })
        }).catch(error => {
            console.log(error)
            message.error('Erro al obtener la información de las Compras')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof IngresosList
    * @method   renderMonto
    * @description  Retorna el monto con el color correspondinte dependiendo del tipo de transaccion
    **/
    renderMonto = (tipo, monto) => {
        let colors = ['#000000', '#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    render() {

        return (
            <>
                <Content>
                    <Row>
                        <Col span={24}>
                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.compras}
                                pagination={{
                                    current: this.state.page,
                                    pageSize: this.state.limit,
                                    total: this.state.total,
                                    hideOnSinglePage: false,
                                    position: 'bottom',
                                    className: "flex-left",
                                    onChange: (page) => this.getCompras(page)
                                }}
                                loading={this.state.loading}
                                header={<Row className="header-list width-100 pr-1 pl-1" >
                                    <Col xs={12} lg={2} className="center">
                                        <Text>Fecha</Text>
                                    </Col>
                                    <Col xs={12} lg={5} className="center">
                                        <Text>Producto</Text>
                                    </Col>
                                    <Col xs={12} lg={2} className="center">
                                        <Text>Cantidad</Text>
                                    </Col>
                                    <Col xs={12} lg={4} className="center">
                                        <Text>Monto</Text>
                                    </Col>
                                    <Col xs={12} lg={5} className="center">
                                        <Text>Almacen</Text>
                                    </Col>
                                    <Col xs={12} lg={4} className="center">
                                        <Text>Fabricante</Text>
                                    </Col>
                                    <Col xs={24} lg={2} className="center">
                                        <Text>Acciones</Text>
                                    </Col>
                                </Row>}
                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <div className={`badge-card tipo-${item.tipo}`}>
                                            </div>
                                            <Row className="width-100">
                                                <Col xs={12} lg={2} className="center">
                                                    <Text strong>{moment(item.createdAt).format('DD/MM/YYYY')}</Text>
                                                </Col>
                                                <Col xs={12} lg={5} className="center">
                                                    <Text className="text-gray">{item.inventario_producto?.np_cliente}</Text>
                                                </Col>
                                                <Col xs={12} lg={2} className="center">
                                                    <Text className="text-gray">{item.orden_detalles?.cantidad}</Text>
                                                </Col>
                                                <Col xs={12} lg={4} className="center">
                                                    <Text className="text-gray-dark">$ {item.total?.toMoney(true)} MXN</Text>
                                                </Col>
                                                <Col xs={12} lg={5} className="center">
                                                    <Text className="text-gray">{item.almacen?.direccion}</Text>
                                                </Col>
                                                <Col xs={12} lg={4} className="center">
                                                    <Text className="text-gray">{item.orden_detalles?.producto_id?.np_fabricante}</Text>
                                                </Col>
                                                <Col xs={24} lg={2} className="center">
                                                    <Space>
                                                        <Button
                                                            style={{paddingTop: '9px'}}
                                                            icon={<IconEye />}
                                                            type="primary"
                                                            title="Editar"
                                                            onClick={() => this.setState({ orden: item, modalVisible: true })}
                                                        >
                                                        </Button>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />

                        </Col>
                    </Row>
                </Content>
                <ModalCompraDetalle
                    visible={this.state.modalVisible}
                    onClose={() => this.setState({ modalVisible: false, orden: undefined })}
                    orden={this.state.orden}
                />
            </>
        )
    }
}