import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Statistic, Spin, Space} from 'antd';
const { Title, Text } = Typography;
const axios = require('axios').default;
const moment = require('moment');



/**
 *
 *
 * @class ModalTransaccionesDetalle
 * @extends {Component}
 */
class ModalFacturasDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            factura: {},
            transaccion: null,
            razon_social: null,
            proveedor: null
        }

    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getFactura()
    }


    /**
    * @memberof ModalTran
    * @method getFactura
    * @description Obtiene la informacion de la transacciones para el modal de detalle. Actualiza el state
    * con informacion obtenida.
    */
    getFactura = () => {
        this.setState({ loading: true })

        let url = "/facturas/get"

        if(this.props.nota_credito_modal) url = "/nota-credito"

        axios.get(url, {
            params: { id: this.props.factura_id },
        })
            .then((factura) => {
                const data = factura.data.data;
                console.log("data", data);
                this.setState({factura: data})
            })
            .catch(error => {
                console.log('error', error.response)
            })
            .finally(() => {
                this.setState({ loading: false })
            })


    }

    render() {
        const { factura } = this.state;

        return (
            <Form layout="vertical"   >
                <Title level={3} className="text-center">Detalle Factura </Title>
                <Spin spinning={this.state.loading}>
                    <Row justify="center" style={{ marginTop: "1rem" }} gutter={[16,16]}>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Concepto" >
                                <Text type="secondary" >{factura.concepto}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 12 }}>
                            <Form.Item label="Fecha" >
                                <Text type="secondary" >{moment(factura.fecha).format('LL')}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="UUID" >
                                <Text type="secondary" >{factura.uuid}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 12 }}>
                            <Form.Item label="Emisor">
                                <Space direction="vertical">
                                    <Text type="secondary" >{factura.emisor?.nombre}</Text>
                                    <small>{factura.emisor?.rfc}</small>
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 12 }}>
                            <Form.Item label="Receptor" >
                                <Space direction="vertical">
                                    <Text type="secondary" >{factura.receptor?.nombre}</Text>
                                    <small>{factura.receptor?.rfc}</small>
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col xs={16}>
                            <Title style={{textAlign: "right"}} level={4} className="m-0">Sub-total:</Title>
                        </Col>
                        <Col xs={8} lg={{ span: 8 }} className="flex-right">
                            <Statistic
                                value={factura.sub_total}
                                valueStyle={{ fontSize: '14px', fontWeight: 'bold', color: "black" }}
                                prefix={'$'}
                                suffix={'MXN'}
                            />
                        </Col>
                        <Col xs={16}>
                            <Title style={{textAlign: "right"}} level={4} className="m-0">Impuestos Tras.:</Title>
                        </Col>
                        <Col xs={8} lg={{ span: 8 }} className="flex-right">
                            <Statistic
                                value={factura.total_impuestos_trasladados}
                                valueStyle={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}
                                prefix={'$'}
                                suffix={'MXN'}
                            />
                        </Col>
                        <Col xs={16}>
                            <Title style={{textAlign: "right"}} level={4} className="m-0">Total:</Title>
                        </Col>
                        <Col xs={8} lg={{ span: 8 }} className="flex-right">
                            <Statistic
                                value={factura.total}
                                valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF00' }}
                                prefix={'$'}
                                suffix={'MXN'}
                            />
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        width={700}
    >
        <ModalFacturasDetalle {...props} />
    </Modal>

}