
import React from 'react'
import Cookies from 'universal-cookie'

export const Auth = () => {

    let cookie = new Cookies()

    let sessionStorageStatus = sessionStorage.getItem("token") !== null && sessionStorage.getItem("token") !== undefined

    if (sessionStorageStatus)  
        return true;

    if (!sessionStorageStatus && cookie.get("token") !== null && cookie.get("token") !== undefined) {
        sessionStorage.setItem("token", cookie.get("token"))
        return true;
    }

    return false;
}
