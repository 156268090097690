import React from 'react'
import { Route, Switch } from "react-router-dom";


// componentes
import Categorias from '../components/Admin/Categorias/Categorias'



/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCuentas(props) {

    return (
        <Switch>
            <Route exact path="/admin/categorias" render={() => <Categorias {...props} />} />

            

        </Switch>
    )
}

export default RouterCuentas;
