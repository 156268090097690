import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Col, Row, message, Card, PageHeader, Tag,
    List, Spin, Typography, Radio
} from 'antd'
import axios from 'axios'

import { CardInfoCustomerHeader } from '../../Widgets/Cards'

import { SocketContext } from '../../../Hooks/Socket';

const moment = require('moment');
const { Text, Title } = Typography;
const { Content } = Layout;


/**
 *
 *
 * @export
 * @class DashboardSpot
 * @extends {Component}
 */

class DashboardSpot extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,


            ordenes: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
                status: 2

            },


            datos: {
                aceptadas: 0,
                en_proceso: 0,
                pagado: 0,
                por_aceptar: 0,
                por_cotizar: 0,
                por_pagar: 0,
            },

            filtroSearch: '',
            searching: true,
            showFiltro: {
            },
            monto_a_pagar: 0,
            pagado: 0,
            saldo: 0,



        }
    }

    /**
     * @memberof DashboardSpot
     * @method componentDidMount 
     * @description Cargamos la informacion inicial
     */
    componentDidMount() {

        console.log(this.props.search)

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getData()
    }

    componentDidUpdate(prevProps){
        if(this.state.ordenes.search !== this.props.search){

            this.getData({ search: this.props.search })
        }
    }

    /**
     * @memberof DashboardSpot
     * @method getData 
     * @description Obtiene la informacion de el spot
     * 
     * @params initial Bool si el valor es true obtiene toda la informacion ncesaria para el dashboard,
     * caso contrario solo obtiene los datos de las cotizacion spot
     */
    getData = (
        {
            page = this.state.ordenes.page,
            limit = this.state.ordenes.limit,

            status = this.state.ordenes.status,

            search = this.props.search,
        } = this.state.ordenes
    ) => {
        this.setState({ loading: true, ordenes: {
            ...this.state.ordenes, page, limit, status, search
        }})
        axios.get('/customer/dashboard/spot', {
            params: {
                page, limit, status, search
            }
        }).then(({ data }) => {

            const ordenes = data.ordenes
            console.log("X A",data)
            this.setState({
                ordenes: {...this.state.ordenes, data: ordenes.itemsList, page: ordenes.currentPage, total: ordenes.itemCount, pages: ordenes.pageCount},

                datos: data.data ? data.data : this.state.datos,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la información')
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof DashboardSpot
     * @method renderStatus 
     * @description renderiza los status
     */
    renderStatus = (status) => {
            let tags = {
                1: <Tag color="blue">Creada</Tag>,
                2: <Tag color="purple">Enviada</Tag>,
                3: <Tag color="green">Aceptada</Tag>,
                4: <Tag color="red">Rechazada</Tag>,
                5: <Tag color="purple">Recotizando</Tag>,
    
            }
            return status ? tags[status] : <Tag>N/A</Tag>
    }

    getPMLink = (cot_status, log_status, id) => {
        let seccion = ''

        if(log_status > 0){
            switch(log_status){
                case 1:
                    seccion = "produccion"
                    break
                case 2:
                    seccion = "transito"
                    break
                case 3:
                    seccion = "entrega"
                    break
                default:
                    seccion = "produccion"
            }
        }else{
            switch(cot_status){
                case 1:
                case 2:
                case 4:
                case 5:
                    seccion = "cotizaciones"
                    break
                case 3:
                    seccion = "accounting"
                    break
                case 6:
                    seccion = "entrega"
                    break
                default: 
                    seccion = "cotizaciones"
            }
        }


        return `/customer/misPedidos/${id}/${seccion}`
        
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                                    <span className="ant-page-header-heading-title" >
                                        Mis Pedidos
                                    </span>
                                </Col>
                            </Row>
                        }
                    />
                    <Content className="admin-content content-bg pd-1">

                        <Row>
                            <Col xs={24} className='mb-1'>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} md={12} xl={6}>
                                        <CardInfoCustomerHeader
                                            bg_color={"linear-gradient(89.69deg, #F39034 2.03%, #FF2727 99.73%)"}
                                            titulo={"Pendientes a Cotizar"}
                                            texto={"Cotizaciones"}
                                            valor={this.state.datos?.por_cotizar}
                                            icon="IconPdtCot"
                                        />

                                    </Col>
                                    <Col xs={24} md={12} xl={6}>
                                        <CardInfoCustomerHeader
                                            bg_color={"linear-gradient(270deg, #003AD2 0%, #0097EC 100%)"}
                                            titulo={"Pendientes a Aceptar"}
                                            texto={"Cotizaciones"}
                                            valor={this.state.datos.por_aceptar}
                                            icon="IconPdtAcp"
                                        />
                                    </Col>
                                    <Col xs={24} md={12} xl={6}>
                                        <CardInfoCustomerHeader
                                            bg_color={"linear-gradient(270.23deg, #00A843 0.19%, #1FD071 99.8%)"}
                                            titulo={"Aceptadas"}
                                            texto={"Cotizaciones"}
                                            valor={this.state.datos.aceptadas}
                                            icon="IconPrdAcp"
                                        />

                                    </Col>
                                    <Col xs={24} md={12} xl={6}>
                                        <CardInfoCustomerHeader
                                            bg_color={"linear-gradient(269.97deg, #5900C9 0.02%, #9852F0 99.98%)"}
                                            titulo={"En Proceso"}
                                            texto={"Cotizaciones"}
                                            valor={this.state.datos.en_proceso}
                                            icon="IconPrdPrc"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} xl={19} >
                                        <Card className='pd-0'>
                                            <Row className='mb-1'>
                                                <Col xs={24} lg={6} >
                                                    <Title level={5} className='title-blue'> Cotizaciones Spot</Title>
                                                </Col>

                                                <Col xs={24} lg={18} className='right '>
                                                    <Radio.Group
                                                        defaultValue={2}
                                                        buttonStyle="solid"
                                                        className='right item-radio'
                                                        onChange={e => this.getData({
                                                            status: e.target.value
                                                        })}
                                                    >
                                                        <Radio.Button value={null}>Todas</Radio.Button>
                                                        <Radio.Button value={1}>Por Cotizar</Radio.Button>
                                                        <Radio.Button value={2}>Por Aceptar</Radio.Button>
                                                        <Radio.Button value={3}>En Proceso</Radio.Button>
                                                        <Radio.Button value={6}>Completadas</Radio.Button>
                                                    </Radio.Group>
                                                </Col>

                                            </Row>

                                            <List
                                                loading={this.state.loading}
                                                className="component-list "
                                                itemLayout="horizontal"
                                                locale={{ emptyText: "Sin información para mostrar" }}
                                                dataSource={this.state.ordenes.data}
                                                pagination={{
                                                    current: this.state.ordenes.page,
                                                    pageSize: this.state.ordenes.limit,
                                                    total: this.state.ordenes.total,
                                                    position: 'bottom',
                                                    className: "flex-right",
                                                    onChange: (page, limit) => {
                                                        this.getData({ page, limit })
                                                    }
                                                }}

                                                header={<Row className="header-list width-100" >

                                                    <Col span={6} className="center">
                                                        <Text strong>Folio</Text>
                                                    </Col>
                                                    <Col span={6} className="center">
                                                        <Text strong>Fecha</Text>
                                                    </Col>
                                                    <Col span={6} className="center">
                                                        <Text strong>Estatus</Text>
                                                    </Col>
                                                    <Col span={6} className="center">
                                                        <Text strong>Monto</Text>
                                                    </Col>
                                                </Row>}

                                                renderItem={item => (
                                                    <List.Item
                                                        className="component-list-item row-list-item">
                                                        <Link className="width-100" to={this.getPMLink(item.cot_status, item.logistica_estatus, item._id)}>
                                                            <Row className="width-100 font-12 hover">
                                                                <Col span={6} className="center">
                                                                    <Text className='item-orden-id'>{item.folio}</Text>
                                                                </Col>
                                                                <Col span={6} className="center">
                                                                    <Text className='text-blue' >{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                                                </Col>
                                                                <Col span={6} className="center">
                                                                    {/* <Text className='item-estatus'> {this.renderStatus(item.cot_status)} </Text> */}
                                                                    {this.renderStatus(item.cot_status)}
                                                                </Col>
                                                                <Col span={6} className="center">
                                                                    <Text className='title-blue' >$ {item.total ? item.total.toMoney(true) : 0} MXN</Text>
                                                                </Col>
                                                            </Row>
                                                        </Link>
                                                    </List.Item>
                                                )}
                                            />
                                        </Card>
                                    </Col>
                                    <Col xs={24} xl={5}>
                                        <Link className="text-gray" to={"/customer/misPedidos/cotizaciones"}>
                                            <Card >

                                                <Row style={{ borderBottom: "solid 1px #CCC" }}>
                                                    <Title level={5} className='title-blue'> Cotizaciones Autorizadas</Title>
                                                    <Col span={24} className='mb-1'>
                                                        <Text className='text-blue'>Monto a Pagar </Text>
                                                        <Text className='right text-blue'>$ {this.state.datos.por_pagar}</Text>
                                                    </Col>
                                                    <Col span={24} className='mb-1'>
                                                        <Text className='text-blue'>Pagado </Text>
                                                        <Text className='right text-blue'>$ {this.state.datos.pagado}</Text>
                                                    </Col>
                                                    <Col span={24} className='mb-1'>
                                                        <Text className='text-blue'>Saldo </Text>
                                                        <Text level={5} strong style={{ borderTop: "solid 0.5px #CCC" }} className="right title-blue">$ {(this.state.datos.por_pagar + this.state.datos.pagado).toMoney(true)}</Text>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Spin>
            </>

        )
    }
}

export default function (props){

    
    const socket = React.useContext(SocketContext)

    const onNotificaciones = ({notificaciones, count}) => {
        if(count > 0){
            console.log(notificaciones, count)
            socket.emit('leidos', notificaciones.data.map(notificacion => notificacion._id))
            window.location.reload()
        }
    }

    React.useEffect(() => { 

        socket.on('notificaciones', onNotificaciones)

        return () => {
            socket.off('notificaciones', onNotificaciones)
        }
    }, [socket])

    return <DashboardSpot {...props}/>
}