import React, { Component } from "react";
import {

    Layout,
    Modal,
    Dropdown,
    Menu,
    Button, Col, Row, Space, Popconfirm, Collapse, Typography, List, message, Pagination
} from "antd";
import {
    ReloadOutlined, WarningFilled, MinusOutlined, PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    MoreOutlined
} from '@ant-design/icons'

import { IconDelete, IconEdit, IconStockGreen, IconStockYellow, IconStockRed } from '../../../Widgets/Iconos';
import FloatingButton from './../../../Widgets/Floating Button/FloatingButton';

import ModalTraspasoProductos from './../ModalTraspasoProductos'
import ModalSolicitudProductos from './../ModalSolicitudProductos'

import ModalRegistroProducto from "../ModalRegistroProducto";
import ModalRegistroAlmacen from "../ModalRegistroAlmacen";


//css
import '../../../../Styles/Modules/ManagerInventarios/ManagerInventarios.css'

const { Content } = Layout;
const { Text } = Typography
const { Panel } = Collapse;
const axios = require('axios')

var tabActiva = "";

/**
 * @export
 * @class ProductosList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class ProductosList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            productos: [],
            productosCliente: [],
            cliente_id: "",

            page: 1,
            limit: 20,
            total: 0,

            producto_id: undefined,
            modalVisible: false,
            modalVisibleTraspaso: false,
            modalVisibleSolicitud: false,
            modalVisibleRegistro: false,
            modalVisibleAlmacen: false,
            modalVisibleAlmacenNR: false,

            actualizarTabla: false,

            almacen_ids: [],

            tmp_producto_id: undefined,
            tmp_inventario_id: undefined,
            tmp_inventario_producto_id: undefined,

            tmp_almacen_id: undefined,
            tmp_almacen: undefined,
            tmp_inv_max: 0,


            filtroSearch: '',
            searching: true,
            loading: false,



                        
            almacen_producto_id: null,
            producto_id: null

        }
    }




    componentDidMount = () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        var ruta = window.location.pathname;
        var cliente_id = ruta.split("/")[((ruta.split("/").length) - 1)]

        if (cliente_id !== "vendedor") {
            this.state.cliente_id = cliente_id;
            this.setState({
                cliente_id: cliente_id
            })
        }


        this.getProductos()
    }


    componentDidUpdate = () => {
        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getProductos();
            this.state.searching = false;
        }


        if (this.props.actualizarTabla !== tabActiva) {
            tabActiva = this.props.actualizarTabla;
        }
        if (tabActiva === "Productos" && this.state.actualizarTabla === true) {
            this.state.actualizarTabla = false;
            this.setState({ actualizarTabla: false })
            this.getProductos();


        }
        else if (tabActiva !== "Productos" && !(this.state.actualizarTabla)) {
            this.state.actualizarTabla = true;
            this.setState({ actualizarTabla: true })
        }




    }



    getProductos = ({

        page = this.state.page,

        limit = this.state.limit,
    } = this.state) => {

        let search = this.props.search;
        this.setState({ page, limit, loading: true })

        axios.get('/vmi/list', {
            params: {
                page,
                limit,
                productos: true,
                cliente_id: this.state.cliente_id,
                search
            }
        })
            .then(({ data }) => {

                var array_artCliente = [], dataClienteArt = data.data.itemsList;
                for (let index = 0; index < dataClienteArt.length; index++) {
                    const reg = dataClienteArt[index];
                    array_artCliente.push({ _id: reg.producto_id._id });
                }
                this.setState({
                    productos: [...data.data.itemsList],
                    productosCliente: [...array_artCliente],
                    total: data.data.itemCount,
                    searching: true,
                    filtroSearch: search,
                    loading: false
                })

            })
            .catch(error => {
                message.error("No se obtuvieron los productos")
                console.log(error)
            })
    }


    render() {

        return (
            <>
                <Content>
                    <Row>
                        <Col span={24}>
                            <List
                                loading={this.state.loading}
                                dataSource={this.state.productos}
                                className="component-list lista-vmi"
                                locale={{ emptyText: "Sin Productos" }}

                                renderItem={producto => (

                                    <Collapse
                                        bordered={false}
                                        defaultActiveKey={['1']}
                                        expandIcon={({ isActive }) => isActive ?
                                            <MinusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} /> :
                                            <PlusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} />}
                                        className="collapse-almacenes"
                                    >
                                        <Panel
                                            header={
                                                <Row className="width-100 header-collapse " >
                                                    <Col xs={14} md={20} xl={5} xxl={5} className="flex-left">
                                                        {producto?.cantidad <= producto?.inv_minimo ?
                                                            <IconStockRed className="text-red collapse-text-big collapse-icon-big" />
                                                            :
                                                            producto?.cantidad >= (((producto?.inv_maximo - producto?.inv_minimo) * 0.3) + producto?.inv_minimo) ?
                                                                <IconStockGreen className="text-green collapse-text-big collapse-icon-big" />
                                                                :
                                                                <IconStockYellow className="text-yellow collapse-text-big collapse-icon-big" />
                                                        }
                                                        <Text className="collapse-text-big" style={{ marginLeft: 5 }}>{producto.producto_id?.np_fabricante}</Text>
                                                    </Col>
                                                    <Col xs={8} md={8} xl={2} xxl={2} className="flex-column">
                                                        <Text>Stock</Text>
                                                        <Text>{producto?.cantidad}</Text>
                                                    </Col>
                                                    <Col xs={8} md={8} xl={2} xxl={2} className="flex-column">
                                                        <Text>Precio</Text>
                                                        <Text strong>$ {producto?.precio.toMoney(true)}</Text>
                                                    </Col>
                                                    <Col xs={8} md={8} xl={2} xxl={2} className="flex-column">
                                                        <Text>Total</Text>
                                                        <Text strong>$ {(producto?.precio * producto?.cantidad).toMoney(true)}</Text>

                                                    </Col>
                                                    <Col xs={8} md={8} xl={2} xxl={1} className="flex-column">
                                                        <Text>Min</Text>
                                                        <Text>{producto?.inv_minimo}</Text>
                                                    </Col>
                                                    <Col xs={8} md={8} xl={2} xxl={1} className="flex-column  ">
                                                        <Text>Max</Text>
                                                        <Text>{producto?.inv_maximo}</Text>
                                                    </Col>
                                                    <Col xs={24} md={24} xl={8} xxl={10}>
                                                        <Row justify="center" className="text-center">
                                                            <Col span={8} className="flex-column">
                                                                <Text strong className="collapse-text-medium">Nexus</Text>
                                                                <Text >{producto?.producto_id?.np_nexus}</Text>
                                                            </Col>
                                                            <Col span={8} className="flex-column">
                                                                <Text strong className="collapse-text-medium">Proveedor</Text>
                                                                <Text >{producto?.proveedor_id?.nombre}</Text>
                                                            </Col>
                                                            <Col span={8} className="flex-column">
                                                                <Text strong className="collapse-text-medium">Cliente</Text>
                                                                <Text >{producto?.np_cliente}</Text>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={24} md={24} xl={1} xxl={1} className="center">
                                                        <Dropdown overlay={
                                                            <Menu mode="horizontal">
                                                                <Menu.Item

                                                                    onClick={e => {
                                                                        // e.preventDefault();
                                                                        // e.stopPropagation();
                                                                        const almacen_ids = producto.almacenes.map(almacen => almacen.almacen_id?._id)

                                                                        this.setState({
                                                                            modalVisibleAlmacen: true,

                                                                            tmp_inv_max: producto.inv_maximo,
                                                                            tmp_producto_id: producto.producto_id._id,
                                                                            tmp_inventario_id: producto.inventario_id,
                                                                            tmp_inventario_producto_id: producto._id,
                                                                            almacen_ids,
                                                                            tmp_almacen_id: undefined,
                                                                            tmp_almacen: undefined
                                                                        })
                                                                    }}
                                                                    key="agregar-almacen" icon={<PlusOutlined style={{ color: "currentcolor" }} />}>Agregar Almacen</Menu.Item>
                                                                <Menu.Item
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            modalVisibleRegistro: true,
                                                                            producto_id: producto._id
                                                                        })
                                                                        // e.preventDefault();
                                                                        // e.stopPropagation();
                                                                    }}
                                                                    key="editar-producto" icon={<EditOutlined style={{ color: "currentcolor" }} />}>Editar Producto</Menu.Item>

                                                                {producto?.cantidad === 0 ?
                                                                    <Menu.Item
                                                                        onClick={(e) => {
                                                                            // e.preventDefault();
                                                                            // e.stopPropagation();

                                                                            Modal.confirm({
                                                                                title: "¿Deseas eliminar este producto?",
                                                                                okText: "Si",
                                                                                cancelText: "No",
                                                                                onOk: () => {
                                                                                    axios.post('/inventarios/productos/deleteProducto', { id: producto._id })
                                                                                        .then(response => {
                                                                                            message.success('¡Producto eliminado!')
                                                                                            this.getProductos()
                                                                                        })
                                                                                        .catch(error => {
                                                                                            message.error('¡No se pudo eliminar el Producto!')
                                                                                            console.log(error)
                                                                                        })
                                                                                }
                                                                            })
                                                                        }}
                                                                        key="eliminar-producto" icon={<DeleteOutlined style={{ color: "currentcolor" }} />}>Eliminar Producto</Menu.Item>
                                                                    : null}

                                                            </Menu>
                                                        }>
                                                            <MoreOutlined style={{ fontSize: 30 }} justify="end" />
                                                        </Dropdown>
                                                    </Col>
                                                </Row>
                                            }
                                        >
                                            <Row>
                                                <Col span={11}>
                                                    <Text className="text-gray">Dirección</Text>
                                                </Col>
                                                <Col span={3} className="center">
                                                    <Text className="text-gray">Ubicación</Text>
                                                </Col>
                                                <Col span={3} className="center">
                                                    <Text className="text-gray">Total</Text>
                                                </Col>
                                                <Col span={3} className=" flex-right" style={{ paddingRight: '1.5rem' }}>
                                                    <Text className="text-gray">Cantidad</Text>
                                                </Col>
                                                <Col span={4} className="flex-right" style={{ paddingRight: '1.5rem' }}>

                                                </Col>
                                            </Row>
                                            {
                                                producto?.almacenes?.map((almacen) => {

                                                    const { almacen_id, cantidad, ubicacion, _id, inventario_id } = almacen
                                                    return (
                                                        <Row>
                                                            <Col span={11}>
                                                                <Text ellipsis>{almacen_id?.direccion}</Text>
                                                            </Col>
                                                            <Col span={3} className="center">
                                                                <Text >{ubicacion}</Text>
                                                            </Col>
                                                            <Col span={3} className="center">
                                                                <Text >$ {(producto?.precio * cantidad).toMoney(true)}</Text>
                                                            </Col>
                                                            <Col span={3} className="center flex-right" style={{ paddingRight: '1.5rem' }}>
                                                                <Text >{cantidad}</Text>
                                                            </Col>
                                                            <Col span={4} className="flex-right" style={{ paddingRight: '1.5rem' }}>

                                                                <Button
                                                                    type="primary"
                                                                    icon={<IconEdit />}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            modalVisibleAlmacen: true,
                                                                            tmp_producto_id: producto.producto_id._id,
                                                                            tmp_inventario_id: producto.inventario_id,
                                                                            tmp_inventario_producto_id: producto._id,
                                                                            tmp_almacen_id: almacen_id._id,
                                                                            tmp_almacen: {
                                                                                direccion: almacen_id?.direccion,
                                                                                ubicacion: ubicacion,
                                                                                cantidad: cantidad
                                                                            }
                                                                        })
                                                                    }}
                                                                    title="Editar"
                                                                    style={{ marginRight: "8px" }}
                                                                />

                                                                <Button
                                                                    title="Traspaso"
                                                                    icon={<ReloadOutlined className="text-white" />}
                                                                    type="primary"
                                                                    className="btn-traspaso"

                                                                    onClick={() => {
                                                                        console.log("almacen", JSON.stringify(almacen))
                                                                        console.log("producto", JSON.stringify(producto))
                                                                        this.setState({
                                                                            modalVisibleTraspaso: true,
                                                                            almacen_producto_id: almacen._id,//producto.producto_id._id,
                                                                            producto_id: producto.producto_id._id,

                                                                            // modalVisibleAlmacen: true,
                                                                            // tmp_producto_id: producto.producto_id._id,
                                                                            // tmp_inventario_id: producto.inventario_id,
                                                                            // tmp_inventario_producto_id: item._id,
                                                                            // tmp_almacen_id: almacen_id._id,
                                                                            // tmp_almacen: {
                                                                            //     direccion: almacen_id?.direccion,

                                                                            // {
                                                                            //     id: almacen_id._id,
                                                                            //     direccion: almacen_id.direccion,
                                                                            //     inventario_id: inventario_id,
                                                                            //     producto: {
                                                                            //         id: item.producto_id?._id,
                                                                            //         marca: item.producto_id?.marca
                                                                            //     },
                                                                            //     almacen_producto: {
                                                                            //         id: item?._id,
                                                                            //         cantidad: cantidad
                                                                            //     }
                                                                            // }
                                                                        })
                                                                    }}

                                                                >
                                                                </Button>
                                                                <Button
                                                                    title="Solicitud"
                                                                    style={{ marginRight: '0.5rem' }}
                                                                    icon={<WarningFilled className="text-white" />}
                                                                    type="primary"

                                                                    onClick={() => this.setState({
                                                                        modalVisibleSolicitud: true,
                                                                        producto: producto,
                                                                        almacen: almacen
                                                                    })}
                                                                >
                                                                </Button>


                                                                {cantidad === 0 ?
                                                                    <Popconfirm
                                                                        placement="topRight"
                                                                        title="¿Deseas eliminar este producto del almacén?"
                                                                        okText="Si"
                                                                        cancelText="No"
                                                                        onConfirm={() => {
                                                                            axios.post('/almacenes/productos/delete', { id: _id })
                                                                                .then(response => {
                                                                                    message.success('¡Producto de Almacén eliminado!')
                                                                                    this.getProductos()
                                                                                })
                                                                                .catch(error => {
                                                                                    message.error('¡No se pudo eliminar el Producto de Almacén!')
                                                                                    console.log(error)
                                                                                })
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            type="primary"
                                                                            icon={<IconDelete />}
                                                                            title="Eliminar"
                                                                            danger
                                                                        />
                                                                    </Popconfirm>
                                                                    :
                                                                    <Button
                                                                        type="primary"
                                                                        icon={<IconDelete />}
                                                                        title="Eliminar"
                                                                        disabled
                                                                        danger
                                                                    />
                                                                }

                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </Panel>
                                    </Collapse>
                                )}
                            />
                        </Col>


                        <Col span={24}>
                            <Pagination
                                current={this.state.page}
                                pageSize={this.state.limit}
                                total={this.state.total}
                                showSizeChanger={true}
                                onChange={(page, limit) => this.getProductos({ page, limit })}
                            />
                        </Col>
                    </Row>

                    <FloatingButton title="Nuevo registro" onClick={() => {

                        this.setState({ modalVisibleRegistro: true })
                    }} />
                </Content>

                <ModalRegistroAlmacen

                    id={this.state.tmp_almacen_id}
                    visible={this.state.modalVisibleAlmacen}
                    cliente_id={this.state.cliente_id}

                    almacen_ids={this.state.almacen_ids}

                    producto_id={this.state.tmp_producto_id}
                    inventario_id={this.state.tmp_inventario_id}
                    inventario_producto_id={this.state.tmp_inventario_producto_id}

                    inv_max={this.state.tmp_inv_max}

                    almacen={this.state.tmp_almacen}
                    onSuccess={() => {
                        this.setState({
                            modalVisibleAlmacen: false,
                            tmp_producto_id: undefined,
                            tmp_inventario_id: undefined,
                            tmp_almacen_id: undefined,
                            tmp_almacen: undefined,
                            tmp_inv_max: 0
                        })

                    }}
                    onClose={() => {
                        this.setState({
                            modalVisibleAlmacen: false,
                            tmp_producto_id: undefined,
                            tmp_inventario_id: undefined,
                            tmp_almacen_id: undefined,
                            tmp_almacen: undefined,
                            tmp_inv_max: 0
                        })
                        this.getProductos()
                    }}
                />


                <ModalRegistroProducto
                    id={this.state.producto_id}
                    cliente_id={this.state.cliente_id}
                    visible={this.state.modalVisibleRegistro}
                    inventario_id={this.state.inventario?._id}


                    onSuccess={() => {
                        this.setState({ modalVisibleRegistro: false })

                    }}
                    onClose={() => {
                        this.setState({ modalVisibleRegistro: false })
                        this.getProductos()
                    }}
                />
                <ModalTraspasoProductos
                    visible={this.state.modalVisibleTraspaso}
                    almacen_producto_id={this.state.almacen_producto_id}
                    producto_id={this.state.producto_id}
                    onSuccess={() => {
                        this.getProductos();
                    }}
                    onClose={() => {
                        this.setState({
                            modalVisibleTraspaso: false,
                            almacen: undefined,
                        });
                        this.getProductos();
                    }}
                />
                <ModalSolicitudProductos
                    visible={this.state.modalVisibleSolicitud}
                    cliente_id={this.state.cliente_id}
                    almacen={this.state.almacen?.almacen_id}
                    producto={this.state.producto}
                    onClose={() => {
                        this.setState({
                            modalVisibleSolicitud: false,
                            almacen: undefined,
                            producto: undefined
                        });
                        this.getProductos();
                    }}
                    productosList={true}
                />

            </>
        )
    }
}
