import React, { Component } from "react";


import { Row, Col, Form, Modal, Button, Radio, Typography, Checkbox, message, Spin, Divider, Upload,  } from 'antd';

import { UploadOutlined } from '@ant-design/icons'

import axios from 'axios';


import { IconCreditoNexus, IconCreditCard } from '../../../Widgets/Iconos';




import { loadStripe } from '@stripe/stripe-js';
import { Elements, } from '@stripe/react-stripe-js';


import { SocketContext } from '../../../../Hooks/Socket';
import { User } from '../../../../Hooks/Logged';

//componentes
import { CheckoutForm } from '../../../Widgets/paypear/PaymentForm/Stripe'
import "../../../../Styles/Modules/Customer/ModalPago.scss";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, { locale: 'en' });
const { Title, Paragraph } = Typography;


/**
 * @class ModalPago
 * @extends {Component}
 */
class ModalPago extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            return: false,
            premium: false,
            is_credito: false,
            factura: true,
            update: false,
            files: []
        }
    }

    ModalPago = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    onChange = (event) => {
        this.setState({ is_credito: (event.target.value === 2) })
    }

    onFinish = (values) => {
        this.props.updateCotizacion({ status: 3, factura: this.state.factura,  })
        this.props.onClose()
    }

    setCredito = () => {
        this.setState({loading: true}, () => {
            this.props.updateCotizacion({
                status: 3,
                facturado: this.state.factura,
                comprar: true,
                ordenes_compra: this.state.files.map(file => file.name)
            })
            .then(()=>{

                if(this.props.socket?.connected){
                    console.log('Socket corriendo')
                    let notificacion = {
                        evento: `El cliente ha pagado la cotizacion`,
                        detalle: `El cliente ha pagado la orden ${this.props.folio}`,
                        usuarios_destino: [],
                        cliente: true,
                        usuario_id: this.props.user._id
                    }
                    this.props.socket.emit('nueva_notificacion', notificacion)
                }

                this.props.onClose()
            }).catch(error => {
                message.error('Error al aceptar la cotización')
            }).finally(e => this.setState({ loading: false }))
        })
        
    }

    removeFile = (filename) => {
        axios.post("/upload/delete", {
            filename
        })
            .then(res => {
                console.log("Archivo removido con exito");
                this.setState({
                    files: this.state.files.filter(file => (file.response?.filename !== filename && file.name !== filename))
                })
            })
            .catch(res => {
                console.log("Archivo no se puedo remover");
            })
    };

    normFile = (e) => {
        const { file, fileList } = e;

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                //this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                files: fileList.map(item => ({
                    url: axios.defaults.baseURL + '/voucher/' + item.response.filename,
                    name: item.response.filename
                }))
            })
        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !== undefined) ? file.response.filename : file.name);
    };

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Row className="mt-1"  >
                    <Col span={12}>
                        <Title level={4}>Subtotal: </Title>
                    </Col>
                    <Col span={12} align="end">
                        <Title level={5} className="text-gray">$ {this.props.subtotal?.toMoney(true)} MXN</Title>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col span={12}>
                        <Title level={4}>IVA (16%): </Title>
                    </Col>
                    <Col span={12} align="end">
                        <Title level={5} className="text-gray">$ {this.props.impuestos?.toMoney(true)} MXN</Title>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col span={12}>
                        <Title level={3}>Total a pagar: </Title>
                    </Col>
                    <Col span={12} align="end">
                        <Title level={4} className="text-gray">$ {this.props.total.toMoney(true)} MXN</Title>
                    </Col>
                </Row>
                {(this.props.user?.cliente_id?.premium) ?
                    <Row className="mt-2 " align="center" justify="center">
                        <Radio.Group className="radio-payment-selection" defaultValue={1} onChange={this.onChange}>
                            <Radio.Button className="button-payment-selection" value={1}>
                                <IconCreditoNexus className="icon" style={{ color: "currentcolor", }} width={40} />
                                <span className="text-payment-selection">Crédito Nexus</span>
                            </Radio.Button>
                            <Radio.Button className="button-payment-selection" value={2}>
                                <IconCreditCard className="icon credit-card" style={{ color: "currentcolor", }} color="currentcolor" width={40} />
                                <span className="text-payment-selection">Pago con Tarjeta</span>
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                    : null
                }
                {(!this.props.user?.cliente_id?.premium || this.state.is_credito) ? <Row className={`width-100 `}>
                        <Col span={24}>
                            <Elements stripe={stripePromise}>
                                <CheckoutForm {...this.props}
                                    facturado={this.state.factura}
                                    extra={<Checkbox
                                        checked={this.state.factura}
                                        onChange={value => this.setState({ factura: value.target.checked })}
                                    >Incluir Factura</Checkbox>}
                                />
                            </Elements>
                        </Col>
                    </Row>
                    :
                    <Row className="mt-1 w-100" justify="center">
                        <Col span={24} style={{ marginTop: "1em" }}>
                            <Paragraph level={5}>Al aceptar, el total de su compra será añadido a su saldo pendiente. </Paragraph>
                            <Paragraph level={5}>Una vez realizado el pago y liquidación del adeudo, la cantidad total volverá a estar disponible en su línea de crédito.</Paragraph>
                            <Checkbox
                                checked={this.state.factura}
                                onChange={value => this.setState({ factura: value.target.checked })}
                            >Incluir Factura</Checkbox>
                        </Col>
                        <Upload
                            className="avatar-uploader width-100 mt-1"
                            action={axios.defaults.baseURL + "/voucher/add"}
                            multiple={true}
                            onChange={this.normFile}
                        >
                            <Button className="width-100">
                                <UploadOutlined /> Subir Ordenes Compra
                            </Button>
                        </Upload>
                        <Col span={24} style={{ marginTop: "2em" }}>
                            <Button loading={this.state.loading} type="primary" block size="large" onClick={this.setCredito}>
                                Comprar
                            </Button>
                        </Col>
                    </Row>
                }
            </Spin>
        )
    }
}

export default function (props) {
    const { visible = false, onClose = () => { } } = props

    const socket = React.useContext(SocketContext)
    const user = React.useContext(User)

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        style={{ padding: "1em" }}
    >
        <div className="center">
            <Title level={3}>Pago de Orden</Title>
        </div>
        <ModalPago {...props} socket={socket} user={user}/>
    </Modal>

}