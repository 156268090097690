
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Navbar from '../components/Header/Header';
import { Layout } from "antd";

import Cookies from "universal-cookie";

import { User, SetUser } from '../Hooks/Logged';

import Admin_Sidebar from "../components/Sidebar/Sidebar";


// ROUTERS
import RouterDashboard from "./RouterDashboard";
import RouterFinanzas from "./RouterFinanzas";
import RouterClientes from "./RouterClientes";
import RouterUsuarios from "./RouterUsuarios";
import RouterNegocios from "./RouterNegocios";
import RouterAreas from "./RouterAreas";
import RouterRazonesSociales from "./RouterRazonesSociales";
import RouterProveedores from "./RouterProveedores";
import RouterClasificadores from "./RouterClasificadores";
import RouterProjectManager from "./RouterProjectManager";
import RouterInventarios from "./RouterInventarios";
import RouterChat from "./RouterChat";

import RouterCompras from "./RouterCompras";



import '../Styles/Global/admin.css';
import RouterCuentas from "./RouterCuentas";
import RouterProductos from "./RouterProductos";
import RouterManagerInventarios from "./RouterManagerInventarios";


import RouterKPIs from "./RouterKPIs";
import RouterContabilidad from "./RouterContabilidad";
import RouterCategorias from "./RouterCategorias"
// import RouterOrdenesCompras from "./RouterOrdenesCompras";

import ISYWidget from "iseeyoutech-widget-web/dist/iseeyoutech-widget-web.es.js";
import "iseeyoutech-widget-web/dist/style.css";


const { Content } = Layout;


class AdminRoutes extends Component {

    static contextType = User;

    constructor(props) {
        super(props)
        this.state = {
            back: false,
            responsiveSidebar: false,

            showSearch: false,
            filterSearch: "",
            searching: false,
            collapsed: false,
        };
    }

    SetFilterSearch(e) {
        this.setState({ filterSearch: e })
    }

    updateFilterSearch = (ShowFilter) => {
        console.log("mostrar filtro : ", ShowFilter)
        if (ShowFilter === undefined) { ShowFilter = false }
        this.setState({
            showSearch: ShowFilter,
        })
    };


    render() {
        const { back } = this.state;
        const { updateFilterSearch } = this;

        if (this.props.user?.tipo === 4) {
            return <Redirect to={'/customer/misPedidos'} />
        }


        return (
            <Layout className="layout"  >
                <User.Consumer>
                    {value => { this.context = value }}
                </User.Consumer>
                <Navbar
                    user={this.context}
                    back={back}
                    onBack={this.onBack}
                    setUser={this.setUser}
                    collapsed={this.state.collapsed}
                    setCollapse={value => this.setState({ collapsed: value })}

                    showResponsiveSidebar={() => this.setState({ responsiveSidebar: !this.state.responsiveSidebar })}
                    showSearch={this.state.showSearch}
                    setFilterSearch={(e) => this.SetFilterSearch(e)}
                    filterSearchVal={this.state.filterSearch}
                    admin={true}
                />
                <Layout>
                    <Admin_Sidebar
                        collapsed={this.state.collapsed}
                        setCollapse={value => this.setState({ collapsed: value })}
                        user={this.context}
                        back={back}
                        setUser={this.setUser}

                    />
                    <Content>
                        <Switch>
                            <Route exact path="/admin/dashboard"
                                render={(props) => <RouterDashboard  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />



                            <Route path="/admin/finanzas"
                                render={(props) => <RouterFinanzas  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route path="/admin/clientes"
                                render={(props) => <RouterClientes  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route path="/admin/project-manager"
                                render={(props) => <RouterProjectManager  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route path="/admin/cuentas"
                                render={(props) => <RouterCuentas  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route exact path="/admin/usuarios"
                                render={(props) => <RouterUsuarios  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route exact path="/admin/negocios"
                                render={(props) => <RouterNegocios  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route path="/admin/proveedores"
                                render={(props) => <RouterProveedores  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route path="/admin/areas"
                                render={(props) => <RouterAreas  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route path="/admin/clasificadores"
                                render={(props) => <RouterClasificadores  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route path="/admin/razones-sociales"
                                render={(props) => <RouterRazonesSociales  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                            <Route path="/admin/productos"
                                render={(props) => <RouterProductos  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />} />

                            <Route path="/admin/manager-inventarios"
                                render={(props) => <RouterManagerInventarios {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route path="/admin/inventarios"
                                render={(props) => <RouterInventarios  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route path="/admin/kpis"
                                render={(props) => <RouterKPIs  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route path="/admin/chat"
                                render={(props) => <RouterChat  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route path="/admin/contabilidad"
                                render={(props) => <RouterContabilidad  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route path="/admin/compras"
                                render={(props) => <RouterCompras {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route path="/admin/categorias"
                                render={(props) => <RouterCategorias {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />




                        </Switch>

                    </Content>
                </Layout>
                {/* <ISYWidget
                    nombre={(this.props?.user?.nombre) + " " + (this.props?.user?.apellidos ?? "")}
                    email={this.props?.user?.email}
                    proyectoId="632b31b87957e70d98f85999"
                    URL="https://ws.admin.iseeyoutech.com"
                /> */}
            </Layout>
        )
    }
}

export default (props) => <AdminRoutes {...props} setUser={React.useContext(SetUser)} user={React.useContext(User)} />