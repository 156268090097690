import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, DatePicker, Input, InputNumber, Select, message, Spin } from 'antd';


const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');



/**
 *
 *
 * @class ModalFacturas
 * @extends {Component}
 */
class ModalFacturas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proveedores: [],
            razones_sociales: [],
            ordenes: [],
            proveedor: undefined,
            razon_social: undefined,
            orden: undefined,
            tipo_cambio: 0,
            disabled: false

        }
    }

    ModalFacturas = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getRazonesSociales()
        this.getProveedores()
        this.getTipoCambio();
        this.getOrdenes();

        if (this.props.factura_id !== undefined || this.props.factura_id != null) {
            this.getFactura();
        }
    }

    /**
    * @memberof ModalFacuras
    * @method getOrdenes
    * @description Trae las ordenes generadas
    * 
    */
    getOrdenes = (search) => {
        axios.get('/ordenes', {
            params: {
                search,
                limit: 20
            }
        }).then(response => {
            this.setState({ ordenes: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Ordenes')
        })
    }

    /**
     * @memberof ModalFacturas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        if (this.props.factura_id) {
            this.updateFactura(values)
        } else {
            this.addFactura(values)
        }
    }

    /**
    * @memberof ModalFacuras
    * @method getProveedores
    * @description Trae los proveedores
    */
    getProveedores = (search) => {
        axios.get('/proveedores/list', {
            params: {
                search,
                paginate: true,
                page: 1
            }
        })
            .then(({data}) => {
                this.setState({
                    proveedores: data.data.itemsList
                })
            })
            .catch(err => console.log(err))
    }

    /**
    * @memberof ModalFacuras
    * @method getRazonesSociales
    * @description Trae las razones sociales
    */
    getRazonesSociales = (search) => {

        axios.get('/razones-sociales', {
            params: { 
                search,
                paginate: true,
                page: 1
             },
            headers: { Authorization: sessionStorage.getItem('token') }
        })
            .then(({data}) => {
                this.setState({
                    razones_sociales: data.data.itemsList
                })
            })
            .catch(err => console.log(err))
    }

    /**
     * @memberof ModalFacturas
     * @method getFactura
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    getFactura = () => {
        this.setState({ loading: true })

        axios.get('/facturas/get', {
            params: { 
                id: this.props.factura_id,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(async response => {
            let factura = response.data.data

            console.log('factura', factura)

            this.ModalFacturas.current?.setFieldsValue({
                concepto: factura.concepto,
                fecha: moment(factura.fecha),
                estatus: factura.estatus,
                tipo: factura.tipo,
                tipo_cambio: factura.tipo_cambio,
                monto: factura.monto,
                monto_dolar: factura.monto_dolar,
                proveedor_id: factura.proveedor_id?._id,
                razon_social_id: factura.razon_social_id?._id,
                orden_id: factura.orden_id?._id,
                uuid: factura.uuid,
                observaciones: factura.observaciones
            })

            this.setState({
                proveedor: factura.proveedor_id,
                razon_social: factura.razon_social_id,
                orden: factura.orden_id,
                disabled: (!!factura.syncfy_id || factura.tipo_facturacion === 1)
            })

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la transaccion')
        }).finally(() => {

            this.setState({ loading: false, disabled: true })
        })
    }


    /**
    * @memberof ModalFacturas
    * @method getTipoCambio
    * @description Trae el ulitmo tipo de cambio utilizado 
    * 
    */
    getTipoCambio = () => {
        axios.get('/tipo-cambio/get').then(response => {
            this.ModalFacturas.current?.setFieldsValue({
                tipo_cambio: response.data.data[0].valor.toMoney()
            })

        }).catch(error => {
            console.log(error);
            message.error('Error al traer tipo de cambio')
        })
    }


    /**
     * @memberof ModalFacturas
     * @method addFactura
     * @description Trae las cuentas 
     */
    addFactura = (values) => {
        console.log('values', values)

        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {

            axios.post('/facturas/add', {
                ...values,
                monto_pesos: parseFloat(values.monto_pesos).toMoney(),
                monto_dolar: parseFloat(values.monto_dolar).toMoney(),
                tipo_cambio: parseFloat(values.tipo_cambio).toMoney(),
            }).then(response => {
                message.success('Transacción creada')
                this.props.onClose()
            }).catch(error => {
                console.log(error)
                message.error('Error al guardar')
            }).finally(() => this.setState({ loading: false }))
        })
        
    }

    /**
    * @memberof ModalFacturas
    * @method updateFactura
    * @description Actualiza la informacion de una factura
    */
    updateFactura = (values) => {
        this.setState({ loading: true })
        axios.post('/facturas/update', {
            ...values,
            monto: values.monto.toMoney(),
            monto_dolar: values.monto_dolar?.toMoney(),
            tipo_cambio: values.tipo_cambio?.toMoney(),
            id: this.props.factura_id
        }).then(response => {
            message.success('Transacción Actualizada')
            this.props.onClose()
        }).catch(error => {
            console.log(error)
            message.error('Error al guardar')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalFacturas
     * @method onChangePesos
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares 
     * 
     */
    onChangePesos = (value) => {
        let tipo_cambio = this.ModalFacturas.current?.getFieldValue('tipo_cambio')
        this.ModalFacturas.current?.setFieldsValue({
            monto_dolar: parseFloat(value / tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalFacturas
     * @method onChangeDolar
     * @description Hace la conversion de dolares a pesos para actualizar el campo de pesos 
     * 
     */
    onChangeDolar = (value) => {
        let tipo_cambio = this.ModalFacturas.current?.getFieldValue('tipo_cambio')
        this.ModalFacturas.current?.setFieldsValue({
            monto_pesos: parseFloat(value * tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalFacturas
     * @method onChangeTipo
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares, usando el nuevo tipo de cambio
     */
    onChangeTipo = (value) => {
        let monto_pesos = this.ModalFacturas.current?.getFieldValue('monto_pesos')
        this.ModalFacturas.current?.setFieldsValue({
            monto_dolar: parseFloat(monto_pesos / value).toMoney()
        })
    }

    /**
    * @memberof ModalFacturas
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

        let arr = [];
        if (array) {
            array.forEach(function ({ _id, nombre, razon_social, folio }, index) {
                arr.push(<Option value={_id}>{nombre}{razon_social} {folio}</Option>)
            })
        }

        if (array && addElemnt) {
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}{addElemnt.razon_social} {addElemnt.folio}</Option>)
            }
        }
        return arr
    }

    render() {
        const { disabled } = this.state;
        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalFacturas}
                onFinish={this.onFinish}
                initialValues={{
                    fecha: moment(),
                    tipo_cambio: this.state.tipo_cambio,
                    tipo: -1,
                    ...(!disabled ? {estatus: 2} : null)
                }}

            >
                <Spin spinning={this.state.loading}>

                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]}
                            >
                                <Input disabled={disabled} placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="UUID"
                                name="uuid"
                            >
                                <Input  disabled={this.state.disabled} placeholder="UUID"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                                rules={[{
                                    required: false,
                                    message: "Por favor, seleccione el tipo"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione al proveedor"
                                    showSearch
                                    onSearch={this.getProveedores}
                                    filterOption={false}
                                    disabled={disabled}
                                >
                                    {this.selectOptions(this.state.proveedores, this.state.proveedor)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Razón Social"
                                name="razon_social_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la razón social"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione la razon social"
                                    showSearch
                                    onSearch={this.getRazonesSociales}
                                    filterOption={false}
                                    disabled={disabled}
                                >
                                    
                                    {this.selectOptions(this.state.razones_sociales, this.state.razon_social)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11 }}>
                            <Form.Item 
                                label="Tipo"
                                name="tipo"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo"
                                }]}
                            >
                                <Select
                                     disabled={this.state.disabled}
                                    placeholder="Seleccione tipo">
                                    <Option value={0}>Sin Cambios</Option>
                                    <Option value={1}>Ingreso</Option>
                                    <Option value={-1}>Egreso</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Folio Orden"
                                name="orden_id"
                            >

                                <Select
                                    placeholder="Seleccione el Folio"
                                    showSearch
                                    onSearch={this.getOrdenes}
                                    filterOption={false}
                                    disabled={disabled}
                                >
                                    {this.selectOptions(this.state.ordenes, this.state.orden)}

                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>

                    

                    <Row>
                        <Col xs={24} lg={6} >
                            <Form.Item
                                label="Tipo Cambio"
                                name="tipo_cambio"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber  min={1} defaultValue={0} className="width-100" onChange={this.onChangeTipo} disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 8, push: 1 }} >
                            <Form.Item
                                label="Monto (Pesos)"
                                name="monto"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber disabled={this.state.disabled} min={0.01} defaultValue={0}  decimalSeparator="," className="width-100 " onChange={this.onChangePesos} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 8, push: 2 }} >
                            <Form.Item
                                label="Monto (Dolar)"
                                name="monto_dolar"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    required: false,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber disabled={this.state.disabled} min={0.01} defaultValue={0}  decimalSeparator="," className="width-100 " onChange={this.onChangeDolar} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} >
                            <Form.Item
                                label="Observaciones"
                                name="observaciones"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    max: 1000,
                                    message: "Máximo de caracteres alcanzado (1000)"
                                }]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, factura_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3} className="text-center">{factura_id != null ? "Editar " : "Nueva "} Factura</Title>
        </div>
        <ModalFacturas {...props} />
    </Modal>

}