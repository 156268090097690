import React from 'react'
import { Route, Switch } from "react-router-dom";
import Dashboard from '../components/Admin/Dashboard';


/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterDashboard( props) {
    return (
        <Switch>
            <Route exact path="/admin/dashboard" render={() => <Dashboard {...props} />} />
        </Switch>
    )
}

export default RouterDashboard;
