import React, { Component, useState, createContext, useContext } from 'react';
import { Button, Upload, Row, Radio, Space, Col, Modal, Typography, Form, Input, InputNumber, Select, message } from 'antd';
import { CloseOutlined, InboxOutlined } from '@ant-design/icons';

import '../../../Styles/Modules/Public/modal-agregar-producto.css';
import UploadFotoProducto from './UploadFotoProducto';
// import UploadArchivoProducto from './UploadArchivoProducto';
import AddOnMoneda from '../../Widgets/Inputs/AddOnMoneda';
import Carrito, { SetCarrito } from '../../../Hooks/Carrito';


const axios = require("axios").default

const { Title, Paragraph } = Typography;

class ModalAgregarProducto extends Component {

    formRef = createContext();

    constructor(props) {
        super(props);
        this.state = {
            product: {},
            prioridad: 1
        }
    }

    componentDidMount = () => {
        this.loadForm();
    }

    onFormChange = (_, values) => {

        console.log(_, values);

        if (values.imagen?.length > 0) {
            this.props.setImage(values.imagen[0].filename)
        }

        if (values.archivo?.length > 0) {
            this.props.setFiles(values.imagen[0].filename)
        }

        if (values.prioridad !== this.state.prioridad) this.setState({ prioridad: values.prioridad })
    }

    loadForm = () => {
        const { productIdx, products } = this.props;
        if (productIdx === null) return;

        this.formRef.current?.setFieldsValue(products[productIdx])
    }

    onFormFinish = values => {
        const product = values;
        const id = this.props.productIdx;
        console.log(values);
        const arr = this.props.products;
        if (id !== null) {
            if (arr[id].imagen && this.props.image) {
                this.props.deleteFile(arr[id].imagen[0].filename);
            }
            if (arr[id].archivos && this.props.image) {
                this.props.deleteFile(arr[id].archivos[0].filename);
            }
            arr[id] = product;
        } else {
            arr.push(product);
        }

        this.props.setProducts(arr);
        this.props.onClose()
    }


    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            });
    };

    /**
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;
        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].filename = fileList[x].response.filename
                fileList[x].name = fileList[x].response.name
            }

        }
        if (file.status === "removed")
            this.removeFile((file?.response?.filename) ? file.response.filename : file.filename);
        return e.fileList;
    };

    render() {
        return (
            <Form
                layout="vertical"
                onFinish={this.onFormFinish}
                onValuesChange={this.onFormChange}
                initialValues={{
                    prioridad: 1
                }}
                ref={this.formRef}
            >
                <Row justify="center">
                    <Col xs={22} style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                        <UploadFotoProducto />
                    </Col>
                </Row>
                <Row justify={"center"} align={"middle"} gutter={[24, 24]}>
                    <Col xs={22} md={11}>
                        <Form.Item
                            className="agregar-producto-col-input"
                            label="Marca"
                            name="marca"
                        >
                            <Input placeholder="Ingresa la marca" maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col xs={22} md={11}>
                        <Form.Item
                            className="agregar-producto-col-input"
                            label="Modelo"
                            name="modelo"
                        >
                            <Input placeholder="Ingresa el modelo" />
                        </Form.Item>
                    </Col>
                    <Col xs={22}>
                        <Form.Item
                            className="agregar-producto-col-input"
                            label="Descripción"
                            name="descripcion"

                        >
                            <Input.TextArea row={5} maxLength={2000} />
                        </Form.Item>
                    </Col>
                    <Col xs={22} md={7}>
                        <Form.Item
                            className="agregar-producto-row-input"
                            label="Unidad de Medida"
                            name="um"

                        >
                            <Input placeholder="Litros, kilos, etc..." />
                        </Form.Item>
                    </Col>
                    <Col xs={22} md={7}>
                        <Form.Item
                            className="agregar-producto-row-input"
                            label="Cantidad"
                            name="cantidad"
                            rules={[
                                {
                                    required: true,
                                    message: "Ingresa la cantidad, por favor",
                                    pattern: new RegExp(/[0-9]+/)
                                }
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                    <Col  xs={22} md={8}>
                        <Form.Item
                            className="agregar-producto-row-input input-add-on"
                            label="Precio Unitario de Referencia"
                            name="precio_ref"
                        >
                            <InputNumber
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                className="width-100"
                                controls={false}
                                addonAfter={(
                                    <AddOnMoneda />
                                )}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={22}>
                        <Form.Item
                            label="La prioridad 1 nos indica aquello que es más relevante para tu cotización" className="agregar-producto-row-input agregar-producto-form-item"
                            style={{ marginBottom: 0 }}
                            name="prioridad"
                            rules={[
                                {
                                    required: true,
                                    message: "Ingresa la prioridad, por favor",
                                }
                            ]}
                        >
                            <Radio.Group buttonStyle="solid" className="agregar-producto-radio-group">
                                <Radio.Button
                                    className="agregar-producto-radio-box"
                                    value={1}
                                >
                                    <Space align="center" size={16}>
                                        <div className="agregar-producto-radio-icon">
                                            {
                                                this.state.prioridad !== 2 ? (
                                                    <div className="agregar-producto-radio-icon-mark">{this.state.prioridad === 1 ? 1 : ''}</div>
                                                ) : (
                                                    <span className="agregar-producto-radio-icon-num"> 2 </span>
                                                )
                                            }
                                        </div>
                                        <Paragraph className="agregar-producto-radio-label">
                                            <b>A:</b> Precio
                                        </Paragraph>
                                    </Space>
                                </Radio.Button>
                                <Radio.Button
                                    className="agregar-producto-radio-box"
                                    value={2}
                                >
                                    <Space align="center" size={16}>
                                        <div className="agregar-producto-radio-icon">
                                            {
                                                this.state.prioridad !== 1 ? (
                                                    <div className="agregar-producto-radio-icon-mark">{this.state.prioridad === 2 ? 1 : ''}</div>
                                                ) : (
                                                    <span className="agregar-producto-radio-icon-num"> 2 </span>
                                                )
                                            }
                                        </div>
                                        <Paragraph className="agregar-producto-radio-label">
                                            <b>B:</b> <span>Tiempo de Entrega</span>
                                        </Paragraph>
                                    </Space>
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col xs={22} style={{ display: 'flex',  marginTop: '1rem' }}>
                        <Form.Item
                            label="Documentos del Producto"
                            name="archivos"
                            getValueFromEvent={this.normFile}
                            valuePropName="fileList"
                        >
                            <Upload
                                accept='.doc,.docx,.odt,.pdf,.xls,.xlsx,.ods,.ppt,.pptx,.csv'
                                action={axios.defaults.baseURL + "/upload/add"}
                                multiple={true}
                            >
                                <Button
                                    className="agregar-producto-button"

                                >
                                    Subir Archivo
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col xs={{ span: 22 }} style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                        <Form.Item>
                            <Button
                                className="agregar-producto-button"
                                htmlType="submit"
                            >
                                guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default function (props) {

    let [image, setImage] = useState(null);
    const productos = useContext(Carrito)
    const setProductos = useContext(SetCarrito)

    return <Modal
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        width={800}
        visible={props.visible}
        onCancel={() => props.onClose(image, props.productIdx)}
        className="agregar-producto"
    >
        <Row>
            <Col xs={2}>
                <CloseOutlined
                    onClick={() => props.onClose(image, props.productIdx)}
                    style={{ fontSize: '2.5rem' }}
                />
            </Col>
            <Col xs={20} style={{ textAlign: 'center' }}>
                <Title level={2}>
                    Producto a Cotizar
                </Title>
            </Col>
        </Row>
        <ModalAgregarProducto {...props} setImage={setImage} products={productos} setProducts={setProductos}/>
    </Modal>
}