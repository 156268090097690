import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, message, Spin, Select } from 'antd';

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalReporteOC
 * @extends {Component}
 */
class ModalReporteOC extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            allowAlmacenes: true,

            clientes: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
                filters: {},
                loading: false
            },



            almacenes: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
                filters: {},
                loading: false,
                cliente_id: null
            },


        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getClientes()
    }





   /**
    *
    *
    * @memberof ModalReporteOC
    * @description Obtiene los clientes que tienen inventario
    * @param values pagina
    */
    getClientes = ({
        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search
    } = this.state.clientes) => {
        this.setState(state => {
            state.clientes.loading = true;
            state.clientes.page = page;
            state.clientes.limit = limit;
            return state;

        })

        axios.get('/inventarios/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {
                console.log('response clientes',response)

                const data = response.data.data.itemsList;
                const paginator = response.data.data.paginator;
                this.setState(state => {
                    if (page === 1)
                        state.clientes.data = data
                    else
                        state.clientes.data = [...data]
                    state.clientes.total = paginator.itemCount
                    state.clientes.pages = paginator.pageCount
                    state.clientes.loading = false
                    return state;
                })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
    }


    /**
     *
     *
     * @memberof ModalReporteOC
     */
    onPopupScrollClientes = event => {
        const { page, pages, } = this.state.clientes
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.loading
        )
            this.getClientes({ page: page + 1 })
    }




    /**
     *
     *
     * @memberof ModalReporteOC
     */
    getAlmacenes = ({
        page = this.state.almacenes.page,
        limit = this.state.almacenes.limit,
        search = this.state.almacenes.search,
        cliente_id = this.state.almacenes.cliente_id,
    } = this.state.almacenes) => {
        this.setState(state => {
            state.almacenes.loading = true;
            state.almacenes.page = page;
            state.almacenes.limit = limit;
            state.almacenes.cliente_id = cliente_id
            return state;

        })

        axios.get('/almacenes/list', {
            params: {
                page, limit, search, cliente_id
            }
        })
            .then(response => {
                console.log('response', response)
                this.setState(state => {
                    if (page === 1)
                        state.almacenes.data = response.data.data.itemsList
                    else
                        state.almacenes.data = [...state.almacenes.data, ...response.data.data.itemsList]

                    state.almacenes.total = response.data.data.paginator.itemCount
                    state.almacenes.pages = response.data.data.paginator.pageCount
                    state.almacenes.loading = false
                    return state;
                })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
    }



    /**
     *
     *
     * @memberof ModalReporteOC
     */
    onPopupScrollAlmacenes = event => {
        const { page, pages, } = this.state.almacenes
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.loading
        )
            this.getAlmacenes({ page: page + 1 })
    }



    /**
     *
     *
     * @memberof ModalReporteOC
     */
    onSelectCliente = (value) => {
        let almacenes = this.state.almacenes;
        almacenes.cliente_id = value;

        this.setState({ almacenes, allowAlmacenes: false });
        this.getAlmacenes()


    }



    /**
     *
     *
     * @memberof ModalReporteOC
     * @method onSave
     * @description Envia la peticion para generar el reporte
     */
    onSave = (values) => {
        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            console.log('data to send', values);
    
            let cliente_id = values.cliente_id.value,
                almacen_id = values.almacen_id.value;
    
            axios.post('/reportes/ordenes/compras/add', {
                cliente_id,
                almacen_id
            })
                .then(({ data }) => {
                    console.log('data', data)
                    message.success(data.message)
                })
                .catch(({ error }) => {
                    message.error(error.message.content)
                    console.log('error', error)
                })
                .finally(()=>{
                    this.setState({loading: false}, () => this.props.onClose())
                })
        })
        
    }



    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Form layout="vertical" ref={this.formModalNegocios} onFinish={this.onSave} >
                    <Row gutter={[16, 16]}>

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Clientes"
                                name="cliente_id"
                                rules={[
                                    { required: true, message: "Seleccione el cliente" }
                                ]} >
                                <Select
                                    labelInValue
                                    showSearch
                                    placeholder="Buscar"
                                    optionFilterProp="children"
                                    loading={this.state.clientes.loading}
                                    onSearch={search => this.getClientes({ page: 1, search })}
                                    onPopupScroll={this.onPopupScrollClientes}
                                    onSelect={(option, element) => {
                                        this.onSelectCliente(option.value)
                                    }}
                                >
                                    {this.state.clientes.data.map(({ _id, cliente }) => <Option key={cliente?._id} value={cliente?._id}  >{cliente?.razon_social || cliente?.nombre}</Option>)}

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Almacén"
                                name="almacen_id">
                                <Select
                                    disabled={this.state.allowAlmacenes}
                                    labelInValue
                                    showSearch
                                    placeholder="Buscar"
                                    optionFilterProp="children"
                                    loading={this.state.almacenes.loading}
                                    onSearch={search => this.getAlmacenes({ page: 1, search })}
                                    onPopupScroll={this.onPopupScrollAlmacenes}
                                >{this.state.almacenes.data.map(({ _id, direccion }) => <Option key={_id} value={_id} title={direccion}>{direccion}</Option>)}</Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin >
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns Component
 */
export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}>
        <div className="center">
            <Title level={3}>Reporte de Compra</Title>
        </div>
        <ModalReporteOC {...props} />
    </Modal>

}