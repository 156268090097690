import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography,  Form, Input, message, Spin, Select, Radio } from 'antd';



const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalProveedor
 * @extends {Component}
 */
class ModalProveedor extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proveedor: {},
            extranjero: false
        }
    }

    ModalProveedor = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProveedor()
    }

    /**
     *
     *
     * @memberof ModalProveedor
     * @method getProveedor
     * @description Obtiene el listado de proveedores
     */
    getProveedor = () => {
        axios.get('/proveedores/get', {
            params: {
                id: this.props.proveedor_id
            }
        }).then(({data}) => {
            
            console.log(data.data)
            this.setState({
                proveedor: data.data
            })
            this.ModalProveedor.current?.setFieldsValue(data.data)

        }).catch(error => {
            message.error('Error al traer el proveedor')
            console.log(error)
        })
    }


    /**
     * @memberof ModalProveedor
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario, actualiza los datos del proveedor
     */
    onFinish = (values) => {

        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            axios.post('/proveedores/update', {
                id: this.props.proveedor_id,
                ...values
            })
                .then(success => {
                    
                    message.success("Se ha actualizado el proveedor")
                })
                .catch(error => {
                    message.error('Error al actualizar proveedor')
                    console.log(error)
                })
                .finally(() => {
                    this.setState({loading: false}, () => this.props.onClose())
                })
        })
        

    }

    /**
     * @memberof ModalProveedor
     * @method onFormChange
     * @description Actualiza el estado para saber si es extranjero
     */
    onFormChange = (_, values) => {
        this.setState({
            extranjero: values.tipo === 2
        })
    }

    render() {


        return (
            <Form layout="vertical" ref={this.ModalProveedor} onValuesChange={this.onFormChange} onFinish={this.onFinish} >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Tipo"
                                name="tipo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el tipo"
                                }]}
                            >
                                <Radio.Group> 
                                    <Radio value={1}>Nacional</Radio>
                                    <Radio value={2}>Extranjero</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Impuesto"
                                name="impuesto"
                                rules={[{
                                    required: !this.state.extranjero,
                                    message: "Por favor, ingrese el tipo de impuesto"
                                }]}
                            >
                                <Radio.Group disabled={this.state.extranjero} > 
                                    <Radio value={1}>I.V.A. Normal 16%</Radio>
                                    <Radio value={2}>I.V.A. Fronterizo 8%</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col xs={24} >
                            <Form.Item
                                label="RFC"
                                name="rfc"
                                rules={[{
                                    required: !this.state.extranjero,
                                    message: "Por favor, ingrese el RFC"
                                },
                                    {
                                        pattern: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
                                        message: 'RFC invalido'
                                    }
                                ]}
                            >
                                <Input placeholder="RFC" />
                            </Form.Item>
                        </Col>


                    </Row>
                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Actualizar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Modficar Proveedor </Title>
        </div>
        <ModalProveedor {...props} />
    </Modal>

}