import React, { Component, useContext } from 'react';
import { BsBellFill } from "react-icons/bs";
import { Link, Redirect,  } from "react-router-dom";
import { Menu, Dropdown, Badge, Typography } from 'antd';

//hooks
import { SocketContext } from '../../Hooks/Socket';
import { User, SetUser } from "../../Hooks/Logged";

const { Text } = Typography

class Notificaaciones extends Component{

	constructor(props){
		super(props)
		this.state = {
			conversaciones: [],
			notificaciones: 0
		}
	}

	static contextType = SocketContext;

	componentDidMount(){
		this.context.removeListener('notificacion_nueva');

		this.context.on('start_notifications',this.start_notifications)

        this.context.on('notificacion_nueva',this.notificacion_nueva)

		this.context.emit("header", sessionStorage.getItem("token"))

	}


	/**
    * @const start_notifications
    * @description Establece las notificaciones, que se obtienen al principio
    */
    start_notifications = (props) => {

    	this.setState({
    		notificaciones: props.count,
    		conversaciones: props.list,
    	})
    }

    /**
	* @const start_notifications
	* @description Al llegar un nuevo mensaje verifica si no esta en la lista de conversaciones, si no para añadirla y aumantar el contador
	*/
    notificacion_nueva = (props) => {
    	console.log("props", props);
        let index = this.state.conversaciones.findIndex(e=> e._id.toString() == props.orden._id.toString())
        console.log("index", index);
            
        if(index === -1){

        	let arr = this.state.conversaciones
        	arr.push(props.orden)

        	this.setState({
        		notificaciones: this.state.notificaciones + 1,
        		conversaciones: arr,
        	})
               
    	}            
    }

    /**
	* @const read_notificacion
	* @description Al hacer click sobre una notificacion, la elimina del state
	*/
    read_notificacion = (orden_id) => {
        let index = this.state.conversaciones.findIndex(e=> e._id.toString() === orden_id.toString())
            
        if(index != -1){

        	let arr = this.state.conversaciones
        	arr.splice(index, 1)

        	this.setState({
        		notificaciones: this.state.notificaciones - 1,
        		conversaciones: arr,
        	})
               
    	}            
    }

    /**
    * @memberof ProjectManager
    * @method redirect
    * @description retorna el url llevando a la seccion correspondiente dependiendo del estatus de la orden
    *
    */
    redirectChat = ({ _id, estatus, tipo_orden, orden_detalles }) => {

        let section = ""
        let faltante = orden_detalles?.find(item => item.cantidad_faltante > 0) ? true : false

        if (tipo_orden === 1) {
            switch (estatus) {
                case 1:
                    section = "supplychain"
                    break
                case 4:
                    section = "matriz-costos"
                    break
                case 5:
                    section = "supplychain"
                    break;
                case 7:
                    section = "cuenta-ventas"
                    break
                case 11:
                    section = "logistica"
                    break
                default:
                    section = "supplychain"
                    break
            }
        }

        if (tipo_orden === 2) {
            if (faltante && estatus <= 5) {
                section = "matriz-costos"
            }else if (estatus <= 7) {
                section = "cuenta-ventas"
            }else if (estatus > 7) {
                section = "logistica"
            }
        }
        return `/admin/project-manager/${section}/${_id}`
    }


	render(){

		const { notificaciones, conversaciones } = this.state

		const { user } = this.props 

		return (
			 <Dropdown
                arrow={false}
                trigger={['click']}
                placement="bottomRight"
                overlay={
                    <Menu>

                    	{/*Owner*/}
                    	{user.tipo === 1 ? <Menu.Item> 
                    		<Link to={'/admin/chat'} onClick={()=>this.setState({notificaciones: 0, conversaciones: []})}>
                                Nuevos mensajes de {conversaciones.length} conversacion(es)
                            </Link> </Menu.Item> : null}

                    	{/*Administrador*/}
                    	{user.tipo === 2 ? conversaciones.map(c => <Menu.Item 
                    		key={c._id}
                    		onClick={()=>this.read_notificacion(c._id)}
                    		>
                			<Link to={this.redirectChat(c)}>
                        		Nuevos mensajes de la orden {c.folio}
                    		</Link>
                        </Menu.Item>): null}

                    	{/*cliente*/}
                        {user.tipo === 4 ? conversaciones.map(c => <Menu.Item
                        	key={c._id}
                    		onClick={()=>this.read_notificacion(c._id)}
                        	>
                        	<Link to={`/customer/misPedidos/${c._id}/cotizaciones`}>
                            	Nuevos mensajes de la orden {c.folio}
                        	</Link>
                        </Menu.Item>): null}

                       {conversaciones.length === 0 ? <Menu.Item key={"2"} className="center">
                            <Text className="text-gray">Sin Notificaciones</Text>
                       	</Menu.Item> : null}
                    </Menu>
                }
            >
                    <Badge count={notificaciones}>
                        <BsBellFill className="icon-bell hover" />
                    </Badge>
            </Dropdown>
		)
	}

}


export default function (props) {

	const user = React.useContext(User)
    
    return <Notificaaciones {...props} user={user}/>

}