import React, { Component } from "react";
import { Layout, Button, Col, Row, Space, Card, Typography, List, message, Tooltip, Modal } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";

import { IconMail, IconAceptarOrden, IconPDF, IconProveedores } from '../../../Widgets/Iconos';
import ModalAlmacen from "./ModalAlmacen"

import { User } from '../../../../Hooks/Logged';
import { SocketContext } from '../../../../Hooks/Socket';
import ModalNotificar from "./ModalNotificar";
import ModalProveedor from "./ModalProveedor";
// import  from "antd/lib/skeleton/Paragraph";

const { Content } = Layout;
const { Text, Title,Paragraph } = Typography
const axios = require('axios')
const moment = require('moment')


/**
 * @export
 * @class EgresosList
 * @extends {Component}
 * @description Vista de Project Manager
 */
class EgresosList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            orden_compra_id: undefined,
            admin: undefined,
            folio: undefined,
            ordenes: [],
            owner: false,
            page: 1,
            limit: 10,
            total: 0,
            transaccion_id: undefined,
            proveedor_id: undefined,
            modalProveedorVisible: false,
            modalTransaccionEgreso: false,
            modalNotificarVisible: false,
            text: 'Sin Ordenes de compra',
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenesCompra()
    }

    componentDidUpdate = (prevProps) => {

        //si cambia la orden, actualiza las ordenes
        if (this.props.orden_id && this.props.orden_id !== prevProps.orden_id) {
            this.getOrdenesCompra(1)
        }
    }

    /**
    *
    *
    * @memberof AccountPurchases
    * @method getOrdenesCompra
    * @description Obtiene las ordenes de compra de la orden correspondiente
    */
    getOrdenesCompra = (page = this.state.page) => {

        const orden_id = this.props.orden_id;
        const orden_compra_id = this.props.orden_compra_id;

        if (!orden_id) return;
        this.setState({ loading: true })
        axios.get('/ordenesCompra/getListaCompras', {
            params: {
                orden_id: orden_id,
                orden_compra_id,
                page: page
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        })
            .then(({ data }) => {

                this.setState({
                    ordenes: data.ordenes_compra.itemsList,
                    total: data.ordenes_compra.paginator.itemCount,
                    page: data.ordenes_compra.paginator.currentPage,
                })
            })
            .catch(err => {
                if (err.response?.status === 403) {
                    this.setState({ text: 'No tienes permisos para Ordenes de Compras' })
                }
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof AccountPurchases
     * @method aceptarOrden
     * @description Actualiza una orden de compra
     */
    aceptarOrden = (id) => {
        this.setState({ loading: true })
        axios.post("/ordenesCompra/update",
            {
                id,
                estatus: 1
            })
            .then(response => {
                if (response.data.success) {
                    message.success('Se ha actualizado el estatus de orden de compra.');
                    this.getOrdenesCompra();
                }
                else {
                    message.error('Error al actualizar estatus de orden de compra.');
                }
            })
            .catch(err => {
                console.log(err)
                message.error('Error al actualizar estatus de orden de compra.');
                this.setState({ loading: false })

            })
    }


    /**
 *
 *
 * @memberof AccountPurchases
 * @method sendEmail
 * @description Envia una orden de compra por email
 */
    sendEmail = (id) => {
        const user = this.props.user
        const { agente_compras_id } = this.props.orden

        return axios.get('/ordenesCompra/purchase-order', {
            params: {
                orden_compra_id: id,
                admin_email: user.email,
                enviar: true
            }
        }).then(response => {
            console.log(response)
            const socket = this.props.socket

            const destinos = [user?._id]

            if (user?._id !== agente_compras_id?._id && agente_compras_id !== undefined) {
                destinos.push(agente_compras_id?._id)
            }

            if (socket.connect) {
                let notificacion = {
                    evento: `Se ha enviado el mensaje con la orden compra  al proveedor`,
                    detalle: `El mensaje con la orden compra se enviado al proveedor y una copia se te ha enviado por correo`,
                    usuarios_destino: destinos,
                    usuario_id: user?._id
                }
                socket.emit('nueva_notificacion', notificacion)
            }

            message.success('Orden Enviada')
            this.getOrdenesCompra();
        }).catch(error => {
            console.log(error)
            message.error('Error al enviar  orden')
        })

    }


    /**
     *
     *
     * @memberof AccountPurchases
     * @method renderEstatus
     * @description Obtiene las ordenes de compra de la orden correspondiente
     */
    renderEstatus = (estatus) => {

        let estatus_label = {
            0: 'Creada',
            1: 'Aceptada',
            2: 'Confirmada',
            3: 'Incompleta',
            4: 'Completa',
            5: 'En entrega',
            6: 'Entregada',
            7: 'Completa',
            8: 'Enviada'
        }

        return (estatus !== null || estatus !== undefined) ? estatus_label[estatus] : 'N/A'
    }


    render() {

        const { premium, orden } = this.props;



        return (
            <>
                <Content>
                    <List
                        className="component-list"
                        dataSource={this.state.ordenes}
                        locale={{ emptyText: this.state.text }}
                        pagination={{
                            current: this.state.page,
                            pageSize: this.state.limit,
                            total: this.state.total,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className: "flex-left",
                            onChange: this.getTransacciones,
                        }}
                        header={<Row className="width-100">

                            {/* Fecha */}
                            <Col span={2} className="center">
                                <Text strong>Fecha</Text>
                            </Col>

                            {/* Folio */}
                            <Col span={3} className="center">
                                <Text strong>Folio</Text>
                            </Col>

                            {/* Proveedor */}
                            <Col span={5} className="center">
                                <Text strong>Proveedor</Text>
                            </Col>

                            {/* Estatus */}
                            <Col span={4} className="center">
                                <Text strong>Estatus</Text>
                            </Col>

                            {/* Monto total */}
                            <Col span={3} className="center">
                                <Text strong>Monto Total</Text>
                            </Col>

                            {/* Monto pagado */}
                            <Col span={3} className="center">
                                <Text strong>Monto Pagado</Text>
                            </Col>

                            {/* Acciones */}
                            <Col span={4} className="center">

                                <Button danger size="small" 
                                    onClick={() => Modal.confirm({

                                        okButtonProps: { danger: true},
                                        icon: <WarningOutlined style={{  color: "#F50" }} />,
                                        title: <><Title level={5}>¿Está seguro de volver a generar las Ordenes de Compra?</Title></>,
                                        content: <>
                                            <Paragraph>
                                                Al realizar este proceso, <strong>TODAS</strong> las Ordenes de Compra, así como los recibos y entregas relacionadas a las mismas serán <strong>ELIMINADAS</strong> para volver generar nuevas con los proveedores y precios actualizados.
                                            </Paragraph>
                                            <Title level={5}>ESTE PROCESO ES IRREVERSIBLE</Title>
                                        </>,
                                        onOk: () => {
                                            this.setState({ loading: true })
                                            axios.get("/ordenesCompra/generateListaCompra", {
                                                params: {
                                                    orden_id: orden?._id
                                                }
                                            })
                                                .then(e => {
                                                    this.getOrdenesCompra();
                                                    this.setState({ loading: false })
                                                })
                                                .catch(err => {
                                                    this.setState({ loading: false })
                                                    message.error('Error al generar lista de compra')
                                                })
                                        }
                                    })}
                                    >Generar Ordenes de Compra</Button>
                            </Col>
                        </Row>}


                        footer={

                            (Array.isArray(orden.ordenes_compra) && orden.ordenes_compra.length > 0) ?
                                <>
                                    <Title level={5}>Ordenes de Compras del Cliente</Title>
                                    {orden.ordenes_compra.map(orden_compra => <List.Item>
                                        <Card className="card-list">
                                            <a target="_blank" href={axios.defaults.baseURL + "/voucher/" + orden_compra}>
                                                <Row className="width-100">
                                                    {/* Fecha */}
                                                    <Col span={24}>
                                                        <Text strong>{orden_compra}</Text>
                                                    </Col>
                                                </Row>
                                            </a>
                                        </Card>
                                    </List.Item>

                                    )}
                                </>
                                : null
                        }
                        renderItem={item => (
                            <List.Item>
                                <Card className="card-list">
                                    <Row className="width-100">

                                        {/* Fecha */}
                                        <Col xs={6} xxl={2} className="center">
                                            <Text strong>{moment(item.fecha_vigencia).format('DD/MM/YYYY')}</Text>
                                        </Col>

                                        {/* Folio */}
                                        <Col xs={6} xxl={3} className="center">
                                            <Text strong>{item.folio}</Text>
                                        </Col>

                                        {/* Proveedor */}
                                        <Col xs={6} xxl={5} className="center">
                                            <Text className="text-gray-dark">{item.proveedor?.nombre_comercial}</Text>
                                        </Col>

                                        {/* Estatus */}
                                        <Col xs={6} xxl={4} className="center">
                                            <Text style={{ minWidth: "80px", textAlign: "center", background: "#C4C4C4", color: "#222222", padding: "5px  15px", borderRadius: "20px" }}>{this.renderEstatus(item.estatus)}</Text>
                                        </Col>

                                        {/* Monto total */}
                                        <Col xs={8} xxl={3} className="center">
                                            <Text className="text-gray-dark">{item.monto_total ? `$ ${(item.monto_total).toMoney(true)} MXN` : "$ 0 MXN"}</Text>
                                        </Col>

                                        {/* Monto pagado */}
                                        <Col xs={8} xxl={3} className="center">
                                            <Text className="text-gray-dark">{item.monto_pagado ? `$ ${(item.monto_pagado).toMoney(true)} MXN` : "$ 0 MXN"}</Text>
                                        </Col>

                                        {/* Acciones */}
                                        <Col xs={8} xxl={4} className="center">
                                            <Space>
                                                <Button
                                                    icon={<IconProveedores />}
                                                    type="primary"
                                                    title="IVA"
                                                    onClick={() => this.setState({ modalProveedorVisible: true, proveedor_id: item.proveedor_id })}

                                                >
                                                </Button>

                                                <Button
                                                    icon={<IconMail />}
                                                    type="primary"
                                                    title="Enviar"
                                                    onClick={() => this.sendEmail(item._id)}
                                                    style={{
                                                        backgroundColor: "#B845FF",
                                                        borderColor: "#B845FF"
                                                    }}
                                                >
                                                </Button>

                                                {(this.props.user?.tipo == 1) ? (
                                                    <Button
                                                        icon={<IconAceptarOrden />}
                                                        type="primary"
                                                        title="Aceptar"
                                                        onClick={() => this.aceptarOrden(item._id)}
                                                        disabled={item.estatus !== 0 || !(this.props.user?.tipo === 1) || (!item.almacen_id && orden?.tipo_orden === 2)}
                                                        style={item.estatus !== 0 ? {
                                                            backgroundColor: "#6CE634",
                                                            borderColor: "#6CE634",
                                                            paddingTop: '10px'
                                                        } : {
                                                            backgroundColor: "#FFCB45",
                                                            borderColor: "#FFCB45"
                                                        }}
                                                    >
                                                    </Button>
                                                ) : <Button
                                                    icon={<IconMail />}
                                                    onClick={() => this.setState({ modalNotificarVisible: true, orden_compra_id: item._id, admin: this.props.user, folio: item.folio })}
                                                    type="primary"
                                                    title="Nofiticar a Dueño"
                                                    disabled={item.estatus !== 0}
                                                    style={item.estatus !== 0 ? {
                                                        backgroundColor: "#6CE634",
                                                        borderColor: "#6CE634",
                                                    } : {
                                                        backgroundColor: "#FFCB45",
                                                        borderColor: "#FFCB45"
                                                    }}
                                                ></Button>}


                                                <Button
                                                    icon={<IconPDF />}
                                                    type="primary"
                                                    title="PDF"
                                                    onClick={() => {
                                                        let url = new URL(axios.defaults.baseURL + '/ordenesCompra/purchase-order')
                                                        url.searchParams.append('orden_compra_id', item._id)
                                                        url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                                                        window.open(url, '_blank')
                                                    }}
                                                    style={{
                                                        backgroundColor: "#54FFD6",
                                                        borderColor: "#54FFD6",
                                                    }}
                                                >
                                                </Button>

                                                {(!item.almacen_id && orden?.tipo_orden === 2) ?
                                                    <Button
                                                        icon={<WarningOutlined style={{ color: "#FFF" }} />}
                                                        type="primary"
                                                        title="Agregar Almacen"
                                                        onClick={() => {
                                                            this.setState({
                                                                modalAlmacenVisible: true,
                                                                orden_compra_id: item._id
                                                            })
                                                        }}
                                                    />
                                                    : null}
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}


                    />

                </Content>
                <ModalAlmacen
                    visible={this.state.modalAlmacenVisible}
                    cliente_id={this.props.cliente?._id}
                    orden_compra_id={this.state.orden_compra_id}
                    onClose={() => {
                        this.setState({ modalAlmacenVisible: false, orden_compra_id: undefined })
                        this.getOrdenesCompra();
                    }}
                />
                <ModalNotificar
                    visible={this.state.modalNotificarVisible}
                    path={this.props.path}
                    admin={this.state.admin}
                    folio={this.state.folio}
                    orden_compra_id={this.state.orden_compra_id}
                    onClose={() => {
                        this.setState({ modalNotificarVisible: false, orden_compra_id: undefined, admin: undefined })
                        this.getOrdenesCompra();
                    }}
                />
                <ModalProveedor
                    visible={this.state.modalProveedorVisible}
                    proveedor_id={this.state.proveedor_id}
                    onClose={() => {
                        this.setState({ modalProveedorVisible: false, proveedor_id: undefined })
                        this.getOrdenesCompra();
                    }}
                />
            </>
        )
    }
}

export default function (props) {

    const user = React.useContext(User)
    const socket = React.useContext(SocketContext)

    return (<EgresosList {...props} user={user} socket={socket} />)
}