import React, { Component, createRef } from "react";
import { Button, Layout, Image, Typography, Row, Col, Divider, Card, Carousel, Select, Form, List, Collapse, Radio, message } from 'antd';
import { Link, useLocation } from 'react-router-dom'
import { ProductoCard } from '../Widgets/Cards'

import '../../Styles/Global/productos.scss'
import '../../Styles/Global/footer.scss'
import 'iseeyoutech-widget-web/dist/style.css'
import { Footer } from "antd/lib/layout/layout";
import axios from "axios";
import { useContext } from "react";
import Carrito, { SetCarrito } from "../../Hooks/Carrito";

const { Content } = Layout
const { Title, Paragraph, Text } = Typography
/**
 *
 *
 * @export
 * @class Productos
 * @extends {Component}
 * @description Pagina principal del sistema
 */ 
class Productos extends Component {

    constructor(props){
        super(props)
        this.state = {
            productos: {
                docs: [],
                page: 1,
                limit: 15,
                search: null,
                filters: {

                },
                sort: {
                    createdAt: -1
                },
                sortValue: undefined
            }
        }
    }

    componentDidMount() {
        let params = new URLSearchParams(this.props.location?.search)
        let search = params.get('search')
        this.getProductos({search})
    }

    componentDidUpdate(prevProps){
        if(prevProps.location?.search !== this.props.location?.search){
            let params = new URLSearchParams(this.props.location?.search)
            let search = params.get('search')
            this.getProductos({search})
        }
    }

    getProductos = ({
        page = this.state.productos.page,
        limit = this.state.productos.limit,
        sort = this.state.productos.sort,
        filters = this.state.productos.filters,
        search = this.state.productos.search
    } = this.state.productos) => {
        axios.get('/public/productos', {
            params: {
                page,
                limit,
                sort,
                filters,
                search
            }
        }).then(({data}) => {
            this.setState({productos: {...data, search}})
        }).catch((err) => {
            message.error("Error al obtener los productos")
        })
    }

    render() {
        return (
            <Layout className="productos-page">
                <Content className="productos-main">
                    <Row gutter={[30, 30]}>
                        <Col xs={24} lg={5} className="productos-header">
                            <Title level={1} className="productos-title">Catalogo</Title>
                        </Col>
                        <Col xs={24} lg={19} className="productos-sorts">
                            <Select 
                                placeholder="Seleccionar orden"
                                className="sorts-select"
                                onSelect={({value, label}) => {
                                    const sort = {}
                                    sort[value[0]] = value[1]

                                    this.getProductos({sort})
                                    this.setState({sortValue: {value, label}})
                                }}
                                value={this.state.sortValue}
                                labelInValue
                            >
                                <Select.Option value={['createdAt', -1]}>
                                    Mas recientes
                                </Select.Option>
                                <Select.Option value={['createdAt', 1]}>
                                    Mas antiguos
                                </Select.Option>
                            </Select>
                        </Col>
                        {/* <Col xs={24} lg={5} className="productos-filters">
                            <Text>{this.state.productos.totalDocs || 0} Productos</Text>
                            <Form
                                layout="horizontal"
                                className="filters"
                                onValuesChange={(_, values) => {
                                    this.getProductos({
                                        filters: values
                                    })
                                }}
                            >
                                <Collapse className="filters-collapse" ghost>
                                    <Collapse.Panel showArrow={false} header="COLECCION" key="coleccion">
                                        <Form.Item
                                            name="coleccion"
                                        >   
                                            <Radio.Group  className="filters-buttons">
                                                <Radio.Button value="a">A</Radio.Button>
                                                <Radio.Button value="b">B</Radio.Button>
                                                <Radio.Button value="c">B</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Collapse.Panel>
                                    <Collapse.Panel showArrow={false} header="TAMAÑO" key="size">
                                         <Form.Item
                                            name="size"
                                        >   
                                            <Radio.Group  className="filters-buttons">
                                                <Radio.Button value="a">A</Radio.Button>
                                                <Radio.Button value="b">B</Radio.Button>
                                                <Radio.Button value="c">B</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Collapse.Panel>
                                    <Collapse.Panel showArrow={false} header="COLOR" key="color">
                                         <Form.Item
                                            name="color"
                                        >   
                                            <Radio.Group  className="filters-buttons">
                                                <Radio.Button value="a">A</Radio.Button>
                                                <Radio.Button value="b">B</Radio.Button>
                                                <Radio.Button value="c">B</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Collapse.Panel>
                                    <Collapse.Panel showArrow={false} header="TIPO" key="tipo">
                                         <Form.Item
                                            name="tipo"
                                        >   
                                            <Radio.Group  className="filters-buttons">
                                                <Radio.Button value="a">A</Radio.Button>
                                                <Radio.Button value="b">B</Radio.Button>
                                                <Radio.Button value="c">B</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Collapse.Panel>
                                </Collapse>
                            </Form>
                        </Col> */}
                        <Col xs={24}>
                            <List
                                grid={{
                                    column: 5,
                                    gutter: [16, 16],
                                    lg: 4,
                                    md: 3,
                                    xs: 2
                                }}
                                dataSource={this.state.productos.docs}
                                pagination={{
                                    pageSize: this.state.productos.limit,
                                    current: this.state.productos.page,
                                    total: this.state.productos.totalDocs,
                                    onChange: (page) => this.getProductos({page}),
                                    onShowSizeChange: (page, limit) => this.getProductos({page, limit})
                                }}
                                renderItem={(item) => (
                                    <List.Item>
                                        <ProductoCard 
                                            producto={item}
                                            onAdd={() => {
                                                let is_added = this.props.carrito?.find(p => p._id === item._id)
                                                if(is_added){
                                                    message.info("El producto ya esta agregado")
                                                    return
                                                }

                                                const carrito = this.props.carrito
                                                carrito.push(item)
                                                this.props.setCarrito(carrito)

                                                message.success("Producto agregado al carrito")
                                            }}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Content>
                <Footer
                    className="footer"
                >   
                    <Row className="footer-wrapper">
                        <Col xs={24} md={8} className="footer-about">
                            <img src="/images/nexus-footer.svg" className="footer-logo"/>
                            <Paragraph className="footer-descripcion">Traemos lo que necesitas de cualquier parte del mundo y nos encargamos de todos los detalles.</Paragraph>
                        </Col>
                        <Col xs={24} md={8} className="footer-directorio">
                            <Title level={3}>Directorio</Title>
                            <nav>
                                <Link to="/">Inicio</Link>
                                {/*<Link to="#">Preguntas Frecuentes</Link>*/}
                                <Link to="/terminos">Terminos y Condiciones</Link>
                                <Link to="/login">Iniciar Sesión</Link>
                            </nav>
                        </Col>
                        <Col xs={24} md={8} className="footer-sucursales">
                            <Title level={3}>Sucursales</Title>
                            <div className="footer-listado">
                                <Paragraph className="sucursal">
                                    <strong> Nexus Nova Aguascalientes </strong> <br />
                                    {/* San Emilion 304, 20310 Viñedos San Felipe, Ags. <br /> */}
                                    Avenida las Américas #1608, Valle del Dorado <br />
                                    <a target="_blank" href="tel:+524499768841">+52 (449) 976 88 41</a>
                                </Paragraph>
                                
                                {/*<Paragraph className="sucursal">
                                    <strong> Nexus Nova Guadalajara </strong> <br/>
                                    Justicia 91, Colonia Esperanza, CP 44300 Guadalajara, Jalisco <br/>
                                    +524492041003
                                </Paragraph>
                                <Paragraph className="sucursal">
                                    <strong> Nexus Nova Guanajuato </strong> <br/>
                                    Boulevard Brisas de Sarandi #304 Interior manzana 4 bodega #146 Colonia brisas del Campestre CP 37669 León Guanajuato México <br/>
                                    +524792325305
                                </Paragraph>*/}
                            </div>
                        </Col>
                        <Col span={24}><Divider/></Col>
                        <Col span={24}>
                            <Row justify={"space-between"} align={"middle"}>
                                <Text>@2024 Copyright ISEEYOUTECHNOLOGGIES</Text>
                                <Text>Nexus Nova Todos los derechos Reservados </Text>
                            </Row>
                        </Col>
                    </Row>
                </Footer>
            </Layout>
        )
    }

}

export default (props) => {

    return <Productos {...props}  carrito={useContext(Carrito)} setCarrito={useContext(SetCarrito)}/>
}