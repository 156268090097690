
import React from 'react'
import { Spin, Layout, Col, Row, PageHeader, Modal, Typography, Button, Space, Pagination, Popconfirm, List, Card, message, Tag, Tooltip } from "antd";
import { IconEdit, IconDelete, } from '../../Widgets/Iconos';
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

import moment from 'moment';
import DrawerFiltrosReportes from './DrawerFiltrosReportes';
import ModalReporteOC from "./ModalReporteOC";


import DrawerEditarOC from './Edicion/DrawerEditarOC';


const axios = require('axios')

const { Content } = Layout;
const { Text } = Typography;


/**
 *
 *
 * @export
 * @class ReporteOrdenesCompras
 * @extends {React.Component}
 */
export default class ReporteOrdenesCompras extends React.Component {


    /**
     *Creates an instance of ReporteOrdenesCompras.
     * @param {*} props
     * @memberof ReporteOrdenesCompras
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            //data
            reportes: [],
            currentPage: 1,
            itemCount: 0,
            perPage: 10,


            //filtros
            filtroSearch: '',
            searching: true,
            showFiltro: {},
            filtros: [],
            visibleFilters: false,


            //otros componentes
            modal_visible: false,
            drawer_productos_visible: false,
        }
    }


    filterForms = React.createRef();


    /**
     *
     *
     * @memberof Dashboard
     * @description Carga los datos a la vista
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.props.updateFilterSearch(true);
        this.getReportes(1)
    }

    /**
     *
     *
     * @memberof ReporteOrdenesCompras
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getReportes()
            this.state.searching = false;
        }
    }

    /**
     * @method getReportes
     * @description Obtiene los productos de un almacen de la DB
     */
    getReportes = (page = this.state.currentPage, filtros = [], limit = this.state.perPage) => {
        let search = this.props.search;
        axios.post('/reportes/ordenes/compras/list', {
            page: page,
            limit,
            filtros,
            search
        }).then(res => {
            console.log('getReportes', res.data.data)
            const data = res.data.data

            this.setState({
                reportes: data.itemsList,
                currentPage: data.paginator.currentPage,
                itemCount: data.paginator.itemCount,
                perPage: data.paginator.perPage,
                pageCount: data.paginator.pageCount,

                filtroSearch: search,
                searching: true,
                loading: false,
                filtros
            })

        }).catch(error => {
            message.error('Error al traer el reportes')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    onConfirm = (id) => {
        let thos = this;
        Modal.confirm({
            title: 'IMPORTANTE',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Cancelar',
            okText: 'Continuar',
            content: 'Las ordenes de compra generadas también seran eliminadas. ¿Desea Continuar?',
            onOk() {
                axios.post('/reportes/delete', { id: id }).then((response) => {
                    message.success('Reporte Eliminado')
                    thos.getReportes()
                }).catch((error) => {
                    message.error('Reporte NO Eliminado')
                    thos.getReportes();
                })
            }
        })
    }

    toogleFilters = (value) => {
        this.setState({ showFiltro: value });
        this.getReportes()
        this.renderFiltros()
    }

    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Transacciones
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        const { objectName } = filtro
        try {
            let values = this?.drawerFiltros?.formFiltros.getFieldValue(objectName)
            console.log('values', values)
            let index = values.findIndex(e => e === filtro._id)
            values.splice(index, 1)

            let newValues = {}
            newValues[objectName] = values
            this?.drawerFiltros?.formFiltros?.setFieldsValue(newValues)
            this?.drawerFiltros?.formFiltros?.submit()
        } catch (error) {
            console.log('error', error)
            this.setState({ visibleFilters: true })
        }
    }

    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Transacciones
     * @description Renderizamos los filtros que estén en el arreglo.
     */
    renderFiltros = ({ filtros } = this.state) => {
        return filtros.map(filtro => {
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}
            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.cliente.nombre || filtro?.filter?.direccion}
            </Tag>
        });
    }


    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24, }} xs={{ span: 24, }}  >
                                    <span className="ant-page-header-heading-title">
                                        Reportes de Compras
                                    </span>
                                </Col>
                                <Col span={24}>
                                    {this.renderFiltros()}
                                </Col>
                            </Row>
                        }

                        extra={
                            <Button className="button-plus" icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />} onClick={() => this.setState({ visibleFilters: true })} />
                        }

                    />
                    <Content className="admin-content content-bg pd-1 ">
                        <List
                            loading={this.state.loading}
                            className="component-list "
                            size="large"
                            itemLayout="horizontal"
                            dataSource={this.state.reportes}
                            header={<Row className="header-list width-100 pl-1 pr-1 ">
                                <Col xs={24} md={5} xl={4} className=" center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col xs={24} md={5} xl={4} className="center">
                                    <Text strong>Semana</Text>
                                </Col>
                                <Col xs={24} md={5} xl={3} className="center">
                                    <Text strong>Cliente</Text>
                                </Col>
                                <Col xs={24} md={5} xl={3} className="center">
                                    <Text strong>Almacén</Text>
                                </Col>
                                <Col xs={24} md={5} xl={4} className="center">
                                    <Text strong># productos</Text>
                                </Col>
                                <Col xs={24} md={5} xl={3} className="center">
                                    <Text strong>monto</Text>
                                </Col>

                                <Col xs={24} md={5} xl={3} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item ">
                                    <Card className="card-list">
                                        <Row className="width-100">
                                            <Col xs={24} md={5} xl={4} className=" center">
                                                <Text strong>{moment(item.fecha).format('DD-MM-YYYY HH:mm')}</Text>
                                            </Col>
                                            <Col xs={24} md={5} xl={4} className="center">
                                                <Text strong>{item.semana}</Text>
                                            </Col>
                                            <Col xs={24} md={5} xl={3} className="center">
                                                <Text strong> {item.cliente?.razon_social || item.cliente?.nombre}  </Text>
                                            </Col>
                                            <Col xs={24} md={5} xl={3} className="center">
                                                <Text strong>{item.almacen?.direccion} </Text>
                                            </Col>
                                            <Col xs={24} md={5} xl={4} className="center">
                                                <Text strong> {item.productos} </Text>
                                            </Col>
                                            <Col xs={24} md={5} xl={3} className="center">
                                                <Text strong>$ {item.monto.toMoney()}</Text>
                                            </Col>
                                            <Col xs={24} md={5} xl={3} className="center">
                                                <Space direction="horizontal">
                                                    <Button
                                                        icon={<IconEdit />}
                                                        disabled={item.orden_compras?.estatus == 8}
                                                        type="primary"
                                                        title={(item.orden_compras?.estatus != 8)?"Editar Reporte" :"Orden de Compras Enviada al Proveedor"}
                                                        onClick={() => this.setState({ drawer_productos_visible: true, reporte_id: item._id })}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este reporte?"
                                                        onConfirm={() => this.onConfirm(item._id)}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            danger
                                                            icon={<IconDelete />} title="Eliminar" />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}

                        />
                        <Row className="mt-2 pb-3">
                            <Pagination
                                current={this.state.currentPage}
                                total={this.state.itemCount}
                                pageSize={this.state.perPage}
                                showSizeChanger={true}
                                onChange={(page, limit) => this.getReportes(page, this.filterForms.getFieldsValue(), limit)}

                            />
                        </Row>
                        <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true, reporte_id: null })} />
                    </Content>
                </Spin>

                <DrawerFiltrosReportes
                    ref={ref => this.drawerFiltros = ref}
                    updateFilters={filtros => this.getReportes(1, filtros)}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                />

                <ModalReporteOC
                    visible={this.state.modal_visible}
                    reporte_id={this.state.reporte_id}
                    onClose={() => {
                        this.setState({ modal_visible: false })
                        this.getReportes()
                    }}
                />

                <DrawerEditarOC
                    reporte_id={this.state.reporte_id}
                    visible={this.state.drawer_productos_visible}
                    onSave={() => {
                        this.setState({ drawer_productos_visible: false, reporte_id: null })
                        this.getReportes()
                    }}
                    onClose={() => this.setState({ drawer_productos_visible: false, reporte_id: null })}
                />
            </>
        )
    }
}