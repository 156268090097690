import React from 'react'
import { Route, Switch } from "react-router-dom";


// componentes
import Clientes from '../components/Admin/Clientes/Clientes'
import DetalleClientes from '../components/Admin/Clientes/DetalleClientes'
import Usuarios from '../components/Admin/Usuarios/Usuarios';



/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterClientes(props) {

    return (
        <Switch>
            <Route exact path="/admin/clientes" render={() => <Clientes {...props} />} />
            <Route exact path="/admin/clientes/informacion/:id" render={(route_props) => <DetalleClientes  {...props} {...route_props} />} />
            <Route exact path="/admin/clientes/usuarios/:id" render={(props) => <Usuarios  {...props} />} />
        </Switch>
    )
}

export default RouterClientes;
