import React, { Component } from 'react';
import { Button, Popconfirm, Spin, Table, PageHeader, Layout, message, Breadcrumb, Typography, Space } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom";

//Componentes
import { IconDelete, IconEdit, IconArrowBack } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

import ModalRubros from './ModalRubros'

const { Content } = Layout;

/**
 * @export
 * @class Rubros
 * @extends {Component}
 * @description Vista de Rubros
 */
export default class Rubros extends Component {

    back = () => {
       this.props.history.goBack();
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            area_id: undefined,
            rubro_id: undefined,
            rubros: [],
            area: [],

            page: 1,
            total: 0,
            filtroSearch:'',
            searching: true,
        }
    }

    componentDidMount() {
        var ruta = window.location.pathname;
        var area_id = ruta.split("/")[((ruta.split("/").length) - 1)]
        if (area_id !== "rubros") {
            this.state.area_id = area_id;
            this.setState({
                area_id: area_id
            })
        }


        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getArea();
        this.getRubros();
    }


    /**
     *
     *
     * @memberof Rubros
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
     componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getRubros();
            this.state.searching = false;
        }
    }

    /**
     * @memberof Rubros
     * @method getArea
     * @description Obtiene una area de la DB
     */
    getArea = () => {
        let search = this.props.search;
        axios.get('/areas/get', {
            params: {
                id: this.state.area_id,
                search:search
            }
        }).then(response => {
            let area = response.data.data
            this.setState({
                area: response.data.data,
                searching: true,
                filtroSearch: search
            })


        }).catch(error => {
            message.error('Error al traer area')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

      /**
     * @memberof Rubros
     * @method getArea
     * @description Obtiene los rubros del area seleccionada
     */
    getRubros = (page = 1) => {

        axios.get('/rubros/list', {
            params: {
                page: page,
                area_id: this.state.area_id,
            }
        }).then(response => {

            this.setState({
                rubros: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={(this.state.area != []) ?
                            (
                                <div className="flex-left">
                                    <Button ghost onClick={this.back} className="no-border" title="Volver a VMI">
                                        <IconArrowBack />
                                    </Button>
                                    <Breadcrumb
                                        separator=" de ">
                                        <Breadcrumb.Item>Rubros</Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Link className="text-gray" title="Ir a areas" to={`/admin/areas`}>
                                                {this.state.area.nombre}
                                            </Link>
                                        </Breadcrumb.Item>

                                    </Breadcrumb>
                                </div>
                            )
                            : "Rubros"}
                    />

                    <Content className="admin-content content-bg pd-1">
                        <Table
                            scroll={{ x: 1000, y: 1000 }}
                            columns={[
                                {
                                    title: 'Color',
                                    key: 'avatar',
                                    width: '100px',
                                    render: (text, { nombre, apellido, logo, color }) => <CustomAvatar color={color} />,

                                },
                                {
                                    title: 'Nombre',
                                    dataIndex: 'nombre',
                                    render: (text, record) => (
                                        <h3>{record.nombre}</h3>
                                    ),
                                },

                                {
                                    title: 'Descripción',
                                    dataIndex: 'descripcion',
                                    key: 'descripcion',
                                },

                                {
                                    title: 'Acciones',
                                    key: 'actions',
                                    fixed: 'right',
                                    width: '210px',
                                    render: (text, record) => (
                                        <Space>
                                            <Button

                                                type="primary"
                                                icon={<IconEdit />}
                                                title="Editar"
                                                onClick={() => { this.setState({ modalVisible: true, rubro_id: record._id }) }}
                                            />
                                            <Popconfirm
                                                placement="topRight"
                                                title="¿Deseas eliminar este rubro?"
                                                onConfirm={() => axios.post('/rubros/delete',
                                                    { id: record._id }).then(() => {
                                                        this.getRubros()
                                                        message.success('Rubro eliminado')
                                                    }).catch(error => console.log(error))}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button
                                                    type="primary"
                                                    icon={<IconDelete />}
                                                    title="Eliminar"
                                                    danger
                                                />
                                            </Popconfirm>
                                        </Space>
                                    ),
                                },
                            ]}
                            dataSource={this.state.rubros}
                            pagination={{
                                onChange: this.getRubros,
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                position: ['bottomLeft'],
                            }}
                            locale={{ emptyText: 'Sin rubros disponibles' }}
                             />

                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />

                    <ModalRubros
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, rubro_id: undefined })
                            this.getRubros()
                        }}
                        rubro_id={this.state.rubro_id}
                        area_id={this.state.area_id}
                    />
                </Spin>
            </>
        )
    }
}