import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Typography, Select, Avatar, InputNumber } from 'antd';
import PropTypes from "prop-types";
const axios = require('axios').default;


const { Title, Text } = Typography;
const { Option } = Select;





/**
 *
 *
 * @export
 * @class FormAlmacenProducto
 * @extends {Component}
 */
class FormAlmacenProducto extends Component {


    /**
     *
     *
     * @static
     * @memberof FormAlmacenProducto
     * 
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            inventario_producto_edit: undefined,
            productos: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
            }




        }
    }

    formAlmacenProducto = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.almacen_producto_id !== undefined)
            this.getAlmacenProducto()

        this.getProductos();
    }

    /**
     * @memberof AlmacenProductos
     * @method getProductos
     * @description obtiene los productos del inventario de un cliente
     */
    getProductos = (search) => {

        const { almacen_id, inventario_id } = this.props

        axios.get('/inventarios/productos', {
            params: {
                limit: 1000,
                search,
                almacen_id,
                inventario_id,
                almacenes: true,
            }
        })
            .then(({ data }) => this.setState(state => {
                console.log('PRODUCTOS', data)
                this.setState({ productos: data.data })
            }))
            .catch(error => {
                // console.log(error)
                message.error('Error al traer la información')
            })
    }

    /**
     * @memberof AlmacenProductos
     * @method getAlmacenProducto
     * @description obtiene los productos del inventario de un cliente
     */
    getAlmacenProducto = () => {

        const { almacen_producto_id } = this.props

        axios.get('/almacenes/productos/get', {
            params: {
                id: almacen_producto_id
            }
        })
            .then(({ data }) => this.setState(state => {

                this.setState({ inventario_producto_edit: data.data.inventario_producto_id })

                this.formAlmacenProducto.current?.setFieldsValue({
                    cantidad: data.data.cantidad,
                    ubicacion: data.data.ubicacion,
                    inventario_producto_id: data.data.inventario_producto_id?._id,
                })

            }))
            .catch(error => {
                message.error('Error al traer la información')
            })
    }

    /**
     * @method onPopupScrollProductos 
     * @description Al scrolear cargamos mas productos
     */
    onPopupScrollProductos = event => {
        const { page, pages, } = this.state.productos
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.loading
        ) {
            this.getProductos({ page: page + 1 })
        }
    }



    /**
     *
     *
     * @memberof FormAlmacenProducto
     * 
     * @method save
     * @description Guardamos el producto
     */
    save = (values) => {
        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            axios.post('/almacenes/productos/add', {
                ...values,
                almacen_id: this.props.almacen_id,
                inventario_id: this.props.inventario_id
            })
                .then(() => {
                    message.success("¡Se ha guardado correctamente el producto!")
                    this.props.onCancel();
                })
                .catch((e) => {
                    message.error('No se pudo guardar el producto')
                })
                .finally(() => this.setState({
                    loading: false,
                    loadingImage: false,
                    image: undefined
                }))
        })
        
    }

    /**
     *
     *
     * @memberof FormAlmacenProducto
     * 
     * @method update
     * @description Actualizamos el producto.
     */
    update = (values) => {

        console.log('editas,', this.props.almacen_producto_id)

        axios.post('/almacenes/productos/update', {
            ...values,
            id: this.props.almacen_producto_id
        })
            .then(() => {
                message.success("¡Se ha actualizado correctamente el producto!")
                this.props.onCancel();
            })
            .catch((e) => {
                message.error('No se pudo guardar el producto')
            })
            .finally(() => this.setState({
                loading: false,
                loadingImage: false,
                image: undefined
            }))
    }





    selectProducto = React.createRef()

    render() {
        const { formAlmacenProducto, } = this;
        const { form, loading, inventario_producto_edit } = this.state;



        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.almacen_producto_id ? "Editar Producto" : "Nuevo Producto"}</Title>
                <Form
                    layout="vertical"
                    ref={formAlmacenProducto}
                    initialValues={form}
                    onFinish={(this.props.almacen_producto_id !== undefined) ? this.update : this.save} >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Productos"
                                name="inventario_producto_id"
                                rules={[
                                    { required: true, message: "Seleccione un producto" }
                                ]} >
                                <Select
                                    showSearch
                                    placeholder="Buscar"
                                    optionFilterProp="children"
                                    loading={loading}
                                    onSearch={search => this.getProductos({ page_: 1, search })}
                                    onPopupScroll={this.onPopupScrollProductos}
                                >
                                    {this.state.productos.data.map((item) => <Option key={item.producto_id?._id} value={item._id} title={item.producto_id?.np_fabricante}>
                                        {item.producto_id?.np_fabricante}
                                        <div>
                                            <Avatar
                                                style={{ display: "inline-block", marginRight: 4 }}
                                                shape="square" src={(Array.isArray(item.producto_id?.imagenes) && item.producto_id?.length > 0) ? (axios.defaults.baseURL + '/upload/' + item.producto_id?.imagenes[0].filename) : undefined} />
                                            <Text strong>{item.producto_id?.marca}</Text> &nbsp; <Text>{item.producto_id?.np_nexus}</Text><br />
                                            <Text>{item.producto_id?.descripcion}</Text>
                                        </div>
                                    </Option>)}

                                    {this.state.inventario_producto_edit ? <Option key={inventario_producto_edit.producto_id?._id} value={inventario_producto_edit._id} title={inventario_producto_edit.producto_id?.np_fabricante}>
                                        {inventario_producto_edit.producto_id?.np_fabricante}
                                        <div>
                                            <Avatar
                                                style={{ display: "inline-block", marginRight: 4 }}
                                                shape="square" src={(Array.isArray(inventario_producto_edit.producto_id?.imagenes) && inventario_producto_edit.producto_id?.length > 0) ? (axios.defaults.baseURL + '/upload/' + inventario_producto_edit.producto_id?.imagenes[0].filename) : undefined} />
                                            <Text strong>{inventario_producto_edit.producto_id?.marca}</Text> &nbsp; <Text>{inventario_producto_edit.producto_id?.np_nexus}</Text><br />
                                            <Text>{inventario_producto_edit.producto_id?.descripcion}</Text>
                                        </div>
                                    </Option> : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"
                                rules={[
                                    { required: true, message: "Ingrese la cantidad" }
                                ]} >
                                <InputNumber placeholder="1" className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Ubicación"
                                name="ubicacion">
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormAlmacenProducto {...props} />
        </Modal>
    )
}
