import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Spin, } from 'antd';

//componentes
import CustomAvatar from "../../../Widgets/Avatar/Avatar";

const { Title, Text } = Typography;
const axios = require('axios').default;
const moment = require('moment')

/**
 *
 *
 * @class ModalVentaDetalle
 * @extends {Component}
 */
class ModalVentaDetalle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            return: false,
            premium: false
        }
    }

    ModalVentaDetalle = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }



    render() {

        const { orden } = this.props

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    onFinish={this.onFinish}
                >
                    <Row className="mt-1">
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Fecha de Compra"
                            >
                                <Text className="text-gray font-18">{moment(orden?.createdAt).format('DD-MM-YYYY')}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Cliente Solicitante"
                            >
                                <Text className="text-gray font-18">{orden?.cliente_id?.nombre}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Responsable Solicitante"
                            >
                                <CustomAvatar name={['n a', 'a']} /> <Text className="text-gray">Responsable</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Almacén"
                            >
                                <Text className="text-gray font-18">{orden?.almacen_id?.direccion}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Producto"
                            >
                                <Text className="text-gray font-18">{orden?.producto_id?.np_fabricante}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Cantidad"
                            >
                                <Text className="text-gray font-18">{orden?.orden_detalles?.cantidad}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Total"
                            >
                                <Text className="text-gray font-18">$ {orden.total.toMoney(true)} MXN</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Pagado"
                            >
                                <Text className="text-gray font-18">$ {orden.pagado.toMoney(true)} MXN</Text>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" onClick={() => this.props.onClose()}>
                                    Aceptar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Detalles de Venta </Title>
        </div>
        <ModalVentaDetalle {...props} />
    </Modal>

}