import React, { useState, createRef } from 'react';
import { Drawer, Button, Space } from 'antd';
import TableReporteAlmacen from "./TablaReporteOC";



/**
 *
 *
 * @export
 * @param {*} props
 * @returns Drawer Component
 */
export default function (props) {
    
    const { onClose, visible } = props;
    const [reporte, setReporte] = useState("");
    let refTableReporteProductos = createRef()

    const saveValues = async () => {
        await refTableReporteProductos.current.formTableReporteProductos.current.submit()
    }

    console.log('drawer edit oc props',props)
    return <Drawer
        title={"Editar Reporte"}
        placement={"bottom"}
        height="80vh"
        onClose={onClose}
        visible={visible}
        destroyOnClose={true}
        extra={
            <Space>
                <Button type='danger' onClick={onClose}>Cancelar</Button>
                <Button type="primary" onClick={saveValues}>
                    Guardar Cambios
                </Button>
            </Space>
        }
    >
        <TableReporteAlmacen
            ref={refTableReporteProductos}
            {...props}
            reporte={reporte}
            setReporte={setReporte}
            />
    </Drawer>
}