import React, { Component } from 'react';
import { Layout,  Col, Row, message,Spin, Typography, Tabs, Card } from 'antd'
import axios from 'axios'

//Modales
import Pagos from './Pagos'
import Facturas from './Facturas'
import Ordenes from './Ordenes'

const { Text, Title } = Typography;
const { TabPane } = Tabs;


/**
 * @export
 * @class Cotizaciones
 * @extends {Component}
 */
export default class Cotizaciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            orden_id: this.props.match.params.orden_id,
            data: [],
            loading: false,
            cliente: {},
            orden: {},
        }
    }

    /**
     * @memberof Cotizaciones
     * @method componentDidMount 
     * @description Cargamos la informacion inicial
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrden()
    }

    /**
     * @memberof Cotizaciones
     * @method componentDidUpdate 
     * @description Cargamos la informacion inicial
     */
    componentDidUpdate(prevProps) {
        if(this.props.match.params.orden_id && this.props.match.params.orden_id !== this.state.orden_id){
            this.setState({orden_id: this.props.match.params.orden_id},()=>{this.getOrden()})
        }
    }

    /**
     * @memberof Cotizaciones
     * @method getOrden 
     * @description Obtenemos la informacion de la orden 
     */
    getOrden = () => {
        this.setState({loading: true})
        axios.get('/customer/orden/get',{
            params:{
                orden_id: this.state.orden_id
            }
        }).then(response => {
            this.setState({
                cliente: response.data.data.cliente_id,
                orden: response.data.data
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la información')
        }).finally(()=>this.setState({loading: false}))
    }

    back = () => {
        this.props.history.goBack();
    }

    render() {
        const { cliente, orden } = this.state

        let monto_pendiente = this.state.orden?.total;

        monto_pendiente -= orden?.pagado;

        if(monto_pendiente <= 0)
            monto_pendiente = 0

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Row>
                        <Col span={24} className="mt-05">
                            <Title level={3}>Accounting</Title>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card
                                className="card-shadow card-account-purchases width-100" >
                                <Row>
                                    <Col span={8}>
                                        <Row justify="center">
                                            <Col span={24}>
                                                <Title className="card-title-purple" level={5}>Monto Total</Title>
                                            </Col>
                                            <Col span={24} className="center">
                                                <Text className="card-monto-gray">$ {this.state.orden?.total?.toMoney(true)} MXN</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8}>
                                        <Row justify="center">
                                            <Col span={24}>
                                                <Title className="card-title-purple" level={5}>Monto Pagado</Title>
                                            </Col>
                                            <Col span={24} className="center">
                                                <Text className="card-monto-gray">$ {this.state.orden?.pagado?.toMoney(true)} MXN</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8}>
                                        <Row justify="center">
                                            <Col span={24}>
                                                <Title className="card-title-purple" level={5}>Monto Pendiente</Title>
                                            </Col>
                                            <Col span={24} className="center">
                                                <Text className="card-monto-gray">$ {monto_pendiente.toMoney(true)} MXN</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Tabs defaultActiveKey="pagos" className="tabs-inventario">
                                <TabPane tab="Pagos" key="pagos">
                                    <Pagos orden_id={this.state.orden_id}/>
                                </TabPane>
                                <TabPane tab="Ordenes" key="ordenes">
                                    <Ordenes orden_id={this.state.orden_id}/>
                                </TabPane>
                                <TabPane tab="Facturas" key="facturas">
                                    <Facturas orden_id={this.state.orden_id}/> 
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Spin>
            </>

        )
    }
}