import React, { Component } from "react";
import { Layout, Button, Col, Row, Space, Popconfirm, Card, Typography, List, message } from "antd";

import { IconDetails, IconEdit, IconDelete } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

import ModalTransaccionEgreso from "./ModalTransaccionEgreso"
import ModalTransaccionesDetalle from "./ModalTransaccionesDetalle"

const { Content } = Layout;
const { Text } = Typography
const axios = require('axios')
const moment = require('moment')


/**
 * @export
 * @class EgresosList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class EgresosList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            ingresos: [],
            page: 1,
            limit: 10,
            total: 0,
            transaccion_id: undefined,
            modalTransaccionEgreso: false,

            filtroSearch: '',
            searching: true,
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getTransacciones()
    }

    componentDidUpdate = (prevProps) => {

        console.log(this.props)

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getTransacciones();
            this.state.searching = false;
        }

        //si cambia la orden, actualiza las transacciones
        if(this.props.orden_id && this.props.orden_id !== prevProps.orden_id){
            this.getTransacciones(1)
        }
    }


    /**
    *
    *
    * @memberof AccountPurchases
    * @method getTransacciones
    * @description Obtiene las transacciones de la orden correspondiente
    */
    getTransacciones = (page = this.state.page) => {

        let search = this.props.search;

        const orden_id = this.props.orden_id;

        if (!orden_id) return;
        this.setState({ loading: true })
        axios.post('/transacciones/list', {
            id: orden_id,
            tipo: 2,
            page: page,
            search
        })
            .then(({ data }) => {
                this.setState({
                    ingresos: data.data.itemsList,
                    total: data.data.itemCount,
                    page: data.data.currentPage,

                    searching: true,
                    filtroSearch: search
                })
            })
            .catch(err => console.log(err))
            .finally(() => this.setState({ loading: false }))
    }



    render() {

        return (
            <>
                <Content>
                    <List
                        className="component-list"
                        dataSource={this.state.ingresos}
                        loading={this.state.loading}
                        pagination={{
                            current: this.state.page,
                            pageSize: this.state.limit,
                            total: this.state.total,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className: "flex-left",
                            onChange: (page) => this.getTransacciones(page),
                        }}
                        renderItem={item => (
                            <List.Item>
                                <Card className="card-list">
                                    <div className={`badge-card tipo-${item.tipo}`}>  </div>
                                    <Row className="width-100">
                                        <Col xs={2} className="center">
                                            <Text strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                        </Col>
                                        <Col xs={6} className="center">
                                            <Text className="text-gray-dark">{item.concepto}</Text>
                                        </Col>
                                        <Col xs={2} className="center">
                                            <Text className="text-gray-dark">{item.orden_id?.folio}</Text>
                                        </Col>
                                        <Col xs={1} className="center">
                                            <CustomAvatar name={[item.cliente_id?.nombre, item.cliente_id?.apellido]} />
                                        </Col>
                                        <Col xs={4} className="center">
                                            <Text className="text-gray-dark">{item.cuenta_id?.nombre}</Text>
                                        </Col>
                                        <Col xs={5} className="center">
                                            <span style={{ color: (item.tipo === 1 ? "#10A902" : "#FF0000"), fontWeight: "700", fontSize: "16px", fontFamily: "Poppins" }} > $ {item.monto.toMoney(true)} MXN</span>
                                        </Col>
                                        <Col xs={4} className="center">
                                            <Space>
                                                <Button
                                                    icon={<IconDetails />}
                                                    type="primary"
                                                    title="Ver Detalles"
                                                    onClick={() => this.setState({ modalTransaccionDetalle: true, transaccion_id: item._id })}
                                                    style={{
                                                        backgroundColor: "#ADFF45",
                                                        borderColor: "#ADFF45"
                                                    }}
                                                >
                                                </Button>
                                                <Button
                                                    icon={<IconEdit />}
                                                    type="primary"
                                                    title="Editar"
                                                    onClick={() => this.setState({ modalTransaccionEgreso: true, transaccion_id: item._id })}
                                                    style={{
                                                        backgroundColor: "#456EFF",
                                                        borderColor: "#456EFF"
                                                    }}
                                                >
                                                </Button>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar este registro?"
                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={() => axios.post('/transacciones/delete', { id: item._id }).then((response) => {
                                                        message.success('Transaccion Eliminada')
                                                        this.getTransacciones()
                                                        this.props.updateOrden()
                                                    }).catch((error) => {
                                                        message.success('Transacción NO Eliminada')
                                                        this.getTransacciones();
                                                    })}
                                                >
                                                    <Button type="primary" danger icon={<IconDelete />} title="Eliminar" />
                                                </Popconfirm>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                </Content>
                <FloatingButton
                    title="Nuevo registro"
                    onClick={() => this.setState({ modalTransaccionEgreso: true })}
                />
                <ModalTransaccionEgreso
                    orden_id={this.props.orden_id}
                    visible={this.state.modalTransaccionEgreso}
                    onClose={(bool) => {
                        this.getTransacciones()
                        this.setState({ modalTransaccionEgreso: false, transaccion_id: undefined })
                        if (bool)
                            this.props.updateOrden()
                    }}
                    transaccion_id={this.state.transaccion_id}
                    orden={this.props.orden}

                />
                <ModalTransaccionesDetalle
                    visible={this.state.modalTransaccionDetalle}
                    onClose={() => {
                        this.setState({ modalTransaccionDetalle: false, transaccion_id: undefined })
                    }}
                    id={this.state.transaccion_id}
                />
            </>
        )
    }
}