import React, { Component } from "react";
import { Layout, Button, Col, Row, Space, Popconfirm, Typography, List, message, Card, Tag } from "antd";
import { IconDelete, IconEdit, IconEye } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

import '../../../Styles/Modules/ManagerInventarios/ManagerInventarios.css'
//Modales
import ModalSolicitudProductos from "./ModalSolicitudProductos";
import ModalSolicitudEdicion from "./Solicitudes/ModalSolicitudEdicion";
import ModalSolicitudDetalle from "./Solicitudes/ModalSolicitudDetalle";

const { Content } = Layout;
const { Text } = Typography
const axios = require('axios')
const moment = require('moment')

var tabActiva = "";


/**
 * @export
 * @class SolicitudesList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class SolicitudesList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            solicitudes: [],

            page: 1,
            limit: 10,
            total: 0,

            filtroSearch: '',
            searching: true,
        }
    }


    /**
     *
     *
     * @memberof SolicitudesList
     * @description  Llamara a this.getSolicitudes cuando obtenga el inventario
     */
    componentDidMount = () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        this.getInventario() 
    }


    componentDidUpdate = () => {

 
        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getSolicitudes();
            this.state.searching = false;
        }
    

        if (this.props.actualizarTabla !== tabActiva) {
            tabActiva = this.props.actualizarTabla;
        }
        if (tabActiva === "Solicitudes" && this.state.actualizarTabla === true) {
            this.state.actualizarTabla = false;
            this.setState({ actualizarTabla: false })
            this.getSolicitudes();

        }
        else if (tabActiva !== "Solicitudes" && !(this.state.actualizarTabla)) {
            this.state.actualizarTabla = true;
            this.setState({ actualizarTabla: true })
        }
   
    }


    /**
     * @memberof SolicitudesList
     * @method getSolicitudes
     * @description Obtener las solicitudes del id de una inventario dado
     */
    getSolicitudes = (page = this.state.page) => {

        let search = this.props.search;
        this.setState({loading: true})
        axios.get('/solicitudes/list', {
            params: {
                cliente_id: this.props.cliente_id,
                page,
            }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({
                    solicitudes: data.data.itemsList,
                    page: data.data.paginator.currentPage,
                    total: data.data.paginator.itemCount
                })
            })
            .catch(error => {
                message.error('Error al obtener solicitudes')
            }).finally(()=>this.setState({loading: false}))
    }

    /**
     *
     *
     * @memberof SolicitudesList
     * @method getInventario
     * @description Obtener inventario del id de un cliente dado por props
     */
    getInventario = () => {
        axios.get('/inventarios/get', {
            params: {
                cliente_id: this.props.cliente_id
            }
        })
            .then(({ data }) => {
                this.setState({
                    inventario: data.data
                }, () => {
                    this.getSolicitudes()
                })
            })
            .catch(error => {
                message.error("No se obtuvo el Inventario")
            })
    }


    render() {

        return (
            <>
                <Content>
                    <Row>
                        <Col span={24}>
                            <List
                                className="component-list lista-vmi"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin Solicitudes" }}
                                dataSource={this.state.solicitudes}
                                loading={this.state.loading}
                                pagination={{
                                    current: this.state.page,
                                    pageSize: this.state.limit,
                                    total: this.state.total,
                                    hideOnSinglePage: false,
                                    position: 'bottom',
                                    className: "flex-left",
                                    onChange: this.getSolicitudes
                                }}
                                renderItem={({ _id, tipo, estatus, almacen_id, producto_id, cantidad, createdAt, cliente_solicitante_id }) => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <div className={`badge-card tipo-${tipo}`}>
                                            </div>
                                            <Row className="width-100">
                                                <Col span={2} className="center">
                                                    <Text strong>{moment(createdAt).format('DD/MM/YYYY')}</Text>
                                                </Col>
                                                <Col xs={5} className="center">
                                                    <Text className="text-gray-dark">{producto_id?.np_fabricante}</Text>
                                                </Col>
                                                <Col xs={2} className="center">
                                                    <Text className="text-gray-dark">{cantidad} {producto_id?.unidad?.toUnidades()}</Text>
                                                </Col>
                                                <Col xs={4} className="center">
                                                    <Text className="text-purple">{cliente_solicitante_id?.nombre} {cliente_solicitante_id?.apellidos}</Text>
                                                </Col>
                                                <Col xs={4} className="center">
                                                    <Text className="text-gray-dark" ellipsis>{almacen_id?.direccion}</Text>
                                                </Col>
                                                <Col xs={2} className="center">
                                                    <Tag className="tag-list" color={`${tipo === 1 ? 'yellow' : 'red'}`} >{tipo === 1 ? 'Entrada' : 'Salida'}</Tag>
                                                </Col>
                                                <Col xs={2} className=" center">
                                                    <Tag className="tag-list" color={`${estatus === 0 ? 'blue' : (estatus === 2 ? 'red' : 'green')}`} >{estatus === 0 ? 'Solicitud' : (estatus === 2 ? 'Rechazada' : 'Aceptada')}</Tag>
                                                </Col>
                                                <Col xs={3} className="center">
                                                    <Space>
                                                        <Button
                                                            icon={<IconEye />}
                                                            type="primary"
                                                            title="Ver Detalle"
                                                            onClick={() => this.setState({ modalSolicitudDetalleVisible: true, solicitud_id: _id, tipo })}
                                                        />
                                                        
                                                        <Button
                                                            icon={<IconEdit />}
                                                            type="primary"
                                                            title="Editar"
                                                            onClick={() => this.setState({ modal_edicion: true, solicitud_id: _id })}
                                                            disabled={estatus >= 1}
                                                        />

                                                        {estatus >= 1 ? <Button 
                                                                            type="primary" 
                                                                            danger 
                                                                            icon={<IconDelete />} 
                                                                            title="Eliminar" 
                                                                            disabled={true}
                                                                /> : <Popconfirm
                                                                    placement="topRight"
                                                                    title="¿Deseas eliminar esta Solicitud?"
                                                                    onConfirm={() => axios.post('/solicitudes/delete', { id: _id }).then((response) => {
                                                                        message.success('Solicitud Eliminada')
                                                                        this.getSolicitudes()
                                                                    }).catch((error) => {
                                                                        message.error('Solicitud NO Eliminada')
                                                                    })
                                                                    }
                                                                    okText="Si"
                                                                    cancelText="No"
                                                                >
                                                                    <Button type="primary" danger icon={<IconDelete />} title="Eliminar" />
                                                                </Popconfirm>
                                                           
                                                        }
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />

                        </Col>
                    </Row>
                    <FloatingButton title="Nueva solicitud" onClick={() => this.setState({ modalSolicitudVisible: true })} />
                </Content>
                <ModalSolicitudProductos
                    visible={this.state.modalSolicitudVisible}
                    cliente_id={this.props.cliente_id}
                    solicitud_id={this.state.solicitud_id}
                    onClose={() => {
                        this.setState({
                            modalSolicitudVisible: false,
                            solicitud_id: undefined
                        })
                        this.getSolicitudes()
                    }}
                />

                <ModalSolicitudEdicion
                    visible={this.state.modal_edicion}
                    cliente_id={this.props.cliente_id}
                    solicitud_id={this.state.solicitud_id}
                    responsable={this.props.responsable}
                    onClose={() => {
                        this.setState({
                            modal_edicion: false,
                            solicitud_id: undefined
                        })
                        this.getSolicitudes()
                    }}
                />

                <ModalSolicitudDetalle
                    visible={this.state.modalSolicitudDetalleVisible}
                    cliente_id={this.props.cliente_id}
                    solicitud_id={this.state.solicitud_id}
                    tipo={this.state.tipo}
                    onClose={() => {
                        //this.getSolicitudes()
                        this.setState({
                            modalSolicitudDetalleVisible: false,
                            solicitud_id: undefined
                        })
                    }}
                />
            </>
        )
    }
}