import React, { Component } from 'react';
import { Button, Popconfirm, Spin, Table, PageHeader, Layout, message, Tooltip, Space, Modal } from 'antd'
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { FaUsers } from "react-icons/fa";
import axios from 'axios';
import { Link } from "react-router-dom";

//Componentes
import { IconDelete, IconEdit, IconEye } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalClientes from './ModalClientes'

const { Content } = Layout;

/**
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista de Clientes
 */
export default class Clientes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            cliente: undefined,
            clientes: [],

            page: 1,
            total: 0,
            filtroSearch: '',
            searching: true,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getClientes();
    }


    /**
     * @memberof Clientes
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getClientes();
            this.state.searching = false;
        }
    }

    /**
    * @memberof Clientes
    * 
    * @method getClientes
    * @description Obtiene la lista de clientes
    */
    getClientes = (page = this.state.page) => {

        let search = this.props.search;
        this.setState({ loading: true })
        axios.get('/clientes/list', {
            params: {
                page: page,
                search: search,
                registrosInventario: true
            }
        }).then(response => {
            console.log(response)

            this.setState({
                clientes: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
                searching: true,
                filtroSearch: search
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Clientes"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <Table
                            scroll={{ x: 1000 }}
                            loading={this.state.loading}
                            columns={[
                                {

                                    dataIndex: 'premium',
                                    key: 'premium',
                                    width: '40px',
                                    render: (text, record) => (
                                        (record.premium == true) ? <StarFilled className="star-filled" /> : <StarOutlined />
                                    ),
                                },
                                {
                                    title: 'Nombre',
                                    dataIndex: 'nombre',
                                    render: (text, record) => (
                                        <h3>{record.nombre}</h3>
                                    ),
                                },

                                {
                                    title: 'Email',
                                    dataIndex: 'email',
                                    key: 'email',
                                },

                                {
                                    title: 'Teléfono',
                                    dataIndex: 'telefono',
                                    key: 'telefono',
                                },

                                {
                                    title: 'RFC',
                                    dataIndex: 'rfc',
                                    key: 'rfc',
                                },
                                {
                                    title: 'Razón Social',
                                    dataIndex: 'razon_social',
                                    key: 'razon_social',
                                },
                                {
                                    title: 'Acciones',
                                    key: 'actions',
                                    fixed: 'right',
                                    width: '15%',
                                    render: (text, record) => (
                                        <Space>
                                            <Link title="Editar" to={`/admin/clientes/informacion/${record._id}`}>
                                                <Button type="primary" icon={<IconEye />} name={record.nombre} id={record._id}
                                                    title="Ver detalle"
                                                    style={{ paddingTop: '9px' }}>
                                                </Button>
                                            </Link>
                                            <Link title="Usuarios" to={`/admin/clientes/usuarios/${record._id}`}>
                                                <Button
                                                    type="primary"
                                                    icon={<FaUsers />}
                                                    title="Usuarios"
                                                    disabled={!record.premium}
                                                />
                                            </Link>
                                            <Button
                                                type="primary"
                                                icon={<IconEdit />}
                                                title="Editar"
                                                onClick={() => { this.setState({ modalVisible: true, cliente_id: record._id }) }}
                                            />
                                            <Tooltip title={"Este cliente tiene Compras y Ordenes de Compra relacionadas. Asegurese de eliminarlas antes de continuar."} trigger={record.ordenes ? ["hover"]: []}>
                                                <Button
                                                    disabled={record.ordenes}
                                                    type="primary"
                                                    icon={<IconDelete />}
                                                    title="Eliminar"
                                                    danger
                                                    onClick={() => {
                                                        Modal.confirm({
                                                            title: "¿Estás seguro de eliminar este cliente?",
                                                            description: "Al hacerlo, tambien eliminará las compras, ordenes y usuarios asociados a este cliente.",
                                                            onOk: axios.post('/clientes/delete', { id: record._id }).then(() => {
                                                                this.getClientes()
                                                                message.success('Cliente eliminado')
                                                            })
                                                        })
                                                    }}
                                                />
                                            </Tooltip>
                                        </Space>
                                    ),
                                },
                            ]}
                            dataSource={this.state.clientes}
                            pagination={{
                                onChange: this.getClientes,
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                position: ['bottomLeft'],
                            }}
                            locale={{ emptyText: 'Sin clientes disponibles' }}
                        />

                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />


                    <ModalClientes
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, cliente_id: undefined })
                            this.getClientes(this.state.page)
                        }}
                        cliente_id={this.state.cliente_id}
                    />
                </Spin>
            </>
        )
    }
}