import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin } from 'antd';

import ColorPicker from '../../Widgets/ColorPicker';

const { Title } = Typography;

const axios = require('axios').default;


class ModalClasificadores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: false,
            return: false,
        }
    }

    ModalClasificadores = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.id !== undefined) {
            this.getClasificador()
        }
    }


    /**
     * @memberof ModalClasificadores
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        if (this.props.id) {
            this.updateClasificador(values)
        } else {
            this.addClasificador(values)
        }
    }


    /**
     * @memberof ModalClasificadores
     * @method addClasificador
     * @description Añade un clasificador a la BD
     *
     */
    addClasificador = (values) => {

        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {

            axios.post('/clasificadores/add', {
                ...values
            }).then(response => {
    
                if (response.data.success) {
                    message.success('clasificador creado')
                    this.setState({loading: false}, () => this.props.onClose())
                }
                else if (response.data.message !== undefined) {
                    message.error(response.data.message.title + "  " + response.data.message.content)
                }
                else {
                    message.error('Error al crear clasificador, verifique los datos ingresados.')
                }
            }).catch(error => {
                console.log(error)
                message.error('Error al crear clasificador.')
            }).finally(() => {
                this.setState({loading: false})
            })
        })

    }


    /**
     * @memberof ModalClasificadores
     * @method getClasificador
     * @description Obtiene un clasificador de la DB
     */
    getClasificador = () => {

        this.setState({ loading: true })

        axios.get('/clasificadores/get', {
            params: {
                id: this.props.id
            }
        }).then(response => {
            let data = response.data.data;
            this.setState({
                data: data,
            })

            this.ModalClasificadores.current.setFieldsValue({
                nombre: data.nombre,
                descripcion: data.descripcion,
                color: data.color,
            })

        }).catch(error => {
            message.error('Error al traer Clasificador')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalClasificadores
     * @method updateClasificador
     * @description Actualiza la información de un clasificador
     * 
     */
    updateClasificador = (values) => {
        this.setState({ loading: true })
        axios.post('/clasificadores/update', {
            ...values,
            id: this.props.id,
        }).then(response => {

            if (response.data.success) {
                message.success('Clasificador Actualizado')
                this.props.onClose()
            }
            else if (response.data.message !== undefined) {
                message.error(response.data.message.title + "  " + response.data.message.content)
            }
            else {
                message.error('Error al editar Clasificador, verifique los datos ingresados.')
            }

        }).catch(error => {
            message.error('Error al actualizar Clasificador')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <Form
                layout="vertical"
                ref={this.ModalClasificadores}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            // rules={[{
                            //     required: true,
                            //     message: "Por favor, ingrese una descipción"
                            // }]}
                            >
                                <Input placeholder="Descripción" ></Input>
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Color"
                                name="color"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione un color"
                                }]}
                            >
                                <ColorPicker />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Clasificador</Title>
        </div>
        <ModalClasificadores {...props} />
    </Modal>

}