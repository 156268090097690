import React, { Component } from "react";
import { Layout, Menu, Button } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import '../../Styles/Global/sider.css';
import { Link } from "react-router-dom"
// import { useHistory } from "react-router-dom";

import {
    IconMisPedidos,
    IconVMI,
    IconCotizacion

} from '../Widgets/Iconos.js'


const { SubMenu } = Menu;
const { Sider } = Layout;
let roles = {
    finanzas: false,
    crm: false,
    p_manager: false,
    legal: false,
    cuentas: false,
    clasificadores: false,
    rubros: false,
    proyectos: false,
    usuarios: false,

    manager: false,

    estatus_avance: false,
    bitacora_estatus_avance: false,
};




/**
 * @export
 * @class Cliente_Sidebar
 * @extends {Component}
 */
class Cliente_Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }
    }

    /**
     * @memberof Cliente_Sidebar
     */
    toogle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    render() {
        const { user } = this.props;
        // console.log('customer',user)
        let owner = false;
        if (typeof user == "object") {
            if (user.roles !== undefined)
                roles = user.roles;

            if (user.tipo == 1)
                owner = true;
        }
        return (
            <Sider theme="light"
                width={300}
                breakpoint="lg"
                collapsedWidth="0"
                className="custom-sider sider-width" 
                //trigger={null} 
                //collapsed 
                //collapsed={this.state.collapsed}
            >
                <Menu
                    mode="inline"
                    theme="light"
                    className="heigth-100 "
                    style={{ borderRight: 0 }}
                >
                    {/*<Button ghost onClick={this.toogle} className="no-border">
                        {this.state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>*/}

                    <Menu.ItemGroup key="g1" title="NAVEGACIÓN">

                        <Menu.Item key="MisPedidos" icon={<IconMisPedidos />}>
                            <Link to={'/customer/misPedidos'}>Mis Pedidos</Link>
                        </Menu.Item>

                        {(owner || user?.cliente_id?.premium) ? <Menu.Item key="VMI" icon={<IconVMI />}>
                            <Link to={'/customer/VMI'}>Ordenes</Link>
                        </Menu.Item> : null}

                        {(owner || user?.cliente_id?.premium) ? <Menu.Item key="VMI-A" icon={<IconVMI />}>
                            <Link to={'/customer/VMI/comprar'}>VMI</Link>
                        </Menu.Item> : null}

                        <Menu.Item key="Cotizador" icon={<IconCotizacion />}>
                            <Link to={'/customer/cotizador'}>Cotizador Spot</Link>
                        </Menu.Item>



                    </Menu.ItemGroup>
                </Menu>

            </Sider>
        )
    }
}

export default (props) => <Cliente_Sidebar  {...props} />
