import React from 'react';
import { Row, Col, Typography, } from 'antd';
import { IconCheck } from '../../Widgets/Iconos';

import '../../../Styles/Modules/Public/marketplace.scss';

const { Title, Paragraph } = Typography;

export default function StepSuccess({ folio, nombre }) {

    return (
        <Row justify="center" className='mt-2'>
            <Col xs={24} style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="marketplace-content">
                <Title level={3}>
                    ¡Hemos recibido tu Solicitud!
                </Title>
                <IconCheck className="marketplace-icon-mark" style={{ margin: "2rem 0" }} />
                <Paragraph style={{ maxWidth: 350, textAlign: "center" }}>
                    Muchas gracias por confiar en nosotros, {nombre}, en un plazo no mayor a 48 horas, tendrás la cotización en tu correo electrónico que hemos enviado.
                </Paragraph>
                <Paragraph style={{ margin: "2rem 0 1rem 0" }}>
                    El número de folio de tu pedido es el:
                </Paragraph>
                <Paragraph style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                    {folio}
                </Paragraph>
                <Paragraph style={{ margin: "0rem 0 2rem 0", textAlign: "center" }}>
                    Con tu cuenta Nexus, puedes seguir tu cotización en tiempo real:
                </Paragraph>
                <a href="https://clients.nexusnova.mx/" style={{ color: 'white', fontWeight: "bold", fontSize: "1.125rem" }}>
                    https://clients.nexusnova.mx/
                </a>
            </Col>
        </Row>
    )
}