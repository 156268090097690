import React, { Component } from "react";
import { Button, Col, Row, Collapse, Typography, List, message } from "antd";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'

import { IconCarrito } from '../../Widgets/Iconos';
import ModalCompraProductos from './ModalCompraProductos';

import '../../../Styles/Modules/Customer/customer.css'

const { Title, Text } = Typography
const { Panel } = Collapse;
const axios = require('axios')


/**
 * @export
 * @class Productos
 * @extends {Component}
 * @description Lista de almacenes
 */
export default class Productos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalVisible: false,

            productos: [],
            page: 1,
            limit: 10,
            total: 0,

            searching: true,
            filtroSearch: '',
        }
    }


    /**
    * @method componentDidMount 
    * @description Cargamos la informacion inicial
    */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProductos();
    }


    /**
    * @method componentDidUpdate
    * @description Actualiza la consulta si se buscan datos
    */
    componentDidUpdate() {
        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getProductos();
            this.state.searching = false;
        }
    }



    /**
     * @memberof Productos
     * @method getClasificador
     * @description Obtiene los productos del cliente y enque almacenes se encuantran
     */
    getProductos = (page = this.state.page) => {

        let search = this.props.search;
        this.setState({ loading: true, productos: [] })
        axios.get('/vmi/list', {
            params: {
                productos: true,
                search,
                page
            }
        }).then(response => {
            this.setState({
                productos: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount,

                filtroSearch: search,
                searching: true,
                loading: false
            })
        }).catch(error => {
            console.log(error)
            message.error('Erro al obtener la información de los productos')
        }).finally(() => this.setState({ loading: false }))
    }

    render() {

        return (
            <>
                <Row>
                    <Col span={24}>
                        <List
                            dataSource={this.state.productos}
                            pagination={{
                                current: this.state.page,
                                pageSize: this.state.limit,
                                total: this.state.total,
                                hideOnSinglePage: true,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: this.getProductos,
                            }}
                            loading={this.state.loading}
                            renderItem={item => (
                                <Collapse
                                    bordered={false}
                                    defaultActiveKey={['1']}
                                    expandIcon={({ isActive }) => isActive ? <MinusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} /> : <PlusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} />}
                                    className="collapse-almacenes"
                                >
                                    <Panel
                                        header={
                                            <Row className="width-100 header-collapse ">
                                                <Col xs={4} xl={1} className="center">
                                                </Col>
                                                <Col xs={20} xl={4} className="flex-left">
                                                    <Text className="collapse-text-big">{item.np_cliente}</Text>
                                                </Col>
                                                <Col xs={12} lg={8} xl={4} className="flex-column">
                                                    <Text>Stock</Text>
                                                    <Text>{item.cantidad - item.cliente_cantidad_proceso}</Text>
                                                </Col>
                                                <Col xs={12} lg={8} xl={2} className="flex-column">
                                                    <Text>Min</Text>
                                                    <Text>{item.inv_minimo}</Text>
                                                </Col>
                                                <Col xs={12} lg={8} xl={2} className="flex-column">
                                                    <Text>Max</Text>
                                                    <Text>{item.inv_maximo}</Text>
                                                </Col>
                                                <Col xs={12} lg={8} xl={2} className="flex-column">
                                                    <Text>Precio</Text>
                                                    <Text >$ {item.precio.toMoney(true)}</Text>
                                                </Col>
                                                <Col xs={12} lg={8} xl={4} className="flex-column">
                                                    <Text>Descripcion</Text>
                                                    <Text className="text-white">{item?.producto_id?.descripcion}</Text>
                                                </Col>
                                                <Col xs={12} lg={8} xl={5} className="flex-column">
                                                    <Text>Marca</Text>
                                                    <Text className="text-gray">{item.producto_id?.marca}</Text>
                                                </Col>
                                            </Row>
                                        }
                                    >
                                        <Row className="width-100 border-bottom">
                                            <Col span={11} className="center">
                                                <Text className="text-gray-dark">Almacén</Text>
                                            </Col>
                                            <Col span={5} className="flex-column">
                                                <Text className="text-gray-dark">Ubicación</Text>
                                            </Col>
                                            <Col span={5} className="flex-right">
                                                <Text className="text-gray-dark">Cantidad</Text>
                                            </Col>
                                        </Row>
                                        {item.almacenes?.map(alm => {
                                            
                                            // alm.cantidad - alm.cantidad_proceso
                                            console.log('alm.cantidad', alm.cantidad);
                                            console.log('alm.cantidad_proceso', alm.cantidad_proceso);

                                            return <Row>
                                                <Col span={11} className="center">
                                                    <Text ellipsis>{alm.almacen_id.direccion}</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text className="text-gray">{alm.ubicacion}</Text>
                                                </Col>
                                                <Col span={5} className="flex-right" >
                                                    <Text className="text-gray">{alm.cantidad - alm.cantidad_proceso}</Text>
                                                </Col>
                                                <Col span={2} className="flex-right" >
                                                    <Button
                                                        icon={<IconCarrito />}
                                                        style={{ paddingTop: "12px" }}
                                                        type="primary"
                                                        className="btn-compra"
                                                        onClick={() => this.setState({
                                                            modalVisible: true,
                                                            producto: item.producto_id,
                                                            np_cliente: item.np_cliente,
                                                            cantidad_disp: alm.cantidad - alm.cantidad_proceso,
                                                            cantidad_proceso: alm.cantidad_proceso,
                                                            almacen_id: alm.almacen_id._id,
                                                            precio: item.precio //precio inventario cliente
                                                        })}
                                                    >
                                                    </Button>
                                                </Col>
                                            </Row>
                                        })}
                                    </Panel>
                                </Collapse>
                            )}
                        />
                    </Col>
                </Row>
                <ModalCompraProductos
                    visible={this.state.modalVisible}
                    onClose={() => {
                        this.setState({
                            modalVisible: false,
                            producto: undefined,
                            cantidad_disp: undefined,
                            cantidad_proceso: undefined,
                            almacen_id: undefined,
                            precio: undefined,
                        })
                        this.getProductos()
                    }}
                    producto={this.state.producto}
                    np_cliente={this.state.np_cliente}
                    cantidad_disp={this.state.cantidad_disp}
                    cantidad_proceso={this.state.cantidad_proceso}
                    almacen_id={this.state.almacen_id}
                    precio={this.state.precio}
                />
            </>
        )
    }
}