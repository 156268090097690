import React, { Component } from "react";
import { Col, Row, Typography, List, Card, message, Space, Button,Statistic } from "antd";

import '../../../Styles/Modules/Customer/customer.css'

const moment = require('moment')
const { Text } = Typography
const axios = require('axios')


/**
 * @export
 * @class PagosList
 * @extends {Component}
 * @description Lista de almacenes
 */
export default class PagosList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pagos: [],
            page: 1,
            limit: 10,
            total: 0,
            loading: false,

            searching: true,
            filtroSearch: '',
        }
    }


    /**
    * @method componentDidMount 
    * @description Cargamos la informacion inicial
    */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getPagos()
    }



    /**
    * @method componentDidUpdate
    * @description Actualiza la consulta si se buscan datos
    */
     componentDidUpdate() {
        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getPagos();
            this.state.searching = false;
        }
    }



    getPagos = (page = this.state.page) => {

        let search = this.props.search;

        this.setState({ loading: true })
        axios.post('/transacciones/list', {
            vmi:  true,
            search,
            page
        }).then(response => {
            this.setState({
                pagos: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount,
                
                filtroSearch: search,
                searching: true,
                loading: false

            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener los pagos')
        }).finally(() => this.setState({ loading: false }))
    }
    
    /**
    * @memberof IngresosList
    * @method   renderMonto
    * @description  Retorna el monto con el color correspondinte dependiendo del tipo de transaccion
    **/
     renderMonto = (tipo, monto) => {
        let colors = ['#000000', '#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }


    render() {

        return (
            <>
                <Row>
                    <Col span={24}>
                        <List
                            className="w-100"
                            dataSource={this.state.pagos}
                            pagination={{
                                current: this.state.page,
                                pageSize: this.state.limit,
                                total: this.state.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: this.getPagos
                            }}
                            loading={this.state.loading}
                            header={<Row className="header-list width-100 pr-1 pl-1" >
                                <Col span={5} className="center">
                                    <Text>Fecha</Text>
                                </Col>
                                <Col span={10} className="center">
                                    <Text style={{ textAlign: "start", width: '100%' }} >Descripción</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text>Folio</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text>Total</Text>
                                </Col>
                            </Row>}
                            renderItem={item => (
                                <List.Item className="component-list-item width-100">
                                    <Card className="card-list width-100">
                                        <Row className="width-100 font-13">
                                            <Col span={5} className="center">
                                                <Text strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                            </Col>
                                            <Col xs={10} className="center" >
                                                <Text className="text-gray-dark" style={{ textAlign: "start", width: '100%' }} strong>{item.concepto?.length > 50 ? item.concepto?.slice(0, 50) + "..." : item.concepto}</Text>
                                            </Col>

                                            <Col xs={5} className="center">
                                                <Text className="text-gray-dark">{item.orden_id?.folio}</Text>
                                            </Col>
                                            <Col xs={4} className="center">
                                                <Text>{this.renderMonto(item.tipo, item.monto)} </Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}