import React, { Component, useContext } from 'react';
import { Button, Modal, Typography, Form, Input, Row, Col, } from 'antd';
import { User, SetUser } from '../../../Hooks/Logged';
import '../../../Styles/Modules/Public/marketplace.scss';



const axios = require('axios')

const { Title, Paragraph, Text } = Typography;

class StepCuenta extends Component {

    static contextType = SetUser

    constructor(props) {
        super(props);
        this.state = {
            type: 2
        }
    }


    /**
     *
     *
     * @param {*} data
     * @memberof StepCuenta
     * 
     * @description Registra o inicia sesión según sea el caso.
     */
    handleAccount = data => {
        axios.post(((this.state.type === 2) ? '/login' : '/register'), data)
            .then((response) => {

                sessionStorage.setItem('token', response.headers.authorization)
                axios.defaults.headers.post["Authorization"] = sessionStorage.getItem('token')

                const data = this.state.type === 2 ? response.data.user : response.data.data;

                this.context(data)
                this.props.onSuccess()

            })
            .catch((error) => {

                console.log(error)
                Modal.error({
                    title: "No fue posible " + ((this.state.type === 2) ? 'iniciar sesión' : 'registrarse'),
                    content: error?.response?.data?.message
                })
            })
            .finally(f => {
            })
    }

    logout = () => {
        this.props.setUser(undefined);
        sessionStorage.clear();
    }

    changeTypeLogin = () => this.setState({ type: (this.state.type === 1) ? 2 : 1 })


    render() {
        return (
            this.props.user ? (
                <Row className="marketplace-auth-logged" justify={"center"} gutter={[24, 24]}>
                    <Col xs={20} md={16} lg={13} className="logged-header">
                        <Title level={4} >Tienes una sesión abierta</Title>
                        <Text >{this.props?.user?.nombre} {this.props?.user?.apellidos}</Text>
                    </Col>
                    <Col xs={20} md={16} lg={13} className='logged-actions'>
                        <Button className="logged-continue" onClick={() => this.props.onSuccess()}>Continuar con ella</Button>
                        <Button type="link" className='logged-logout' onClick={() => this.logout()}>Cerrar Sesión</Button>
                    </Col>
                </Row>
            ) : (

                <Form
                    name="basic"
                    layout="vertical"
                    className='marketplace-auth'
                    ref={ref => window.formRef = this.formRef = ref}
                    onFinish={this.handleAccount}
                    initialValues={{
                        tipo: 4
                    }}
                >
                    <div className="auth-container">

                        <Title level={3}>
                            {(this.state.type === 1) ? "Registrate" : "Iniciar sesión"}
                        </Title>
                        <Form.Item name="tipo" type="hidden" style={{ display: "none" }} />
                        <Form.Item
                            className="cuenta-form-item"
                            label="Correo Electrónico / Email"
                            name="email"
                            required={[{ required: true, message: "Debe de ingresar el correo electrónico" }]}
                        >
                            <Input type="text" placeholder='Correo Electrónico' />
                        </Form.Item>
                        <Form.Item
                            className="cuenta-form-item"
                            label="Contraseña"
                            name="password"
                            rules={(this.state.type === 1) ?


                                [
                                    {
                                        required: true,
                                        message: 'Por favor ingresa tu  contraseña'
                                    },
                                    {
                                        min: 8,
                                        message: 'La contraseña debe de tener mínimo 8 caracteres'
                                    },
                                    {
                                        pattern: new RegExp("^(?=.*[0-9])(?=.*[a-z]|[A-Z]).+$"),
                                        message: 'Debe tener números y letras en mayusculas y minusculas'
                                    },
                                ]

                                : [
                                    {
                                        required: true,
                                        message: 'Por favor ingresa tu  contraseña'
                                    },
                                ]}>
                            <Input.Password placeholder="Contraseña" />
                        </Form.Item>
                        {(this.state.type === 1) ? <Form.Item
                            className="cuenta-form-item"
                            name="password_confirmation"
                            label="Confirmar Contraseña"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Debe de confirmar su contraseña',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Las contraseñas no coinciden'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="Confirmar Contraseña" />
                        </Form.Item> : null}
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block className="auth-button" >
                                {(this.state.type === 1) ? "Registrate" : "Iniciar sesión"}
                            </Button>
                        </Form.Item>
                        <Paragraph >
                            {(this.state.type === 1) ? "¿Ya Tienes Cuenta?" : "¿No Tienes Cuenta?"}
                            <Button
                                className="cuenta-form-link"
                                onClick={this.changeTypeLogin}
                            >
                                {(this.state.type === 1) ? "Iniciar sesión" : "Registrate"}
                            </Button>
                        </Paragraph>



                    </div>
                </Form>
            )
        )
    }
}


export default function (props) {

    let user = useContext(User)
    const setUser = useContext(SetUser)

    return <StepCuenta {...props} user={user} setUser={setUser} />
}