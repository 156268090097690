import React from 'react'
import { Route, Switch } from "react-router-dom";


// componentes
import Productos from '../components/Admin/Productos/Productos'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
 export default function RouterProductos(props) {

    const { updateFilterSearch, search } = props


    return (
        <Switch>
            <Route exact path="/admin/productos" render={props => <Productos {...props} updateFilterSearch={updateFilterSearch} search={search}/>} />
        </Switch>
    )
}