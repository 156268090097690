import React, { Component } from 'react';
import { Button, Popconfirm, Space, Card, Row, List, Col, Spin, PageHeader, Layout, message, Typography, Switch } from 'antd'
import axios from 'axios';

import { IconDelete, IconEdit,IconArrowBack } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import ModalCatalogo from "./ModalCatalogo";

const { Content } = Layout;
const { Text } = Typography

/**
 * @export
 * @class Catalogos
 * @extends {Component}
 * @description Vista de Catalogos
 */
export default class Catalogos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            catalogos: [],
            proveedor_id: this.props.match.params.proveedor_id,
            proveedor: null,
            productos: {
                data: [],
                page: 1,
                limit: 10,

                total: 0,
                pages: 0
            }
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCatalogos();
    }




    /**
     * @memberof Catalogos
     * 
     * @method getCatalogos
     * @description Obtenemos la informacion del catalogos
     */
    getCatalogos = (page = 1) => {
        this.setState({ loading: true })
        axios.get('/catalogos', {
            params: {
                proveedor_id: this.props.match.params.proveedor_id,
                page
            }
        }).then(({ data }) => {
            console.log(data)
            this.setState({ catalogos: data.data.itemsList })
        })
            .catch(error => {
                console.log(error)
                message.error('Error al traer la información')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof FormCatalogo
     * 
     * @method updateMany
     * @description Actualizamos los multiples catalogos, en su estado activo
     */
    updateMany = (catalogo_id) => {
        this.setState({ loading: true })
        axios.post('/catalogos/active', {
            id: catalogo_id,
            proveedor_id: this.state.proveedor_id

        }).then(({ data }) => {
            message.success('Catalogos Actualizados')
            this.getCatalogos(this.state.page)
        })
            .catch(error => {
                console.log(error)
                message.error('Error al Actualizar')
                this.setState({ loading: false })
            })
    }


    showModal = (producto_id = undefined) => this.setState({ modalCatalogo: true, producto_id });

    back = () => {
        this.props.history.goBack();
    }

    render() {

        const { catalogos } = this.state

        return (
            <Spin spinning={false}>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Catálogos"
                    backIcon={<IconArrowBack />}
                    onBack={this.back}
                />
                <Content className="admin-content content-bg pd-1">
                    <List
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Catálogos " }}
                        dataSource={catalogos}
                        loading={this.state.loading}
                        pagination={{
                            onChange: (page, limit) => this.getCatalogos(page),
                            current: this.state.page,
                            pageSize: 10,
                            total: this.state.total,

                            hideOnSinglePage: false,
                            position: 'bottom',
                            className: "flex-left"
                        }}
                        header={<Row className="header-list width-100 pr-1 pl-1" >

                            <Col lg={5} className="center">
                                <Text strong>Nombre</Text>
                            </Col>
                            <Col lg={6} className="center">
                                <Text strong>Descripción</Text>
                            </Col>
                            <Col lg={8} className="center">
                                <Text strong>Archivo</Text>
                            </Col>
                            <Col lg={3} className="center">
                                <Text strong>Activo</Text>
                            </Col>
                            <Col lg={2} className="center">
                                <Text strong>Acciones</Text>
                            </Col>
                        </Row>
                        }

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">
                                        <Col lg={5} className="center">
                                            <Text>{item.nombre}</Text>
                                        </Col>
                                        <Col lg={6} className="center">
                                            <Text>{item.descripcion}</Text>
                                        </Col>
                                        <Col lg={8} className="center">
                                            <a href={`${axios.defaults.baseURL}/voucher/${item.archivo}`} target="_blank">{item.archivo}</a>
                                        </Col>
                                        <Col lg={3} className="center">
                                            <Switch checked={item.activo} onChange={() => this.updateMany(item._id)} />
                                        </Col>
                                        <Col lg={2} className="center">
                                            <Space>
                                                <Button type="primary" icon={<IconEdit />} onClick={() => this.setState({ catalogo_id: item._id, modalCatalogo: true })} />
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar este Catálago?"
                                                    onConfirm={() => axios.post('/catalogos/delete', { id: item._id })
                                                        .then((response) => {
                                                            message.success("Eliminado Correctamente")
                                                            this.getCatalogos()
                                                        })
                                                        .catch((error) => {
                                                            message.error("No es posible eliminar")
                                                            this.getCatalogos();
                                                        })
                                                    }
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button type="normal" danger icon={<IconDelete />} title="Eliminar" />
                                                </Popconfirm>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalCatalogo: true })} />
                </Content>
                <ModalCatalogo
                    visible={this.state.modalCatalogo}
                    onCancel={() => {
                        this.setState({ modalCatalogo: false, catalogo_id: undefined })
                        this.getCatalogos()
                    }}
                    proveedor_id={this.state.proveedor_id}
                    catalogo_id={this.state.catalogo_id}
                />
            </Spin>
        )
    }
}