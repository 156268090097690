import React from 'react'
import { Route, Switch } from "react-router-dom";
import Negocios from '../components/Admin/Negocios/Negocios';


/**
 *
 *
 * @export
 * @function RouterNegocios
 */
function RouterNegocios( props) {
    return (
        <Switch>
            <Route exact path="/admin/negocios" render={() => <Negocios {...props} />} />
        </Switch>
    )
}

export default RouterNegocios;
