import React, { Component } from "react";
import { Layout, Button, Col, Row, Space, Popconfirm, Card, Typography, List, Menu, Dropdown, message } from "antd";

import { GoFileCode } from "react-icons/go";
import { FilePdfOutlined,  EyeFilled, EditFilled, DeleteFilled } from "@ant-design/icons";

//componentes
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import { IconDetails, IconEdit, IconDelete, IconSAT } from '../../../Widgets/Iconos';

//Modales
import ModalFacturas from "./ModalFacturas"
import ModalFacturasDetalle from "./ModalFacturasDetalle"
import DrawerTimbradoOrden from "../../Finanzas/Facturas/DrawerTimbrado"


const { Content } = Layout;
const { Text, Title } = Typography
const axios = require('axios')
const moment = require('moment')

/**
 * @export
 * @class EgresosList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class EgresosList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            facturas: [],
            page: 1,
            limit: 10,
            total: 0,
            transaccion_id: undefined,
            modalTransaccionEgreso: false,
            visibleDrawerTimbradoOrden: false,
            filtroSearch: '',
            searching: true,

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getFacturas()
    }


    componentDidUpdate = (prevProps) => {

        console.log(this.props)

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getFacturas();
            this.state.searching = false;
        }

        //si cambia la orden, actualiza las facturas
        if (this.props.orden_id && this.props.orden_id !== prevProps.orden_id) {
            this.getFacturas(1)
        }
    }

    /**
    *
    *
    * @memberof AccountPurchases
    * @method getFacturas
    * @description Obtiene las facturas de la orden acual
    */
    getFacturas = (page = 1) => {
        let search = this.props.search;
        const { tipo } = this.state;
        const { orden_id } = this.props;
        if (!orden_id) return;

        axios.get('/facturas/list', {
            params: {
                id: orden_id,
                tipo,
                page: page,
                search

            },
        })
            .then(({ data }) => {
                this.setState({
                    facturas: data.data.itemsList,
                    total: data.data.paginator.itemCount,
                    page: data.data.paginator.currentPage,

                    searching: true,
                    filtroSearch: search
                })
            })
            .catch(err => console.log(err))
    }

    /**
     *
     *
     * @memberof AccountPurchases
     * @method deleteItem
     * @description Elimina una factura
     */
    deleteItem = (id) => {

        axios.post('/facturas/delete', {
            id
        }).then(response => {
            this.getFacturas();
            this.props.updateOrden()
            message.success('Factura Eliminada')
        }).catch(err => {
            console.log(err)
            message.error('Error al eliminar la factura')
        })
    }


    /**
     *
     *
     * @return {*} 
     * @memberof EgresosList
     */
    render() {

        return (
            <>
                <Content>
                    <List
                        className="component-list"
                        dataSource={this.state.facturas}
                        pagination={{
                            current: this.state.page,
                            pageSize: this.state.limit,
                            total: this.state.total,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className: "flex-left",
                            onChange: this.getFacturas,
                        }}
                        header={<Row className="width-100">

                            <Col span={2} className="center">
                                <Text strong>Fecha</Text>
                            </Col>

                            <Col span={4} className="center">
                                <Text strong>Concepto</Text>
                            </Col>

                            <Col span={2} className="center">
                                <Text strong>Tipo</Text>
                            </Col>

                            <Col span={3} className="center">
                                <Text strong>Emisor</Text>
                            </Col>

                            <Col span={3} className="center">
                                <Text strong>Receptor</Text>
                            </Col>

                            <Col span={3} className="center">
                                <Text strong>Monto</Text>
                            </Col>

                            <Col span={3} className="center">
                                <Text strong>Monto Restante</Text>
                            </Col>

                            <Col span={4} className="center">
                                <Text strong>Acciones</Text>

                            </Col>
                        </Row>}
                        renderItem={item => (
                            <List.Item>
                                <Card className="card-list font-12">
                                    <Row className="width-100">
                                        <Col xs={2} className="center">
                                            <Text strong>{moment(item.fecha_vigencia).format('DD/MM/YYYY')}</Text>
                                        </Col>
                                        <Col xs={4} className="center">
                                            <Text className="text-gray-dark">{item.concepto}</Text>
                                        </Col>
                                        <Col xs={2} className="center">
                                            <Text className="text-gray-dark">{
                                                item.tipo === -1 ? "Egreso"
                                                    : item.tipo === 0 ? "Sin Cambios"
                                                        : "Ingreso"
                                            }</Text>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Text className="text-gray-dark">{item.emisor ? item.emisor.nombre : '-'}</Text>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Text className="text-gray-dark">{item.receptor ? item.receptor.nombre : '-'}</Text>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Text className="text-gray-dark">$ {item.monto.toMoney(true)} MXN</Text>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Text className="text-gray-dark">$ {(item.monto_restante) ? item.monto_restante.toMoney(true) : 0} MXN</Text>
                                        </Col>
                                        <Col xs={4} className="center">
                                            <Space>
                                                <Button
                                                    icon={<EyeFilled style={{ color: "white"}}/>}
                                                    type="primary"
                                                    size="small"
                                                    title="Ver Detalles"
                                                    onClick={() => this.setState({
                                                        modalFacturaDetalleVisible: true,
                                                        factura_id: item._id
                                                    })}
                                                    style={{
                                                        backgroundColor: "#ADFF45",
                                                        borderColor: "#ADFF45",
                                                        color: "white"
                                                    }}
                                                >
                                                </Button>
                                                <Button
                                                    icon={<EditFilled style={{ color: "white"}} />}
                                                    type="primary"
                                                    title="Editar"
                                                    size="small"
                                                    onClick={() => {
                                                        this.setState({
                                                            modalFacturaVisible: true,
                                                            factura_id: item._id
                                                        })
                                                    }}
                                                    style={{
                                                        backgroundColor: "#456EFF",
                                                        borderColor: "#456EFF"
                                                    }}
                                                >
                                                </Button>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={<>
                                                        ¿Deseas eliminar este registro?
                                                        <br></br> ELIMINARLO NO LO CANCELARÁ DEL SAT
                                                    </>}


                                                    okText="Si"
                                                    cancelText="No"
                                                    onConfirm={() => this.deleteItem(item._id)}
                                                >
                                                    <Button type="primary" size="small" danger icon={<DeleteFilled style={{ color: "white"}} />} title="Eliminar" />
                                                </Popconfirm>
                                                <Button
                                                    type="primary"
                                                    size="small"
                                                    icon={<FilePdfOutlined style={{ color: "currentColor" }} />}
                                                    onClick={() => window.open(`${axios.defaults.baseURL}/facturas/cfdi?factura_id=${item._id}&Authorization=${sessionStorage.getItem("token")}`, '_blank')}
                                                    title="Ver PDF"
                                                    disabled={!(item.tipo_facturacion === 1)}

                                                ></Button>

                                                {(typeof item?.factura_xml === "string") ? <Button
                                                    size="small"
                                                    icon={<GoFileCode style={{ color: "currentColor", fontSize: 25, position: "relative", }} />}
                                                    type="primary"
                                                    download={true}
                                                    href={`${axios.defaults.baseURL}/facturas/cfdi/xml?factura_id=${item._id}&Authorization=${sessionStorage.getItem("token")}&dummy=dummy`} /> : null}


                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                </Content>
                <ModalFacturas
                    visible={this.state.modalFacturaVisible}
                    factura_id={this.state.factura_id}
                    onClose={() => {
                        this.setState({ modalFacturaVisible: false, factura_id: undefined });
                        this.getFacturas();
                    }}
                    orden={this.props.orden}
                />
                <ModalFacturasDetalle
                    visible={this.state.modalFacturaDetalleVisible}
                    factura_id={this.state.factura_id}
                    onClose={() => {
                        this.setState({ modalFacturaDetalleVisible: false, factura_id: undefined });
                        this.getFacturas();
                    }}
                    orden={this.props.orden}
                />

                <DrawerTimbradoOrden
                    visible={this.state.visibleDrawerTimbradoOrden}
                    orden_id={this.props.orden_id}
                    onClose={() => {
                        this.setState({ visibleDrawerTimbradoOrden: false })
                    }}
                    onSuccess={() => {
                        this.setState({ visibleDrawerTimbradoOrden: false })
                        this.getFacturas()
                    }}
                />
                {/*  */}
                <FloatingButton
                    placement="topRight"
                    arrow
                    onClick={() => this.setState({ visibleDrawerTimbradoOrden: true })} 
                    title="Opciones de Facturas" />


            </>
        )
    }
}