import React, { Component } from "react";

import {
    Layout, Row, Col, PageHeader, Space, Button, List,
    Tag, Pagination, Typography, Card, message, Spin,
    Tooltip,
    Statistic,
    Popconfirm,
    IconDelete, 

} from "antd";


import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'


//Componentes

// import { WarningOutlined, EditFilled, FilePdfFilled, MailOutlined } from "@ant-design/icons";
// import { IconAceptarOrden, } from '../../Widgets/Iconos';

// import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
// //Modales
import ModalTransacciones from '../../Finanzas/Transacciones/ModalTransacciones'
import ModalTransaccionesDetalle from '../../Finanzas/Transacciones/ModalTransaccionesDetalle'
import ModalTransaccionMultiple from '../../Finanzas/Transacciones/ModalTransaccionMultiple'
// import { User } from '../../../Hooks/Logged';
// import { SocketContext } from '../../../Hooks/Socket';
// import ModalNotificar from "./ModalNotificar";

const { Content } = Layout;
const { Text } = Typography;

const axios = require('axios')
const moment = require('moment')

/**
 *
 *
 * @export
 * @class Transacciones
 * @extends {Component}
 * @description Vista del listado de Transacciones
 */
class Transacciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            transacciones: {

                data: [],

                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

                //filtros
                cliente_id: this.props.cliente_id
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getTransacciones()
    }

    /**
    *
    *
    * @memberof Transacciones
    * @description Obtiene todas todas las transacciones dle cliente
    * @param transacciones Objeto de paginación
    */
    getTransacciones = (
        {
            page = this.state.transacciones.page,
            limit = this.state.transacciones.limit,
        } = this.state.transacciones,
        { transacciones } = this.state

    ) => {

        transacciones.page = page;
        transacciones.limit = limit;

        this.setState({ transacciones })
        console.log('this.props.cliente_id', this.props.cliente_id)
        axios.post('/transacciones/list', {
            page,
            limit,
            cliente_id: this.props.cliente_id
        })
            .then(({ data }) => {

                console.log('data', data)
                this.setState(state => {
                    state.transacciones.total = data.data.itemCount
                    state.transacciones.data = data.data.itemsList
                    state.transacciones.pages = data.data.pageCount
                    state.transacciones.loading = false
                    return state;
                })
            })
            .catch(err => {
                message.error('No se pudieron cargar las ordenes de compra.')
            })
            .finally(() => this.setState({ loading: false }))

    }

    renderMonto = (tipo, monto) => {
        let colors = ['#00FF19', '#FF0000', '#000000', '#000000'];
        return <Statistic value={monto.toMoney()} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    render() {
        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list "
                    size="large"
                    itemLayout="horizontal"
                    dataSource={this.state.transacciones.data}
                    header={<Row className="header-list width-100 pl-1 pr-1 ">
                        <Col xs={24} md={2} lg={2} xl={2} xxl={2} className=" center">
                            <Text strong>fecha</Text>
                        </Col>
                        <Col xs={24} md={3} lg={4} xl={4} xxl={4} className="center">
                            <Text strong>concepto</Text>
                        </Col>
                        <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                            <Text strong>área</Text>
                        </Col>
                        <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                            <Text strong>rubro</Text>
                        </Col>
                        <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                            <Text strong>folio</Text>
                        </Col>
                        <Col xs={24} md={3} lg={3} xl={3} xxl={3} className=" center">
                            <Text strong>cuenta</Text>
                        </Col>
                        <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                            <Text strong>monto</Text>
                        </Col>
                    </Row>
                    }
                    renderItem={item => (
                        <List.Item className="component-list-item ">
                            <Card className="card-list">
                                <div className={`badge-card tipo-${item.tipo}`}>
                                </div>
                                <Row className="width-100">
                                    <Col xs={24} md={2} lg={2} xl={2} xxl={2} className="center">
                                        <Text ellipsis strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                    </Col>
                                    <Col xs={24} md={4} lg={4} xl={4} xxl={4} className="center">
                                        <Text ellipsis className="text-gray-dark">{item.concepto}</Text>
                                    </Col>
                                    <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                        <Text ellipsis className="text-gray-dark">{item.area_id?.nombre ? item.area_id?.nombre : '-'}</Text>
                                    </Col>
                                    <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                        <Text ellipsis className="text-gray-dark">{item.rubro_id?.nombre ? item.rubro_id?.nombre : '-'}</Text>
                                    </Col>
                                    <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                        <Text ellipsis className="text-gray-dark">{item.orden_id?.folio ? item.orden_id?.folio : '-'}</Text>
                                    </Col>
                                    <Col xs={24} md={3} lg={1} xl={1} xxl={1} className="center">
                                        {/* <CustomAvatar name={[item.cliente_id?.nombre, item.cliente_id?.apellido]} /> */}
                                    </Col>
                                    <Col xs={24} md={3} lg={3} xl={3} xxl={3} className=" center">
                                        <Text ellipsis className="text-gray-dark">{item.cuenta_id?.nombre}</Text>
                                    </Col>
                                    <Col xs={24} md={3} lg={3} xl={3} xxl={3} className="center">
                                        <Text ellipsis>{this.renderMonto(item.tipo, item.monto)} </Text>
                                    </Col>
                                    <Col xs={24} md={6} lg={2} xl={2} xxl={2} className="center">
                                        <Space direction="horizontal">
                                            <Button
                                                icon={<EyeOutlined />}
                                                type="primary"
                                                title="Ver Detalle"
                                                onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}>
                                            </Button>
                                            <Button
                                                icon={<EditOutlined />}
                                                type="primary"
                                                title="Editar"
                                                onClick={() => this.setState({ modalTransacVisible: true, transaccion_id: item._id })}>
                                            </Button>
                                            <Popconfirm
                                                placement="topRight"
                                                title="¿Deseas eliminar esta Transacción?"
                                                onConfirm={() => axios.post('/transacciones/delete', { id: item._id }).then((response) => {
                                                    message.success('Transaccion Eliminada')
                                                    this.getTransacciones()
                                                }).catch((error) => {
                                                    message.error('Transacción NO Eliminada')
                                                    this.getTransacciones();
                                                })
                                                }
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button
                                                    type="primary"
                                                    danger
                                                    icon={<DeleteOutlined />} title="Eliminar" />
                                            </Popconfirm>
                                        </Space>

                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}

                />
                <ModalTransacciones
                    visible={this.state.modalTransacVisible}
                    // visible={true}
                    transaccion_id={this.state.transaccion_id}
                    onClose={() => {
                        this.setState({ modalTransacVisible: false, transaccion_id: undefined })
                        this.getTransacciones(this.state.currentPage)
                    }}
                />
                <ModalTransaccionesDetalle
                    visible={this.state.modalTransacDetalleVisible}
                    onClose={() => this.setState({
                        modalTransacDetalleVisible: false,
                        transaccion_id: undefined
                    })}
                    id={this.state.transaccion_id}
                />
                {/* <DrawerFiltrosTransacciones
                    ref={ref => this.drawerFiltros = ref}
                    updateFilters={filtros => this.getTransacciones(1, filtros)}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                /> */}

                <ModalTransaccionMultiple
                    visible={this.state.modalVisibleCSV}
                    onClose={(flag) => {
                        this.setState({ modalVisibleCSV: false })
                        this.getTransacciones(this.state.currentPage)
                    }}
                />

            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {


    return (<Transacciones {...props} />)
}