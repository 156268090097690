import React, { Component } from 'react';
import { Button, Popconfirm, Space, Card, Row, List, Col, Spin, PageHeader, Layout, message, Typography } from 'antd'
import axios from 'axios';

//Componentes
import { IconDelete, IconEdit,IconArrowBack } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalInventarioProducto from "./ModalInventarioProducto";
import ModalInventarioProductoMultiple from './ModalInventarioProductoMultiple'

const { Content } = Layout;
const { Text } = Typography

/**
 * @export
 * @class Productos
 * @extends {Component}
 * @description Vista de Productos
 */
export default class AlmacenProductos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            ids: [],

            productos: {
                data: [],
                page: 1,
                limit: 10,

                total: 0,
                pages: 0
            }
        }
    }

    componentDidMount() {
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProductos();
    }


    /**
     *
     *
     * @memberof Transacciones
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {
        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getProductos();
            this.state.searching = false;
        }
    }


    getProductos = ({
        page = 1,
        limit = this.state.clientes.limit,

        search = this.props.search,

    } = this.state.productos) => {

        const { almacen_id, inventario_id } = this.props.match.params
        this.setState(state => {
            state.productos.page = page;
            state.productos.limit = limit;
            return state
        })
        axios.get('/inventarios/productos', {
            params: {
                page,
                limit,
                search,
                almacen_id,
                inventario_id
            }
        })
            .then(({ data }) => {
                this.setState(state => {
                    state.productos.data = data.data.data
                    state.productos.total = data.data.total
                    state.productos.pages = data.data.pages
                    state.ids = data.data.data.map(item => item.producto_id._id)
                    return state;
                })
            })
            .catch(error => {
                // console.log(error)
                message.error('Error al traer la información')
            })
    }


    showModal = (producto_id = undefined) => this.setState({ modalAlmacenProducto: true, producto_id })

    back = () => {
        this.props.history.goBack();
     }
    render() {

        const { showModal } = this
        const { productos } = this.state

        const contentMenuPlus = (
            <div className="flex-column">
                <Button type="primary" className="width-100 mb-05" onClick={() => showModal()}>
                    Añadir Producto
                </Button>
                <Button type="primary" className="width-100" onClick={() => this.setState({ modalVisibleCSV: true })}>
                    Añadir Múltiples
                </Button>
            </div>
        );

        return (
            <Spin spinning={false}>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Productos"
                    backIcon={<IconArrowBack/>}
                    onBack={this.back}
                />
                <Content className="admin-content content-bg pd-1">
                    <List
                        className="component-list scroll"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Productos en el Inventario" }}
                        dataSource={productos.data}
                        pagination={{
                            onChange: (page, limit) => this.getProductos({ page, limit }),
                            current: productos.page,
                            pageSize: productos.limit,
                            total: productos.total,

                            hideOnSinglePage: false,
                            position: 'bottom',
                            className: "flex-left"
                        }}
                        header={<Row className="header-list width-100 pr-1 pl-1" >
                            <Col span={4} className="center">
                                <Text>Número de Parte del Fabricante</Text>
                            </Col>
                            <Col span={4} className="center">
                                <Text>Número de Parte del Cliente</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text>Cantidad</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text>Inventario Mínimo</Text>
                            </Col>
                            <Col span={3} className="center">
                                <Text>Inventario Maximo</Text>
                            </Col>
                            <Col span={4} className="center">
                                <Text>Precio del Producto</Text>
                            </Col>
                        </Row>
                        }

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">
                                        <Col span={4} className="center">
                                            <Text>{item.producto_id?.np_fabricante}</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <Text>{item.np_cliente}</Text>
                                        </Col>
                                        <Col span={3} className="center">
                                            <Text>{item.cantidad}</Text>
                                        </Col>
                                        <Col span={3} className="center">
                                            <Text>{item.inv_minimo}</Text>
                                        </Col>
                                        <Col span={3} className="center">
                                            <Text>{item.inv_maximo}</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <Text>$ {item.precio.toMoney(true)} MXN</Text>
                                        </Col>
                                        <Col span={3} className="center">
                                            <Space>
                                                <Button type="primary" icon={<IconEdit />} onClick={() => showModal(item._id)} />
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar este negocio?"
                                                    onConfirm={() => axios.delete('/producto/delete', { params: { id: item._id } })
                                                        .then((response) => {
                                                            message.success("Eliminado Correctamente")
                                                            this.getProductos()
                                                        })
                                                        .catch((error) => {
                                                            message.success("No es posible eliminar")
                                                            this.getProductos();
                                                        })
                                                    }
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button type="normal" danger icon={<IconDelete />} title="Eliminar" />
                                                </Popconfirm>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    <FloatingButton content={contentMenuPlus}  />
                </Content>
                <ModalInventarioProducto
                    id={this.state.producto_id}
                    producto_ids = {this.state.ids}
                    inventario_id={this.props.match.params.inventario_id}
                    visible={this.state.modalAlmacenProducto}
                    onCancel={() => {
                        this.setState({ modalAlmacenProducto: false })
                        this.getProductos()
                    }}
                />
                <ModalInventarioProductoMultiple 
                    visible={this.state.modalVisibleCSV}
                    onClose={(flag)=>{
                        this.setState({modalVisibleCSV: false})
                        this.getProductos()
                    }}
                    inventario_id={this.props.match.params.inventario_id}
                />
            </Spin>
        )
    }
}