import React, { Component } from 'react';
import { Button, Col, Row, message, Spin, Typography, Modal } from 'antd'
import axios from 'axios'

import { CardCustomerRowCotizacion } from "../../Widgets/Cards";

import { SocketContext } from '../../../Hooks/Socket';
import { User } from '../../../Hooks/Logged';

//Modales
import ModalRecotizar from './Acounting/ModalRecotizar'
import ModalPago from './Acounting/ModalPago'


const { Text, Title } = Typography;


/**
 * @export
 * @class Cotizaciones
 * @extends {Component}
 */
class Cotizaciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            orden_id: this.props.match.params.orden_id,
            data: [],
            loading: false,
            cliente: {},
            orden: {},
            modalRechazoVisible: false
        }
    }

    /**
     * @memberof Cotizaciones
     * @method componentDidMount 
     * @description Cargamos la informacion inicial
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrden(0)
    }

    /**
     * @memberof Cotizaciones
     * @method componentDidUpdate 
     * @description Cargamos la informacion inicial
     */
    componentDidUpdate(prevProps) {
        if(this.props.match.params.orden_id && this.props.match.params.orden_id !== this.state.orden_id){
            this.setState({orden_id: this.props.match.params.orden_id},()=>{this.getOrden(99)})
        }
    }

    /**
     * @memberof Cotizaciones
     * @method getOrden 
     * @description Obtenemos la informacion de la orden 
     */
    getOrden = (g) => {
        console.log("getOrden",g);
        this.setState({ loading: true })
        axios.get('/customer/orden/get', {
            params: {
                orden_id: this.state.orden_id
            }
        }).then(response => {
            console.log("response", response);
            this.setState({
                cliente: response.data.data?.cliente_id,
                orden: response.data.data
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof Cotizaciones
     * @method updateCotizacion 
     * @description Actualizamos la cotizacion, solamente el campo de status
     */
    updateCotizacion = ({
        status = undefined,
        facturado = undefined,
        comprar = undefined,
        ordenes_compra = undefined
    } = {}) => {
        
        return axios.put('/cotizacion/update', {
            id: this.state.orden?.cotizacion_id?._id,
            status,
            facturado,
            comprar,
            ordenes_compra
        })
            .then(response => {
                
                if(this.props.socket?.connected){
                    console.log('Socket corriendo')
                    let notificacion = {
                        evento: `El cliente ha rechazado la cotización`,
                        detalle: `El cliente ha rechazado la cotización de la orden ${this.state.orden?.folio}`,
                        usuarios_destino: [],
                        cliente: true,
                        usuario_id: this.props.user._id
                    }
                    this.props.socket.emit('nueva_notificacion', notificacion)
                }

                message.success('Cotización Actualizada');
                this.getOrden(1);

            })
            .catch(error => {
                let msg = 'Error al Actualizar la Cotización'
                console.log(error)
                if (error.response?.status === 403)
                    msg = error.response?.data?.message
                message.error(msg)
            })
    }

    /**
     *
     *
     * @memberof Cotizaciones
     * 
     * @method getPDF
     * @description Generamos el PDF de la cotizacion
     */
    getPDF = () => {
        let urlPDF = new URL(axios.defaults.baseURL + '/project-manager/matriz/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('orden_id', this.state.orden._id)
        return urlPDF.href
    }


    back = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Row>
                        <Col span={24}>
                            <Title level={3} className="mt-1">Cotizaciones</Title>
                        </Col>
                    </Row>
                    {this.state.orden?.orden_detalles ? <Row>
                        <Col span={24}>
                            <CardCustomerRowCotizacion
                                orden={this.state.orden}
                                getPDF={() => this.getPDF()}
                                showPago={() => this.setState({ modalPago: true })}
                                openModal={() => this.setState({ modalVisible: true })}
                                openModalRechazo={() => this.setState({ modalRechazoVisible: true })}
                                
                            />
                        </Col>
                    </Row> : <Row>
                        <Col span={24} className="center">
                            <Text className="text-gray"> Sin Cotizaciones </Text>
                        </Col>
                    </Row>}
                </Spin>
                <Modal
                    visible={this.state.modalRechazoVisible}
                    onOk={() => {
                        this.setState({
                            modalRechazoVisible: false
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            modalRechazoVisible: false
                        })
                    }}
                    title={null}
                    footer={null}
                    closable={true}
                    destroyOnClose={true}
                    zIndex={1000}
                >

                    <Row style={{ textAlign: "center" }} >
                        <Col xs={22} >
                            <Title level={3}>  ¿Deseas rechazar la cotización? </Title>
                        </Col>
                    </Row>

                    <Row className="mt-1 " justify='center'>
                        <Col xs={22} >
                            <Text>
                                Al rechazar, la cotización quedará cancelada. <br />
                                Puede recotizar agregando las observaciones o mejoras para tomarlas en cuenta.
                            </Text>
                        </Col>
                        <Col span={22} className="mt-1 center">
                            <Button htmlType="submit" type="primary" className="mr-05" danger onClick={() => {
                                this.updateCotizacion({ status: 4 });
                                this.setState({ modalRechazoVisible: false });
                                this.getOrden()
                            }}>
                                Rechazar
                            </Button>
                            <Button htmlType="submit" style={{ backgroundColor: "#e4e837" }} className="mr-05" onClick={() => {
                                this.setState({ modalRechazoVisible: false, modalVisible: true });
                            }}>
                                Re - Cotizar
                            </Button>
                            <Button htmlType="submit" type="ghost" onClick={() => {
                                this.setState({ modalRechazoVisible: false });
                            }}>
                                Cancelar
                            </Button>
                        </Col>
                    </Row>
                </Modal>
                <ModalRecotizar
                    visible={this.state.modalVisible}
                    onClose={() => {
                        this.setState({ modalVisible: false })
                        this.getOrden(2)
                    }}
                    cotizacion_id={this.state.orden?.cotizacion_id?._id}
                    folio={this.state.orden?.folio}
                />
                <ModalPago
                    visible={this.state.modalPago}
                    onClose={() => {
                        this.setState({ modalPago: false })
                        this.getOrden(3)
                    }}
                    orden_id={this.state.orden_id}
                    total={this.state.orden?.total}
                    subtotal={this.state.orden?.subtotal}
                    impuestos={this.state.orden?.impuestos}
                    updateCotizacion={this.updateCotizacion}
                />
            </>

        )
    }
}

export default function (props){
    const user = React.useContext(User)
    const socket = React.useContext(SocketContext)

    return(<Cotizaciones {...props} user={user} socket={socket}/>)
}