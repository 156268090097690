import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Typography, Upload, Switch, Select} from 'antd';
import PropTypes from "prop-types";
import { UploadOutlined } from '@ant-design/icons';
const axios = require('axios').default;
const { Title, } = Typography;
const { Option } = Select




/**
 *
 *
 * @export
 * @class FormCatalogo
 * @extends {Component}
 */
class FormCatalogo extends Component {


    /**
     *
     *
     * @static
     * @memberof FormCatalogo
     * 
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            clientes: [],
            usuarios: [],
            image: {},

            proveedor: null,

            inventario_producto_edit: undefined,
            productos: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
            },

            categorias: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
                filters: {},
                loading: false
            },




        }
    }

    formCatalogo = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.catalogo_id) {
            this.getCatalogo()
        }
        this.getCategorias()
        this.getProveedor()
    }



    /**
     *
     *
     * @memberof FormCatalogo
     * 
     * @method getCatalogo
     * @description Obtenemos la informacion del catalogo
     */
    getCatalogo = () => {
        this.setState({ loading: true })
        axios.get('/catalogos/get', {
            params: {
                id: this.props.catalogo_id,
            }
        }).then(response => {

            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + response.data.data.archivo,
                    name: response.data.data.archivo
                }
            })

            this.formCatalogo.current.setFieldsValue({
                nombre: response.data.data.nombre,
                descripcion: response.data.data.descripcion,
                categoria_id: response.data.data.categoria_id,
                activo: response.data.data.activo,
                url: response.data.data.url,
                archivo: response.data.data.archivo ? [{
                    uid: 1,
                    name: response.data.data.archivo,
                    status: 'done',
                    url: `${axios.defaults.baseURL}/voucher/${response.data.data.archivo}`
                }] : null
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar la información')
        }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @function FormCatalogo
    * @description Metodo que se ajecuta al dar enter al formulario
    * */
    onFinish = async (values) => {

        if (!this.state.image?.name) {
            message.error('Es necesario subir un archivo (PDF)')
            return;
        }

        if (this.props.catalogo_id) {
            this.updateCatalogo(values)
        } else {
            this.addCatalogo(values)
        }
    }




    getProveedor = () => {
        axios.get('/proveedores/get', {
            params: {
                id: this.props.proveedor_id
            }
        })
            .then(({ data }) => {
                this.setState({ proveedor: data.data })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @param {*} [{
     *         page = this.state.proveedores.page, Pagina actual del proveedor
     *         limit = this.state.proveedores.limit, Limite de proveedores
     *         search = this.state.proveedores.search Busqueda
     *     }=this.state.proveedores]
     * @memberof FormProveedor
     * 
     * @description Obtenemos los proveedores
     */
     getCategorias = ({
        page = this.state.categorias.page,
        limit = this.state.categorias.limit,
        search = this.state.categorias.search
    } = this.state.categorias) => {
        this.setState(state => {
            state.categorias.loading = true
            state.categorias.page = page;
            state.categorias.limit = limit;
            return state;
        })
        axios.get('/categorias/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {
                console.log("Response categorias",response)
                let { data } = this.state.categorias

                let categorias = data

                if(page === 1){
                    categorias = response.data.data.itemsList
                }else{
                    categorias = [...categorias ,...response.data.data.itemsList]
                }
                this.setState(state => {
                    state.categorias.data = categorias
                    state.categorias.total = response.data.data.paginator.itemCount
                    state.categorias.pages = response.data.data.paginator.pageCount
                    state.categorias.loading = false
                    return state;
                })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
    }



    /**
     *
     * @memberof FormCatalogo
     *
     * @method addCatalogo
     * @description Añade un catalogo a la BD
     *
     */
    addCatalogo = (values) => {
        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            axios.post('/catalogos/add', {
                ...values,
                proveedor_id: this.props.proveedor_id,
                archivo: this.state.image.name,
            })
                .then(() => {
                    message.success("¡Se ha guardado correctamente!")
                    this.props.onCancel();
                })
                .catch((e) => {
                    message.error('No se pudo guardar el Catalogo')
                    console.log('e', e)
                })
                .finally(() => this.setState({
                    loading: false,
                }))
        })
        
    }

    /**
     *
     *
     * @memberof FormCatalogo
     * 
     * @method updateCatalogo
     * @description Actualizamos el catalogo.
     */
    updateCatalogo = (values) => {
        this.setState({ loading: true })
        axios.post('/catalogos/update', {
            ...values,
            id: this.props.catalogo_id,
            archivo: this.state.image.name,
        })
            .then(() => {
                message.success("¡Se ha actualizado correctamente!")
                this.props.onCancel();
            })
            .catch((e) => {
                console.log(e)
                message.error('No se actualizo el Catalogo')
            })
            .finally(() => this.setState({
                loading: false,
            }))
    }

    /**
  *
  * @memberof FormCatalogo
  *
  * @method normFile
  * @description Metodo que se ejecuta al guardar las imagenes
  *
  */
    normFile = (e) => {
        const { file } = e;

        if (file.status === "uploading")
            this.setState({ loadingImage: true })


        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingImage: false
            })

            if (this.props.cuenta_id !== undefined)
                axios.post('/cuentas/update', {
                    logo: e.file.response.filename,
                    id: this.props.cuenta_id
                })


        }

        if (file.status === "removed") {
            this.setState({ image: null })
        }

        console.log(file)

        return e && e.fileList;
    };


    render() {
        const { formCatalogo } = this;
        const { form, loading, } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.catalogo_id ? "Editar Catálogo" : "Nuevo Catálogo"}</Title>
                <Form
                    layout="vertical"
                    ref={formCatalogo}
                    initialValues={form}
                    onFinish={this.onFinish}

                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[
                                    { required: true, message: "Ingrese el Nombre" }
                                ]}
                            >
                                <Input placeholder="Catálogo" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input placeholder="Descripción" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Activo"
                                name="activo"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={24} className="colDocsComprobantes">
                            <Form.Item
                                label="Catálogo"
                                name="archivo"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                                <Upload
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/voucher/add"}
                                    multiple={true}
                                >
                                    <Button>
                                        <UploadOutlined /> Subir Archivo
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Categoria"
                                name="categoria_id"
                                rules={[
                                    { required: true, message: "Ingrese la Categoria" }
                                ]} >
                                <Select
                                    showSearch
                                    placeholder="Buscar"
                                    optionFilterProp="children"
                                    loading={this.state.categorias.loading}
                                    onSearch={search => this.getCategorias({ page: 1, search })}
                                    onPopupScroll={this.onPopupScrollProveedores}
                                >
                                    {this.state.categorias.data.map(({ _id, nombre}) => <Option key={_id} value={_id} title={nombre}>
                                        {nombre}
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormCatalogo {...props} />
        </Modal>
    )
}
