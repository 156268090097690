import React, { Component } from 'react';
import { Button, Popconfirm, Spin, Table, PageHeader, Layout, message, Space } from 'antd'

import axios from 'axios';
import { Link } from "react-router-dom";
import { VscOpenPreview } from "react-icons/vsc";

//Componentes
import { IconDelete, IconEdit } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalProveedores from './ModalProveedores'
import ModalMultiple from './ModalMultiple'

const { Content } = Layout;

/**
 * @name nestedTable
 * @param {*} comentarios Array contiene todos los registros de comentarios de cada proveedor
 * @description Retorna una Tabla de datos que c
 */
const nestedTable = (comentarios) => (
    <Table
        dataSource={comentarios}
        columns={[
            {
                title: "Comentario",
                dataIndex: 'comentario',
                render: (text, record) => (
                    <span>{record.entrada}</span>
                )
            }
        ]}
        locale={{ emptyText: 'Sin comentarios disponibles' }}
        pagination={false}
    />
)

/**
 * @export
 * @class Proveedores
 * @extends {Component}
 * @description Vista de Proveedores
 */
export default class Proveedores extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            proveedor: undefined,
            proveedores: [],

            page: 1,
            limit: 10,
            total: 0,

            filtroSearch: '',
            searching: true,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProveedores(1);
    }

    /**
     *
     *
     * @memberof Proveedores
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate(prevProps) {


        if (this.props.search !== prevProps.search) {
            this.setState({ search: this.props.search }, () => { this.getProveedores() })
        }
    }

    getProveedores = (page = this.state.page) => {
        let search = this.props.search;
        let items = this.state.proveedores;
        axios.get('/proveedores/list', {
            params: {
                page: page,
                limit: this.state.limit,
                search: search
            }
        }).then(response => {
            console.log('response', response)
            let data = response.data.data.itemsList;
            this.setState({
                proveedores: (page == 1) ? data : [...items, ...data],
                total: response.data.data.paginator.itemCount,
                page: response.data.data.paginator.currentPage,
                searching: false,
                filtroSearch: search
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }


    render() {
        const contentMenuPlus = (
            <div className="flex-column">
                <Button type="primary" className="width-100 mb-05" onClick={() => this.setState({ modalVisible: true })}>
                    Añadir Proveedor
                </Button>
                <Button type="primary" className="width-100" onClick={() => this.setState({ modalVisibleCSV: true })}>
                    Añadir Múltiples
                </Button>
            </div>
        );

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Proveedores"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <Table
                            scroll={{ x: 2000, y: 1000 }}
                            columns={[
                                {
                                    title: "Nombre",
                                    dataIndex: 'nombre',
                                    width: 150,
                                    render: (text, record) => (
                                        <h3>{record.nombre}</h3>
                                    )
                                },
                                {
                                    title: 'Nombre Comercial',
                                    dataIndex: 'nombre_comercial',
                                    render: (text, record) => (
                                        <span>{record.nombre_comercial}</span>
                                    )
                                },
                                {
                                    title: 'RFC',
                                    dataIndex: 'rfc',
                                    key: 'rfc',
                                    width: 150,
                                    render: (text, record) => (
                                        <span>{record.rfc}</span>
                                    )
                                },
                                {
                                    title: 'Email',
                                    dataIndex: 'email',
                                    key: 'email',
                                    render: (text, record) => (
                                        <span>{record.email}</span>
                                    )
                                },
                                {
                                    title: 'Teléfono',
                                    dataIndex: 'telefono',
                                    key: 'telefono',
                                    width: 200,
                                    render: (text, record) => (
                                        <span>{record.telefono}</span>
                                    )
                                },
                                {
                                    title: 'Pais',
                                    dataIndex: 'pais',
                                    key: 'pais',
                                    width: 120,
                                    render: (text, record) => (
                                        <span>{record.pais}</span>
                                    )
                                },
                                {
                                    title: 'Dirección',
                                    dataIndex: 'direccion',
                                    key: 'direccion',
                                    render: (text, record) => (
                                        <span>{record.direccion}</span>
                                    )
                                },
                                {
                                    title: 'Persona',
                                    dataIndex: 'persona',
                                    key: 'persona',
                                    width: 250,
                                    render: (text, record) => (
                                        <span>{record.persona}</span>
                                    )
                                },
                                {
                                    title: 'Última Compra',
                                    dataIndex: 'ultima_compra',
                                    key: 'ultima_compra',
                                    width: 120,
                                    render: (text, record) => {

                                        let d = new Date();
                                        const ms = d.getTime(record.ultima_compra);
                                        d = new Date(ms);

                                        return <span>{`${d.getDate()}/${d.getMonth()}/${d.getFullYear()}`}</span>
                                    }
                                },
                                {
                                    title: 'Acciones',
                                    key: 'actions',
                                    fixed: 'right',
                                    width: 180,
                                    render: (text, record) => (
                                        <Space>
                                            <Link to={`/admin/proveedores/${record._id}/catalogos`}>
                                                <Button
                                                    type="primary"
                                                    icon={< VscOpenPreview style={{ fontSize: 20, position: 'relative', top: 3 }} />}
                                                    title="Catálogos"
                                                />
                                            </Link>
                                            <Button
                                                type="primary"
                                                icon={<IconEdit />}
                                                title="Editar"
                                                onClick={() => { this.setState({ modalVisible: true, proveedor_id: record._id }) }}
                                            />
                                            <Popconfirm
                                                placement="topRight"
                                                title="¿Deseas eliminar este proveedor?"
                                                onConfirm={
                                                    () => axios.post('/proveedores/delete',
                                                        { id: record._id }).then(() => {
                                                            this.getProveedores()
                                                            message.success('Proveedor eliminado')
                                                        }).catch(error => console.log(error))}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button
                                                    type="primary"
                                                    icon={<IconDelete />}
                                                    title="Eliminar"
                                                    danger
                                                />
                                            </Popconfirm>
                                        </Space>
                                    ),
                                },
                            ]}
                            expandable={{
                                expandedRowRender: record => nestedTable(record.comentarios)
                            }}
                            dataSource={this.state.proveedores}
                            pagination={{
                                onChange: this.getProveedores,
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                position: ['bottomLeft'],
                            }}
                            rowKey={record => record._id}
                            locale={{ emptyText: 'Sin proveedores disponibles' }}
                        />

                    </Content>

                    <FloatingButton title="Nuevo registro" content={contentMenuPlus} />


                    <ModalProveedores
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, proveedor_id: undefined })
                            this.getProveedores()
                        }}
                        proveedor_id={this.state.proveedor_id}
                    />

                    <ModalMultiple
                        visible={this.state.modalVisibleCSV}
                        onClose={() => {
                            this.setState({ modalVisibleCSV: false, proveedor_id: undefined })
                            this.getProveedores()
                        }}
                        proveedor_id={this.state.proveedor_id}
                    />
                </Spin>
            </>
        )
    }
}