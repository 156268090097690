import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Spin, Space, } from 'antd';


const { Title, Text } = Typography;
const axios = require('axios').default;
const moment = require('moment')

/**
 *
 *
 * @class ModalOrdenDetalle
 * @extends {Component}
 */
class ModalOrdenDetalle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            return: false,
            premium: false
        }
    }

    ModalOrdenDetalle = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
     *
     *
     * @param {*} tipo
     * @param {number} [valor=0]
     * @returns
     * @memberof ModalOrdenDetalle
     */
    renderEstatusLogistica = value => {
        console.log('v', value)

        switch (value) {
            case 0:
                return "Sin entregar"
                break;
            case 1:
                return "En entrega"
                break;
            case 2:
                return "En entrega"
                break;
            case 3:
                return "Entregado"
                break;
            default:
                break;
        }
    }

    /**
     *
     *
     * @param {*} tipo
     * @param {number} [valor=0]
     * @returns
     * @memberof ModalOrdenDetalle
     */
    renderEstatusPagado = value => {
        if (value) return "Pagado"
        return "Sin Pagar"

    }

    render() {

        const { orden } = this.props

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    onFinish={this.onFinish}
                >
                    <Row className="mt-1">
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Estatus"
                            >
                                <Space size={'small'}>
                                <Text className='item-estatus'>{this.renderEstatusLogistica(orden?.logistica_estatus)}</Text>
                                <Text className='item-estatus'>{this.renderEstatusPagado(orden?.pago_recibido)}</Text>
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Fecha de Creación"
                            >
                                <Text className="text-gray font-18">{moment(orden?.createdAt).format('DD-MM-YYYY')}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Producto"
                            >
                                <Text className="text-gray font-18">{orden?.producto_id?.np_fabricante}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Cantidad"
                            >
                                <Text className="text-gray font-18">{orden?.orden_detalles?.cantidad}</Text>
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} lg={11}>

                            <Form.Item
                                label="Referencia"
                            >
                                <Text className="text-gray font-18">{orden?.referencia || "Pendiente por generar"}</Text>
                            </Form.Item>
                        </Col>
                        {
                            orden?.orden_detalles?.cantidad_entregada > 0 ? (
                                <Col xs={24} lg={{ span: 11, push: 2 }}>
                                    <Form.Item
                                        label="Cantidad Entregada"
                                    >
                                        <Text className="text-gray font-18">{orden?.orden_detalles?.cantidad_entregada}</Text>
                                    </Form.Item>
                                </Col>
                            ): null
                        }

                    </Row>
                    
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Detalles de la Orden </Title>
        </div>
        <ModalOrdenDetalle {...props} />
    </Modal>

}