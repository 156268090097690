
import React from 'react'
import { Spin, Layout, Col, Row, PageHeader, Typography, Input, Button, Space, Modal, List, Empty, message } from "antd";
import { CardOperations, CardSaldosGraph, CardOperationsList } from '../Widgets/Cards';
import { ReloadOutlined, UnorderedListOutlined, MoneyCollectOutlined, ArrowDownOutlined, ArrowUpOutlined, FileExcelOutlined } from "@ant-design/icons";
import '../../Styles/Modules/Dashboard/dashboard.css';

import moment from 'moment';
const axios = require('axios')

const { Content } = Layout;
const { Title } = Typography;


export default class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            loading_cambio: false,
            tipo_cambio_actual: 0,
            historial: [],
            page: 1,
            modal_visible: false,


            finanzas_saldos: 0,
            finanzas_ganancia: 0,
            finanzas_ganancia_pasado: 0,
            finanzas_costos: 0,
            finanzas_gastos: 0,
            finanzas_gastos_pasado: 0,
            cuentas: [],
            rubros: [],


            fiscal_saldos: 0,
            fiscal_costos: 0,


            fiscal_ganancia: 0,
            fiscal_ganancia_pasado: 0,
            fiscal_gastos: 0,
            fiscal_gastos_pasado: 0,

            razones_sociales: [],
            proveedores: []

        }
    }



    /**
     *
     *
     * @memberof Dashboard
     * @description Carga los datos a la vista
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getHistorialCambio();
        this.getDataDashboard();

    }
    /**
     *
     *
     * @memberof Dashboard
     * @description Consulta el historial de los tipos de cambios
     */
    getHistorialCambio = (page) => {
        let items = this.state.historial;

        this.setState({ loading_cambio: true })
        axios.get('/tipo-cambio', {
            params: {
                page: page
            }
        })
            .then(res => {
                const data = res.data.data.itemsList;
                this.setState({
                    historial: (page == 1) ? data : [...items, ...data],
                    tipo_cambio_actual: data[0].valor.toFixed(2),
                    page: res.data.data.paginator.currentPage,
                    itemCount: res.data.data.paginator.itemCount,
                    pageCount: res.data.data.paginator.pageCount,

                })
            }
            )
            .catch(error => {
                console.log('error', error);
            })
            .finally(() => {
                this.setState({ loading_cambio: false })
            })
    }


    /**
     *
     *
     * @memberof Dashboard
     * @description Actualiza el tipo de cambio actual al hacer click al boton de refresh
     */
    updateTipoCambio = () => {
        let valor = this.state.tipo_cambio_actual;
        axios.post('/tipo-cambio/add', {
            valor: valor
        })
            .then(res => {
                message.success('Se ha actualizado el tipo de cambio');
                this.getHistorialCambio();
            })
            .catch(error => {
                message.error('Ha ocurrido un error al intentar actualizar el tipo de cambio');
                console.log('error al update tipo cambio', error);
            })


    }
    /**
     *
     *
     * @memberof Dashboard
     * @description Carga mas elementos al historial
     */
    onLoadMore = () => {
        let current = this.state.page;
        current = current + 1;
        this.getHistorialCambio(current);

    };


    /**
     *
     *
     * @memberof Dashboard
     * @description Abre o cierra el modal de historial
     */
    modalToogle = () => {
        let toogle = this.state.modal_visible;
        this.setState({ modal_visible: !toogle });
    }


    renderIcon = (index, valor) => {
        let next = index + 1;

        if (next < this.state.historial.length) {
            if (valor.toFixed(2) < this.state.historial[next].valor.toFixed(2)) {
                return <ArrowDownOutlined style={{ color: '#24CA49' }} />
            }
            else
                return <ArrowUpOutlined style={{ color: '#FD4438' }} />
        }
        return <MoneyCollectOutlined />

    }




    /**
    * @memberof Dashboard
    * 
    * @method getDataDashboard
    * @description Obtiene la información del dashboard
    */
    getDataDashboard = () => {

        axios.get('/dashboard', {})
            .then(response => {
                var data = response.data;

                if (data.success) {
                    data = data.data;
                    this.setState({
                        finanzas_saldos: data.finanzas_saldos,
                        finanzas_ganancia: data.finanzas_ganancia,
                        finanzas_ganancia_pasado: data.finanzas_ganancia_pasado,
                        finanzas_costos: data.finanzas_costos,
                        finanzas_gastos: data.finanzas_gastos,
                        finanzas_gastos_pasado: data.finanzas_gastos_pasado,
                        cuentas: data.cuentas,
                        rubros: data.rubros,


                        fiscal_saldos: data.fiscal_saldos,
                        fiscal_costos: data.fiscal_costos,
                        razones_sociales: data.razones_sociales,
                        proveedores: data.proveedores,

                        fiscal_ganancia: data.fiscal_ganancia,
                        fiscal_ganancia_pasado: data.fiscal_ganancia_pasado,
                        fiscal_gastos: data.fiscal_gastos,


                    })
                }
            }).catch(error => {
                console.log(error)
                message.error('Error al traer la información')
            })
    }


    getTransacciones =  (tipo) => {

        let url = new URL(axios.defaults.baseURL + '/dashboard/csv')
        url.searchParams.set('Authorization', sessionStorage.getItem('token'))
        url.searchParams.set("tipo", tipo)
        window.open(url, '_blank');

    }



    render() {
        var { finanzas_saldos, finanzas_ganancia, finanzas_ganancia_pasado, finanzas_costos, finanzas_gastos, finanzas_gastos_pasado, cuentas, rubros, fiscal_saldos,
            fiscal_ganancia, fiscal_ganancia_pasado, fiscal_gastos, fiscal_gastos_pasado, razones_sociales, proveedores, } = this.state;
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 16}} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                                    <span className="ant-page-header-heading-title">
                                        Dashboard
                                    </span>
                                </Col>
                                <Col xxl={{ span: 2 }} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                   
                                </Col>
                                <Col xxl={{ span: 6}} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                                    <Space direction="horizontal">
                                        <label className="label-tipo-cambio">Tipo de cambio </label>
                                        <div className="flex">
                                            <Input className="input-tipo-cambio" prefix={"$"} value={`${this.state.tipo_cambio_actual}`} onChange={(e) => this.setState({ tipo_cambio_actual: e.target.value })} />
                                            <Button type="primary" className="btn-tipo-cambio" loading={this.state.loading_cambio} icon={<ReloadOutlined style={{ color: '#2A5068' }} />} onClick={this.updateTipoCambio} />
                                        </div>

                                        <Button type="normal" className="btn-tipo-cambio-ouline " onClick={this.modalToogle} icon={<UnorderedListOutlined style={{ color: '#fff' }} />} />

                                    </Space>
                                </Col>
                            </Row>
                        }
                    />
                    <Content className=" content-bg pr-2 pl-2 pt-1 pb-1 ">
                        <Row gutter={[16, 8]}>
                            <Col span={24}>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}><Title className="subtitle-dashboard" level={2}>Finanzas</Title></Col>
                                    <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                                        <CardSaldosGraph
                                            total={finanzas_saldos}
                                            data={cuentas}
                                            tipo_cambio={this.state.tipo_cambio_actual}
                                        />
                                    </Col>
                                    <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                                        <CardOperations title="Ingresos"
                                            total={finanzas_ganancia}
                                            total_mp={finanzas_ganancia_pasado}
                                            tipo_cambio={this.state.tipo_cambio_actual}
                                            getTransacciones={()=>this.getTransacciones("Transacciones")}
                                        />
                                    </Col>
                                    <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                                        <CardOperationsList
                                            total={finanzas_gastos}
                                            total_mp={finanzas_gastos_pasado}
                                            dataTop={rubros}
                                            title="Egresos"
                                            ipo_cambio={this.state.tipo_cambio_actual}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}><Title className="subtitle-dashboard" level={2}>Fiscal</Title></Col >
                                    <Col span={8} lg={8} md={24} sm={24} xs={24}>
                                        <CardSaldosGraph
                                            total={fiscal_saldos}
                                            data={razones_sociales} />
                                    </Col>
                                    <Col span={8} lg={8} md={12} sm={24} xs={24}>
                                        <CardOperations title="Facturado este mes"
                                            total={fiscal_ganancia}
                                            total_mp={fiscal_ganancia_pasado}
                                            tipo="Facturas"
                                            getTransacciones={()=>this.getTransacciones("Facturas")}
                                        />
                                    </Col>
                                    <Col span={8} lg={8} md={12} sm={24} xs={24}>
                                        <CardOperationsList title="Facturación"
                                            total={fiscal_gastos}
                                            total_mp={fiscal_gastos_pasado}
                                            dataTop={proveedores}
                                            progress={true}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Spin>
                <Modal
                    onCancel={this.modalToogle}
                    visible={this.state.modal_visible}
                    className="modal-historial"
                    title={<div className="header-list no-border "><Title level={4} className="text-center">Historial de Tipo de Cambio</Title></div>}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    destroyOnClose={true} >
                    <List
                        size="small"
                        itemLayout="horizontal"
                        dataSource={this.state.historial}
                        locale={{
                            emptyText:
                                <Empty
                                    image={<MoneyCollectOutlined style={{ fontSize: 35 }} />}
                                    imageStyle={{ height: 60, padding: '5px' }}
                                    description={<>No se ha registrado ningun Valor </>}
                                />
                        }}
                        className="historial-tipo-cambio list-border-none"
                        pagination={{
                            current: this.state.page,
                            pageSize: 10,
                            total: this.state.total,
                            position: 'bottom',
                            className: "flex-left",
                            size: 'small'

                        }}
                        loading={this.state.loading_cambio}
                        renderItem={({ _id, valor, usuario_id, createdAt }, index) => <List.Item style={{ backgroundColor: '#fff' }}>
                            <Row className="width-100 ">
                                <Col span={1}>{this.renderIcon(index, valor)}</Col>
                                <Col span={4}>  $ {valor.toFixed(2)}</Col>
                                <Col span={11}> {usuario_id?.nombre} {usuario_id?.apellido}</Col>
                                <Col span={8}>{moment(createdAt).format('LLL')}</Col>
                            </Row>
                        </List.Item>}
                    />

                </Modal>
            </>
        )
    }
}