import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { Layout, Col, Row, message, PageHeader, Spin, Typography } from 'antd'
import axios from 'axios'

//componentes
import StepTracker from "./StepTracker"
import { CardCustomerProjectInfo, CardCustomerProgresoOrden, CardCustomerEntrega, CardTaskProgress } from "../../Widgets/Cards";
import Cotizaciones from './Cotizaciones'
import Acounting from './Acounting/Acounting'
import Produccion from './Logistica/Produccion'
import Transito from './Logistica/Transito'
import Entrega from './Logistica/Entrega'
import { IconArrowBack } from '../../Widgets/Iconos';

import { SocketContext } from '../../../Hooks/Socket';

const { Content } = Layout;


/**
 * @export
 * @class LayoutDashbord
 * @extends {Component}
 */
class LayoutDashbord extends Component {

    static contextType = SocketContext

    constructor(props) {
        super(props)
        this.state = {
            orden_id: this.props.match.params.orden_id,
            data: [],
            loading: false,
            cliente: {},
            orden: {
                rfq_detalle:[{
                    tiempo:0,
                    precio:0
                }]
            },
        }
    }
    stepTracker = React.createRef();

    /**
     * @memberof LayoutDashbord
     * @method componentDidMount 
     * @description Cargamos la informacion inicial
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getOrden()
    }

     /**
     * @memberof Cotizaciones
     * @method componentDidUpdate 
     * @description Cargamos la informacion inicial
     */
    componentDidUpdate(prevProps) {
        if(this.props.match.params.orden_id && this.props.match.params.orden_id !== this.state.orden_id){
            this.setState({orden_id: this.props.match.params.orden_id},()=>{this.getOrden()})
        }
    }

    /**
     * @memberof LayoutDashbord
     * @method getOrden 
     * @description Obtenemos la informacion de la orden 
     */
    getOrden = () => {
        this.setState({ loading: true })
        axios.get('/customer/orden/get', {
            params: {
                orden_id: this.props.match.params.orden_id
            }
        }).then(response => {
            console.log('Obteniendo datos')
            this.setState({
                cliente: response.data.data.cliente_id,
                orden: response.data.data,
                orden_id: response.data.data._id
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la orden')
        }).finally(() => this.setState({ loading: false }))
    }

    back = () => {
        this.props.history.goBack();
    }
    render() {
        const { cliente, orden } = this.state;
        console.log('orden layout',orden)
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                                    <span className="ant-page-header-heading-title" >
                                        Mis Pedidos
                                    </span>
                                </Col>
                            </Row>
                        }
                        onBack={this.back}
                        backIcon={<IconArrowBack/>}
                    />
                    <Content className="content-bg pd-1">
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={12} lg={7}>
                                <StepTracker orden_id={this.state.orden_id} ref={this.stepTracker} />
                            </Col>
                            <Col xs={24} md={12} lg={17}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} md={24} lg={12} xl={8}>
                                        <CardCustomerProjectInfo
                                            orden={orden}
                                            nombre={cliente.razon_social || cliente.nombre }  
                                            telefono={cliente.telefono}
                                            email={cliente.email}
                                            quote_day={orden.createdAt}
                                        />
                                    </Col>
                                    <Col xs={24} md={24} lg={12} xl={8}>
                                        <CardTaskProgress
                                        title="Progreso Orden"
                                            started={orden.createdAt}
                                            percent={orden.percentage}
                                        />
                                    </Col>
                                    <Col xs={24} md={24} lg={12} xl={8}>
                                        <CardCustomerEntrega
                                            direccion={cliente?.direccion}
                                            tiempo={orden.rfq_detalle[0]?.tiempo}
                                            precio={orden.rfq_detalle[0]?.precio}
                                        />
                                    </Col>
                                </Row>
                                <Switch>
                                    <Route exact path="/customer/misPedidos/:orden_id/cotizaciones" render={(props2) => <Cotizaciones {...this.props} {...props2} ref={this.stepTracker}/>} />
                                    <Route exact path="/customer/misPedidos/:orden_id/accounting"   render={(props2) => <Acounting {...this.props} {...props2} orden={orden}/>} />
                                    <Route exact path="/customer/misPedidos/:orden_id/produccion"     render={(props2) => <Produccion {...this.props} {...props2} />} />
                                    <Route exact path="/customer/misPedidos/:orden_id/transito"  render={(props2) => <Transito {...this.props} {...props2} orden={orden} />} />
                                    <Route exact path="/customer/misPedidos/:orden_id/entrega"     render={(props2) => <Entrega {...this.props} {...props2} />} />
                                </Switch>
                            </Col>
                        </Row>
                    </Content>
                </Spin>
            </>
        )
    }
}


export default function (props){

    
    const socket = React.useContext(SocketContext)

    const onNotificaciones = ({notificaciones, count}) => {
        if(count > 0){
            console.log(notificaciones, count)
            socket.emit('leidos', notificaciones.data.map(notificacion => notificacion._id))
            window.location.reload()
        }
    }

    React.useEffect(() => { 

        socket.on('notificaciones', onNotificaciones)

        return () => {
            socket.off('notificaciones', onNotificaciones)
        }
    }, [socket])

    return <LayoutDashbord {...props}/>
}