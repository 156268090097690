import React, { Component } from 'react';
import { Button, Row, Col, Typography, List } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import { CardProducto } from '../../Widgets/Cards';
import '../../../Styles/Modules/Public/marketplace.scss';
import { useContext } from 'react';
import Carrito, { SetCarrito } from '../../../Hooks/Carrito';
import ModalAgregarProducto from './ModalAgregarProducto';
import axios from 'axios';

const { Title, Paragraph } = Typography;


/**
 *
 *
 * @export
 * @class StepReview
 * @extends {Component}
 */
class StepReview extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Row className='marketplace-cotizador' justify={"center"} align={"middle"} gutter={[24, 24]}>
                <Col xs={24}>
                    <Title level={3} className='cotizador-title'>
                        Información de cotización
                    </Title>
                </Col>
                <Col xs={24} className='cotizador-subtitle'>
                    <Title level={4}>Lista de Productos</Title>
                </Col>
                <Col xs={24}>
                    <List
                        className='cotizador-productos'
                        //pagination={{}}
                        dataSource={this.props.productos}
                        renderItem={(item, index) => (
                            <List.Item>
                                <CardProducto
                                    producto={item}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        )
    }
}

export default (props) => {

    const carrito = useContext(Carrito)
    const setCarrito = useContext(SetCarrito)

    return <StepReview {...props} productos={carrito} setProductos={setCarrito}/>
}