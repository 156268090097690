import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Typography, Select, Avatar, InputNumber } from 'antd';
import PropTypes from "prop-types";
const axios = require('axios').default;


const { Title, Text } = Typography;
const { Option } = Select;



/**
 *
 *
 * @export
 * @class FormProducto
 * @extends {Component}
 */
class FormProducto extends Component {


    /**
     *
     *
     * @static
     * @memberof FormProducto
     * 
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,

            productos: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
            },

            form: {
                inv_minimo: 1
            }
        }
    }



    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.id !== undefined)
            this.get()

        this.getProductos();
    }


    /**
     * @param {*} [{
     *         page = this.state.proveedores.page, Pagina actual del proveedor
     *         limit = this.state.proveedores.limit, Limite de proveedores
     *         search = this.state.proveedores.search Busqueda
     *     }=this.state.proveedores]
     * @memberof ModalInventarioProducto
     * 
     * @description Obtenemos los produtos paginados
     */
    getProductos = ({

        page = this.state.productos.page,
        limit = this.state.productos.limit,
        search = this.state.productos.search

    } = this.state.productos) => {
        this.setState(state => {
            state.loading = true
            state.productos.page = page;
            state.productos.limit = limit;
            return state;
        })
        axios.get('/productos', { params: { page, limit, search } })
            .then(response => {

                let productos = []

                if(page === 1)
                    productos = response.data.data.data
                else
                    productos = [...this.state.productos.data, ...response.data.data.data]

                this.setState(state => {
                    state.productos.data = productos.filter(producto => !(this.props.producto_ids.includes(producto._id)))
                    state.productos.total = response.data.data.total
                    state.productos.pages = response.data.data.pages

                    state.loading = false
                    return state;
                })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
    }

    /**
     *
     *
     * @param {*} event
     * @memberof FormProducto
     * @description Al scrolleamos los productos.
     */
    onPopupScrollProductos = event => {
        const { page, pages, } = this.state.productos
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.loading
        ) {
            this.getProductos({ page: page + 1 })
        }
    }



    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method get
     * @description Obtenemos el producto
     */
    get = (id = this.props.id) => axios.get("inventarios/productos/get", {
        params: { id }
    })
        .then(async ({ data }) => {
            data = data.data
            this.formModal.current.setFieldsValue({
                ...data,
                producto_id: { key: data.producto_id?._id, value: data.producto_id?._id, label: data.producto_id?.np_fabricante }
            })
        })
        .catch(res => {
            console.log("res",res)
            message.error('Error al traer la información')
            // message.error('No se pudo cargar el Negocio')
        })



    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method save
     * @description Guardamos el producto
     */
    save = (values) => {

        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {

            const v = {...values}
            v.cantidad = 0
    
            axios.post('/inventarios/productos/add', {
                ...v,
                id: v._id,
                inventario_id: this.props.inventario_id
            })
                .then(() => {
                    message.success("¡Se ha guardado correctamente el producto!")
                    this.props.onCancel();
                })
                .catch((e) => {
                    message.error('No se pudo guardar el producto')
                })
                .finally(() => this.setState({
                    loading: false,
                    loadingImage: false,
                    image: undefined
                }))
        })

    }

    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method update
     * @description Actualizamos el producto.
     */
    update = (values) => {
        axios.put('/inventarios/productos/update', {
            ...values,
            id: this.props.id,
            inventario_id: this.props.inventario_id
        })
            .then(() => {
                message.success("¡Se ha guardado correctamente el producto!")
                this.props.onCancel();
            })
            .catch((e) => {
                message.error('No se pudo guardar el producto')
            })
            .finally(() => this.setState({
                loading: false,
                loadingImage: false,
                image: undefined
            }))
    }

    finish = values => {
        values.producto_id = (values.producto_id.value) ? values.producto_id.value : values.producto_id

        //validaciones
        if (values.precio === 0) {
            message.error("El valor de precio del cliente no puede ser 0")
        }

        else if (values.inv_minimo >= values.inv_maximo) {
            message.error("El valor de inventario mínimo no puede ser mayor o igual al inventario máximo")
        }

        else {
            if (this.props.id !== undefined)
                this.update(values)
            else
                this.save(values)
        }
    }

    formModal = React.createRef();



    render() {
        const { formModal, } = this;
        const { form, loading, } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.id ? "Editar Producto" : "Nuevo Producto"}</Title>
                <Form layout="vertical" ref={formModal} initialValues={form} onFinish={this.finish} >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Productos"
                                name="producto_id"
                                rules={[
                                    { required: true, message: "Ingrese el SKU" }
                                ]} >
                                <Select
                                    labelInValue
                                    showSearch
                                    placeholder="Buscar..."
                                    optionFilterProp="children"
                                    loading={loading}
                                    onSearch={search => this.getProductos({ page_: 1, search })}
                                    onPopupScroll={this.onPopupScrollProductos}
                                >
                                    {this.state.productos.data.map(producto => <Option key={producto._id} value={producto._id} title={producto.np_fabricante}>
                                        {producto.np_fabricante}
                                        <div>
                                            <Avatar
                                                style={{ display: "inline-block", marginRight: 4 }}
                                                shape="square" src={(Array.isArray(producto.imagenes) && producto.imagenes.length > 0) ? (axios.defaults.baseURL + '/upload/' + producto.imagenes[0].filename) : undefined} />
                                            <Text strong>{producto.marca}</Text> &nbsp; <Text>{producto.np_nexus}</Text><br />
                                            <Text>{producto.descripcion}</Text>
                                        </div>
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Número de Parte del Cliente"
                                name="np_cliente"
                                rules={[
                                    { required: true, message: "Ingrese el número de parte NEXUS" }
                                ]} >
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Inventario Mínimo"
                                name="inv_minimo"
                                rules={[
                                    { required: true, message: "Ingrese la cantidad mínima" }
                                ]} >
                                <InputNumber placeholder="0" className="width-100"
                                    min={0} defaultValue={0}
                                     />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Inventario Máximo"
                                name="inv_maximo"
                                min={1}
                                rules={[
                                    { required: true, message: "Ingrese la cantidad mínima" }
                                ]} >
                                <InputNumber placeholder="1" className="width-100"
                                    min={0} defaultValue={0}
                                    />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Precio del Cliente"
                                name="precio"
                                rules={[
                                    { required: true, message: "Ingrese el precio del cliente." }
                                ]} >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormProducto {...props} />
        </Modal>
    )
}
