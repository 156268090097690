import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, DatePicker, Input, InputNumber, Select, message, Spin } from 'antd';


const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');




/**
 *
 *
 * @class modal_form
 * @extends {Component}
 */
class ModalAsociacionFacturasTransacciones extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            orden: undefined,
            facturas: {
                data: [],
                loading: false,
                page: 1,
                limit: 10,
                total: 0,
                filtroSearch: '',
                searching: true,

            },
            transacciones: {
                data: [],
                loading: false,
                page: 1,
                limit: 10,
                total: 0,
                filtroSearch: '',
                searching: true,

            },

        }
    }

    modal_form = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.log('props', this.props)

        if (this.props.factura_id !== undefined) {
            console.log('cargando facturas')
            this.getFactura();
            this.getTransacciones()
        }
        if (this.props.transaccion_id !== undefined) {
            console.log('cargando transaccion')
            this.getTransaccion();
            this.getFacturas();
        }
    }



    /**
       *
       *
       * @memberof getTransaccion
       * @description Obtiene todas todas las transacciones
       * @param values pagina
       */
    getTransaccion = (id) => {
        axios.post('/transacciones/get', {
            id: id,
        }).then((transaccion) => {
            const data = transaccion.data.data;
            this.setState({ transaccion: data });
        })
            .catch(error => {
                console.log('error', error.response)
            })


    }

    /**
     * @memberof modal_form
     * @method getFactura
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    getFactura = () => {
        axios.get('/facturas/get', {
            params: {
                id: this.props.factura_id,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(async response => {
            let factura = response.data.data
            this.setState({
                factura: factura,
                disabled: factura.syncfy_id ? true : false,
            })

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la transaccion')
        }).finally(() => {

            this.setState({ loading: false, disabled: true })
        })
    }



    getFacturas = (
        {
            page = this.state.facturas.page,
            limit = this.state.facturas.limit,
            search = this.state.facturas.search,

        } = this.state.facturas,
        { facturas } = this.state,
    ) => {
        facturas.loading = true;
        facturas.page = page;
        facturas.limit = limit;
        facturas.search = search;
        this.setState({ facturas })

        axios.get('/facturas/list', {
            params: {
                page,
                limit,
                search,
                razon_social_id: this.props.razon_social_id,
                sin_transacciones: true
            }
        })
            .then(response => {
                console.log('res facturas', response)
                let facturas = {
                    data: response.data.data.itemsList,
                    page: response.data.data.paginator.currentPage,
                    total: response.data.data.paginator.itemCount,
                    filtroSearch: search,
                    searching: true,
                    loading: false
                }
                this.setState({
                    facturas
                })
            })
            .catch(error => {
                message.error('Error al Cargar las facturas')
                console.log(error)
            })

    }


    /**
       *
       *
       * @memberof Transacciones
       * @description Obtiene todas todas las transacciones
       * @param values pagina
       */
    getTransacciones = (
        {
            page = this.state.transacciones.page,
            limit = this.state.transacciones.limit,
            search = this.state.transacciones.search,

        } = this.state.transacciones,
        { transacciones } = this.state,
    ) => {
        transacciones.loading = true;
        transacciones.page = page;
        transacciones.limit = limit;
        transacciones.search = search;
        this.setState({ transacciones })
        axios.post('/transacciones/list', {
            page,
            limit,
            search,
            razon_social_id: this.props.razon_social_id,
        })
            .then(res => {
                console.log('res transacciones', res)
                let transacciones = {
                    data: res.data.data.itemsList,
                    page: res.data.data.currentPage,
                    total: res.data.data.itemCount,
                    perPage: res.data.data.perPage,
                    pages: res.data.data.pageCount,
                    filtroSearch: search,
                    searching: true,
                    loading: false
                }
                this.setState({
                    transacciones
                })
            })
            .catch(res => {
                console.log("error", res);
                message.error('No se pudieron cargar las transacciones')
            })
    }




    /**
      *
      *
      * @memberof Transacciones
      * @description Obtiene todas todas las transacciones
      * @param values pagina
      */
    onFinish = (values) => {

        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {

            if (this.props.factura_id !== undefined) {
                // this.addTransaccionToFactura(values);
                axios.post('/transacciones/link/facturas', {
                    factura_id: this.props.factura_id,
                    ...values
                })
                    .then(res => {
                        console.log('res factura asignar', res)
                        message.success('Se asigno factura a transaccion')
                    })
                    .catch(error => {
                        message.error('No se puso asignar factura a transaccion')
                    })
                    .finally(() => {
                      this.setState({loading: false}, () => this.props.onClose())
                    })
            }
            else {
                axios.post('/transacciones/link/facturas', {
                    transaccion_id: this.props.transaccion_id,
                    ...values
                })
                    .then(res => {
                        console.log('res transaccion asignar', res)
                        message.success('Se asigno transacción a factura')
    
                    })
                    .catch(error => {
                        message.error('No se puso asignar transacción a factura')
                    })
                    .finally(() => {
                      this.setState({loading: false}, () => this.props.onClose())
                    })
    
            }
        })



    }

    render() {

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };
        return (
            <Form layout="vertical" {...layout} onFinish={this.onFinish} >

                {(this.props.factura_id != undefined) ?
                    <Form.Item label="Transacciones" name="transaccion_id" >
                        <Select
                            placeholder="Seleccione el Folio"
                            showSearch
                            onSearch={(search) => this.getTransacciones({ search })}
                            filterOption={false}
                        >
                            {this.state.transacciones.data.map(transaccion => {
                                return <Option value={transaccion._id} >
                                    {transaccion.concepto || transaccion.cliente_id?.razon_social || transaccion.cliente_id?.nombre}
                                    <div className="text-gray">{transaccion.orden_id?.folio}</div>
                                    <div className="text-gray">{transaccion.orden_compra_id?.folio}</div>
                                </Option>
                            })}
                        </Select>
                    </Form.Item>
                    :
                    <Form.Item label="Facturas" name="factura_id" >
                        <Select
                            placeholder="Seleccione el Folio"
                            showSearch
                            onSearch={this.getFacturas}
                            filterOption={false}
                        >
                            {this.state.facturas.data.map(factura => {
                                return <Option value={factura._id} >
                                    {factura.concepto || factura.proveedor_id?.nombre}
                                    <div className="text-gray">{factura.orden_id?.folio}</div>
                                    <div className="text-gray">{factura.proveedor_id?.nombre}</div>
                                </Option>
                            })}
                        </Select>
                    </Form.Item>
                }


                <Form.Item className="center">
                    <Button htmlType="submit" type="primary" loading={this.state.loading}>
                        Guardar
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, factura_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3} className="text-center">Asignar {factura_id ? 'Transacción a Factura' : 'Factura a Transacción'}</Title>
        </div>
        <ModalAsociacionFacturasTransacciones {...props} />
    </Modal>

}