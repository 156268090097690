import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, DatePicker, Input, InputNumber, Select, message, Spin, Cascader, Tag } from 'antd';

//compoenentes
import CustomAvatar from "../../../Widgets/Avatar/Avatar";


const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');

class ModalClasificarTransacciones extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            areas: [],
            rubros: [],
            clientes: [],
            cuentas: [],
            tags: [],
            ordenes: [],
            razones: [],
            ordenes_compras: [],
            tipo_cambio: 0,
            countSeleccionadas: 0,
            transaccionUnica: {}
        }
    }

    ModalTransacciones = React.createRef();

    /**
    * @memberof ModalClasificarTransacciones
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {
        this.getTags();
        this.getOrdenes();
        this.getOrdenesCompras()
        this.getRazones();
        this.getClientes();
        this.getAreas();

        let countSeleccionadas = Object.keys(this.props.seleccionadas).length
        let transaccionUnica = undefined
        if (countSeleccionadas === 1) {
            transaccionUnica = Object.values(this.props.seleccionadas)[0]
            transaccionUnica.monto_dolar = 0
        }

        this.setState({ countSeleccionadas: countSeleccionadas, transaccionUnica }, () => {
            this.getTipoCambio();
        })



    }


    /**
     * @memberof ModalClasificarTransacciones
     * @method getTags
     * @description Trae las cuentas 
     * 
     */
    getTags = (search) => {
        axios.get('/clasificadores/list', {
            params: {
                page: 1,
                limit: 100,
                search
            }
        }).then(response => {
            this.setState({
                tags: response.data.data.itemsList,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }

    /**
    * @memberof ModalClasificarTransacciones
    * @method getOrdenes
    * @description Trae los negocios
    * 
    */
    getOrdenes = ({
        cliente_id = this.state.cliente_id,
        ordenes_search = this.state.ordenes_search,
    } = this.state) => {
        this.setState({ cliente_id, ordenes_search })

        axios.get('/ordenes', {
            params: {
                cliente_id,
                search: ordenes_search,
                limit: 20
            }
        }).then(response => {
            this.setState({ ordenes: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Ordenes')
        })
    }

    /**
    * @memberof ModalClasificarTransacciones
    * @method getOrdenes
    * @description Trae los negocios
    * 
    */
    getOrdenesCompras = ({
        orden_id = this.state.orden_id,
        ordenes_compras_search = this.state.ordenes_compras_search,
    } = this.state) => {
        this.setState({ orden_id, ordenes_compras_search })

        axios.get('/ordenesCompra/list', {
            params: {
                orden_id,
                search: ordenes_compras_search,
                limit: 20
            }
        })
            .then(response => {
                this.setState({ ordenes_compras: response.data.data.itemsList })
            })
            .catch(error => {
                console.log(error)
                message.error('Error al traer los Ordenes')
            })
    }

    /**
    * @memberof ModalClasificarTransacciones
    * @method getRazones
    * @description Trae los negocios
    * 
    */
    getRazones = (search) => {
        axios.get('/razones-sociales', {
            params: {
                search,
                limit: 20
            }
        }).then(response => {
            this.setState({ razones: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Razones Sociales')
        })
    }

    /**
     * @memberof ModalClasificarTransacciones
     * @method getClientes
     * @description Trae los negocios
     * 
     */
    getClientes = (search) => {
        return axios.get('/clientes/list', {
            params: {
                search,
                limit: 20,
                transacciones: true
            }
        }).then(response => {
            this.setState({ clientes: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los clientes')
        })
    }


    /**
    * @memberof ModalClasificarTransacciones
    * @method getAreas
    * @description Trae las areasm las pone en el formato adecuado para el cascader
    * 
    */
    getAreas = (search) => {
        return axios.get('/areas/list', {
            params: {
                search,
                paginate: false
            }
        }).then(response => {
            this.setState({
                areas: response.data.data.map(area => ({
                    value: area._id, label: area.nombre, isLeaf: false
                }))
            })
        }).catch(error => {
            console.log(error)
        })
    }

    /**
     * @memberof ModalClasificarTransacciones
     * @method loadRubros
     * @description Trae los rubros asiganos a una area y los formatea para el cascader
     * 
     */
    loadRubros = selectedOptions => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        let areas = this.state.areas

        axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: targetOption.value
            }
        }).then(response => {
            targetOption.loading = false;
            let index = areas.findIndex(area => area.value.toString() === targetOption.value.toString())

            if (index !== -1 && response.data.data.length > 0) {
                areas[index].children = response.data.data.map(rubro => ({ label: rubro.nombre, value: rubro._id }))
                this.setState({ areas: [...areas] }) // Esto era {areas}
            } else {
                message.error('Rubros no encontrados')
            }


        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los rubros')
        })

    };

    /**
    * @memberof ModalClasificarTransacciones
    * @method getTipoCambio
    * @description Trae el ulitmo tipo de cambio utilizado 
    * 
    */
    getTipoCambio = () => {
        let transaccionUnica = this.state.transaccionUnica

        console.log('getTipoCambio ', transaccionUnica)
        axios.get('/transacciones/tipo-cambio').then(response => {

            let tipo_cambio = response.data.data.toMoney()

            if (transaccionUnica !== undefined) transaccionUnica.monto_dolar = (Math.abs(parseFloat(transaccionUnica.amount)) / tipo_cambio).toMoney()

            console.log(transaccionUnica)

            this.ModalTransacciones.current?.setFieldsValue({
                tipo_cambio: tipo_cambio
            })
            this.setState({
                tipo_cambio: tipo_cambio,
                transaccionUnica
            })

        }).catch(error => {
            console.log(error);
            message.error('Error al traer tipo de cambio')
        })
    }

    /**
     * @memberof ModalClasificarTransacciones
     * @method onChangeTipo
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares, usando el nuevo tipo de cambio
     */
    onChangeTipo = (value) => {
        let transaccionUnica = this.state.transaccionUnica
        if (transaccionUnica !== undefined) transaccionUnica.monto_dolar = (Math.abs(parseFloat(transaccionUnica.amount)) / value).toMoney()
        this.setState({
            tipo_cambio: value?.toMoney(),
            transaccionUnica
        })
    }

    /**
     * @memberof ModalClasificarTransacciones
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        console.log('onFinish', values)
        try {


            values.tags = values.tags.map(tag => {
                let obj = JSON.parse(tag)

                return obj._id
            })

            if (values.area_rubro?.length === 2) {
                values.area_id = values.area_rubro[0]
                values.rubro_id = values.area_rubro[1]
            } else {
                message.error('Seleccione un Area Y Rubro')
                return;
            }

        } catch (e) {
            console.log(e)
        }

        this.clasificarTransacciones(values)
    }

    /**
     * @memberof ModalTransacciones
     * @method addTransaccion
     * @description Trae las cuentas 
     * 
     */
    clasificarTransacciones = (values) => {
        console.log('clasificarTransacciones', values)
        this.setState({ loading: true })
        axios.post('/syncfy/transacciones/add', {
            ...values,
            cuenta_id: this.props.cuenta_id,
            transacciones: Object.values(this.props.seleccionadas),
            tipo_cambio: this.state.tipo_cambio,
        })
            .then(response => {
                message.success('Transacciones clasificadas')
                this.props.onClose()
                this.props.clearSeleccionadas()
            })
            .catch(error => {
                console.log(error)
                message.error('Error al clasificar transacciones')
            })
            .finally(() => this.setState({ loading: false }))
    }


    render() {


        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalTransacciones}
                onFinish={this.onFinish}
                initialValues={{
                    fecha: moment(),
                }}
            >
                <Spin spinning={this.state.loading}>

                    <Row>
                        {this.state.countSeleccionadas > 1 ? null : <Col xs={24} lg={24}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]}
                            >
                                <Input placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>}
                        <Row>
                        </Row>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                            >
                                <Select
                                    showArrow
                                    className="witdh-100"
                                    placeholder="Seleccione el Cliente"
                                    onSearch={(value) => {
                                        if (value.length > 0) this.getClientes(value)
                                    }}
                                    onSelect={cliente_id => this.getOrdenes({ cliente_id })}
                                    showSearch
                                    allowClear
                                    filterOption={false}

                                >
                                    {this.state.clientes.map(cliente => {
                                        return <Option value={cliente._id} >{cliente.razon_social} {cliente.nombre} {cliente.nombres} {cliente.apellido} {cliente.email}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Orden"
                                name="orden_id"
                            >
                                <Select
                                    showArrow
                                    filterOption={false}
                                    placeholder="Seleccione el Folio"
                                    onSearch={(ordenes_search) => this.getOrdenes({ ordenes_search })}

                                    onSelect={orden_id => this.getOrdenesCompras({ orden_id })}
                                    showSearch
                                >
                                    {
                                        this.state.ordenes.map(orden => {
                                            return <Option value={orden._id}>{orden.folio}</Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Orden de Compra"
                                name="orden_compra_id"
                            >
                                <Select
                                    showArrow
                                    placeholder="Seleccione el Folio"
                                    filterOption={false}
                                    onSearch={(ordenes_compras_search) => this.getOrdenesCompras({ ordenes_compras_search })}
                                    showSearch
                                >
                                    {this.state.ordenes_compras.map(orden => {
                                        return <Option value={orden._id}>{orden.folio}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Área / Rubro"
                                name="area_rubro"
                            >
                                <Cascader
                                    placeholder="Seleccione el Area y Rubro"
                                    options={this.state.areas}
                                    loadData={this.loadRubros}
                                    changeOnSelect
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Tags"
                                name="tags"
                            >
                                <Select
                                    mode="multiple"
                                    showArrow
                                    style={{ width: '100%' }}
                                //tagRender={this.tagRender}
                                //onSearch={(value)=>this.getTags(value)}
                                >
                                    {this.state.tags.map(tag => {
                                        return <Option value={`{"_id":"${tag._id}", "color":"${tag.color}"}`} po={45}>{tag.nombre}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Razón Social"
                                name="razon_social_id"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la Razón Social"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione la Razón Social"
                                    filterOption={false}
                                    onSearch={(value) => this.getRazones(value)}
                                    showSearch
                                >
                                    {
                                        this.state.razones.map(razon => {
                                            return <Option value={razon._id}>{razon.razon_social}</Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={24} lg={6} >
                            <Form.Item
                                label="Tipo Cambio"
                                name="tipo_cambio"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber min={1} defaultValue={0} className="width-100" onChange={this.onChangeTipo} />
                            </Form.Item>
                        </Col>
                        {this.state.countSeleccionadas > 1 ? null :

                            <>
                                <Col xs={24} lg={{ span: 8, push: 1 }} >
                                    <Form.Item
                                        label="Monto (Pesos)"
                                    >
                                        <Text>$ {Math.abs(parseFloat(this.state.transaccionUnica.amount))} MXN</Text>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={{ span: 8, push: 2 }} >
                                    <Form.Item
                                        label="Monto (Dolar)"
                                    >
                                        <Text>$ {this.state.transaccionUnica.monto_dolar} USD</Text>
                                    </Form.Item>
                                </Col>
                            </>}
                    </Row>


                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>

        )
    }
}




export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>Clasificar Transacciones</Title>
        </div>
        <ModalClasificarTransacciones {...props} />
    </Modal>

}



