import React, { Component } from "react";
import { Button, Col, Tooltip, Form, Input, message, Modal, Row, Spin, Typography, Upload, Select, } from 'antd';
import { UploadOutlined } from "@ant-design/icons";

import { FaMinus, } from "react-icons/fa";

import { PlusOutlined } from "@ant-design/icons"

import CustomAvatar from "../../Widgets/Avatar/Avatar";

import User from "../../../Hooks/Logged";

const axios = require('axios').default;


const { Title } = Typography;
const { Option } = Select;



/**
 *
 *
 * @class NuevoUsuarioSyncfy
 * @extends {Component}
 * 
 * @description Modal para agregar un nuevo usuario a Syncfy
 */
class NuevoUsuarioSyncfy extends Component {

    static contextType = User

    state = {
        usuarios: {
            data: [],
            page: 1,
            limit: 50,
            pages: 0,
            search: null
        },

    }


    /**
     *
     *
     * @memberof NuevoUsuarioSyncfy
     * @description Se ejecuta al inicar
     */
    componentDidMount() {
        this.getUsuarios()
    }

    /**
    * @function getUsuarios
    * @description Obtenemos los usuarios del sistema para agregarlos a Syncfy
    * */
    getUsuarios = async ({ search = this.state.usuarios.search } = this.state.usuarios) => {
        await axios.get("/usuarios", {
            params: {
                search: (typeof search === "string" && search.length > 1) ? search : undefined,
                paginate: true,
                limit: 100,
            },
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({
                    usuarios: {
                        data: [...data.data],
                        //page: data.data.paginator.currentPage,
                        //limit: data.data.paginator.perPage,
                        //pages: data.data.paginator.pageCount
                    }
                })

                if (this.context?._id)
                    this.form.current.setFieldsValue({ usuario_id: this.context?._id })
            })
            .catch(e => console.log('er', e))
    }

    /**
     *
     *
     * @param {*} values
     * @memberof NuevoUsuarioSyncfy
     * 
     * @method onFinish
     * @description Agregamos el usuario a la lista de Usuarios de Syncfy
     */
    onFinish = (values) => axios.post('/syncfy/users', {
        ...values,
        cuenta_id: this.props.id
    })
        .then(() => {
            message.success("El Usuario ha sido agregado a Syncfy")
            this.props.onCancel()
        })
        .catch((error) => {
            console.error(error)
            message.success("El usuario no se puede agregar.")
        })

    form = React.createRef()

    render() {
        return <Form
            ref={this.form}
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            autoComplete="off"
        >
            <Form.Item

                label="Usuarios del Sistema"
                extra="Debes seleccionar el usuario que quieres agregar a la lista de aceptados de Syncfy."
                name="usuario_id"
                rules={[
                    { required: true, message: 'Para poder proceder, debes ingresar el usuario' }
                ]}

            >
                <Select
                    showSearch
                    filterOption={false}
                    onSearch={search => this.getUsuarios({ search })}>
                    {this.state.usuarios.data?.map(function ({ _id, nombre, logo, color, apellido }, index) {
                        return <Option value={_id} style={{ margin: '2px 0 2px 0' }}>
                            <CustomAvatar
                                image={logo}
                                name={nombre}
                                color={color}
                                size="small"
                                style={{
                                    marginRight: '5px'
                                }}
                            />
                            {nombre} {apellido}
                        </Option>
                    })}
                </Select>

            </Form.Item>
        </Form>
    }
}


/**
 *
 *
 * @param {*} props
 * @return {*} 
 * 
 * @description Permite agregar un usuario del sistema ERP a Syncfy
 */
function ModalNuevoUsuario(props) {

    const { visible, onCancel, id } = props


    const formRef = React.createRef()

    /**
     *
     *
     * @param {*} values
     * @memberof NuevoUsuarioSyncfy
     * 
     * @method onFinish
     * @description Ejecutamos el onfinish del formulario
     */
    const onFinish = () => formRef.current.form.current.submit()

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title="Agregar un Usuario"
        destroyOnClose={true}
        onOk={onFinish}
        zIndex={1000}
    >
        <NuevoUsuarioSyncfy ref={formRef} {...props} />
    </Modal>
}



/**
 *
 *
 * @export
 * @class ModalTaxPayer
 * @extends {Component}
 */
class ModalTaxPayer extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cer: undefined,
            syncfy_users_loading: false,
            syncfy_users: [],
        }
    }

    formModalRS = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getUsuariosSyncfy()
        this.getRazonSocial()
    }

    /**
     *
     * @memberof ModalTaxPayer
     *
     * @method onFinish
     * @description envia los datos para registrar el contribuyente en syncfy
     *
     */
    onFinish = (values) => {


        if (this.state.loading) return

        this.setState({ loading: true }, () => {
            const formData = new FormData();

            formData.append(
                "cer",
                this.state.cer,
                this.state.cer.name,
            );

            formData.append(
                "key",
                this.state.key,
                this.state.key.name,
            );

            formData.append("password", values.password);

            formData.append("razon_social_id", this.props.razon_social_id);
            formData.append("syncfy_usuario_id", values.syncfy_usuario_id);

            axios.post("/syncfy/add/taxpayer", formData)
                .then(response => {
                    console.log("response", response);
                    this.props.onCancel()
                    message.success('Registrado con exito')
                }).catch(error => {
                    console.log("error", error);
                    Modal.error({
                        title: "Error al registrar",
                        content: "Verifique que su usuario se haya registrado previamente en SYNCFY."
                    })
                })
                .finally(() => {
                    this.setState({ loading: false })
                });
        })



    }

    /**
     * @methodOf FormRazonSocials
     * @function getRazonSocial
     *
     * @description Obtiene la razón social
     *
     * */
    getRazonSocial = (id = this.props.razon_social_id) => axios.get("/razones-sociales/get", {
        params: { id }
    })
        .then(async ({ data }) => {
            this.setState({ razon_social:  data.data })
            this.formModalRS.current.setFieldsValue({ syncfy_usuario_id: data?.data.syncfy_usuario_id })
        })
        .catch(res => {
            message.error('No se pudo cargar la Razón Social')
            console.log("no obtenida", res);
        })

    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method getUsuariosSyncfy
     * @description Obtenemos los usuarios de Syncfy 
     */
    getUsuariosSyncfy = () => {

        this.setState({
            syncfy_users_loading: true,
        })

        axios.get('/syncfy/users')
            .then((response) => {
                this.setState({ syncfy_users: response.data.data })
                console.log('response', response.data)
            })
            .catch((response) => {
                console.log('error', response)
            })
            .finally(() => {
                this.setState({
                    syncfy_users_loading: false
                })
            })
    }

    /**
     *
     * @memberof ModalTaxPayer
     *
     * @method normFile
     * @description Pone los archivos .key y .cer en el state para despues enviarlos
     *
     */
    normFile = (e) => {
        console.log('e.file', e.file)
        if (e.file.type === 'application/x-iwork-keynote-sffkey' || e.file.type === 'application/pkix-cert'
            || e.file.type === 'application/x-x509-ca-cert' || e.file.name.split('.')[1] === 'key') {

            if (e.file.name.split('.')[1] === 'key')
                this.setState({ key: e.file.originFileObj })

            if (e.file.name.split('.')[1] === 'cer')
                this.setState({ cer: e.file.originFileObj })

            e.file.status = 'done'

            return e && e.fileList;

        } else {

            return e && e.fileList;
        }

    };

    
    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method deleteUser
     * @description Eliminamos un usuario de syncfy
     */
     deleteUser = ({ id_user, name }) => {
        Modal.confirm({
            title: `¿Deseas eliminar a ${name} de la lista de usuarios de syncfy ?`,
            content: "Al eliminar el usuario, no será posible usar las cuentas que se hayan creado.",

            okText: "Eliminar " + name,
            okCancel: "Cancelar",
            onOk: () => axios.delete('/syncfy/users', { params: { id_user } })
                .then(() => {
                    message.success("Se ha eliminado el usuario de Syncfy.");
                    this.getUsuariosSyncfy()
                })
                .catch((e) => { console.log('e', e) })
        })
    }
    render() {
        const { formModalRS, onFinish, } = this;
        const { loading, } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">Configurar Razón Social para timbrado</Title>
                <Form
                    layout="vertical"
                    ref={formModalRS}
                    onFinish={onFinish}
                >
                    <Row justify="center" className="mt-2">
                        <Col xs={20}>
                            <Row className="w-100">
                                <Col span={21}>
                                    <Spin spinning={this.state.syncfy_users_loading || this.state.loading}>
                                        <Form.Item
                                            label="Usuario de Synfcy"
                                            name="syncfy_usuario_id"
                                            rules={[
                                                {
                                                    require: true,
                                                    message: "Indique el Usuario Responsable"
                                                }
                                            ]}
                                        >
                                            <Select
                                                allowClear={true}
                                                className="syncfy-input"
                                            >
                                                {this.state.syncfy_users.map(({ id_user, name }) => <Option style={{ position: 'relative' }} value={id_user}>
                                                    {name}
                                                    <Button
                                                        className="button-syncfy button-delete-syncfy"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            this.deleteUser({ id_user, name })
                                                        }}
                                                        size="small" type="primary" shape="circle" danger icon={<FaMinus size='8px' className="icon" />} />
                                                </Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Spin>
                                </Col>
                                <Col span={3}>
                                    <Tooltip title="Agregar nuevo usuario de Synfy" >
                                        <Button
                                            onClick={() => this.setState({ modalUsuario: true })}
                                            style={{
                                                display: 'block',
                                                margin: '30px auto 0 auto',
                                                borderRadius: '6px'
                                            }} type="primary" icon={<PlusOutlined style={{ color: "white" }} />} />
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={20}>
                            <Form.Item
                                name="upload"
                                label="Archivos .key y .cer"
                                valuePropName="fileList"
                                accept="application/*"
                                getValueFromEvent={this.normFile}
                            >
                                <Upload name="cer" multiple={true}>
                                    <Button block icon={<UploadOutlined />}>Presione aqui para subir</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={20}>
                            <Form.Item
                                name="password"
                                label="Contraseña CSD"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la contraseña"
                                }]}
                            >
                                <Input.Password />
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <ModalNuevoUsuario
                    visible={this.state.modalUsuario}
                    id={this.props.id}
                    onCancel={() => {

                        this.getUsuariosSyncfy()
                        this.setState({ modalUsuario: false })
                    }}
                />
            </Spin>

        )
    }
}


/**
 *@function ModalNegocios
 *@description 
 */
export default function (props) {
    return (
        <Modal
            visible={props.visible}
            onCancel={props.onCancel}
            title={null}
            footer={null}
            closable={true}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <ModalTaxPayer {...props} />
        </Modal>
    )
}
