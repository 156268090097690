import React, { Component } from 'react';
import { Button, Popconfirm, Spin, Table, PageHeader, Layout, message, Space } from 'antd'

import axios from 'axios';
import { Link } from "react-router-dom";

//Componentes
import { IconDelete, IconEdit, IconDetails, IconPlus } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalAreas from './ModalAreas'

const { Content } = Layout;

/**
 * @export
 * @class Areas
 * @extends {Component}
 * @description Vista de Areas
 */
export default class Areas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            area_id: undefined,
            areas: [],

            page: 1,
            total: 0,
            filtroSearch:'',
            searching: true,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getAreas();
    }


    /**
     *
     *
     * @memberof Areas
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
     componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getAreas();
            this.state.searching = false;
        }
    }

    /**
     *
     *
     * @param {*} [page=this.state.page] Numero de la pagina a obtener
     * @memberof Areas
     * @method getAreas
     * @description Obtiene el listado de areas
     */
    getAreas = (page = this.state.page) => {
        let search = this.props.search;
        this.setState({loading: true})
        axios.get('/areas/list', {
            params: {
                page: page,
                search:search
            }
        }).then(response => {

            this.setState({
                areas: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
                searching: true,
                filtroSearch: search
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Áreas"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <Table
                            loading={this.state.loading}
                            scroll={{ x: 1000, y: 1000 }}
                            columns={[
                                {
                                    title: 'Nombre',
                                    dataIndex: 'nombre',
                                    render: (text, record) => (
                                        <h3>{record.nombre}</h3>
                                    ),
                                },

                                {
                                    title: 'Descripción',
                                    dataIndex: 'descripcion',
                                    key: 'descripcion',
                                    render: (text, record) => (
                                        <span>{record.descripcion?record.descripcion:'Sin Descripción'}</span>
                                    ),
                                },

                                {
                                    title: 'Acciones',
                                    key: 'actions',
                                    fixed: 'right',
                                    width: '210px',
                                    render: (text, record) => (
                                        <Space>
                                            <Link title="Ver rubros" to={`/admin/areas/rubros/${record._id}`}>
                                                <Button 
                                                    type=""  name={record.nombre} id={record._id}
                                                    title="Ver rubros"
                                                    icon={<IconDetails />}/>
                                            </Link>


                                            <Button
                                                type="primary"
                                                icon={<IconEdit />}
                                                title="Editar"
                                                onClick={() => { this.setState({ modalVisible: true, area_id: record._id }) }}

                                            />
                                            <Popconfirm
                                                placement="topRight"
                                                title="¿Deseas eliminar esta area?"
                                                onConfirm={() => axios.post('/areas/delete',
                                                    { id: record._id }).then(() => {
                                                        this.getAreas()
                                                        message.success('Area eliminada')
                                                    }).catch(error => console.log(error))}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button
                                                    type="primary"
                                                    icon={<IconDelete />}
                                                    title="Eliminar"
                                                    danger
                                                />
                                            </Popconfirm>
                                        </Space>
                                    ),
                                },
                            ]}
                            dataSource={this.state.areas}
                            pagination={{
                                onChange: this.getAreas,
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                position: ['bottomLeft'],
                            }}
                            locale={{ emptyText: 'Sin áreas disponibles' }}
                             />

                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />


                    <ModalAreas
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, area_id: undefined })
                            this.getAreas()
                        }}
                        area_id={this.state.area_id}
                    />
                </Spin>
            </>
        )
    }
}