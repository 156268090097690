import React, { Component, createRef } from 'react';
import { Row, Col, Typography, Form, Input, InputNumber } from 'antd';

import '../../../Styles/Modules/Public/marketplace.scss';

const { Title } = Typography;

export default class StepInfo extends Component {


    formRef = createRef();

    constructor(props) {
        super(props)
        this.state = {
            hasAccount: true,
            infoLoaded: false,
            values: {}
        }
    }

    onFinish = async (values = this.state.values) => {
       this.props.onSuccess(values)
    }

    render() {
        return (
            <>
                <Title level={3} style={{ textAlign: "center", marginBottom: "1.5rem" }}>
                    Información de Contacto
                </Title>
                <Form
                    layout="vertical"
                    name="form-info"
                    className='marketplace-info'
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row gutter={[36,0]} justify="space-between" style={{ margin: "3rem 0 2rem" }}>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Apellidos"
                                name="apellido"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese los apellidos"
                                }]}
                            >
                                <Input placeholder="Apellidos" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Razón Social"
                                name="razon_social"
                                rules={[{
                                    //required: true,
                                    message: "Por favor, ingrese su razon social"
                                }]}
                            >
                                <Input placeholder="Razón Social" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="RFC"
                                name="rfc"
                                rules={[{
                                    //required: true,
                                    message: "Por favor, ingrese el RFC"
                                },
                                {
                                    pattern: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
                                    message: 'RFC invalido'
                                }
                                ]}
                            >
                                <Input placeholder="RFC" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Dirección"
                                name="direccion"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese Dirección"
                                }]}
                            >
                                <Input placeholder="Dirección" className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Código Postal"
                                name="codigo_postal"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el código postal"
                                }]}
                            >
                                <InputNumber placeholder="Código Postal" className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Teléfono"
                                name="telefono"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese teléfono"
                                }, (({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if ((/^[0-9\+]{10,13}$/).test(value)) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(new Error('Numero de telefono incompleto'));
                                    }
                                }))]}
                            >
                                <InputNumber placeholder="Teléfono" className="width-100" />
                            </Form.Item>
                        </Col>


                    </Row>
                </Form>
            </>
        )
    }
}