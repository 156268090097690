import React from 'react'
import { Route, Switch } from "react-router-dom";



import '../Styles/Modules/Inventarios/inventarios.css'
// componentes
import Inventarios from '../components/Admin/Inventarios/Inventarios'

import AlmacenProductos from '../components/Admin/Inventarios/AlmacenProductos'
import InventariosProductos from '../components/Admin/Inventarios/InventariosProductos'

import InventariosAlmacenes from '../components/Admin/Inventarios/InventariosAlmacenes'

/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCuentas(props) {
    return (
        <Switch>

            {/* Rutas de Alberto */}
            <Route exact path="/admin/inventarios/:inventario_id/productos" render={(props_route) => <InventariosProductos {...props}  {...props_route}/>} />           
            {/* Rutas de Manuel */}
            <Route exact path="/admin/inventarios/:inventario_id/almacenes" render={(prop) => <InventariosAlmacenes {...props}  {...prop}/>} />
            <Route exact path="/admin/inventarios/:inventario_id/almacenes/:almacen_id" render={(prop) => <AlmacenProductos {...props}  {...prop}/>} />
            <Route exact path="/admin/inventarios"  render={(props_route) => <Inventarios {...props} {...props_route} />} />
        </Switch>
    )
}

export default RouterCuentas;
