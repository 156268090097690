
import React, { Component } from "react";
import { Layout, PageHeader, message, Button, Col, Space, List, Row, Typography } from "antd";
import { EllipsisOutlined, FlagFilled, PaperClipOutlined, FieldTimeOutlined} from "@ant-design/icons";


import StepTracker from "../../ProjectManager/Secciones/StepTracker";
import { CardEntrega, CardLegalInfo, CardProjectInfo, CardUtilidad } from "../../../Widgets/Cards";
import { IconArrowBack, IconCheck } from '../../../Widgets/Iconos';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";


import ModalComprobantes from "../Secciones/ModalComprobantes";
const { Content } = Layout;
const { Title, Text } = Typography;
const moment = require('moment');
const axios = require('axios').default;

/**
 *
 *
 * @export
 * @class ProofDelivery
 * @extends {Component}
 */
export default class ProofDelivery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 0,
            modal_visible: false,
            ordenes: [{
                _id: 1,
                fecha: new Date(),
                folio: '0001',
                order: 10012,
                firma: true,
                recibido: 'Juan Perez'

            },
            {
                _id: 2,
                fecha: new Date(),
                folio: '0002',
                order: 10012,
                firma: false,
                recibido: 'Juan Perez'

            }],
            envios: []

        }
    }

    componentDidMount = () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getEnvios()
    }

    getEnvios = () => {
        axios.get('/envios/list', {
            params: {
                orden_id: this.props.match?.params?.orden_id
            }
        })
            .then(({data}) => {
                this.setState({
                    envios: data.data.itemsList
                })
            })
            .catch(error => {
                message.error("Error al obtener Ordenes de Compras")
                console.log(error)
            })
    }

    render() {
        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={
                        <Row className=" width-100 " justify="space-between" align="middle">
                            <Col span={2} xxl={1}>
                                <Button ghost onClick={this.back} className="no-border">
                                    <IconArrowBack />
                                </Button>
                            </Col>

                            <Col span={22} xxl={23}>
                                <span className="ant-page-header-heading-title" >
                                    Cadena de Suministro
                                </span>
                            </Col>
                        </Row>
                    }
                    extra={
                        <>
                            <Button type="link" className="text-white">Cadena de Suministro</Button>
                            <Button type="link" className="text-white">Mantenimiento</Button>
                        </>
                    }
                />
                <Content className="content-bg pd-1">
                    <Row wrap={false} gutter={[16, 16]}>
                        <Col flex="none">
                            <StepTracker orden_id={this.props.match?.params?.orden_id}/>
                        </Col>
                        <Col flex="auto" >
                            <Row gutter={[16, 16]}>
                                <Col span={8} lg={8} md={24} >
                                    <CardProjectInfo />
                                </Col>
                                <Col span={8} lg={8} md={12}>
                                    <Row gutter={[0, 16]}>
                                        <Col span={24}>
                                            <CardUtilidad />
                                        </Col>
                                        <Col span={24}>
                                            <CardLegalInfo />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8} lg={8} md={12}>
                                    <CardEntrega 
                                      orden_id={this.props.match?.params?._id}/>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={[...this.state.envios]}
                                        header={
                                            <Row gutter={[0, 8]}>
                                                <Col span={18} md={20} lg={16} xxl={{ span: 20 }} ><Title level={3}>Proof of Delivery</Title></Col>
                                                <Col span={6} md={4} lg={8} xxl={{ span: 4 }} ><Button type="primary" size="large" className="btn-purple" block>Proof of Delivery</Button></Col>
                                            </Row>
                                        }
                                        renderItem={({_id, fecha, cliente, createdAt, firma}) => (
                                            <List.Item className="order-list-item bg-white">
                                                <Row className="width-100  pd-1" justify="start">
                                                    <Col span={24}>
                                                        <Row className="width-100" justify="start" >
                                                            <Col md={{ span: 22, order: 1 }} xl={{ span: 22, order: 1 }} xxl={{ span: 14, order: 1 }}>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <Text ellipsis strong className="tag-card-pink"> {cliente?.nombre} </Text>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <Text ellipsis className="text-gray mt-1">descripción</Text>
                                                                    </Col>

                                                                </Row>
                                                            </Col>
                                                            <Col md={{ span: 4, order: 2 }} xl={{ span: 4, order: 2 }} xxl={{ span: 4, order: 2 }} >
                                                                <Row justify="center" className="text-center">
                                                                    <Col span={24}><Text className="text-gray">{ firma ? "Entregado" : "Pendiente"}</Text></Col>
                                                                    <Col span={24} className="mt-1">{ firma ? (<IconCheck />) : (<FieldTimeOutlined style={{fontSize: "2rem"}}/>)}</Col>
                                                                    <Col span={24}>{ fecha && firma ? moment(fecha).format('L') : "" }</Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={{ span: 12, order: 3 }} xl={{ span: 12, order: 3 }} xxl={{ span: 5, order: 3 }}>
                                                                <Row>
                                                                    <Col span={24}><Text className="text-gray">Proof of Delivery</Text></Col>
                                                                    <Col span={24} className="mt-1">
                                                                        <Row>
                                                                            {
                                                                                firma ? (
                                                                                    <Button type="link" icon={<PaperClipOutlined />} > {firma} </Button>
                                                                                ) : null
                                                                            }
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={{ span: 2, order: 4 }} xl={{ span: 2, order: 4 }} xxl={{ span: 1, order: 4 }}  >
                                                                <Space>
                                                                    <Button
                                                                        icon={<EllipsisOutlined />}
                                                                        type="ghost"
                                                                        className="no-border"
                                                                        onClick={() => this.setState({ modal_visible: true, envio_id: _id })}
                                                                    >
                                                                    </Button>
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Row align="middle" justify="start">
                                                            <Col span={4} xxl={{ span: 2 }}>
                                                                <Text ellipsis className="text-gray"><FlagFilled /> <span>{moment(createdAt).format('L')}</span></Text>
                                                            </Col>
                                                            <Col span={4} xxl={{ span: 2 }}>
                                                                <Text ellipsis className="text-gray">20 days</Text>
                                                            </Col>
                                                            <Col span={4} xxl={{ span: 2 }}>
                                                                <Text ellipsis className="text-gray"><CustomAvatar /></Text>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        )}

                                    />
                                </Col>


                            </Row>
                        </Col>
                    </Row >
                </Content >

                <ModalComprobantes
                    visible={this.state.modal_visible}
                    envioId = {this.state.envio_id}
                    onSuccess = {() => {
                        this.getEnvios()
                        this.setState({
                            modal_visible: false
                        })
                    }}
                    onClose={() => {
                        this.setState({ modal_visible: false})
                    }}
                />
            </>
        )
    }
}