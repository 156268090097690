import React, { Component } from "react";
import { Button, Col, Form, message, Modal, Avatar, Input, Row, Spin, Typography, Select, InputNumber, } from 'antd';
import PropTypes from "prop-types";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const axios = require('axios').default;
const { Title, Text } = Typography;
const { Option } = Select;


/**
 *
 *
 * @export
 * @class ChangeProveedorProducto
 * @extends {Component}
 */
class ChangeProveedorProducto extends Component {


    /**
     *
     *
     * @static
     * @memberof FormProducto
     * 
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.InputProductoRef = React.createRef()
        this.state = {
            id: undefined,
            loading: false,
            proveedor_id: undefined,

            proveedores: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
            },
        }
    }

    ChangeProveedorProducto = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.log('se monto modal proveedores productos', this.props)
        this.getProveedores()
    }

    /**
     *
     *
     * @param {*} [{
     *         page = this.state.proveedores.page, Pagina actual del proveedor
     *         limit = this.state.proveedores.limit, Limite de proveedores
     *         search = this.state.proveedores.search Busqueda
     *     }=this.state.proveedores]
     * @memberof ChangeProveedorProducto
     * 
     * @description Obtenemos los proveedores
     */
    getProveedores = ({
        page = this.state.proveedores.page,
        limit = this.state.proveedores.limit,
        search = this.state.proveedores.search
    } = this.state.proveedores) => {
        this.setState(state => {
            state.loading = true
            state.proveedores.page = page;
            state.proveedores.limit = limit;
            return state;
        })

        axios.get('/proveedores/list', {
            params: {
                page, limit, search, nin_proveedores: this.props.proveedores_usados
            }
        })
            .then(response => {
                console.log('response ', response)
                this.setState(state => {
                    if (page === 1)
                        state.proveedores.data = response.data.data.itemsList
                    else
                        state.proveedores.data = [...state.proveedores.data, ...response.data.data.itemsList]

                    state.proveedores.total = response.data.data.paginator.itemCount
                    state.proveedores.pages = response.data.data.paginator.pageCount
                    state.loading = false
                    return state;
                })
            })
            .catch(error => {
                message.error('Error al traer la información de los proveedores')
            })
    }

    /**
     *
     *
     * @param {*} [{
     *         page = this.state.proveedores.page, Pagina actual del proveedor
     *         limit = this.state.proveedores.limit, Limite de proveedores
     *         search = this.state.proveedores.search Busqueda
     *     }=this.state.proveedores]
     * @memberof ChangeProveedorProducto
     * 
     * @description Obtenemos los proveedores al scrollear
     */
    onPopupScrollProveedores = event => {
        const { page, pages, } = this.state.proveedores
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.loading
        )
            this.getProveedores({ page: page + 1 })
    }




    /**
     *
     *
     * @memberof ChangeProveedorProducto
     * 
     * @method onFinish
     * @description Cuando se guarda el proveedor del producto
     */
    onFinish = values => {

        const thos = this;
        values.proveedor_id = ((values.proveedor_id?.value) ? values.proveedor_id?.value : values.proveedor_id)
        values.id = this.props.producto._id;
        console.log('values proveedor', values)
        Modal.confirm({
            title: 'IMPORTANTE',
            icon: <ExclamationCircleOutlined />,
            content: 'Por favor verifique que el proveedor del producto sea el correcto.',
            okText: "Es correcto",
            cancelText: "Cancelar",
            onOk() {
              thos.props.onSave(values)
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }



    render() {
        const { loading, } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">Editar Proveedor</Title>

                <Row className="center">
                    <Col xs={24}>
                        <Title level={4}>
                            {this.props.producto?.marca}
                        </Title>
                        <Text>{this.props.producto.descripcion}</Text>
                    </Col>
                    <Col xs={24}>
                        <Title level={5}>
                            Costo : {this.props.producto.costo}
                        </Title>
                    </Col>
                    <Col xs={24}>
                        <Avatar
                            style={{ display: "inline-block", marginRight: 4 }}
                            size={"large"}
                            shape="square"
                            src={(Array.isArray(this.props.producto.imagenes)
                                && this.props.producto.imagenes.length > 0) ?
                                (axios.defaults.baseURL + '/upload/' + this.props.producto.imagenes[0].filename) : undefined} />
                        <Text strong>{this.props.producto.marca}</Text> &nbsp;
                    </Col>
                </Row>
                <Form layout="vertical" ref={this.ChangeProveedorProducto} onFinish={this.onFinish} >
                    <Form.Item
                        label="Proveedor"
                        name="proveedor_id"
                        rules={[
                            { required: true, message: "Ingrese el Proveedor" }
                        ]} >
                        <Select
                            labelInValue
                            showSearch
                            placeholder="Buscar"
                            optionFilterProp="children"
                            loading={loading}
                            onChange={({ value }) => {
                                this.setState({ proveedor_id: value })
                            }}
                            onSearch={search => this.getProveedores({ page_: 1, search })}
                            onPopupScroll={this.onPopupScrollProveedores}
                        >
                            {this.state.proveedores.data.map(({ _id, nombre, nombre_comercial, rfc }) => <Option key={_id} value={_id} title={nombre}>
                                {nombre}
                                <br />
                                <small>{nombre_comercial}</small><br />
                                <small>{rfc}</small>
                            </Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item className="center">
                        <Button htmlType="submit" type="primary" >
                            Guardar
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <ChangeProveedorProducto {...props} />
        </Modal>
    )
}
