import React, { Component } from "react";
import { Layout, PageHeader,  Col, Row, Tabs, message } from "antd";
import { CardVMIInfo } from "../../Widgets/Cards";
import '../../../Styles/Modules/Customer/customer.css'

import Almacenes from './Almacenes'
import ProductosList from './Productos'
import PagosList from './Pagos'
import ComprasList from './ComprasList'

const { Content } = Layout;
const { TabPane } = Tabs;
const axios = require('axios')
/**
 *
 *
 * @export
 * @class Comprar
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class Comprar extends Component {


    constructor(props) {
        super(props)
        this.state = {
            current: 0,
            productos: [],
            list: 5,

            monto_venta: 0,
            facturas_emitidas_monto: 0,
            facturas_cobradas: 0,
            pendiente_facturar_monto: 0,
            cliente: {}


        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getClienteInfo();
    }


    /**
   * @memberof  
   * @method getCliente
   * @description Obtiene un cliente de la DB
   */
    getClienteInfo = () => {

        axios.get('/vmi/getInfoClienteVMI', {
            params: {}
        })
            .then(({ data }) => {

                if (data.success) {
                    data = data.data;
                    this.setState({
                        cliente: data.cliente,
                        facturas_cobradas: data.facturas_cobradas,
                        facturas_emitidas: data.facturas_emitidas,
                        facturas_emitidas_monto: data.facturas_emitidas_monto,
                        monto_venta: data.monto_venta,
                        pendiente_facturar: data.pendiente_facturar,
                        pendiente_facturar_monto: data.pendiente_facturar_monto,
                        tipo: data.tipo,
                        venta_dia: data.venta_dia,
                        venta_mensual: data.venta_mensual,
                        actualizarTabla: 1
                    })
                }

            })
            .catch(error => {
                message.error('No se obtuvo la info cliente')
                console.log(error)
            })
    }


    render() {

        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={<Row className=" width-100 " justify="space-between" align="middle">
                        <Col span={22}>
                            <span className="ant-page-header-heading-title" >
                                Vendor-Managed Inventory
                            </span>
                        </Col>
                    </Row>
                    }
                />
                <Content className="content-bg pd-1" style={{minHeight: '79vh'}}>

                    <Row gutter={[8, 8]}>
                        <Col span={24} className="mb-1">
                            <CardVMIInfo
                                monto_venta={this.state.monto_venta}
                                facturas_emitidas={this.state.facturas_emitidas_monto}
                                facturas_cobradas={this.state.facturas_cobradas}
                                facturas_pendientes={this.state.pendiente_facturar_monto}
                                dias_credito={this.state.cliente?.dias_credito}
                                responsable={this.state.cliente?.responsable_id}
                            />
                        </Col>

                        <Col span={24}>
                            <Tabs defaultActiveKey="productos" destroyInactiveTabPane={true} className="tabs-inventario">
                                <TabPane tab="Productos" key="productos">
                                    <ProductosList search={this.props.search} />
                                </TabPane>
                                <TabPane tab="Almacenes" key="almacenes">
                                    <Almacenes search={this.props.search} />
                                </TabPane>
                                <TabPane tab="Pagos" key="pagos">
                                    <PagosList search={this.props.search} />
                                </TabPane>
                                <TabPane tab="Compras" key="compras">
                                    <ComprasList search={this.props.search} />
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Content>


            </>
        )
    }
}


