import React, { Component } from 'react';
import { Layout, Button, Col, Row, message, Card, PageHeader, List, Spin, Typography, Space, Form, DatePicker, Popover, Tooltip } from 'antd'
import { EyeOutlined, CheckOutlined, FileDoneOutlined, FilePdfOutlined } from "@ant-design/icons";


//componentes
import axios from 'axios'
import FloatingButton from '../../../Widgets/Floating Button/FloatingButton';
import { IconEdit } from '../../../Widgets/Iconos';

//modales
import ModalXML from './../../Syncfy/ModalXML';
import ModalFacturas from './ModalFacturas';
import ModalTimbrado from './ModalTimbrado';

import { GoFileCode } from "react-icons/go";
import { FaFileDownload } from 'react-icons/fa';

const moment = require('moment');
const { Text } = Typography;
const { Content } = Layout;


/**
 *
 *
 * @export
 * @class FacturasSC
 * @extends {Component}
 */
export default class FacturasSC extends Component {

    constructor(props) {
        super(props)
        this.state = {

            facturas: [],
            selectedFacturas: {},

            razon_social_id: this.props.match.params.razon_social_id,

            cuenta: {},
            vista_guardadas: false,
            update: true,

            filtroSearch: '',
            searching: true,
        }
    }

    /**
     *
     *
     * @memberof FacturasSC
     * 
     * @method componentDidMount 
     * @description Cargamos la informacion de las facturas
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getFacturas()
    }



    /**
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getFacturas();
            this.state.searching = false;
        }
    }


    /**
    * @memberof FacturasSC

    * @description  Lista las facturas de una cuenta, en un rango de fechas
    **/
    getFacturas = (page = this.state.page) => {
        this.setState({ loading: true });
        let search = this.props.search;
        axios.get('/facturas/list', {
            params: {
                search,
                page,
            }
        })
            .then(response => {
                this.setState({
                    facturas: response.data.data.itemsList,
                    page: response.data.data.paginator.currentPage,
                    total: response.data.data.paginator.itemCount,

                    filtroSearch: search,
                    searching: true,
                    loading: false
                })
            })
            .catch(error => {
                message.error('Error al Cargar las facturas')
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }

    renderTipo = (value) => {
        switch (value) {
            case 1:
                return "Ingreso"
            case 0:
                return "Sin cambios"
            case -1:
                return "Egreso"
        }
    }


    generarReporte = (values) => {

        if (!values.fecha) message.warning("Seleccione una fecha para generar el reporte")


        let fecha = values.fecha

        console.log(fecha)

        let fecha_inicio = fecha.startOf('month').toDate()
        let fecha_fin = fecha.endOf('month').toDate()

        this.setState({ reporteLoading: true })

        axios({
            url: '/facturas/reporte',
            method: 'GET',
            responseType: 'blob', // important
            params: {
                fecha_inicio,
                fecha_fin
            }
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Reporte de facturas ${fecha.format('MMMM YYYY')}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .finally(() => this.setState({ reporteLoading: false }))
    }

    /**
     *
     *
     * @param {*} item
     * @memberof FacturasSC
     * 
     * @description 
     */
    renderViews = (item) => {


        return <Space>
            <Button
                type="primary"
                icon={<IconEdit />}
                title="Editar"
                onClick={() => { this.setState({ modalFacturasVisible: true, factura_id: item._id }) }}
            />
            <Button
                type="primary"
                icon={<EyeOutlined style={{ color: "currentColor" }} />}
                onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
                title="Ver XML"
                disabled={!!item.factura_xml}
            ></Button>
            <Button
                type="primary"
                icon={<FilePdfOutlined style={{ color: "currentColor" }} />}
                onClick={() => window.open(`${axios.defaults.baseURL}/facturas/cfdi?factura_id=${item._id}&Authorization=${sessionStorage.getItem("token")}`, '_blank')}
                title="Ver PDF"
                disabled={!(item.tipo_facturacion === 1)}
            ></Button>
            <Button
                disabled={typeof item?.factura_xml !== "string"}
                icon={<GoFileCode style={{ color: "currentColor", fontSize: 25, position: "relative", top: 5 }} />}
                type="primary"
                target={"_blank"}
                download={true}
                href={`${axios.defaults.baseURL}/facturas/cfdi/xml?factura_id=${item._id}&Authorization=${sessionStorage.getItem("token")}`} />
        </Space>
        //     return <> 
        // </Button>
        // </>

        //     if (item.factura_xml) {
        //         return <> <Button
        //             type="primary"
        //             icon={<EyeOutlined style={{ color: "#fff" }} />}
        //             onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
        //             title="Ver XML" >
        //         </Button>
        //         </>
        //     } else {
        //         return <><Button
        //             type="primary"
        //             icon={<EyeOutlined />}
        //             disabled={true}
        //             title="Ver XML" />
        //         </>
        //     }
    }

    render() {
        const contentMenuPlus = (
            <div className="flex-column">
                <Button type="primary" className="width-100 mb-05" onClick={() => this.setState({ modalFacturasVisible: true })}>
                    Añadir Factura
                </Button>
                <Button type="primary" className="width-100" onClick={() => this.setState({ modalTimbradoVisible: true })}>
                    Timbrar Factura
                </Button>
            </div>
        );
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Facturas"
                        extra={
                            <Form layout='inline' onFinish={this.generarReporte}>
                                <Row>
                                    <Col span={18}>
                                        <Form.Item name={"fecha"}>
                                            <DatePicker picker='month' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item>
                                            <Tooltip placement="bottomRight" content="Generar reporte de facturas">
                                                <Button loading={this.state.reporteLoading} htmlType="submit" type="primary">
                                                    <FaFileDownload />
                                                </Button>
                                            </Tooltip>
                                        </Form.Item>
                                    </Col>
                                </Row>


                            </Form>
                        }
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Facturas " }}
                            dataSource={this.state.facturas}
                            pagination={{
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page) => this.getFacturas(page)
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >
                                <Col md={4} span={2} className="center">
                                    <Text ellipsis strong>Tipo</Text>
                                </Col>
                                <Col md={6} span={4} className="center">
                                    <Text ellipsis strong>Descripción</Text>
                                </Col>
                                <Col md={4} span={4} className="center">
                                    <Text ellipsis strong>Fecha</Text>
                                </Col>
                                <Col md={5} span={5} className="center">
                                    <Text ellipsis strong>Cantidad</Text>
                                </Col>
                                <Col md={5} span={5} className="center">
                                    <Text ellipsis strong>Acciones</Text>
                                </Col>
                            </Row>}
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <div className={`badge-card tipo-${item.estatus === 0 ? 2 : item.estatus === 1 ? 3 : 1}`}></div>
                                        <Row className="width-100 ">
                                            <Col md={4} span={2} className="center">
                                                <Text ellipsis>{this.renderTipo(item.tipo)}</Text>
                                            </Col>
                                            <Col md={6} span={8} className="center">
                                                <p style={{ width: '100%' }}>
                                                    <strong>{item.concepto}</strong><br />
                                                    <small>{item?.receptor?.rfc}</small>
                                                </p>
                                            </Col>
                                            <Col md={4} span={4} className="center">
                                                <Text ellipsis strong>{moment(item.fecha).format('DD-MM-YYYY')}</Text>
                                            </Col>
                                            <Col md={5} span={4} className="center">
                                                <Text ellipsis>$ {item.monto ? item.monto.toMoney(true) : '0.00'} <small>{item.moneda}</small></Text>
                                            </Col>
                                            <Col md={5} span={5} className="center">

                                                {this.renderViews(item)}

                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                        <FloatingButton
                            background="linear-gradient(126.63deg,#61f5c0 11.98%,#00ff38 83.35%)"
                            icon={<CheckOutlined style={{ fontSize: 50, color: "white" }} />}
                            title="Nuevo registro"
                            content={contentMenuPlus}
                        />
                    </Content>
                    <ModalXML
                        visible={this.state.ModalXMLVisible}
                        onCancel={() => this.setState({ ModalXMLVisible: false, factura_xml_storage: undefined, xml: undefined })}
                        factura_xml_storage={this.state.factura_xml_storage}
                    //factura_xml = {this.state.factura_xml_storage}
                    />
                    <ModalFacturas
                        visible={this.state.modalFacturasVisible}
                        onClose={() => {
                            this.setState({ modalFacturasVisible: false, factura_id: undefined })
                            this.getFacturas()
                        }}
                        factura_id={this.state.factura_id}
                    />
                    <ModalTimbrado
                        visible={this.state.modalTimbradoVisible}
                        onClose={() => {
                            this.setState({ modalTimbradoVisible: false, factura_id: undefined })
                            this.getFacturas()
                        }}
                    />
                </Spin>
            </>

        )
    }
}