import React, { Component } from 'react';
import { Layout, Button, Col, Popconfirm, Row, message, Card, PageHeader, List, Spin, Typography, Space } from 'antd'
import { IconDelete, IconEdit } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import axios from 'axios'
import FloatingButton from '../../Widgets/Floating Button/FloatingButton';
import ModalNegocios from './ModalNegocios';

const { Text } = Typography;
const { Content } = Layout;


export default class Negocios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            id: null,

            loading: true,
            modal_visible: false,
            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,

            filtroSearch: "",
            searching: true,
        }
    }


    /**
     *
     *
     * @memberof Negocios
     * 
     * @method componentDidMount 
     * @description Cargamos la informacion de los Negocios
     */

    componentDidMount() {
        this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getNegocios();
    }


    /**
     *
     *
     * @memberof Negocios
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getNegocios();
            this.state.searching = false;
        }
    }


    /**
         *
         * @memberof Negocios
         * @method getNegocios
         * @description Obtiene la informacion de los Negocios y actualiza los valores locales de la tabla.
         */
    getNegocios = async (page) => {
        this.setState({ loading: true })
        let search = this.props.search;
        axios.get('/negocios', {
            params: {
                page: page,
                limit: 10,
                
                search: search
            }
        })
            .then(res => {
                const data = res.data.data.itemsList;
                this.setState({
                    data: data,
                    loading: false,

                    currentPage: res.data.data.paginator.currentPage,
                    itemCount: res.data.data.paginator.itemCount,
                    perPage: res.data.data.paginator.perPage,
                    pageCount: res.data.data.paginator.pageCount,

                    searching: true,
                    filtroSearch: search
                }, () => console.log(this.state))

            })
            .catch(res => {
                console.log("error", res);
            });
    }


    /**
     * @methodOf  Negocios
     * @function showModal
     *
     * @description Muestra el modal del usuario
     *
     * */
    showModal = (id) => {
        this.setState({
            visible: true,
            id: id
        });
    };

    /**
     * @methodOf  Negocios
     * @function hideModal
     *
     * @description Oculta el modal del usuario
     *
     * */
    hideModal = () => {
        this.setState({
            visible: false,
            id: undefined
        });
    };


    render() {
        const { getNegocios, showModal, hideModal } = this;
        const { visible, currentPage, limit, pageCount,itemCount, data, id, loading } = this.state;
        const user = this.context;
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Negocios"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Negocios " }}
                            dataSource={data}
                            pagination={{
                                current: currentPage,
                                pageSize: 10,
                                total: itemCount,
                                showSizeChanger: false,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className:"flex-left"
                               
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >
                                <Col lg={{ span: 2 }}  className="center">
                                    <Text strong>logo</Text>
                                </Col>

                                <Col lg={{ span: 9 }}  className="center">
                                    <Text strong>nombre</Text>
                                </Col>

                                <Col lg={{ span: 9 }}  className="center">
                                    <Text strong>descripción</Text>
                                </Col>
                                <Col lg={{ span: 4 }}  className="center">
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col lg={{ span: 2 }} className="center">
                                                <CustomAvatar image={item.logo} name={item.nombre} color={item.color} />
                                            </Col>

                                            <Col lg={{ span: 9 }} className="center">
                                                <Text strong>{item.nombre}</Text>
                                            </Col>
                                            <Col lg={{ span: 9 }} className="center">
                                                <Text strong>{item.descripcion}</Text>
                                            </Col>
                                            <Col lg={{ span: 4 }} className="center">
                                                {(user.tipo != 3) ?
                                                    <Space>
                                                        <Button type="primary" icon={<IconEdit />} onClick={() => showModal(item._id)} />
                                                        <Popconfirm
                                                            placement="topRight"
                                                            title="¿Deseas eliminar este negocio?"
                                                            onConfirm={() => axios.delete('/negocios/delete', { params: { id: item._id } }).then((response) => {
                                                                message.success(response.data.message)
                                                                this.getNegocios()
                                                            })
                                                                .catch((error) => {
                                                                    message.error(error.response.data.message);
                                                                    this.getNegocios();
                                                                })
                                                            }
                                                            okText="Si"
                                                            cancelText="No"
                                                        >
                                                            <Button type="normal" danger icon={<IconDelete />} title="Eliminar" />
                                                        </Popconfirm>
                                                    </Space>
                                                    : null}
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                        <FloatingButton title="Nuevo registro" onClick={() => showModal()} />
                    </Content>
                </Spin>
                <ModalNegocios
                    visible={visible}
                    hideModal={hideModal}
                    accept={() => {
                        hideModal();
                        getNegocios();
                    }}
                    update={() => getNegocios()}
                    id={id}

                >

                </ModalNegocios>
            </>

        )
    }
}
