import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, } from 'antd';

import { SocketContext } from '../../../../Hooks/Socket';
import { User } from '../../../../Hooks/Logged';

const { Title } = Typography;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalRecotizar
 * @extends {Component}
 */
class ModalRecotizar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            return: false,
            premium: false
        }
    }

    ModalRecotizar = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
     * @memberof Cotizaciones
     * @method updateCotizacion 
     * @description Actualizamos la cotizacion, solamente el campo de status
     */
    updateCotizacion = (values) => {
        this.setState({ loading: true })
        axios.put('/cotizacion/update', {
            
            id: this.props.cotizacion_id,
            status: 5,
            motivos: values.motivos

        }).then(response => {
            message.success('Cotizacion Actualizada')

            if(this.props.socket?.connected){
                console.log('Socket corriendo')
                let notificacion = {
                    evento: `El cliente ha solicitado recotizar`,
                    detalle: `El cliente ha solicitado recotizar de la orden ${this.props.folio}`,
                    usuarios_destino: [],
                    cliente: true,
                    usuario_id: this.props.user._id
                }
                this.props.socket.emit('nueva_notificacion', notificacion)
            }

            this.props.onClose()
        }).catch(error => {
            let msg = 'Error al Actualizar la Cotizacion'
            console.log(error)
            if (error.response?.status === 403)
                msg = error.response?.data?.message
            message.error(msg)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {


        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    onFinish={this.updateCotizacion}
                >
                    <Row className="mt-1">
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Descripción"
                                name="motivos"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la razón"
                                }]}
                            >
                                <Input.TextArea placeholder="Describe de forma detallada la razón de la recotización" className="width-100" rows={5} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Aceptar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    const socket = React.useContext(SocketContext)
    const user = React.useContext(User)

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Razón para Recotizar </Title>
        </div>
        <ModalRecotizar {...props} socket={socket} user={user}/>
    </Modal>

}