import React from 'react'
import { Route, Switch } from "react-router-dom";


// componentes
import Contabilidad from '../components/Admin/Contabilidad/Contabilidad'
import RazonSocial from '../components/Admin/Contabilidad/RazonSocial'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterContabilidad(props) {

    return (
        <Switch>
            <Route exact path="/admin/contabilidad" render={props => <Contabilidad {...props} />} />
            <Route exact path="/admin/contabilidad/razon-social/:razon_social_id" render={props => <RazonSocial  {...props} />} />
        </Switch>
    )
}

export default RouterContabilidad;
