import React, { Component } from 'react';
import { Button, Popconfirm, Tooltip, Space, Card, Row, List, Col, Spin, PageHeader, Layout, message, Typography } from 'antd'
import axios from 'axios';

//Componentes
import { IconDelete, IconEdit, IconArrowBack } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalAlmacenProducto from "./ModalAlmacenProducto";


const { Content } = Layout;
const { Text } = Typography

/**
 * @export
 * @class Productos
 * @extends {Component}
 * @description Vista de Productos
 */
export default class AlmacenProductos extends Component {

    back = () => {
       this.props.history.goBack();
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            almacen_id: this.props.match.params.almacen_id,
            inventario_id: this.props.match.params.inventario_id,
            almacen_producto_id: undefined,
            almacen: {
                direccion: ''
            },
            productos: {
                data: [],
                page: 1,
                limit: 10,

                total: 0,
                pages: 0
            }
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getAlmacen()
        this.getAlmacenProductos()

    }


    /**
     * @memberof AlmacenProductos
     * @method getAlmacen
     * @description Obtiene un almacen de la DB
     */
    getAlmacen = () => {
        axios.get('/almacenes/get', {
            params: {
                id: this.state.almacen_id
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState({ almacen: response.data.data })

        }).catch(error => {
            message.error('Error al traer el almacen')
            console.log(error)
        })
    }

    /**
     * @memberof AlmacenProductos
     * @method getAlmacenProductos
     * @description Obtiene un almacen de la DB
     */
    getAlmacenProductos = ({

        page = this.state.productos.page,
        limit = this.state.productos.limit

    } = this.state.productos) => {

        this.setState(state => {
            state.productos.page = page;
            state.productos.limit = limit;
            return state;
        })
        return axios.get('/almacenes/productos/list', {
            params: {
                almacen_id: this.state.almacen_id,
                page, 
                limit
            }
        }).then(response => {
            console.log('getAlmacenProductos', response.data.data)
            this.setState(state => {

                // totalDocs: 'total',
                // docs: 'data',
                // limit: 'limit',
                // page: 'page'

                state.productos.data = response.data.data.data;
                state.productos.total = response.data.data.total;
                state.productos.pages = response.data.data.pages;
                return state;
            })

        }).catch(error => {
            message.error('Error al traer el almacen')
            console.log(error)
        })
    }


    render() {

        const { almacenProductos } = this.state

        return (
            <Spin spinning={false}>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={ <div className="flex-left">
                                <Button ghost onClick={this.back} style={{ border: "none" }}>
                                    <IconArrowBack />
                                </Button>
                                {`Productos del Almacén - ${this.state.almacen.direccion}`}
                            </div>
                        }
                />
                <Content className="admin-content content-bg pd-1">
                    <List
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Productos " }}
                        dataSource={this.state.productos.data}
                        pagination={{
                            onChange: (page, limit) => {
                                // console.log({page, limit})
                                this.getAlmacenProductos({ page, limit })
                            },
                            current: this.state.productos.page,
                            total: this.state.productos.total,
                            showSizeChanger:true,
                            pageSize: 10,
 

                            hideOnSinglePage: false,
                            position: 'bottom',
                            className: "flex-left"
                        }}
                        header={<Row className="header-list width-100 pr-1 pl-1" >

                            <Col lg={8} className="center">
                                <Text strong>Producto</Text>
                            </Col>
                            <Col lg={6} className="center">
                                <Text strong>Cantidad</Text>
                            </Col>
                            <Col lg={6} className="center">
                                <Text strong>Ubicación</Text>
                            </Col>
                            <Col lg={4} className="center">
                                <Text strong>Acciones</Text>
                            </Col>
                        </Row>
                        }

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">
                                        <Col lg={8} className="center">
                                            <Text>{item.inventario_producto_id?.producto_id?.np_fabricante}</Text>
                                        </Col>
                                        <Col lg={6} className="center">
                                            <Text>{item.cantidad}</Text>
                                        </Col>
                                        <Col lg={6} className="center">
                                            <Text>{item.ubicacion}</Text>
                                        </Col>
                                        <Col lg={4} className="center">
                                            <Space>
                                                <Tooltip title="Editar">

                                                    <Button type="primary" icon={<IconEdit />} onClick={() => this.setState({
                                                        modalAlmacenProducto: true,
                                                        almacen_producto_id: item._id,
                                                    })} />
                                                </Tooltip>

                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar este Producto  del Almacen?"
                                                    onConfirm={() => axios.post('/almacenes/productos/delete', { id: item._id })
                                                        .then((response) => {
                                                            message.success("Eliminado Correctamente")
                                                            this.getAlmacenProductos()
                                                        })
                                                        .catch((error) => {
                                                            message.error("No es posible eliminar")
                                                            this.getAlmacenProductos();
                                                        })
                                                    }
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Tooltip title="Eliminar">
                                                        <Button type="normal" danger icon={<IconDelete />} title="Eliminar" />
                                                    </Tooltip>
                                                </Popconfirm>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalAlmacenProducto: true })} />
                </Content>
                <ModalAlmacenProducto
                    id={this.state.producto_id}
                    visible={this.state.modalAlmacenProducto}
                    onCancel={() => {
                        this.setState({ modalAlmacenProducto: false, almacen_producto_id: undefined })
                        this.getAlmacenProductos()
                    }}
                    almacen_id={this.state.almacen_id}
                    inventario_id={this.state.inventario_id}
                    almacen_producto_id={this.state.almacen_producto_id}
                />
            </Spin>
        )
    }
}