import React, { Component } from 'react';
import { Button, Popconfirm, Spin, Table, PageHeader, Layout, message, Space } from 'antd'
import axios from 'axios';
//Componentes
import { IconDelete, IconEdit } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

import ModalClasificadores from './ModalClasificadores'

const { Content } = Layout;

/**
 * @export
 * @class Clasificadores
 * @extends {Component}
 * @description Vista de Clasificadores
 */
export default class Clasificadores extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            clasificador_id: undefined,
            data: [],

            page: 1,
            total: 0,

            filtroSearch: '',
            searching: true,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getClasificadores();
    }


    /**
  *
  *
  * @memberof Proveedores
  * 
  * @method componentDidUpdate
  * @description Actualiza la vista si se buscan datos
  */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getClasificadores();
            this.state.searching = false;
        }
    }

    getClasificadores = (page = this.state.page) => {

        let search = this.props.search;
        this.setState({loading: true})
        axios.get('/clasificadores/list', {
            params: {
                page: page,
                search
            }
        }).then(response => {
            this.setState({
                data: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,

                searching: true,
                filtroSearch: search
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Clasificadores"
                    />

                    <Content className="admin-content content-bg pd-1">

                        <Table
                            scroll={{ x: 1000, y: 1000 }}
                            columns={[
                                {
                                    title: 'Color',
                                    key: 'avatar',
                                    width: '100px',
                                    render: (text, { nombre, apellido, logo, color }) => <CustomAvatar color={color} />,

                                },
                                {
                                    title: 'Nombre',
                                    dataIndex: 'nombre',
                                    render: (text, record) => (
                                        <h3>{record.nombre}</h3>
                                    ),
                                },

                                {
                                    title: 'Descripción',
                                    dataIndex: 'descripcion',
                                    key: 'descripcion',
                                },

                                {
                                    title: 'Acciones',
                                    key: 'actions',
                                    fixed: 'right',
                                    width: '210px',
                                    render: (text, record) => (
                                        <Space>
                                            <Button

                                                type="primary"
                                                icon={<IconEdit />}
                                                title="Editar"
                                                onClick={() => { this.setState({ modalVisible: true, clasificador_id: record._id }) }}
                                            />
                                            <Popconfirm
                                                placement="topRight"
                                                title="¿Deseas eliminar esta clasificador?"
                                                onConfirm={() => axios.post('/Clasificadores/delete',
                                                    { id: record._id }).then(() => {
                                                        this.getClasificadores()
                                                        message.success('clasificador eliminado')
                                                    }).catch(error => console.log(error))}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button
                                                    type="primary"
                                                    icon={<IconDelete />}
                                                    title="Eliminar"
                                                    danger
                                                />
                                            </Popconfirm>
                                        </Space>
                                    ),
                                },
                            ]}
                            dataSource={this.state.data}
                            pagination={{
                                onChange: this.getClasificadores,
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                position: ['bottomLeft'],
                            }}
                            loading={this.state.loading}
                            locale={{ emptyText: 'Sin Clasificadores disponibles' }}
                             />

                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />


                    <ModalClasificadores
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, clasificador_id: undefined })
                            this.getClasificadores()
                        }}
                        id={this.state.clasificador_id}
                    />
                </Spin>
            </>
        )
    }
}