import React from 'react'
import { Route, Switch } from "react-router-dom";


// componentes
import Dashboard from '../../components/Customer/MisPedidos/Dashboard'
import Layout from '../../components/Customer/MisPedidos/Layout'

/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterMisPedidos(props) {

    return (
        <Switch>
            <Route exact path="/customer/misPedidos" render={() => <Dashboard {...props} />} />
            <Route path="/customer/misPedidos/:orden_id" render={(props2) => <Layout {...props} {...props2}/>}/>

        </Switch>
    )
}

export default RouterMisPedidos;
