import React, { useState, useContext, useEffect } from 'react';
import { Button, Layout, Row, Col, Typography, Steps, Carousel, message } from 'antd';

import { Redirect } from "react-router-dom";


import '../../Styles/Global/landing.scss';
import '../../Styles/Modules/Public/marketplace.scss';

const axios = require("axios").default;
const { Content, } = Layout;
const { Title, Paragraph } = Typography;


/**
 *
 * @class Marketplace
 * @extends {Component}
 * @description Pagina de nexus para realizar un spot buy
 */

export default function Support() {



    return (
        <Content className="marketplace-content  " style={{ background: "url('/images/bg-3.jpg')", backgroundSize: 'cover', overflow: 'initial', backgroundAttachment: "fixed" }} >
            <Row justify="center" >
                <Col
                    style={{ width: "100%", maxWidth: 1000, margin: "3rem 0" }}
                    className="marketplace-form"
                >
                    <Row >
                        <Col>
                            <Title level={2}>Términos & Condiciones</Title>
                            <Paragraph>Fecha de última actualización: 29 de marzo de 2022</Paragraph>
                        </Col>
                        <Col>
                            <Paragraph>
                                Esta aplicación de software (la “Aplicación”) es proporcionada por Nexus Nova Trading Company S.A. de C.V. 
                                Los servicios que le son proporcionados a usted por Nexus Nova, mediante la Aplicación 
                                (los “Servicios de la Aplicación”), le permiten, a través del dispositivo autorizado Apple o Android de su 
                                propiedad o que usted controla (su “Dispositivo”), consultar y registrar información referente a la operación 
                                y administración diaria de la empresa.
                            </Paragraph>
                            <Paragraph>
                                Los Servicios de la Aplicación podrán estar disponibles mientras su teléfono cuente con conexión a internet. 
                                Más detalles y limitaciones de los Servicios de la Aplicación y del funcionamiento de la misma, se encuentran 
                                disponibles en <a href={"https://nexusnova.mx"}>https://nexusnova.mx</a>
                            </Paragraph>
                            <Paragraph>
                                Cuando usted da click para aceptar los siguientes términos (los “Términos”) o cuando accede o utiliza los 
                                Servicios de la Aplicación en su Dispositivo, usted acuerda y acepta lo siguiente:
                            </Paragraph>
                            <ul>
                                <li><Paragraph>
                                    Estos Términos, incorporan los Términos y Condiciones de Uso de los Servicios Nexus Nova, ubicados en <a href={"https://nexusnova.mx"}>https://nexusnova.mx</a> 
                                    (los “Términos y Condiciones de Uso de los Servicios Nexus Nova”). Estos Términos junto con los Términos y Condiciones de 
                                    Uso de los Servicios Nexus Nova, son obligatorios entre usted y nosotros, y rigen su acceso y uso de los Servicios de la 
                                    Aplicación. Para estos efectos, el término “Servicios” utilizado dentro de los Términos y Condiciones de Uso de los Servicios 
                                    Nexus Nova, usted deberá revisar cuidadosamente está información antes de acceder a, o utilizar los Servicios de la 
                                    Aplicación; asimismo para cualquier duda o sugerencia lo invitamos a contactar a <a href={"mailto:soporte@nexusnova.com.mx"}>soporte@nexusnova.com.mx</a> donde nos pondremos 
                                    a sus ordenes para resolver cualquier duda o sugerencia que usted pudiera tener.
                                </Paragraph></li>
                                <li><Paragraph>
                                    Usted solamente usará los Servicios de la Aplicación cuando sea seguro hacerlo, en cumplimiento con la ley y estos Términos, y no usará los Servicios de la Aplicación mientras conduce;
                                </Paragraph></li>
                                <li><Paragraph>
                                    Usted no usará los Servicios de la Aplicación para acosar o dañar a persona alguna, o de forma indebida, ilegal o con un propósito no autorizado.
                                </Paragraph></li>
                                <li><Paragraph>
                                    Usted podrá cancelar los Servicios de la Aplicación en cualquier momento, contactándonos a la cuenta de correo <a href={"mailto:soporte@nexusnova.com.mx"}>soporte@nexusnova.com.mx</a>. Asimismo, podrá cancelar los Servicios a través de la simple Desinstalación de la Aplicación de su Dispositivo;
                                </Paragraph></li>
                                <li><Paragraph>
                                    Si se presenta algún conflicto, lo resolveremos mediante un arbitraje individual;
                                </Paragraph></li>
                                <li><Paragraph>
                                    Nosotros no somos responsables por los actos llevados a cabo por terceros que puedan acceder a los Servicios de la Aplicación y a la información a través de su Dispositivo. Usted deberá utilizar todas las funciones de seguridad de su Dispositivo, incluyendo cualquier contraseña, bloqueo, o función de encriptado, a fin de protegerse en contra de accesos no autorizados y del uso de los Servicios de la Aplicación y de su información;
                                </Paragraph></li>
                                <li><Paragraph>
                                    El uso de los Servicios de la Aplicación por parte de usted, podrá involucrar servicios que sean puestos a su disposición a través de terceros, como los servicios de Internet inalámbrico que sean proporcionados a su Dispositivo, por su operador de telefonía inalámbrica, y servicios de datos.   
                                </Paragraph></li>
                            </ul>
                            <Paragraph>
                            Estos servicios de terceros, promociones y ofertas, y cualquier otra transacción relacionada que usted contrate, serán entre usted y el tercero, únicamente. Nosotros no le garantizamos el cumplimiento, la confiabilidad o la seguridad, y no somos responsables de ninguna pérdida o daño que pueda ser ocasionado, tales como una pérdida de información personal a través de servicios de Internet de terceros;
                            </Paragraph>
                            <ul>
                                <li><Paragraph>
                                Nexus Nova le otorga a usted, una licencia no comercial, no exclusiva, intransferible, limitada, y rescindible, para usar la Aplicación y los Servicios de la Aplicación, únicamente con su Dispositivo, dentro de los Estados Unidos Mexicanos, sujeto al cumplimiento de reglas y normas establecido por la compañía y que podrá consultar en la plataforma de <a href={"https://nexusnova.mx"}>https://nexusnova.mx</a>. La Aplicación se le otorga únicamente bajo licencia y alta o registro de una cuenta o usuario, no se le está vendiendo y es únicamente para su uso personal. Nexus Nova se reserva todos los derechos, títulos e intereses en y sobre la Aplicación, los Servicios de la Aplicación y toda la información relacionada, incluyendo los derechos de propiedad intelectual, derechos de propiedad e intereses;
                                </Paragraph></li>
                                <li><Paragraph>
                                En caso de cualquier conflicto entre estos Términos y los Términos y Condiciones de Uso de los Servicios OnStar, estos Términos regirán cualquier acceso o uso de los Servicios de la Aplicación. La referencia a “Dispositivo Conectado” en la Sección 13.1 de los Términos y Condiciones de Uso de los Servicios Nexus Nova, no aplicará a su Dispositivo; y
                                </Paragraph></li>
                                <li><Paragraph>
                                Si su Dispositivo, es un dispositivo de Apple, entonces, el siguiente párrafo le aplica: Usted está celebrando el Contrato, incluyendo estos Términos, con Nexus Nova y no con Apple Inc. (“Apple”). Sin embargo, Apple y sus subsidiarias son terceros beneficiarios del Contrato, y Apple podrá ejecutar el Contrato. Nosotros somos únicamente responsables, como se indica en estos Términos, de la Aplicación y la información asociada a la misma; Apple no da garantía alguna con respecto a la Aplicación o a la información y no tiene obligación alguna de proporcionar mantenimiento o servicios de soporte alguno para la Aplicación. Apple no es responsable de hacer frente a ninguna reclamación relacionada con la Aplicación o la información asociada a la misma o la posesión o uso de la Aplicación, incluyendo, sin limitar: (i) reclamaciones por responsabilidad del producto; (ii) cualquier reclamación derivada de que la Aplicación no se ajuste a los requisitos legales o regulatorios, y (iii) reclamaciones que se deriven de las legislaciones sobre protección al consumidor o similares. Si un tercero, reclama que la Aplicación, la información asociada a la misma, o la posesión o uso de la Aplicación o información asociada por parte de usted, infringe derechos de propiedad intelectual de terceras personas, Apple no es responsable por la investigación, defensa, acuerdo y desechamiento de ninguna reclamación por infringir derechos de propiedad intelectual. Usted acepta que ha revisado los Términos de la App Store, disponibles en http://www.apple.com/legal/itunes/us/terms.html#APPS y que deberá cumplir con dichos términos.
                                </Paragraph></li>
                            </ul>
                            <Paragraph>
                            Si tiene preguntas, comentarios o quejas en relación con los Servicios de la Aplicación, por favor contáctenos en cualquier momento a través de nuestro correo electrónico <a href={"mailto:soporte@nexusnova.com.mx"}>soporte@nexusnova.com.mx</a> o mandandanos un mensaje a través del chat interno que podrá localizar en la plataforma de <a href={"https://nexusnova.mx"}>https://nexusnova.mx</a>
                            </Paragraph>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Content>

    )

}