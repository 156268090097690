import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'
import { Table, InputNumber, Typography, Space, Image, Form, message, Alert } from 'antd';
import { EditableProTable } from "@ant-design/pro-table";

import ModalProveedor from './ModalProveedor'

import Decimal from 'decimal.js';

const { Text } = Typography;
const EditableContext = React.createContext(null);

export default function TableMatrizCostos(props) {

    const { productos } = props;
    let [editableKeys, setEditableKeys] = useState([]);
    let [dataSource, setDataSource] = useState([]);
    let [proveedorId, setProveedorId] = useState(undefined);
    let [ordenDetallesId, setOrdenDetallesId] = useState(undefined);
    let [modalVisible, setModalVisible] = useState(false);
    

    const [form] = Form.useForm();

    /**
     *
     *
     * @memberof TableMatrizCostos
     * @method handleSave
     * @description Actualiza los datos del state para el row y celda editado. Se agregan y quitan perdidas segun los valores unitarios.  
     * @param {*} row Contiene el objeto del row editado
    //  */
    const handleSave = (row) => {


        //
        row.costo = new Decimal(row.costo)//parseFloat(row.costo);
        row.importacion = new Decimal(row.importacion) //parseFloat(row.importacion);
        row.fletes = new Decimal(row.fletes) //parseFloat(row.fletes); 
        row.otros = new Decimal(row.otros) //parseFloat(row.otros); 
        row.precio_venta_unitario = new Decimal(row.precio_venta_unitario ?? 0) //parseFloat(row.precio_venta_unitario);
    
        //calculamos los costos
        
        let costo_total = row.costo.mul(row.cantidad).add(row.importacion).add(row.fletes).add(row.otros) //.div(row.cantidad)
        let costo_unitario = costo_total.div(row.cantidad)
        let total_venta = row.precio_venta_unitario.mul(row.cantidad) //(row.precio_venta_unitario * row.cantidad).toMoney();

        row.costo_unitario = props.tipo_orden === 2 ? row.costo : costo_unitario;
        row.costo_total = props.tipo_orden === 2 ? row.costo.mul(row.cantidad_faltante) : costo_total;

        row.total_venta = props.tipo_orden === 2 ? row.precio_venta_unitario.mul(row.cantidad_faltante) : total_venta; // Se tiene que revisar este punto

        row.utilidad_unitaria = row.precio_venta_unitario.sub(row.costo_unitario)
        row.utilidad_total = row.total_venta.sub(row.costo_total)


        if(row.costo_unitario.toNumber() >= row.precio_venta_unitario.toNumber() && !props.perdidas.includes(row._id)){
            props.setPerdidas([
                ...props.perdidas,
                row._id
            ])
        }
        
        if(row.costo_unitario.toNumber() < row.precio_venta_unitario.toNumber() && props.perdidas.includes(row._id)){
            let perdidas = props.perdidas.filter(perdida => perdida !== row._id)
            props.setPerdidas(perdidas)
        }

        row.costo = row.costo.toDecimalPlaces(2).toNumber()
        row.importacion = row.importacion.toDecimalPlaces(2).toNumber()
        row.fletes = row.fletes.toDecimalPlaces(2).toNumber()
        row.otros = row.otros.toDecimalPlaces(2).toNumber()
        row.precio_venta_unitario = row.precio_venta_unitario.toDecimalPlaces(2).toNumber()

        row.costo_unitario = row.costo_unitario.toDecimalPlaces(2).toNumber()
        row.costo_total = row.costo_total.toDecimalPlaces(2).toNumber()

        //calculamos la venta y la utilidad
        row.total_venta = row.total_venta.toDecimalPlaces(2).toNumber()

        row.utilidad_unitaria = row.utilidad_unitaria.toDecimalPlaces(2).toNumber()
        row.utilidad_total = row.utilidad_total.toDecimalPlaces(2).toNumber()

        return row;
    };


    /**
     *
     *
     * @memberof TableMatrizCostos
     * @method updateMatriz
     * @description Actualiza la matriz detalle de la orden detalle
     */
    const updateMatriz = (row) => {
        axios
            .post("/project-manager/update/matriz/detalle", {
                ...row,
            })
            .then((response) => {
                console.log("Matriz guardada");
            })
            .catch((error) => {
                console.log(error);
                message.error("Error al guardar la matriz");
            });
    };

    /**
     *
     *
     * @memberof TableMatrizCostos
     * @method updateMatriz
     * @description Al actualizar un valor, actualizamos toda la fila.
     */
    const onValuesChange = async (row, list) => {

        try{
            row = await handleSave(row);
            let temp = {};
            temp[row._id] = row;
            form.setFieldsValue(temp);
            
            //Actualizamos en la BD
            updateMatriz(row);

            let index = list.findIndex(elm => elm._id.toString() === row._id)

            if(index !== -1){
                list.splice(index, 1 , row)
                //calculamos el margen de ganacia
                let margen = 0, ingresos = 0, utilidad = 0, costos = 0

                for(const orden_detalle of list){
                    ingresos += orden_detalle.total_venta
                    costos += orden_detalle.costo_total
                }

                margen = ((ingresos - costos) / ingresos) * 100

                props.setMargen(margen)
            }

        }catch(e){
            console.log("e", e);

        }
        


    }


    
    let columns = [
        {
            width: "280px",
            dataIndex: "extra",
            key: "extra",
            fixed: "left",
            renderFormItem: (text, { record }, extras) => {
                let rfq_detalle_id = text.entry?.rfq_detalle_id;
                let producto = text.entry?.producto_id;
                let cantidad = text.entry?.cantidad;

                let orden = text?.entry;
                return (
                    <Space>
                        <Image
                            width={80}
                            src={
                                producto?.imagenes?.length > 0
                                    ? `${axios.defaults.baseURL}/upload/${producto?.imagenes[0]?.filename}`
                                    : `/images/nofile.png`
                            }
                            className="product-image"
                            preview={true}
                        />
                        <Space direction="vertical" size={0}>
                            <Text className="product-text-name">
                                {producto
                                    ? producto.modelo
                                    : ""}
                            </Text>
                            
                            <Text className="product-text-name">
                                Cant. {(props.orden?.tipo_orden && props.orden?.tipo_orden == 2) ? orden?.cantidad_faltante : cantidad }
                            </Text>
                            <Text className="product-text">
                                {producto
                                    ?
                                        producto.marca
                                    :
                                        ""}
                            </Text>
                            <Text className="product-text">
                                {
                                    producto?.descripcion?.length > 82 ?
                                    producto?.descripcion?.slice(0, 78) + "..." :
                                    producto?.descripcion
                                }
                            </Text>
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: "Proveedor",
            dataIndex: "proveedor_id",
            key: "proveedor_id",
            width: "180px",
            renderFormItem: (item, { record }, extra) => {
                if(props.tipo_orden === 2){
                    return (
                        <>
                            <span className="text-center">
                                {[
                                        <strong>{item.entry.proveedor_id?.nombre}</strong>,
                                        <br />,
                                        item.entry.proveedor_id?.nombre_comercial,
                                    ]}
                            </span><br/><br/>
                            <Link 
                                onClick={() => {
                                    console.log({item})
                                    setProveedorId(item.entry.proveedor_id?._id)
                                    setOrdenDetallesId(item.entry._id)
                                    setModalVisible(true)
                                }} 
                                style={{fontWeight: 600}}
                            >
                                Cambiar
                            </Link>
                        </>
                    )
                }

                return(
                    <span className="text-center">
                        {record
                            ? [
                                <strong>{record.proveedor_id?.nombre}</strong>,
                                <br />,
                                record.proveedor_id?.nombre_comercial,
                            ]
                            : "Sin Proveedor"}
                    </span>
                )
            },
        },
        {
            title: "Costo Uni. Proveedor",
            dataIndex: "costo",
            key: "costo",
            width: "140px",
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: "Ingrese el Costo Unitario",
                    },
                ],
                //trigger: "onBlur",
            },
            renderFormItem: (
                row,
                { type, defaultRender, record, ...rest },
                form
            ) => {
                
                // form.setFieldsValue({
                //     costo: row.entry.info_matriz.costo
                // })

                return(
                <InputNumber
                    controls={false}
                    formatter={(value) =>
                        `$ ${value.replace("$", "").replace(" ", "")}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                        )
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    className="width-100"
                    {...rest}
                    min={0}
                    // onChange={(value) =>
                    //     this.updateValue("costo", value, form, record)
                    // }
                />
               
            )},
        },
        {
            title: "Importacion",
            dataIndex: "importacion",
            key: "importacion",
            width: "140px",
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: "Ingrese el costo de la Importacion",
                    },
                ],
                //trigger: "onBlur",
            },
            renderFormItem: (
                row,
                { type, defaultRender, record, ...rest },
                form
            ) => (
                <InputNumber
                    defaultValue={row.entry.costo_total}
                    controls={false}
                    formatter={(value) =>
                        `$ ${value.replace("$", "").replace(" ", "")}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                        )
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    className="width-100"
                    {...rest}
                    min={0}
                    // onChange={(value) =>
                    //     this.updateValue("importacion", value, form, record)
                    // }
                />
            ),
        },
        {
            title: "Fletes",
            dataIndex: "fletes",
            key: "fletes",
            width: "140px",
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: "Ingrese el costo de los Fletes",
                    },
                ],
                //trigger: "onBlur",
            },
            renderFormItem: (
                _,
                { type, defaultRender, record, ...rest },
                form
            ) => (
                <InputNumber
                    controls={false}
                    formatter={(value) =>
                        `$ ${value.replace("$", "").replace(" ", "")}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                        )
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    className="width-100"
                    {...rest}
                    min={0}
                    // onChange={(value) =>
                    //    this.updateValue("fletes", value, form, record)
                    // }
                />
            ),
        },
        {
            title: "Otros",
            dataIndex: "otros",
            key: "otros",
            width: "140px",
            formItemProps: {
                //trigger: "onBlur",
                rules: [{ required: true, message: "Ingrese Otros costos" }],
            },
            renderFormItem: (
                _,
                { type, defaultRender, record, ...rest },
                form
            ) => (
                <InputNumber
                    acontrols={false}
                    formatter={(value) =>
                        `$ ${value.replace("$", "").replace(" ", "")}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                        )
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    className="width-100"
                    {...rest}
                    min={0}
                    // onChange={(value) =>this.updateValue("otros", value, form, record)}
                />
            ),
        },
        {
            title: "Costo Unitario",
            dataIndex: "costo_unitario",
            key: "costo_unitario",
            width: "140px",
            renderFormItem: (
                _,
                { type, defaultRender, record, ...rest },
                form
            ) => {
                return (
                    <span>
                        $ {parseFloat(record.costo_unitario).toMoney(true)}
                    </span>
                );
            },
        },
        {
            title: "Costo total",
            dataIndex: "costo_total",
            key: "costo_total",
            width: "140px",
            renderFormItem: (item, { record }, extra) => {
                return (
                    <span>
                        $ {record ? record.costo_total?.toMoney(true) : 0.0}
                    </span>
                );
            },
        },
        {
            title: "Precio de Venta unitario",
            dataIndex: "precio_venta_unitario",
            key: "precio_venta_unitario",
            width: "140px",
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: "Ingrese el Precio de venta por unidad",
                    },
                    ({ getFieldsValue }) => ({
                        validator(_, value) {
                            let { costo_unitario  } = getFieldsValue()[_.field.split('.')[0]]
                            
                            if (value > costo_unitario ) 
                                return Promise.resolve();
                            return Promise.reject(new Error("¡El precio de venta debe de ser mayor al costo unitario!"));
                        },
                    }),
                ],
                //trigger: "onBlur",
            },
            renderFormItem: (
                item,
                { type, defaultRender, record, ...rest },
                form
            ) => (
                props.tipo_orden === 2 && !item.entry.nuevo_proveedor ? <span>
                        $ {record ? record.precio_venta_unitario?.toMoney(true) : 0.0}
                    </span>: <InputNumber
                    min={0}
                    controls={false}
                    formatter={(value) => `$ ${value.replace("$", "").replace(" ", "")}`.replace(/\B(?=(\d{3})+(?!\d))/g,",")}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    className="width-100"
                    {...rest}
                />
            ),
        },
        {
            title: "Venta Total",
            dataIndex: "total_venta",
            key: "total_venta",
            width: "140px",
            renderFormItem: (item, { record }, extra) => {
                return (
                    <span>
                        $ {record ? record.total_venta?.toMoney(true) : 0.0}
                    </span>
                );
            },
        },
        {
            title: "Utilidad Unitaria",
            dataIndex: "utilidad_unitaria",
            key: "utilidad_unitaria",
            width: "140px",
            renderFormItem: (item, { record }, extra) => {
                return (
                    <span>
                        ${" "}
                        {record ? record.utilidad_unitaria?.toMoney(true) : 0.0}
                    </span>
                );
            },
        },
        {
            title: "Utilidad Total",
            dataIndex: "utilidad_total",
            key: "utilidad_total",
            width: "140px",
            renderFormItem: (item, { record }, extra) => {

                return (
                    <span>
                        $ {record ? record?.utilidad_total?.toMoney(true) : 0.0}
                    </span>
                );
            },
        }
    ];

    useEffect(() => {
        if (productos !== dataSource) {
            setEditableKeys(productos.map((p) => p._id));
            setDataSource(productos);
        }   
    });

    return (
        <>
            <EditableProTable
                headerTitle=""
                columns={columns.filter((col, index) => {
                    if(props.tipo_orden === 2){
                        if(index === 3 || index === 4 || index === 5 || index === 6)
                            return false
                        else
                            return true
                    }else{
                        return true
                    }
                })}
                rowKey="_id"
                value={dataSource}
                recordCreatorProps={{
                    style: { display: "none" },
                }}
                scroll={{ x: 1050 }}
                editable={{
                    form,
                    type: "multiple",
                    onValuesChange,
                    editableKeys: editableKeys,
                }}
            />
            <ModalProveedor
                proveedor_id={proveedorId}
                orden_detalles_id={ordenDetallesId}
                visible={modalVisible}
                onClose={() => {
                    setProveedorId(undefined)
                    setOrdenDetallesId(undefined)
                    setModalVisible(false)
                    props.getOrden()
                }}
            />
        </>
    );
}
