

import React, { Component } from "react";
import { Popconfirm, Layout, PageHeader, Button, Col, Row, List, Form, Radio, Typography, Avatar, Tag, InputNumber, message, Tooltip, Menu, Dropdown, Modal, Card, Collapse, Space, Image, Empty, Divider } from "antd";
import { ClockCircleOutlined, DollarOutlined, PlusOutlined, EllipsisOutlined, ExclamationCircleOutlined, DeleteOutlined, EditOutlined, CopyOutlined, UsergroupAddOutlined } from '@ant-design/icons';



import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import StepTracker from "../Secciones/StepTracker";
import InputProducto from "../../../Widgets/Inputs/InputProducto";
import { CardEntrega, CardProjectInfo, CardTaskProgress } from "../../../Widgets/Cards";
import { IconArrowBack } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

// modales
import ModalProveedor from "./ModalProveedor";
import ModalAgregarProductos from "./ModalAgregarProductos";
import ModalMotivos from "../ModalMotivos";
import ModalRFQ from "./ModalRFQ";
import DrawerSeleccionarProducto from "./DrawerSeleccionarProducto"
// import "../../";

const { Content } = Layout;
const { Panel } = Collapse;
const { Title, Text, Paragraph } = Typography
const axios = require('axios')


/**
 *
 *
 * @export
 * @class ProductosQuotation
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class ProductosQuotation extends Component {

    back = () => {
        this.props.history.goBack();
    }

    constructor(props) {
        super(props)
        this.state = {
            current: 0,
            productos: [],
            orden_id: this.props.match.params.orden_id,
            rfq_detalle_id: undefined,

            proveedores_usados: [],
            motivos: [],

            cliente: null,
            user: null,
            orden: null,
            percentage: 0,

            visibleModalAgregarProductos: false,


            showLightbox: false,
            photoIndex: 0,
            images: [],


            drawerSeleccionarProductoVisible: false


        }
    }

    stepTracker = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrden()
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.match.params.orden_id && this.props.match.params.orden_id !== this.state.orden_id) {
            this.setState({ orden_id: this.props.match.params.orden_id }, () => {
                this.getOrden();
            })
        }
    }


    /**
    * @memberof ProductosQuotation
    * @method getOrden
    * @description Obtiene la informacion de una orden
    * 
    */
    getOrden = () => {
        this.setState({ modalProveedorVisible: false, proveedores_usados: [], rfq_detalle_id: undefined })
        return axios.get('/project-manager/products-for-quotation', {
            params: {
                id: this.state.orden_id
            }
        })
            .then(({ data }) => {

                this.setState({
                    orden: data.data.orden,
                    cliente: data.data.cliente,
                    user: data.data.user,
                    productos: data.data.rfqs_detalles,
                    percentage: data.data.percentage,
                    rfq_detalle_start: data.data.rfq_detalle_start,
                    motivos: data.data.orden?.cotizacion_id?.motivos,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }


    /**
     *
     *
     * @memberof ProductosQuotation
     * @method updateProveedor
     * @description Eliminamos un proveedor.
     */
    deleteProveedor = (values) => axios.delete('/rfq/proveedor/delete', { params: values }).finally(() => this.getOrden())

    /**
     *
     *
     * @memberof ProductosQuotation
     * @method setRFQ
     * @description Declaramos el RFQ y ol objeto detalle, para guardarlo.
     */
    setRFQ = ({ proveedor_id, rfq_detalle_id, index }) => {
        this.setState(state => {
            if (!state.productos[index].orden_detalle?._id)
                state.productos[index].orden_detalle = {}

            state.productos[index].orden_detalle.rfq_detalle_id = rfq_detalle_id
            state.productos[index].orden_detalle.proveedor_id = proveedor_id

            return state;
        })
    }

    /**
     *
     *
     * @memberof ProductosQuotation
     * @method onMatrix
     * @description Cuando se ejecuta el boton de matriz. Guardamos los valores.
     */
    onMatrix = () => {
        axios.post('/project-manager/products-for-quotation', {
            productos: this.state.productos,
            orden_id: this.state.orden_id
        })
            .then(({ data }) => {

                console.log(data);

                if (data.data.redirect === true)
                    message.success("Aceptado Exitosamente")
                else
                    message.warning("Aún faltan productos por seleccionar.")

                this.getOrden()

                if (Array.isArray(data.data.ordenes_sin_productos) && data.data.ordenes_sin_productos.length > 0)
                    return this.setState({ visibleModalAgregarProductos: true })



                if (data.data.redirect === true)
                    this.props.history.push('/admin/project-manager/matriz-costos/' + this.state.orden_id)
            })
            .catch((error) => {
                console.log(error)
                message.error(error.response?.data?.message ?? "No fue posible aceptar.")
            })
    }

    /**
     *
     *
     * @memberof ProductosQuotation
     * @method cloneRFQ
     * @description Clona un RFQ
     */
    cloneRFQ = (rfq_detalle_id) => {
        Modal.confirm({
            title: '¿Duplicar el RFQ?',
            icon: <ExclamationCircleOutlined />,
            content: 'El RFQ será duplicado con los mismos valores',
            onOk: () => {
                return axios.post('/rfq/orden/clone', {
                    rfq_detalle_id
                }).then(response => {
                    message.success('RFQ duplicado')
                    this.setState({ productos: [] }, () => this.getOrden())
                    this.getOrden()
                }).catch(error => {
                    console.log("error", error);
                    message.error('Error al duplicar el RFQ')
                })
            },
            onCancel() { },
            cancelText: 'Cancelar'
        });
    }

    /**
     *
     *
     * @memberof ProductosQuotation
     * @method deleteRFQ
     * @description Elimina un RFQ
     */
    deleteRFQ = (rfq_detalle_id) => {
        Modal.confirm({
            title: '¿Quieres eliminar este producto?',
            icon: <ExclamationCircleOutlined />,
            content: 'Si eliminas este producto, tambien se eliminarán las cantidades de la matriz',
            okText: 'Si',
            onOk: () => {
                return axios.post('/rfq/orden/delete', {
                    rfq_detalle_id
                }).then(response => {
                    message.success('RFQ eliminado')
                    this.setState({ productos: [] }, () => this.getOrden())
                    this.getOrden()
                }).catch(error => {
                    console.log("error", error);
                    message.error(error.response?.data?.message ? error.response?.data?.message : 'Error al copiar el RFQ')
                })
            },
            onCancel() { },
            cancelText: 'Cancelar'
        });
    }


    formList = React.createRef()

    render() {

        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={<Row className=" width-100 " justify="space-between" align="middle">
                        <Col span={2}>
                            <Button ghost onClick={this.back} className="no-border">
                                <IconArrowBack />
                            </Button>
                        </Col>
                        <Col span={22}>
                            <span className="ant-page-header-heading-title" >
                                Cadena de Suministro
                            </span>
                        </Col>
                    </Row>
                    }
                />
                <Content className="content-bg pd-1">
                    <Row gutter={[16, 16]} style={{ marginBottom: '6rem' }}>
                        <Col xs={24} md={12} lg={8} xl={6}>
                            <StepTracker orden_id={this.state.orden_id} ref={this.stepTracker} />
                        </Col>
                        <Col xs={24} md={24} lg={16} xl={18}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12} xl={8}>
                                    <CardProjectInfo
                                        orden={this.state.orden}
                                        cliente={this.state.cliente}
                                        responsable={this.state.user}
                                        progress={this.state.percentage}
                                    />
                                </Col>
                                <Col xs={24} md={12} xl={8}>
                                    <CardTaskProgress
                                        started={this.state.rfq_detalle_start}
                                        percent={this.state.percentage}
                                    />
                                </Col>
                                <Col xs={24} md={12} xl={8}>
                                    <CardEntrega
                                        direccion={this.state.cliente?.direccion}
                                        motivos={this.state.motivos}
                                        orden_id={this.state.orden?._id}
                                        fecha_entrega={this.state.orden?.fecha_entrega}
                                        update={this.getOrden}
                                        onClick={() => this.setState({ modalMotivos: true })}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <Row justify="space-between product-list-header">
                                        <Col>
                                            <Title level={3}>Productos para Cotizar</Title>
                                        </Col>
                                        <Col>
                                            <Button onClick={this.onMatrix} type="primary" size="large" className="btn-quote">Ir a Matriz</Button>
                                        </Col>
                                    </Row>
                                    {this.state.productos.map((item, index) => <Collapse bordered={false} className="products-list mt-1" >
                                        <Panel

                                            key={item?._id}
                                            header={
                                                <List.Item
                                                    actions={[
                                                        <Tooltip title="Agregamos un producto opción a la lista de opciones">
                                                            <Button
                                                                onClick={(e) => { e.stopPropagation(); this.setState({ drawerSeleccionarProductoVisible: true, rfq_detalle_id: item._id }) }}
                                                                className="ant-btn-primary-cyan" icon={<UsergroupAddOutlined style={{ color: "white" }} type="primary" />} />
                                                        </Tooltip>,
                                                        <Tooltip title="Creamos una copia del producto actual">
                                                            <Button
                                                                onClick={(e) => { e.stopPropagation(); this.cloneRFQ(item._id) }}
                                                                className="ant-btn-primary-yellow" icon={<CopyOutlined style={{ color: "currentColor" }} />} type="primary" />
                                                        </Tooltip>,
                                                        <Tooltip title="Editamos la información del cliente">
                                                            <Button
                                                                onClick={(e) => { e.stopPropagation(); this.setState({ modalRFQ: true, title: 'Editar', rfq_detalle_id: item._id }) }}
                                                                className="ant-btn-primary-blue" icon={<EditOutlined style={{ color: "currentColor" }} />} type="primary" />
                                                        </Tooltip>,
                                                        (this.state.productos?.length > 1) ?
                                                            <Tooltip>
                                                                <Button onClick={(e) => { e.stopPropagation(); this.deleteRFQ(item._id); }} className="ant-btn-primary-red" icon={<DeleteOutlined style={{ color: "currentColor" }} />} type="primary" />
                                                            </Tooltip> : null,
                                                    ]}
                                                    className="width-100">
                                                    <List.Item.Meta
                                                        className="product-list-item-content"
                                                        avatar={(Array.isArray(item.imagenes) && item.imagenes.length > 0) ? <Avatar
                                                            shape="square"
                                                            size={80}
                                                            src={axios.defaults.baseURL + '/upload/th_' + item.imagenes[0]}
                                                            onClick={() => this.setState({
                                                                showLightbox: true,
                                                                photoIndex: 0,
                                                                images: item.imagenes.map(imagen => axios.defaults.baseURL + '/upload/' + imagen)
                                                            })}
                                                        /> : undefined}
                                                        title={<div>
                                                            <Typography className="product-item" >
                                                                {(item.marca) ? <Paragraph className="product-description-content">
                                                                    <small className="product-description">Marca:</small> <Text strong className="product-info" style={{ position: "relative" }}>{item.marca}
                                                                        <Space style={{ position: "absolute", top: -10, right: 0, transform: "translateX(calc(100% + 20px))" }}>
                                                                            {(item.prioridad) ? <Tag color={(item.prioridad == 1) ? "green" : "yellow"} icon={(item.prioridad == 1) ? <ClockCircleOutlined /> : <DollarOutlined />} >{(item.prioridad == 1) ? "Tiempo" : "Precio"}</Tag> : null}
                                                                            <Tag>Cantidad: <strong>{item.cantidad}</strong></Tag>
                                                                        </Space>
                                                                    </Text>
                                                                </Paragraph> : null}
                                                                {(item.modelo) ? <Paragraph className="product-description-content">
                                                                    <small className="product-description">Modelo:</small> <Text strong className="product-info">{item.modelo}</Text>
                                                                </Paragraph> : null}
                                                                {(item.sku) ? <Paragraph className="product-description-content">
                                                                    <small className="product-description">SKU:</small> <Text strong className="product-info">{item.sku}</Text>
                                                                </Paragraph> : null}
                                                                {(item.um) ? <Paragraph className="product-description-content">
                                                                    <small className="product-description">Unidad de Medída:</small> <Text strong className="product-info">{item.um}</Text>
                                                                </Paragraph> : null}
                                                                {(item.descripcion) ? <Paragraph className="product-description-content">
                                                                    <Text strong className="product-info"></Text>
                                                                </Paragraph> : null}
                                                            </Typography>
                                                            {(Array.isArray(item.archivos)) ? item.archivos.map(({ name, filename }) => <a target="_blank" href={`${process.env.REACT_APP_WEB_SERVICE}/upload/${filename}`}><Tag>{name}</Tag></a>) : undefined}
                                                        </div>}
                                                        description={item.descripcion}
                                                    />
                                                </List.Item>
                                            } style={{ background: "white" }}>
                                            <Divider style={{ marginTop: 0, background: "darkgray" }} />
                                            <Radio.Group
                                                className="width-100"
                                                value={item.orden_detalle?.proveedor_id}
                                                onChange={event => this.setRFQ({
                                                    proveedor_id: event.target.value,
                                                    rfq_detalle_id: item._id,
                                                    index
                                                })}
                                            >
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={(Array.isArray(item.proveedores)) ? item.proveedores : []}
                                                    style={{ padding: "0 1em" }}
                                                    locale={{ emptyText: <>No hay opciones de proveedores para este producto <a onClick={(e) => { e.stopPropagation(); this.setState({ drawerSeleccionarProductoVisible: true, rfq_detalle_id: item._id }) }}>¡Agrega uno!</a></>, }}
                                                    header={<List.Item.Meta
                                                        avatar={<div style={{ width: 100 }}></div>}
                                                        title={<Row >
                                                            <Col span={13}>
                                                                <Text strong>Producto / Proveedor</Text>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Text strong>Precio Proveedor</Text>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Text strong>Cantidad de Días</Text>
                                                            </Col>
                                                        </Row>}
                                                    />}
                                                    footer={<List.Item className="width-100">
                                                        <List.Item.Meta
                                                            className="width-100"
                                                            avatar={<Radio value={null} />}
                                                            title={"No Aplica / No incluir en la cotización"}
                                                        />
                                                    </List.Item>}
                                                    renderItem={proveedor => {
                                                        console.log(" * * * * * * * * ")
                                                        console.log(" * * * * * * * * ")
                                                        console.log(" * * * * * * * * ")
                                                        console.log(" * * * * * * * * ")
                                                        console.log(proveedor)
                                                        console.log(" * * * * * * * * ")
                                                        console.log(" * * * * * * * * ")
                                                        console.log(" * * * * * * * * ")
                                                        console.log(" * * * * * * * * ")
                                                        const { _id, producto_id, proveedor_id, precio, dias, ...args } = proveedor


                                                        if (!producto_id)return;

                                                        return <List.Item
                                                            style={{ position: "relative" }}
                                                            key={_id}
                                                            actions={[
                                                                <Tooltip title="Editamos la opción del cliente">
                                                                    <Button
                                                                        className="ant-btn-primary-blue"
                                                                        icon={<EditOutlined style={{ color: "currentColor" }} />}
                                                                        type="primary"
                                                                        onClick={() => this.setState({
                                                                            drawerSeleccionarProductoVisible: true,
                                                                            rfq_detalle_id: item._id,
                                                                            rfq_detalle_proveedor_id: proveedor._id,
                                                                        })}
                                                                    />
                                                                </Tooltip>,
                                                                <Tooltip title="Elimina la opción de la lista de opciones">
                                                                    <Button
                                                                        onClick={() => Modal.confirm({
                                                                            title: "¿Quieres eliminar este proveedor?",
                                                                            onOk: () => this.deleteProveedor({ rfq_detalle_id: item._id, rfq_detalle_proveedor_id: proveedor._id, }),
                                                                            okText: "Eliminar"
                                                                        })}
                                                                        className="ant-btn-primary-red" icon={<DeleteOutlined style={{ color: "currentColor" }} />} type="primary" />,

                                                                </Tooltip>
                                                            ]}
                                                        >
                                                            <List.Item.Meta
                                                                avatar={<Space>
                                                                    <Radio value={proveedor_id?._id} />
                                                                    {(Array.isArray(producto_id?.imagenes) && (producto_id.imagenes[0] !== undefined)) ?
                                                                        <Image
                                                                            width={64}
                                                                            src={axios.defaults.baseURL + '/upload/' + producto_id.imagenes[0].filename}
                                                                        /> : <Avatar shape="square" size={64} ></Avatar>}
                                                                </Space>}
                                                                title={<Row>
                                                                    <Col span={16}>
                                                                        <Typography style={{ lineHeight: 0.5, marginTop: 3 }}>
                                                                            <Paragraph style={{ paddingBottom: 10 }}><span style={{ marginTop: 3, backgroundColor: "lightgray", borderRadius: 5, border: "1px solid gray", padding: "2px 5px", marginRight: "4px" }}>{proveedor_id.nombre}</span></Paragraph>
                                                                            <Paragraph ><Text strong  >{producto_id.sku}</Text> &nbsp;<Text italic style={{ fontSize: "0.765em" }}>{producto_id.np_nexus}</Text>&nbsp;<Text italic style={{ fontSize: "0.8em" }}>{producto_id?.marca}</Text>&nbsp;<Text strong style={{ fontSize: "0.8em" }}>{producto_id.np_fabricante}</Text></Paragraph>
                                                                            <Paragraph><Text strong style={{ fontSize: "0.8em" }}>{producto_id?.proveedor_id?.nombre}</Text></Paragraph>
                                                                        </Typography>
                                                                    </Col>
                                                                    <Col span={4}>{precio?.toLocaleString('en-EN', { style: 'currency', currency: 'USD' })}</Col>
                                                                    <Col span={4}>{dias} días </Col>
                                                                </Row>}
                                                                description={producto_id?.descripcion}
                                                            />
                                                        </List.Item>
                                                    }}
                                                />
                                            </Radio.Group>
                                            <div className="width-100" style={{ display: "flex", justifyContent: "end" }}>
                                                <Button
                                                    onClick={(e) => { e.stopPropagation(); this.setState({ drawerSeleccionarProductoVisible: true, rfq_detalle_id: item._id }) }}
                                                    icon={<PlusOutlined />}>Agregar un nuevo Proveedor</Button>
                                            </div>
                                        </Panel>
                                    </Collapse>)}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalRFQ: true, title: 'Añadir' })} />
                    <ModalProveedor
                        proveedores_usados={this.state.proveedores_usados}
                        visible={this.state.modalProveedorVisible}
                        onCancel={() => this.getOrden()}

                        rfq_detalle_id={this.state.rfq_detalle_id}
                        rfq_detalle_proveedor_id={this.state.rfq_detalle_proveedor_id}
                    />

                    <ModalAgregarProductos
                        visible={this.state.visibleModalAgregarProductos}
                        orden_id={this.state.orden_id}

                        onCancel={({ redirect } = {}) => {
                            this.getOrden()
                            this.setState({ visibleModalAgregarProductos: false })
                            if (redirect === true)
                                this.props.history.push('/admin/project-manager/matriz-costos/' + this.state.orden_id)
                        }}
                    />
                    <ModalMotivos
                        visible={this.state.modalMotivos}
                        onCancel={() => this.setState({ modalMotivos: false })}
                        motivos={this.state.motivos}
                    />


                    <ModalRFQ
                        visible={this.state.modalRFQ}
                        onClose={(flag) => {
                            this.setState({ modalRFQ: false, title: undefined, rfq_detalle_id: undefined })
                            if (flag === true) {
                                this.setState({ productos: [] }, () => this.getOrden())
                            }

                        }}
                        title={this.state.title}
                        orden_id={this.state.orden_id}
                        rfq_detalle_id={this.state.rfq_detalle_id}
                    />
                    {this.state.showLightbox && (
                        <Lightbox
                            mainSrc={this.state.images[this.state.photoIndex]}
                            nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                            prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                            onCloseRequest={() => this.setState({ showLightbox: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    photoIndex: (this.state.photoIndex + 1) % this.state.images.length
                                })
                            }
                        />
                    )}
                    <DrawerSeleccionarProducto
                        visible={this.state.drawerSeleccionarProductoVisible}

                        rfq_detalle_id={this.state.rfq_detalle_id}
                        rfq_detalle_proveedor_id={this.state.rfq_detalle_proveedor_id}

                        onClose={() => {
                            this.setState({
                                drawerSeleccionarProductoVisible: false,
                                productoOpcionSeleccionada: null,
                                rfq_detalle_id: null,
                                rfq_detalle_proveedor_id: null,
                            })
                            this.getOrden();
                        }}
                    />
                </Content>
            </>
        )
    }
}