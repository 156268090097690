import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form,  Input, InputNumber, message, Spin } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";


const { Title } = Typography;
const axios = require('axios').default;



/**
 *
 *
 * @class ModalFacturas
 * @extends {Component}
 */
class ModalEditarVigencia extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proveedores: [],
            proveedor: null,
            razon_social: undefined,
            tipo_cambio: 0,
            disabled: false
        }
    }

    modalForm = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrden()
    }

    /**
     * @memberof ModalEditarVigencia
     * @method onFinish
     * @description Actualizamos la vigencia de la cotización
     */
    onFinish = (values) => {
        axios.put('/cotizacion/update', {
            ...values,
            id: this.state.cotizacion_id
        })
            .then(({ data }) => {
                message.success("Actualizado")
                this.props.onClose()
                // this.setState({cotizacion: })
            })
            .catch(error => {
                message.error('Error al actualizar')
                console.log('error', error)
            })
    }

    /**
     * @memberof ModalEditarVigencia
     * @method getOrden
     * @description Obtenemos la información de la orden
     */
    getOrden = () => {
        axios.get('/ordenes/get', {
            params: {
                id: this.props.orden_id
            }
        })
            .then(({ data }) => {

                this.modalForm.current.setFieldsValue(data.data.cotizacion_id)

                console.log('data.data.cotizacion_id', data.data);
                this.setState({cotizacion_id: data.data.cotizacion_id?._id})
            })
            .catch(error => {
                message.error('Error al obtener la Orden')
                console.log('error', error)
            })
    }


    render() {

        console.log('this.props.orden_id', this.props.orden_id);
        const { disabled } = this.state;
        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.modalForm}
                onFinish={this.onFinish}>
                <Spin spinning={this.state.loading}>

                    <Row className="pl-2 pr-2 mt-2">

                        {(this.props.tipo === 2) ? <Col xs={24} >
                            <Form.Item
                                label="Dias"
                                name="vigencia"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese los dias antes del vencimiento"
                                }]}
                            >
                                <InputNumber className="width-100" placeholder="7 dias" />
                            </Form.Item>
                        </Col>
                            :
                            <Col xs={24}>
                                <Form.List
                                    label="Terminos y Condiciones"
                                    name="terms"

                                >
                                    {(fields, { add, remove }, { errors }) => (
                                        <>
                                            <Row>
                                                <span style={{ color: "red" }}> * &nbsp;</span>Terminos y Condiciones
                                            </Row>
                                            {fields.map((field, index) => (
                                                <Form.Item
                                                    required={false}
                                                    key={field.key}
                                                >
                                                    <Form.Item
                                                        {...field}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Por favor ingrese sus terminos y condiciones",
                                                            },
                                                        ]}
                                                        noStyle
                                                    >
                                                        <Input placeholder="Terminos y Condiciones" style={{ width: 'calc(100% - 22px)' }} />
                                                    </Form.Item>
                                                    {fields.length > 1 ? (
                                                        <MinusCircleOutlined
                                                            style={{ margin: '4px 4px' }}
                                                            onClick={() => remove(field.name)}
                                                        />
                                                    ) : null}
                                                </Form.Item>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    icon={<PlusOutlined />}
                                                    block
                                                >
                                                    Agregar Termino o Condición
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>

                            </Col>
                        }


                    </Row>
                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { tipo, visible = false, onClose = () => { }, factura_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        width={500}
    >
        <div className="center">
            <Title level={3} className="text-center">
                {(tipo === 2) ? "Vigencia de la Cotización" : "Terminos y Condiciones"}
                </Title>
        </div>
        <ModalEditarVigencia {...props} />
    </Modal>

}