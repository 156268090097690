import React, { Component } from "react";
import { Space, Tooltip, Checkbox, Row, Col, Button, Modal, Typography, Form, Input, message, Divider, Spin, Drawer, Table, Select, DatePicker, InputNumber } from 'antd';

import { IconSAT } from '../../../Widgets/Iconos';

import { CloseOutlined, PrinterOutlined } from '@ant-design/icons';
import { c_TipoDeComprobante, c_Exportacion, c_RegimenFiscal, c_UsoCFDI } from '../../../SATCatalogos/index';

import Decimal from "decimal.js";

import { GoFileCode } from "react-icons/go";
import moment from "moment"
const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;


/**
 *
 *
 * @class FacturasList
 * @extends {Component}
 */
class FacturasList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ordenes: [],
            cliente: {},
            razones: [],
            subtotal: 0,
            impuestos: 0,
            total: 0,
            loading: false,
            moneda: "MXN"
        }
    }

    form = React.createRef()

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenes()
        this.getRazonesSociales()
        this.getTipoCambio()
        this.getUsuarios()
        if (this.props.cliente_id) {
            this.getCliente()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.almacen_id && prevProps.almacen_id !== this.props.almacen_id) {
            this.getOrdenes()
        }
    }

    selectOptions = (array) => {

        let arr = [];
        if (array) {
            array.forEach(function ({ _id, nombre, apellido, folio }, index) {
                arr.push(<Option value={_id}>{nombre} {apellido}</Option>)
            })
        }

        return arr
    }

    getTipoCambio = (orden = this.state.orden) => {
        axios.get("/tipo-cambio/get")
            .then(({ data }) => {
                console.log("data ", data)
                this.form.current.setFieldsValue({ TipoCambio: data.data[0].valor })
            })
            .catch((response) => {
                console.log("response ", response)
                // this.formTimbrado.current.setFieldsValue({ TipoCambio: data.data[0].valor })
            })
    }

    getUsuarios = (search) => {

        this.setState({ loading: true })

        axios.get('/usuarios', {
            params: {
                factura_pendiente: true,
                cliente_id: this.props.cliente_id,
                search
            }
        }).then(response => {
            console.log('usuarios de cliente', response.data.data)
            this.setState({
                usuarios: response.data.data.itemsList,
            })


        }).catch(error => {
            message.error('Error al traer usuarios')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    getOrdenes = (usuarios) => {
        //this.setState({loading: true})
        axios.get('/vmi/ventas', {
            params: {
                facturadas: false, //ordenes que no estan facturadas
                cliente_id: this.props.cliente_id,
                almacen_id: this.props.almacen_id,
                paginate: false,
                usuarios
            }
        }).then(response => {


            const { data, factura, cliente, latest_factura } = response.data

            let subtotal = 0, impuestos = 0, total = 0
            for (const orden of data) {
                subtotal += orden.subtotal
                impuestos += orden.impuestos
                total += orden.total
            }

            this.setState({
                ordenes: data,
                subtotal,
                impuestos,
                total,
                cliente
            })

            console.log("ordenes", data)

            let formFacturaValues = {
                Serie: process.env.REACT_APP_VMI_FACTURA_SERIE,
                // Folio: ((latest_factura) ? latest_factura : 1),
                Fecha: moment(),
                TipoDeComprobante: "I",
                Receptor: {
                    Nombre: cliente?.razon_social?.toUpperCase(),
                    Rfc: cliente?.rfc,
                    DomicilioFiscalReceptor: cliente?.codigo_postal,
                    RegimenFiscalReceptor: cliente?.regimen_fiscal,
                },

                Total: Decimal(total).toDecimalPlaces(2).toNumber(),
                SubTotal: Decimal(subtotal).toDecimalPlaces(2).toNumber(),
                Impuestos: {
                    TotalImpuestosTrasladados: Decimal(impuestos).toDecimalPlaces(2).toNumber(),
                },

                Ordenes: data.map(orden => ({
                    _id: orden._id,
                    SubTotal: Decimal(orden.subtotal).toDecimalPlaces(2).toNumber(),
                    Impuesto: Decimal(orden.impuestos).toDecimalPlaces(2).toNumber(),
                    Total: Decimal(orden.total).toDecimalPlaces(2).toNumber(),
                })),

                Conceptos: data.map(orden => ({
                    ClaveProdServ: orden?.producto_id?.clave_producto ?? "01010101",
                    NoIdentificacion: orden?.producto_id?.sku,
                    Cantidad: orden?.orden_detalles.cantidad,
                    ClaveUnidad: orden?.producto_id?.unidad ?? "H87",
                    Unidad: orden?.producto_id?.unidad_nombre ?? "Pieza",
                    Descripcion: orden?.producto_id?.descripcion,

                    ValorUnitario: Decimal(orden?.orden_detalles.info_matriz.precio_venta_unitario).toDecimalPlaces(2).toNumber(),
                    Importe: Decimal(orden?.orden_detalles?.info_matriz?.total_venta).toDecimalPlaces(2).toNumber(),

                    Descuento: "0",
                    ObjetoImp: "02",

                    Impuestos: {
                        Traslados: [{
                            Base: Decimal(orden?.orden_detalles?.info_matriz?.total_venta).toDecimalPlaces(2).toNumber(),
                            Impuesto: "002",
                            TipoFactor: "Tasa",
                            TasaOCuota: "0.160000",
                            Importe: Decimal(orden.orden_detalles.info_matriz.total_venta).mul(Decimal(0.16)).toDecimalPlaces(2).toString(),
                        }],
                    }
                }))
            }

            if (factura === null)
                formFacturaValues.Folio = 1
            else
                formFacturaValues.Folio = (parseInt(factura.folio) + 1).toString().padStart(7, "0")

            console.log("formFacturaValues", formFacturaValues)

            this.form.current.setFieldsValue(formFacturaValues)

        }).catch(error => {
            console.log("error", error);

        })
    }

    getCliente = () => {
        //this.setState({loading: true})
        axios.get('/clientes/get', {
            params: {
                id: this.props.cliente_id,
            }
        }).then(response => {
            console.log("response---", response.data.data);
            this.setState({ cliente: response.data.data })


        }).catch(error => {
            console.log("error", error);

        })
    }

    getRazonesSociales = async (page) => {
        let search = this.props.search;
        axios.get('/razones-sociales', {
            params: {
                paginate: false,
                search: search,
                syncfy_tax: true,
            }
        })
            .then(res => {
                const data = res.data.data
                console.log("data", data);
                this.setState({ razones: data })
            })
            .catch(res => {
                console.log("error", res);
            });
    }

    onFinish = (values) => {
        this.setState({ loading: true })
        console.log("values", values);


        axios.post('/syncfy/timbrado/multiple', {
            ...values,
            Folio: values.Folio.toString().padStart(7, "0"),
            cliente_id: this.props.cliente_id,
        }).then(response => {
            message.success('Ordenes Facturadas')
        }).catch(error => {
            console.log("error.response?.data?.data", error.response?.data?.data);
            if (Array.isArray(error.response?.data?.data)) {
                Modal.error({
                    title: 'Error al timbrar la factura',
                    content: <ul>
                        {error.response.data.data.map(e => <li>{e}</li>)}
                    </ul>
                })
            } else {
                message.error('Error al timbrar la facturas')
            }

        }).finally(() => {
            this.setState({ loading: false })
        })

    }
    /**
     *
     * @memberof FacturasList
     * @method updateValores
     * @description Recibe los cambios en el formulario y actualiza los valores de subtotal, impuestos y total
     * solo si hay un cambio en impuestos o subtotal de lo contrario no hace nada
     * @param {Array} values
     */
    updateValores = (values) => {
        console.log("values", values);

        if (values[0].Impuestos || values[0].SubTotal) {
            console.log("Hay un cambio en impuestos o  subtotal")

            this.form.current.setFieldsValue({
                Total: Decimal(values[1].SubTotal).add(Decimal(values[1].Impuestos.TotalImpuestosTrasladados)).toDecimalPlaces(2).toNumber(),
            })

        }

        if (values[0].Ordenes) {
            console.log("Hay un cambio en ordenes")

            let indexChanged = values[0].Ordenes.length - 1
            let completo = values[1].Ordenes[indexChanged]




            let Ordenes = values[1].Ordenes

            if (completo.NoImpuesto) {
                Ordenes[indexChanged].Impuesto = 0
            } else {
                Ordenes[indexChanged].Impuesto = Decimal(completo.SubTotal).mul(Decimal(0.16)).toDecimalPlaces(2).toNumber()
            }

            Ordenes[indexChanged].Total = Decimal(completo.Impuesto).add(completo.SubTotal).toDecimalPlaces(2).toNumber()

            let Total = Decimal(Ordenes.reduce((a, b) => Decimal(a).add(b.Total), 0)).toDecimalPlaces(2).toNumber()
            let SubTotal = Decimal(Ordenes.reduce((a, b) => Decimal(a).add(b.SubTotal), 0)).toDecimalPlaces(2).toNumber()
            let Impuestos = Decimal(Ordenes.reduce((a, b) => Decimal(a).add(b.Impuesto), 0)).toDecimalPlaces(2).toNumber()


            this.form.current.setFieldsValue({
                Ordenes: Ordenes,

                SubTotal: SubTotal,
                Total: Total,
                Impuestos: {
                    TotalImpuestosTrasladados: Impuestos,
                }
            })
        }
    }

    render() {


        const columns = [
            {
                title: 'Folio',
                dataIndex: 'folio',
                key: 'folio',
            },
            {
                title: 'Producto',
                dataIndex: 'producto_id',
                key: 'producto_id',
                width: '50%',
                render: (text, record) => {

                    return <div>
                        <Text strong>{text.sku}</Text><br />
                        <Text>{text.descripcion}</Text><br />
                        <Text strong>Cantidad: </Text><Text>{record.orden_detalles.cantidad}</Text>
                    </div>
                }
                ,
            },
            {
                title: 'Sub Total',
                dataIndex: 'subtotal',
                key: 'subtotal',
                render: (text, record, index) => {
                    return <>

                        <Form.Item name={["Conceptos", index, "ClaveProdServ"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                        <Form.Item name={["Conceptos", index, "NoIdentificacion"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                        <Form.Item name={["Conceptos", index, "Cantidad"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                        <Form.Item name={["Conceptos", index, "ClaveUnidad"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                        <Form.Item name={["Conceptos", index, "Unidad"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                        <Form.Item name={["Conceptos", index, "Descripcion"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>

                        <Form.Item name={["Conceptos", index, "ValorUnitario"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                        <Form.Item name={["Conceptos", index, "Importe"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>

                        <Form.Item name={["Conceptos", index, "Descuento"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                        <Form.Item name={["Conceptos", index, "ObjetoImp"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>

                        <Form.Item name={["Conceptos", index, "Impuestos", "Traslados", 0, "Base"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                        <Form.Item name={["Conceptos", index, "Impuestos", "Traslados", 0, "Importe"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                        <Form.Item name={["Conceptos", index, "Impuestos", "Traslados", 0, "Impuesto"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                        <Form.Item name={["Conceptos", index, "Impuestos", "Traslados", 0, "TasaOCuota"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                        <Form.Item name={["Conceptos", index, "Impuestos", "Traslados", 0, "TipoFactor"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>

                        <Form.Item name={["Ordenes", index, "SubTotal"]} noStyle>
                            <InputNumber controls={false}
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                bordered={false}
                                className="text-center" />
                        </Form.Item>
                        <Form.Item name={["Ordenes", index, "Impuesto"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                        <Form.Item name={["Ordenes", index, "Total"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                    </>
                }
            },
            {
                title: "Libre de Impuestos",
                dataIndex: "impuestos",
                key: "impuestos",

                render: (text, { name }, index) =>
                    <Form.Item name={["Ordenes", index, "NoImpuesto"]} noStyle valuePropName="checked">
                        <Checkbox />
                    </Form.Item>

                ,
            },
            {
                title: 'Impuesto',
                dataIndex: 'impuesto',
                key: 'impuesto',
                render: (text, record, index) => {
                    return <>
                        <Form.Item name={["Ordenes", index, "_id"]} noStyle>
                            <input type="hidden" />
                        </Form.Item>
                        <Form.Item name={["Ordenes", index, "Impuesto"]} noStyle>
                            <InputNumber controls={false}
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                bordered={false}
                                className="text-center" />
                        </Form.Item>
                    </>
                }
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                render: (text, record, index) => {
                    return <>
                        <Form.Item name={["Ordenes", index, "Total"]} noStyle>
                            <InputNumber controls={false}
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                bordered={false}
                                className="text-center" />
                        </Form.Item>
                    </>
                }
            },
        ];

        const { cliente } = this.state

        return (
            <Spin spinning={this.state.loading}>
                <Form

                    ref={this.form}
                    onFinish={this.onFinish}
                    layout="vertical"
                    scrollToFirstError={true}
                    initialValues={{
                        enviar_receptor: true,
                        enviar_responsable: true,
                    }}
                    onValuesChange={(...a) => this.updateValores(a)}
                >
                    <Row justify="center" align="center">
                        <img src="/images/sat.svg" alt="SAT - Servicio de Administración Tributaria" width={"100%"} style={{ marginTop: "2em", marginBottom: "4em", maxWidth: 350 }} />
                    </Row>
                    <Row>
                        <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                        <Title level={5} >Información de la Factura </Title>
                    </Row>

                    <Row gutter={[16, 16]} justify="end">

                        <Col xs={20} md={12}>

                            <Form.Item
                                name="Serie"
                                label="Serie"
                                rules={[{
                                    required: true,
                                    message: "Ingrese la serie de la factura."
                                }]}
                            >
                                <Select disabled={this.props.disabled}>
                                    <Option value={process.env.REACT_APP_SPOT_SERIE}><strong>{process.env.REACT_APP_SPOT_SERIE}</strong> - SPOT</Option>
                                    <Option value={process.env.REACT_APP_VMI_FACTURA_SERIE}><strong>{process.env.REACT_APP_VMI_FACTURA_SERIE}</strong> - Factura de Almacen (VMI)</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={20} md={12}>
                            <Form.Item
                                name="Folio"
                                label="Folio"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el folio"
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>

                        <Col xs={20} md={12}>
                            <Form.Item
                                name="Fecha"
                                label="Fecha"
                                rules={[{
                                    required: true,
                                    message: "Ingrese la Fecha"
                                }]}
                            >
                                <DatePicker showTime className="width-100" disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>

                        <Col xs={20} md={12}>
                            <Form.Item
                                name="TipoDeComprobante"
                                label="Tipo De Comprobante"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Tipo de Comprobante"
                                }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="filter"
                                    disabled={this.props.disabled}
                                >
                                    {c_TipoDeComprobante.map(({ tipo_comprobante, descripción }) => <Option filter={`${tipo_comprobante} ${descripción}`} value={tipo_comprobante}><strong>{tipo_comprobante}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="FormaPago"
                                label="Forma de Pago"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Tipo de Comprobante"
                                }]}
                            >
                                <Select disabled={this.props.disabled}>
                                    {[
                                        {
                                            "forma_pago": "01",
                                            "descripcion": "Efectivo",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "02",
                                            "descripcion": "Cheque nominativo",
                                            "bancarizado": "Sí"
                                        },
                                        {
                                            "forma_pago": "03",//3,
                                            "descripcion": "Transferencia electrónica de fondos",
                                            "bancarizado": "Sí"
                                        },
                                        {
                                            "forma_pago": "04",
                                            "descripcion": "Tarjeta de crédito",
                                            "bancarizado": "Sí"
                                        },
                                        {
                                            "forma_pago": "05",
                                            "descripcion": "Monedero electrónico",
                                            "bancarizado": "Sí"
                                        },
                                        {
                                            "forma_pago": "06",//6,
                                            "descripcion": "Dinero electrónico",
                                            "bancarizado": "Sí"
                                        },
                                        {
                                            "forma_pago": "08",//8,
                                            "descripcion": "Vales de despensa",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "12",//12,
                                            "descripcion": "Dación en pago",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "13",//13,
                                            "descripcion": "Pago por subrogación",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "14",
                                            "descripcion": "Pago por consignación",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "15",
                                            "descripcion": "Condonación",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "17",
                                            "descripcion": "Compensación",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "23",
                                            "descripcion": "Novación",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "24",
                                            "descripcion": "Confusión",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "25",
                                            "descripcion": "Remisión de deuda",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "26",
                                            "descripcion": "Prescripción o caducidad",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "27",
                                            "descripcion": "A satisfacción del acreedor",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "28",
                                            "descripcion": "Tarjeta de débito",
                                            "bancarizado": "Sí"
                                        },
                                        {
                                            "forma_pago": "29",
                                            "descripcion": "Tarjeta de servicios",
                                            "bancarizado": "Sí"
                                        },
                                        {
                                            "forma_pago": "30",
                                            "descripcion": "Aplicación de anticipos",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "31",
                                            "descripcion": "Intermediario pagos",
                                            "bancarizado": "No"
                                        },
                                        {
                                            "forma_pago": "99",
                                            "descripcion": "Por definir",
                                            "bancarizado": "Opcional"
                                        }
                                    ].map(({ forma_pago, descripcion }) => <Option value={forma_pago}><strong>{forma_pago}</strong> &nbsp; {descripcion}</Option>)}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="MetodoPago"
                                label="Método de Pago"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Método de Pago"
                                }]}
                            >
                                <Select disabled={this.props.disabled}>
                                    <Option value={'PUE'}><strong>PUE</strong> - Pago en Una sola Exhibición</Option>
                                    <Option value={'PPD'}><strong>PPD</strong> - Pago en Parcialidades o Diferido</Option>
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col xs={20} md={12}>
                            <Form.Item
                                name="Exportacion"
                                label="Exportacion"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Tipo de Exportación"
                                }]}
                            >
                                <Select disabled={this.props.disabled}>
                                    {c_Exportacion.map(({ exportacion, descripción }) => <Option filter={`${exportacion} ${descripción}`} value={exportacion}><strong>{exportacion}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col xs={20} md={12}>
                            <Form.Item
                                name="LugarExpedicion"
                                label="Lugar Expedicion (C.P.)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese un código postal"
                                }]}
                            >
                                <Input disabled={this.props.disabled} className="width-100" />
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row>
                        <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                        <Title level={5} className="mb-1">Información del Emisor</Title>
                    </Row>
                    <Row className="mb-1">
                        <Col span={24}>
                            <Form.Item noStyle
                                name="enviar_responsable"
                                valuePropName="checked">
                                <Checkbox >Enviar al Responsable una copia de la factura</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center" gutter={[16, 16]} >
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="razon_social_id"
                                label="Razón Social"
                                rules={[{
                                    required: true,
                                    message: "Ingrese la Razón Social desde donde se efectuará la factura"
                                }]}
                            >
                                <Select
                                    disabled={this.props.disabled}
                                    placeholder="Seleccione la razon social"
                                    onSearch={this.getRazonesSociales}
                                    filterOption={false}
                                    onSelect={(value, { razon_social }) => this.form.current.setFieldsValue({
                                        Emisor: {
                                            Nombre: razon_social.razon_social,
                                            Rfc: razon_social.rfc,
                                            RegimenFiscal: razon_social.regimen_fiscal,
                                        }
                                    })}
                                >
                                    {this.state.razones.map(rs => <Option value={rs._id} razon_social={rs}  >{rs.razon_social}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Emisor", "Nombre"]}
                                label="Nombre del Emisor"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el nombre del Emisor (como se registró en el SAT)"
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Emisor", "Rfc"]}
                                label="Registro Federal de Contribuyentes (RFC)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el RFC del Emisor"
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Emisor", "RegimenFiscal"]}
                                // name="RegimenFiscal"
                                label="Régimen Fiscal"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Régimen Fiscal"
                                }]}
                            >
                                <Select
                                    disabled={this.props.disabled}
                                    showSearch
                                    optionFilterProp="filter"
                                >
                                    {c_RegimenFiscal.map(({ regimen_fiscal, descripción }) => <Option filter={`${regimen_fiscal} ${descripción}`} value={regimen_fiscal}><strong>{regimen_fiscal}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Emisor", "DomicilioFiscalEmisor"]}
                                label="Domicilio Fiscal Emisor (Código Postal)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el domicilio del emisor."
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                        <Title level={5}>Información del Receptor </Title>
                    </Row>
                    <Row className="mb-1">
                        <Col span={24}>
                            <Form.Item noStyle
                                name="enviar_receptor"
                                valuePropName="checked">
                                <Checkbox >Enviar al Receptor una copia de la factura</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} >
                        <Col xs={20} md={24}>
                            <Form.Item
                                name={["Receptor", "Nombre"]}
                                label="Nombre / Razón Social del Emisor"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Nombre / Razón Social del Receptor"
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Receptor", "Rfc"]}
                                label="Registro Federal de Contribuyentes (RFC)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el RFC del cliente / receptor."
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Receptor", "DomicilioFiscalReceptor"]}
                                label="Domicilio Fiscal Receptor (Código Postal)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el domicilio del cliente."
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Receptor", "UsoCFDI"]}
                                label="Uso del CFDI"
                                rules={[{
                                    required: true,
                                    message: "Seleccione una opción."
                                }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="filter"
                                    disabled={this.props.disabled}
                                >
                                    {c_UsoCFDI.map(({ uso_cfdi, descripción }) => <Option filter={`${uso_cfdi} ${descripción}`} value={uso_cfdi}><strong>{uso_cfdi}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Receptor", "RegimenFiscalReceptor"]}
                                label="Régimen Físcal Receptor"
                                rules={[{
                                    required: true,
                                    message: "Seleccione un régimen fiscal"
                                }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="filter"
                                    disabled={this.props.disabled}
                                >
                                    {c_RegimenFiscal.map(({ regimen_fiscal, descripción }) => <Option filter={`${regimen_fiscal} ${descripción}`} value={regimen_fiscal}><strong>{regimen_fiscal}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                        <Title level={5} className="mb-1">Información de Pagos y Productos</Title>
                    </Row>
                    <Row className="mb-3" gutter={[16, 16]}>
                        <Col xs={20} md={6}>
                            <Form.Item
                                name="Moneda"
                                label="Moneda"
                            >
                                <Select disabled={this.props.disabled} onSelect={moneda => this.setState({ moneda })}>
                                    <Option value={'MXN'}>MXN</Option>
                                    <Option value={'USD'}>USD</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={6}>
                            <Form.Item
                                name="TipoCambio"
                                label="Tipo de Cambio"
                            >
                                <InputNumber
                                    style={{ width: "100%" }}
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value && value.replace(/\$\s?|(,*)/g, '')}
                                    disabled={this.state.moneda == "MXN"}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} >
                            <Form.Item
                                label="Observaciones"
                                name="observaciones"
                                rules={[{
                                    max: 1000,
                                    message: "Máximo de caracteres alcanzado (1000)"
                                }]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    <Row className="width-100 mb-2">
                        <Select
                            showArrow
                            showSearch
                            onSearch={value => {
                                this.getUsuarios(value)
                            }}
                            className="width-100"
                            onChange={value => {this.getOrdenes(value)}}
                            placeholder="Seleccione un usuario"
                            filterOption={false}
                            mode="multiple"
                        >
                            {this.selectOptions(this.state.usuarios)}

                        </Select>
                    </Row>
                    <Table
                        loading={this.state.loading}
                        columns={columns}
                        dataSource={this.state.ordenes}
                        locale={{ emptyText: 'Sin Ordenes disponibles' }}
                        pagination={false}
                    />
                    <Row className="mt-1">
                        <Col span={18} className="flex-right">
                            <Title level={4}>Subtotal: </Title>
                        </Col>
                        <Col span={6} className="flex-right">
                            <Form.Item name="SubTotal" noStyle>
                                <InputNumber controls={false}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    bordered={false}
                                    className="text-center" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18} className="flex-right">
                            <Title level={4}>Impuestos (16%): </Title>
                        </Col>
                        <Col span={6} className="flex-right">
                            <Form.Item name={["Impuestos", "TotalImpuestosTrasladados"]} noStyle>
                                <InputNumber controls={false}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    bordered={false}
                                    className="text-center" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={18} className="flex-right">
                            <Title level={4}>Total: </Title>
                        </Col>
                        <Col span={6} className="flex-right">
                            <Form.Item name="Total" noStyle>
                                <InputNumber controls={false}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    bordered={false}
                                    className="text-center" />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    let { onClose, visible } = props
    let [verPDF, setVerPDF] = React.useState(null)
    let [loading, setLoading] = React.useState(false)
    let [disabled, setDisabled] = React.useState(false)

    const timbradoOrden = React.createRef()



    /**
     *
     *
     * @export
     * @param {*} props
     * @return {*} 
     * 
     * @description Cerramos y limpiamos el DRAWER de facturación.
     */
    let onCloseEvent = () => {
        onClose();
        setVerPDF(null)
        setLoading(false)
        setDisabled(false)
    }


    return <Drawer
        title={`Ordenes Sin Facturar`}
        placement="right"

        width="100vh"
        contentWrapperStyle={{ maxWidth: "1200px" }}
        onClose={onCloseEvent}
        visible={visible}
        destroyOnClose
        extra={
            <Space>
                <Button loading={loading} danger icon={<CloseOutlined />} onClick={onClose}>Cancelar</Button>

                <Tooltip trigger={(disabled) ? ["hover"] : []} title="Ya se generó una factura previamente.">
                    <Button loading={loading} disabled={disabled} icon={<IconSAT size={14} color="currentColor" style={{ position: "relative", top: 2, right: 3 }} />} type="primary" onClick={() => {

                        timbradoOrden.current.form.current.submit()
                        console.log("timbradoOrden.current.formTimbrado.current.submit()",)
                        console.log("timbradoOrden.current.formTimbrado.current.submit()", timbradoOrden)

                    }}>Timbrar</Button>
                </Tooltip>
                {verPDF ? <Button
                    loading={loading}
                    icon={<PrinterOutlined style={{ color: "currentColor" }} />}
                    type="primary"
                    onClick={() => window.open(`${axios.defaults.baseURL}/facturas/cfdi?factura_id=${verPDF}&Authorization=${sessionStorage.getItem("token")}`, '_blank')}>Ver PDF</Button> : null}

                {verPDF ? <Button
                    target={"_blank"}
                    loading={loading}
                    icon={<GoFileCode style={{ color: "currentColor", fontSize: 20, position: "relative", top: 4, right: 4 }} />}
                    type="primary"
                    download={true}
                    href={`${axios.defaults.baseURL}/facturas/cfdi/xml?factura_id=${verPDF}&Authorization=${sessionStorage.getItem("token")}&dummy=dummy`}>Descargar XML</Button> : null}
            </Space>
        }
    >
        <FacturasList
            loading={loading}
            setVerPDF={setVerPDF}
            disabled={disabled}
            setLoading={setLoading}
            setDisabled={setDisabled}
            ref={timbradoOrden}
            {...props}
            onClose={onCloseEvent}
        />
    </Drawer>

}