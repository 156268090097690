import React, { Component, } from "react";
import {
    Select, Form
} from 'antd';

const { Option } = Select;

const monedas = [
    "MXN",
    "USD",
    
]


/**
 *
 *
 * @export
 * @class FormProducto
 * @extends {Component}
 */
export default class AddOnMoneda extends Component {

    constructor(props) {
        super(props);
        this.state = {
            monedas
        }
    }

    render() {
        return (
            <Form.Item
                name="moneda"
                noStyle
                initialValue="MXN"
            >
                <Select showSearch filterOption style={{ width: 80 }}>
                    {
                        this.state.monedas.map(moneda => (
                            <Option value={moneda}>{moneda}</Option>
                        ))
                    }
                </Select>
            </Form.Item>
        )
    }
}
