import React from 'react'
import { Route, Switch } from "react-router-dom";


// componentes
import Proveedores from '../components/Admin/Proveedores/Proveedores'

import Catalogos from '../components/Admin/Proveedores/Catalogos/Catalogos'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterProveedores(props) {
    
    return (
        <Switch>
            <Route exact path="/admin/proveedores" render={(props_routes) => <Proveedores {...props} {...props_routes} />} />
            <Route exact path="/admin/proveedores/:proveedor_id/catalogos" render={(props_routes) => <Catalogos {...props} {...props_routes} />} />
        </Switch>
    )
}

export default RouterProveedores;
