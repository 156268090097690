import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Typography, Select, Avatar, InputNumber } from 'antd';
import PropTypes from "prop-types";
const axios = require('axios').default;


const { Title, Text } = Typography;
const { Option } = Select;




/**
 * @export
 * @class FormProducto
 * @extends {Component}
 */
class FormProducto extends Component {

    /**
     * @static
     * @memberof FormProducto
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            producto: undefined,
            cantidad: 0,
            info: undefined,


            productos: {
                data: [],
                page: 1,
                limit: 50,
                total: 0,
                pages: 0,
            },

            almacenes: {
                data: [],
                page: 1,
                limit: 50,
                total: 0,
                pages: 0,
            },

            form: {
                inv_minimo: 1
            }
        }
    }



    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.id)
            this.get()

        // this.getProductosCliente();
        this.getProductos();
        this.getAlmacenes();
    }

    /**
    * @method getProductos
    * @description Obtiene los productos asignados al cliente 
    */
    getProductos = ({
        page = this.state.productos.page,
        limit = this.state.productos.limit,
        search = this.state.productos.search
    } = this.state.productos) => {
        this.setState({
            productos: {
                ...this.state.productos, page, limit, search
            }
        })
        return axios.get('/productos', { params: { page, limit, search } })
            .then(({ data }) => {
                console.log("data", data.data)
                this.setState({
                    productos: {
                        ...this.state.productos,
                        ...data.data
                    }
                })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
    }

    /**
    * @method getAlmacenes
    * @description Obtener almacenes que tienen el id del producto y el cliente dado
    */
    getAlmacenes = ({
        page = this.state.almacenes.page,
        limit = this.state.almacenes.limit,
        search = this.state.almacenes.search
    } = this.state.almacenes) => {

        this.setState({
            almacenes: {
                ...this.state.almacenes, page, limit, search
            }
        })

        axios.get('/vmi/list', {
            params: {
                page, limit,
            
                almacenes: true,
                cliente_id: this.props.cliente_id
            }
        })
            .then(({ data }) => {
                this.setState({
                    almacenes: {
                        ...this.state.almacenes, 
                        data: data.data.itemsList,
                        total: data.data.itemCount,
                        pages: data.data.pageCount,
                    }
                    
                })
            })
            .catch(error => {
                message.error("No se obtuvieron los Almacenes")
                console.log(error)
            })
    }

    onPopupScrollProductos = event => {
        const { page, pages, } = this.state.productos
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.loading
        ) {
            this.getProductos({ page: page + 1 })
        }
    }



    /**
     * @memberof FormProducto
     * 
     * @method get
     * @description Obtenemos el producto
     */
    get = (id = this.props.id) => axios.get("inventarios/productos/get", {
        params: { id }
    })
        .then(async ({ data }) => {
            var info = data.data;

            this.setState({
                producto: info.producto_id,
                cantidad: (this.props.cantidad !== undefined) ? this.props.cantidad : info.cantidad,
                info: info
            });

            this.formModal.current.setFieldsValue({
                np_cliente: info.np_cliente,
                inv_minimo: info.inv_minimo,
                inv_maximo: info.inv_maximo,
                precio: info.precio
            })
        })
        .catch(res => {
            console.log("res", res)
            message.error("Error al obtener el producto")
            // message.error('No se pudo cargar el Negocio')
        })



    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method save
     * @description Guardamos el producto
     */
    save = (values) => {

        if (this.state.loading) return

        this.setState({ loading: true }, () => {
            axios.post('/inventarios/productos/add', {
                ...values,
                id: values._id,
                cliente_id: this.props.cliente_id,
                inventario_id: this.props.inventario_id
            })
                .then(() => {
                    message.success("¡Se ha guardado correctamente el producto!")
                    this.props.onClose();
                })
                .catch((e) => {
                    message.error(e?.response?.data?.message ? e?.response?.data?.message : 'No se pudo guardar el producto')
                })
                .finally(() => this.setState({
                    loading: false,
                    loadingImage: false,
                    image: undefined
                }))
        })

    }

    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method update
     * @description Actualizamos el producto.
     */
    update = (values) => {

        axios.put('/inventarios/productos/update', {
            ...values,
            id: this.props.id,
            inventario_id: this.props.inventario_id
        })
            .then(() => {
                message.success("¡Se ha guardado correctamente el producto!")
                this.props.onClose();
            })
            .catch((e) => {
                message.error('No se pudo guardar el producto')
            })
            .finally(() => this.setState({
                loading: false,
                loadingImage: false,
                image: undefined
            }))
    }


    /**
     * @memberof FormProducto
     * 
     * @method finish
     * @description Se realizan validaciones antes de guardar o actualizar registro
     */

    finish = values => {

        if (values.almacen_id !== undefined && ((values.cantidad === undefined || values.ubicacion === undefined))) {
            message.error("Al seleccionar un almacén, se deben llenar los campos de cantidad y ubicación")
        }

        if (values.almacen_id !== undefined && values.ubicacion !== undefined && (values.cantidad > values.inv_maximo)) {
            message.error("La cantidad no puede ser mayor al inventario máximo")
        }

        else if (values.precio === 0) {
            message.error("El valor de precio del cliente no puede ser 0")
        }

        else if (values.inv_minimo >= values.inv_maximo) {
            message.error("El valor de inventario mínimo no puede ser mayor o igual al inventario máximo")
        }

        else {
            if (this.props.id)
                this.update(values)
            else
                this.save(values)
        }
    }

    formModal = React.createRef();



    onChangeInvCnt = (min, max) => {
        // var form = this.formModal.current.getFieldsValue();

        if (isNaN(min) || isNaN(max))
            return Promise.reject(new Error('Los valores deben ser numéricos'))


        if (min >= max)
            return Promise.reject(new Error('El valor de inventario mínimo no puede ser mayor o igual al inventario máximo'))

        return Promise.resolve();
    }

    render() {
        const { formModal } = this;
        const { form, loading } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.id ? "Editar Producto" : "Nuevo Producto"}</Title>
                <Form layout="vertical" ref={formModal} initialValues={form} onFinish={this.finish} >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>

                            {
                                this.state.producto !== undefined ?
                                    <Row className="center">
                                        <Col xs={24}>
                                            <Title level={4}>
                                                {this.state.producto?.np_fabricante}
                                            </Title>
                                        </Col>
                                        <Col xs={24}>
                                            <Title level={5}>
                                                Cantidad : {this.state.cantidad}
                                            </Title>
                                        </Col>
                                        <Col xs={24}>
                                            <Avatar
                                                style={{
                                                    display: "inline-block",
                                                    marginRight: 4,

                                                }}
                                                size={"large"}
                                                shape="square"
                                                src={(Array.isArray(this.state.producto.imagenes)
                                                    && this.state.producto.imagenes.length > 0) ?
                                                    (axios.defaults.baseURL + '/upload/' + this.state.producto.imagenes[0].filename) : undefined} />
                                            <Text strong>{this.state.producto.marca}</Text> &nbsp;
                                            <Text>{this.state.producto.np_nexus}</Text><br />
                                        </Col>
                                    </Row>
                                    :

                                    <Form.Item
                                        label="Producto"
                                        name="producto_id"
                                        rules={[
                                            { required: true, message: "Seleccione producto" }
                                        ]} >
                                        <Select
                                            placeholder="Busque el producto "
                                            loading={loading}

                                            showSearch
                                            filterOption={false}
                                            onSearch={search => this.getProductos({ search, page: 1 })}
                                        >
                                            {
                                                this.state.productos.data.map(producto => {
                                                    return <Option
                                                        key={producto._id}
                                                        value={producto._id}
                                                        title={producto.np_fabricante}>
                                                        <div>
                                                            <Avatar
                                                                style={{ display: "inline-block", marginRight: 4 }}
                                                                shape="square" src={(Array.isArray(producto.imagenes) && producto.length > 0) ? (axios.defaults.baseURL + '/upload/' + producto.imagenes[0].filename) : undefined} />
                                                            <Text strong>{producto.np_fabricante}</Text> &nbsp; <Text>{producto.np_nexus}</Text> &nbsp; <Text>{producto.descripcion}</Text>
                                                        </div>
                                                    </Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                            }
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Número de Parte del Cliente"
                                name="np_cliente"
                                rules={[
                                    { required: true, message: "Ingrese el número de parte del cliente" }
                                ]} >
                                <Input placeholder="Número de parte del cliente" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Inventario Mínimo"
                                name="inv_minimo"

                                rules={[
                                    { required: true, message: "Ingrese la cantidad mínima" },
                                    ({ getFieldValue }) => ({
                                        validator: (_, value) => this.onChangeInvCnt(value, getFieldValue('inv_maximo'))
                                    }),
                                ]} >
                                <InputNumber placeholder="0" className="width-100"
                                    min={0} defaultValue={0}

                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Inventario Máximo"
                                name="inv_maximo"
                                // maximo
                                rules={[
                                    { required: true, message: "Ingrese la cantidad máxima" },
                                    ({ getFieldValue }) => ({
                                        validator: (_, value) => this.onChangeInvCnt(getFieldValue('inv_minimo'), value)
                                    }),
                                ]} >
                                <InputNumber placeholder="1" className="width-100" min={0} defaultValue={0} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Precio del Cliente"
                                name="precio"
                                rules={[
                                    { required: true, message: "Ingrese el precio del cliente" }
                                ]} >
                                <InputNumber
                                    min={0}
                                    defaultValue={0}
                                    className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>

                        {
                            this.state.producto === undefined ?

                                <Row gutter={[16, 16]} className="width-100" style={{ margin: "0px" }}  >
                                    <Col xs={24}>

                                        <Title level={4} className="width-100 text-center"> Existencia</Title>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            label="Almacén Inicial"
                                            name="almacen_id"
                                        >
                                            <Select
                                                placeholder="Almacén"
                                                optionFilterProp="children"
                                                loading={loading}
                                                allowClear
                                            >
                                                {this.state.almacenes.data.map(alm => <Option key={alm._id}
                                                    value={alm._id} title={alm.direccion}>
                                                    {alm.direccion}
                                                </Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="Cantidad"
                                            name="cantidad"
                                        >
                                            <InputNumber placeholder="100" className="width-100" min={0} defaultValue={0} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="Ubicación"
                                            name="ubicacion"
                                        >
                                            <Input placeholder="Ubicación" className="width-100" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                : null}

                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onCancel, onClose } = props;

    return (
        <Modal
            visible={visible}
            title={null}
            footer={null}
            closable={true}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            onCancel={onClose}
        >
            <FormProducto {...props} />
        </Modal>
    )
}


