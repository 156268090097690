import React, { Component } from 'react';
import { Button, Col, Row, Spin, PageHeader, Layout, message, Typography, Statistic, Card, List } from 'antd'
import axios from 'axios';
import Carousel from 'react-multi-carousel';
import { FaArrowRight } from "react-icons/fa";
import 'react-multi-carousel/lib/styles.css';
//Componentes
import { IconDetails } from '../../Widgets/Iconos';
import { CardSaldo, CardCuenta, CardGains } from "../../Widgets/Cards"
import ModalTransaccionesDetalle from './Transacciones/ModalTransaccionesDetalle';
const { Content, } = Layout;
const { Text, Title } = Typography;
const moment = require('moment')

const responsive = {
    desktop_fhq: {
        breakpoint: { max: 4000, min: 1200 },
        items: 2
    },
    desktop_hq: {
        breakpoint: { max: 1500, min: 1200 },
        items: 1
    },
    desktop_xxl: {
        breakpoint: { max: 1200, min: 992 },
        items: 1
    },
    desktop_xl: {
        breakpoint: { max: 992, min: 768 },
        items: 1
    },
    desktop_lg: {
        breakpoint: { max: 768, min: 576 },
        items: 1
    },
    desktop_md: {
        breakpoint: { max: 576, min: 464 },
        items: 1
    },
    desktop_sm: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    },

    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Vista del Dashboard de Finanzas
 */
export default class DashboardFinanzas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cuentas: [],
            transacciones: [],
            currentPage: 0,
            itemCount: 0,
            perPage: 0,
            pageCount: 0,
            cuentas_incluidas: 0,
            saldo_total: 0
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(false);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCuentas();
        this.getTransacciones();

    }


    /**
     *
     *
     * @memberof DashboardFinanzas
     * @description Obtiene la lista de cuentas disponibles
     */
    getCuentas = () => {
        axios.get('/cuentas/list', {
            params: {
                page: 1,
                limit: 100,
            }
        }).then(response => {
            let cuentas_total = response.data.data.itemsList.length;
            let saldo_total = 0;
            response.data.data.itemsList.map((registro, undex) => {
                saldo_total += parseFloat(registro.saldo);
            })

            this.setState({
                saldo_total: saldo_total,
                cuentas_incluidas: cuentas_total,
                cuentas: response.data.data.itemsList,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }

    /**
    *
    *
    * @memberof DashboardFinanzas
    * @description Obtiene la lista las ultimas transacciones
    * @param values pagina
    */
    getTransacciones = (page = 1) => {
        this.setState({ loading_trans: true })
        axios.get('/transacciones/list', {
            params: {
                page: page,
                limit: 5,
            }
        })
            .then(res => {
                const data = res.data.data
                this.setState({
                    transacciones: data.itemsList,
                    currentPage: data.currentPage,
                    itemCount: data.itemCount,
                    perPage: data.perPage,
                    pageCount: data.pageCount
                })
            })
            .catch(res => {
                console.log("error", res);
                message.error('No se pudieron cargar las transacciones')
            })
            .finally(() => this.setState({ loading_trans: false }))
    }



    /**
     *
     * @memberof DashboardFinanzas\
     * @description Renderiza el monto con el color correspondiente segun el tipo de transaccion
     */
    renderMonto = (tipo, monto) => {
        let colors = ['#000000', '#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Dashboard"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <Row gutter={[16, 16]}>
                            <Col xs={24} lg={10} xl={8} xxl={4}>
                                <CardSaldo saldo={this.state.saldo_total} cuentas={this.state.cuentas_incluidas} />
                            </Col>
                            <Col xs={24} lg={14} xl={16} xxl={20}>
                                <Carousel
                                    responsive={responsive}
                                    className="carousel-cards"
                                >
                                    {this.state.cuentas.map(cuenta => {
                                        return <CardCuenta cuenta={cuenta} />
                                    })}

                                </Carousel>
                            </Col>

                            <Col xs={24} lg={24} xl={24} xxl={24}>
                                <Card className="heigth-100 border-16">
                                    <Row>
                                        <Col span={22}>
                                            <Title level={3} className="text-gray"> Últimas Transacciones </Title>
                                        </Col>
                                        <Col span={2} className="center">
                                            <Button ghost onClick={() => this.props.history.push('/admin/finanzas/transacciones')} icon={<FaArrowRight />} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <List
                                            className="width-100"
                                            dataSource={this.state.transacciones}
                                            locale={{ emptyText: 'Sin Transacciones' }}
                                            key={(item => item._id)}
                                            renderItem={item => (
                                                <List.Item className="width-100">
                                                    <Row className="width-100" align="middle">
                                                        <Col xs={3} lg={5} xl={4} xxl={4}  >
                                                            <Text ellipsis strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                                        </Col>
                                                        <Col xs={5} lg={5} xl={5} xxl={5} className="center">
                                                            <Text ellipsis>{item.concepto}</Text>
                                                        </Col>
                                                        <Col xs={6} lg={4} xl={4} xxl={4} className="center">
                                                            <Text ellipsis>{item.cuenta_id?.nombre}</Text>
                                                        </Col>
                                                        <Col xs={4} lg={4} xl={4} xxl={4} className="center">
                                                            <Text ellipsis>{item.rubro_id?.nombre}</Text>
                                                        </Col>
                                                        <Col xs={4} lg={4} xl={4} xxl={4} className="center">
                                                            <Text ellipsis>{this.renderMonto(item.tipo, item.monto)}</Text>
                                                        </Col>
                                                        <Col xs={2} lg={2} xl={2} xxl={2} className="center">
                                                            <Button type="ghost" icon={<IconDetails />} onClick={() => this.setState({ modalTransacDetalleVisible: true, id: item._id })} />
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )}
                                        />
                                    </Row>
                                </Card>
                            </Col>
                            {/* <Col xs={24} lg={7} xl={5} xxl={4}>
                                <Row gutter={[16, 16]} className=" width-100 ">
                                    <CardGains />
                                    <CardGains title={'Loss'} status={'exception'} />
                                </Row>
                            </Col> */}
                        </Row>

                    </Content>

                    <ModalTransaccionesDetalle
                        visible={this.state.modalTransacDetalleVisible}
                        onClose={() => this.setState({
                            modalTransacDetalleVisible: false,
                            id: null
                        })}
                        id={this.state.id}
                    />
                </Spin>
            </>
        )
    }
}