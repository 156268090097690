import React, { Component } from "react";
import { Layout, Button, Col, Row, Collapse, Typography, List, message, Tooltip } from "antd";
import { ShoppingCartOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'

import ModalCompraProductos from './ModalCompraProductos';
import '../../../Styles/Modules/Customer/customer.css'

const { Content } = Layout;
const { Text } = Typography
const { Panel } = Collapse;
const axios = require('axios')


/**
 * @export
 * @class AlmacenesList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class AlmacenesList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            modalVisible: false,
            almacenes: [],

            page: 1,
            limit: 10,
            total: 0,

            searching: true,
            filtroSearch: '',
        }
    }

    /**
   * @method componentDidMount 
   * @description Cargamos la informacion inicial
   */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getAlmacenes();
    }



    /**
    * @method componentDidUpdate
    * @description Actualiza la consulta si se buscan datos
    */
    componentDidUpdate() {
        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getAlmacenes();
            this.state.searching = false;
        }
    }



    /**
     * @memberof Productos
     * @method getClasificador
     * @description Obtiene los almacenes de los clientes y sus productos
     */
    getAlmacenes = (page = this.state.page) => {
        let search = this.props.search;

        this.setState({ loading: true })
        axios.get('/vmi/list', {
            params: {
                almacenes: true,
                page,
                search
            }
        }).then(response => {
            this.setState({
                almacenes: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount,

                filtroSearch: search,
                searching: true,
                loading: false
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la información de los almacenes')
        }).finally(() => this.setState({ loading: false }))
    }




    render() {

        return (
            <>
                <Content>
                    <Row>
                        <Col span={24}>
                            <List
                                dataSource={this.state.almacenes}
                                pagination={{
                                    current: this.state.page,
                                    pageSize: this.state.limit,
                                    total: this.state.total,
                                    hideOnSinglePage: true,
                                    position: 'bottom',
                                    className: "flex-left",
                                    change: this.getAlmacenes
                                }}
                                loading={this.state.loading}
                                renderItem={item => (
                                    <Collapse
                                        bordered={false}
                                        defaultActiveKey={['1']}
                                        expandIcon={({ isActive }) => isActive ? <MinusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} /> : <PlusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} />}
                                        className="collapse-almacenes"
                                    >
                                        <Panel
                                            header={
                                                <Row className="width-100 header-collapse ">
                                                    <Col span={11} className="flex-left">
                                                        <Text className="collapse-text-big">{item.direccion}</Text>
                                                    </Col>
                                                    <Col span={6} className="flex-column">
                                                        <Text>Productos</Text>
                                                        <Text>{item.productos?.length}</Text>
                                                    </Col>
                                                    <Col span={5} className="flex-column">
                                                        <Text>Ubicación</Text>
                                                        <Text>{item?.direccion}</Text>
                                                    </Col>

                                                </Row>
                                            }
                                        >
                                            <Row className="width-100 border-bottom">
                                                <Col span={8} className="center">
                                                    <Text>Producto</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text className="text-gray">Ubicación</Text>
                                                </Col>
                                                <Col span={4} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                    <Text className="text-gray">Cantidad Disponible</Text>
                                                </Col>
                                                <Col span={4} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                    <Text className="text-gray">Precio</Text>
                                                </Col>
                                                <Col span={4} className="flex-right" style={{ paddingRight: '1.5rem' }}>

                                                </Col>
                                            </Row>
                                            {item.productos?.map(prod => <Row>
                                                <Col span={8} className="center">
                                                    <Text ellipsis>{prod.inventario_producto_id?.np_cliente}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text className="text-gray">{prod.ubicacion}</Text>
                                                </Col>
                                                <Col span={4} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                    <Text className="text-gray">{(prod.cantidad - prod.cantidad_proceso) < 0 ? 0 : (prod.cantidad - prod.cantidad_proceso)}</Text>
                                                </Col>
                                                <Col span={4} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                    <Text className="text-gray">{prod.inventario_producto_id?.precio?.toMoney(true)}</Text>
                                                </Col>
                                                <Col span={4} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                    {/* <Tooltip title="Agregar" trigger={((prod.cantidad - prod.cantidad_proceso) < 1) ? ["hover"]: [] }> */}
                                                        <Button
                                                            disabled={(prod.cantidad - prod.cantidad_proceso) < 1}
                                                            icon={<ShoppingCartOutlined className="text-white" />}
                                                            type="primary"
                                                            className="btn-compra"
                                                            onClick={() => this.setState({
                                                                modalVisible: true,
                                                                producto_id: prod.producto_id?._id,
                                                                producto: prod.producto_id,
                                                                cantidad_disp: prod.cantidad - prod.cantidad_proceso,
                                                                cantidad_proceso: prod.cantidad_proceso,
                                                                cantidad_disp: prod.cantidad,
                                                                almacen_id: prod.almacen_id,
                                                                precio: prod.inventario_producto_id?.precio,
                                                            })}
                                                        >
                                                        </Button>

                                                    {/* </Tooltip> */}
                                                </Col>
                                            </Row>)}
                                        </Panel>
                                    </Collapse>
                                )}
                            />

                        </Col>
                    </Row>


                    <ModalCompraProductos
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({
                                modalVisible: false,
                                producto_id: undefined,
                                producto: undefined,
                                cantidad_disp: undefined,
                                almacen_id: undefined,
                                precio: undefined,
                            })
                            this.getAlmacenes()
                        }}
                        producto={this.state.producto}
                        cantidad_disp={this.state.cantidad_disp}
                        cantidad_proceso={this.state.cantidad_proceso}
                        almacen_id={this.state.almacen_id}
                        precio={this.state.precio}
                    />
                </Content>


            </>
        )
    }
}