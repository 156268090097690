import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Checkbox, Form, Input, message, Spin, Tooltip, InputNumber, Select } from 'antd';
import { StarOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Option } = Select;

const axios = require('axios').default;


/**
 *
 *
 * @class ModalClientes
 * @extends {Component}
 */
class ModalClientes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            return: false,
            premium: false,
            usuarios: [],
            cliente: undefined,
            registrosInventario: false
        }
    }

    ModalClientes = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getUsuarios()


        if (this.props.cliente_id !== undefined) {
            this.getCliente()

        }
    }



    /**
     * @memberof ModalClientes
     * @method getUsuarios
     * @description Obtiene lista de usuarios
     */
    getUsuarios = (search) => {

        this.setState({ loading: true })

        axios.get('/usuarios', {
            params: {
                responsables: true,
                search
            }
        }).then(response => {
            console.log('responsables', response.data.data)
            this.setState({
                usuarios: response.data.data.itemsList,
            })


        }).catch(error => {
            message.error('Error al traer usuarios')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalClientes
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if (this.props.cliente_id) {
            this.updateCliente(values)
        } else {
            this.addCliente(values)
        }
    }


    /**
     * @memberof ModalClientes
     * @method addCliente
     * @description Añade un cliente a la BD
     */
    addCliente = (values) => {

        if (this.state.loading) return

        this.setState({ loading: true }, () => {

            axios.post('/clientes/add', {
                ...values
            }).then(response => {

                if (response.data.success) {
                    message.success('Cliente creado')
                    this.props.onClose()
                }
                else if (response.data.message !== undefined) {
                    message.error(response.data.message.title + "  " + response.data.message.content)
                }
                else {
                    message.error('Error al crear cliente, verifique los datos ingresados.')
                }
            }).catch(error => {
                console.log(error.response)
                message.error('Error al crear cliente.')
            }).finally(() => this.setState({ loading: false }))
        })

    }


    /**
     * @memberof ModalClientes
     * @method getCliente
     * @description Obtiene un cliente de la DB
     */
    getCliente = () => {

        this.setState({ loading: true })

        axios.get('/clientes/get', {
            params: {
                id: this.props.cliente_id
            }
        }).then(response => {

            if (response.data.success) {

                let cliente = response.data.data;

                this.setState({
                    cliente: response.data.data,
                    premium: response.data.data.premium,
                    registrosInventario: response.data.registrosInventario
                })

                //Actualiza los valores del form dentro del modal.
                this.ModalClientes.current.setFieldsValue({
                    nombre: cliente.nombre,
                    apellido: cliente.apellido,
                    email: cliente.email,
                    razon_social: cliente.razon_social,
                    telefono: cliente.telefono,
                    rfc: cliente.rfc,
                    direccion: cliente.direccion,
                    premium: cliente.premium,
                    dias_credito: cliente.dias_credito,
                    responsable_id: cliente?.responsable_id?._id,
                    codigo_postal: cliente?.codigo_postal,
                    regimen_fiscal: cliente?.regimen_fiscal,

                })
            }
            else {
                message.error('Error al traer cliente')
            }

        }).catch(error => {
            message.error('Error al traer cliente')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalClientes
     * @method updateCliente
     * @description Actualiza la información de un cliente
     */
    updateCliente = (values) => {

        this.setState({ loading: true })

        let archivos = []
        if (values.documentos && values.documentos.length > 0) {
            archivos = values.documentos.map(img => img.name)
        }
        axios.post('/clientes/update', {
            id: this.props.cliente_id,
            ...values
        }).then(response => {

            if (response.data.success) {
                message.success('Cliente Actualizado')
                this.props.onClose()
            }
            else if (response.data.message !== undefined) {
                message.error(response.data.message.title + "  " + response.data.message.content)
            }
            else {
                message.error('Error al editar cliente, verifique los datos ingresados.')
            }

        }).catch(error => {
            message.error('Error al actualizar cliente')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalClientes
     * @method onChange
     * @description Cambia el state para mostrar u ocultar el campo
     */
    onChange = (e) => {
        this.setState({ premium: e.target.checked });
    }

    /**
    * @memberof ModalFacturas
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

        let arr = [];
        if (array) {
            array.forEach(function ({ _id, nombre, apellido, folio }, index) {
                arr.push(<Option value={_id}>{nombre} {apellido}</Option>)
            })
        }

        if (array && addElemnt) {
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre} {addElemnt.apellido}</Option>)
            }
        }
        return arr
    }

    render() {


        return (
            <Form
                layout="vertical"

                ref={this.ModalClientes}
                onFinish={this.onFinish}
                initialValues={{ regimen_fiscal: "621" }}
                onValuesChange={(changedValues, allValues) => {
                    if (allValues.premium !== undefined) this.setState({ premium: allValues.premium })
                }} >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center" gutter={[16, 0]}>
                        <Col xs={24} lg={20} >
                            <Tooltip
                                trigger={(this.state.registrosInventario && this.state.premium) ? ["hover"] : []}
                                title={"Para poder quitarle el premium al cliente, es necesario que no tenga ningún inventario. Elimine los inventarios del cliente en 'Inventarios'"}>
                                <Form.Item
                                    name="premium"
                                    valuePropName={'checked'}
                                    onChange={this.onChange} checked={this.state.premium}
                                >
                                    <Checkbox disabled={this.state.registrosInventario && this.state.premium}>Cliente Premium</Checkbox>
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>
                        {!this.state.premium ? (
                            <Col xs={24} lg={20} >
                                <Form.Item
                                    label="Apellidos"
                                    name="apellido"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese los apellidos"
                                    }]}
                                >
                                    <Input placeholder="Apellidos" />
                                </Form.Item>
                            </Col>
                        ) : null}
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese email"
                                }]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Razón Social"
                                name="razon_social"
                                rules={[{
                                    //required: true,
                                    message: "Por favor, ingrese su razon social"
                                }]}
                            >
                                <Input placeholder="Razón Social" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={10} >
                            <Form.Item
                                label="Teléfono"
                                name="telefono"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese teléfono"
                                }, (({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if ((/^[0-9\+]{10,13}$/).test(value)) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(new Error('Numero de telefono incompleto'));
                                    }
                                }))]}
                            >
                                <InputNumber placeholder="Teléfono" className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={10} >
                            <Form.Item
                                label="RFC"
                                name="rfc"
                                rules={[{
                                    //required: true,
                                    message: "Por favor, ingrese el RFC"
                                },
                                {
                                    pattern: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
                                    message: 'RFC invalido'
                                }
                                ]}
                            >
                                <Input placeholder="RFC" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Dirección"
                                name="direccion"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese Dirección"
                                }]}
                            >
                                <Input placeholder="Dirección" className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Código Postal"
                                name="codigo_postal"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el código postal"
                                }]}
                            >
                                <Input placeholder="Código Postal" className="width-100" />
                            </Form.Item>
                        </Col>


                    </Row>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Responsable"
                                name="responsable_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Usuario responsable"
                                }]} >
                                <Select
                                    showArrow
                                    showSearch
                                    onSearch={value => {
                                        this.getUsuarios(value)
                                    }}
                                    className="witdh-100"
                                    placeholder="Seleccione un responsable"
                                    filterOption={false}
                                >
                                    {this.selectOptions(this.state.usuarios, this.state.cliente?.responsable_id)}

                                </Select>
                            </Form.Item>

                        </Col>

                        <Col xs={20}>
                            <Form.Item
                                label="Regimen Fiscal"
                                name="regimen_fiscal"
                                rules={[{
                                    //required: true,
                                    message: "Por favor, seleccione el Regimen"
                                }]} >
                                <Select
                                    showArrow
                                    className="witdh-100"
                                    placeholder="Seleccione un regimen"
                                >
                                    <Option value="601">General de Ley Personas Morales</Option>
                                    <Option value="603">Personas Morales con Fines no Lucrativos</Option>
                                    <Option value="605">Sueldos y Salarios e Ingresos Asimilados a Salarios</Option>
                                    <Option value="606">Arrendamiento</Option>
                                    <Option value="607">Régimen de Enajenación o Adquisición de Bienes</Option>
                                    <Option value="608">Demás ingresos</Option>
                                    <Option value="609">Consolidación</Option>
                                    <Option value="610">Residentes en el Extranjero sin Establecimiento Permanente en México</Option>
                                    <Option value="611">Ingresos por Dividendos (socios y accionistas)</Option>
                                    <Option value="612">Personas Físicas con Actividades Empresariales y Profesionales</Option>
                                    <Option value="616">Sin obligaciones fiscales</Option>
                                    <Option value="620">Sociedades Cooperativas de Producción que optan por diferir sus ingresos</Option>
                                    <Option value="621">Incorporación Fiscal</Option>
                                    <Option value="622">Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</Option>
                                    <Option value="623">Opcional para Grupos de Sociedades</Option>
                                    <Option value="624">Coordinados</Option>
                                    <Option value="625">Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</Option>
                                    <Option value="626">Régimen Simplificado de Confianza</Option>
                                    <Option value="629">De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</Option>


                                </Select>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row className="ant-row-center">

                        {this.state.premium === true ? <Col xs={24} lg={20} >
                            <Form.Item
                                label="Días de Crédito"
                                name="dias_credito"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese los dias de crédito"
                                }]}
                            >
                                <InputNumber className="width-100" />
                            </Form.Item>
                        </Col> : null}

                    </Row>


                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Cliente </Title>
        </div>
        <ModalClientes {...props} />
    </Modal>

}