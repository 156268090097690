import React, { Component } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Upload, Modal, Row, Spin, Typography, Input, Space, Image, InputNumber } from 'antd';
import { EditableProTable } from '@ant-design/pro-table';

const axios = require('axios').default;
const { Title, Text } = Typography;


/**
 *
 *
 * @export
 * @class ModalOrdenCompra
 * @extends {Component}
 */
class ModalOrdenCompra extends Component {


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            loadingFile: false,
            ordenes_compras: [],
            ocupados: [],
            cantidad_max: undefined,
            archivos: null,
            dataSource: [],
            editableKeys: []
        }
    }

    formModalOrdenCompra = React.createRef();
    tableModalOrdenCompra = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.setState({loading: true})

        if(this.props.orden_compra_id) {
            this.getOrdenCompra()
        }else {
            this.getOrden()
        }
    }

    getOrden = () => {
        axios.get('/ordenes/get', {
            params: {
                id: this.props.orden_id,
                populate_detalles: true
            }
        })
            .then(({data}) => {
                const detalles = [data.detalles]
                const ids = [data.detalles._id]
                this.setState({
                    orden_compra: data.data,
                    dataSource: detalles,
                    editableKeys: ids
                })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    /**
     *
     *
     * @memberof ModalOrdenCompra
     * @method getOrdenCompra
     * @description Obtener orden de compra con la coleccion de detalles
     */
    getOrdenCompra = () => {
        axios.get('/ordenesCompra/get', {
            params: {
                id: this.props.orden_compra_id
            }
        })
            .then(({data}) => {
                const detalles = data.orden_detalles
                const ids = detalles?.map(item => item._id)
                this.setState({
                    orden_compra: data.data,
                    dataSource: detalles,
                    editableKeys: ids
                })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState({loading: false})
            })

    }

    /**
     *
     * @memberof ModalOrdenCompra
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param filename (string)
     * Recibe el nombre del archivo.
     */
     removeFile = (filename) => {
        axios.post("/voucher/delete", {
            filename
        })
            .then(res => {
                console.log("Documento removido con exito", res);
            })
            .catch(res => {
                console.log("Documento no se puedo remover", res);
            })
            .finally(() => {
                this.setState({loadingFile: false})
            })
    };

    /**
     *
     * @memberof ModalOrdenCompra
     *
     * @method normFile
     * @description Declaramos las imagenes en el row de la tabla
     *
     */
     normFile = (e) => {

        this.setState({loadingFile: true})

        const { file, fileList } = e;

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status == "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/voucher/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingFile: false
            });

        }
        if (file.status == "removed")
            this.removeFile((file.response.filename != undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };

    /**
     *
     *
     * @param {*} row Objecto con los datos del renglon
     * @param {*} list Objeto con los datos de la tabla
     * @memberof ModalOrdenCompra
     * @method onRowChange
     * @description Actualizar los datos de la tabla
     */
    onRowChange = (row, list) => {
       this.setState({
           dataSource: list
       })
    }

    /**
     *
     *
     * @param {*} values Objecto con los datos del formulario
     * @memberof ModalOrdenCompra
     * @method onFinish
     * @description Manejar los datos del formulario y actualizar datos
     */
    onFinish = (values) => {
        // Revisar si hay una peticion en proceso
        if(this.state.loading) return

        this.setState({loading: true}, () => {
            const v = {...values}

            for(const item of this.state.dataSource){

                let recibida = item.cantidad_recibida
                let entregada = item.cantidad_entregada
                let error = false
                
                if(this.props.vmi && this.props.entrega){
                    entregada = this.props.orden_compra_id ? this.props.orden_compras_entregas : this.props.orden_entregas
                }

                if(this.props.vmi && this.props.recepcion && item.recepcion > (item.cantidad_faltante - recibida)){
                    message.error('Hay cantidades erroneas')
                    error = true
                }
                if(this.props.vmi && !this.props.recepcion && this.props.orden_compra_id && item.entrega > (item.cantidad_faltante - entregada)){
                    message.error('Hay cantidades erroneas')
                    error = true
                }
                
                if(this.props.vmi && !this.props.recepcion && !this.props.orden_compra_id && item.entrega > ((item.cantidad - item.cantidad_faltante) - entregada)){
                    message.error('Hay cantidades erroneas')
                    error = true
                }

                if(!this.props.vmi && this.props.recepcion && (item.recepcion > item.cantidad)){
                    message.error('Hay cantidades erroneas')
                    error = true
                }

                if(!this.props.vmi && !this.props.recepcion && (item.entrega > item.cantidad)){
                    message.error('Hay cantidades erroneas')
                    error = true
                }

                if(error){
                    this.setState({loading: false})
                    return
                }
            }


            if(Array.isArray(v.archivos) && v.archivos?.length > 0) {
                v.archivos = v.archivos?.map(archivo =>  archivo.name) 
            }else{
                v.archivos = []
            }

            if(Array.isArray(v.firma) && v.firma?.length > 0) {
                v.firma = v.firma[0].name
            }else{
                v.firma = undefined
            }


            const payload = {
                recepciones: this.state.dataSource,
                tipo: this.props.recepcion ? 0 : 1,
                ...v
            }

            if(this.props.orden_compra_id){
                payload.orden_compra_id = this.props.orden_compra_id
            }else{
                payload.orden_id = this.props.orden_id
            }

            axios.post('/ordenesCompra/recepciones', payload)
                .then(success => {
                    message.success(`${this.props.recepcion ? "Recepción" : "Entrega"} Creada`)
                    this.setState({loading: false}, () =>  this.props.onSuccess())
                })
                .catch(error => {
                    message.error(error.response.data.message ?? `Error al crear ${this.props.recepcion ? "Recepción" : "Entrega"}`)
                    this.setState({loading: false})
                })
        }) 
    } 

    columns = [
        {
            title: "Producto",
            width: "100%",
            dataIndex: "extra",
            key: "extra",
            renderFormItem: (text, { record }, extras) => {
                let rfq_detalle_id = text.entry?.rfq_detalle_id;
                let cantidad = 0;

                if(this.props.orden_compra_id && this.props.recepcion && text.entry?.cantidad_faltante > 0){
                    cantidad = text.entry?.cantidad_faltante
                }else if(!this.props.orden_compra_id && (text.entry?.cantidad - text.entry?.cantidad_faltante) > 0){
                    cantidad = text.entry?.cantidad - text.entry?.cantidad_faltante
                }else if(this.props.orden_compra_id && this.props.entrega){
                    cantidad = text.entry?.cantidad
                }else{
                    cantidad = text.entry?.cantidad
                }


                let sustraendo = this.props.recepcion ? text.entry?.cantidad_recibida : text.entry?.cantidad_entregada

                if(this.props.vmi && this.props.entrega){
                    sustraendo = this.props.orden_compra_id ? this.props.orden_compras_entregas : this.props.orden_entregas
                }

                let faltante = cantidad - sustraendo
                
                return (
                    <Space>
                        <Image
                            width={80}
                            src={
                                rfq_detalle_id?.imagenes?.length > 0
                                    ? `${axios.defaults.baseURL}/upload/${rfq_detalle_id?.imagenes[0]}`
                                    : `/images/nofile.png`
                            }
                            className="product-image"
                            preview={true}
                        />
                        <Space direction="vertical" size={0}>
                            <Text className="product-text-name">
                                {rfq_detalle_id
                                    ? rfq_detalle_id.modelo
                                    : ""}
                            </Text>
                            <Text className="product-text-name">
                                Cant. {cantidad ? cantidad : 0}
                            </Text>
                            <Text className="product-text-name">
                                Pte. {faltante ? faltante : 0}
                            </Text>
                            <Text className="product-text">
                                {rfq_detalle_id
                                    ? rfq_detalle_id.marca
                                    : ""}
                            </Text>
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: this.props.recepcion ? "Cantidad Recibida" : "Cantidad Entregada",
            dataIndex: this.props.recepcion ? "recepcion" : "entrega",
            key: this.props.recepcion ? "recepcion" : "entrega",
            default: 0,
            width: "140px",
            formItemProps: (form, row) => {
                let recibida = row.entry?.cantidad_recibida
                let entregada = row.entry?.cantidad_entregada
                let cantidad = 0;
                
                if(this.props.vmi && this.props.entrega){
                    entregada = this.props.orden_compra_id ? this.props.orden_compras_entregas : this.props.orden_entregas
                }
                
                if(this.props.orden_compra_id && this.props.recepcion && row.entry?.cantidad_faltante > 0){
                    cantidad = row.entry?.cantidad_faltante
                }else if(!this.props.orden_compra_id && (row.entry?.cantidad - row.entry?.cantidad_faltante) > 0){
                    cantidad = row.entry?.cantidad - row.entry?.cantidad_faltante
                }else{
                    cantidad = row.entry?.cantidad
                }
                
                if(this.props.recepcion){
                    cantidad -= recibida
                }else{
                    cantidad -= entregada
                }

                return  {
                    rules: [
                        {
                            required: true,
                            message: "Ingrese una cantidad",
                        },
                        {
                            max: cantidad,
                            type: 'number',
                            message: "La cantidad excede " + (this.props.recepcion ? "lo faltante" : "las existencias")
                        }
                    ]
                }
            },
            renderFormItem: (
                row,
                { type, defaultRender, record, ...rest },
                form
            ) => {
                let recibida = row.entry?.cantidad_recibida
                let entregada = row.entry?.cantidad_entregada
                let cantidad = row.entry?.cantidad_faltante > 0 ? row.entry?.cantidad_faltante : row.entry?.cantidad;
                
                if(this.props.vmi && this.props.entrega){
                    entregada = this.props.orden_compra_id ? this.props.orden_compras_entregas : this.props.orden_entregas
                }

                if(this.props.recepcion){
                    cantidad -= recibida
                }else{
                    cantidad -= entregada
                }

                return <InputNumber
                    controls={false}
                    defaultValue={0}
                    //max={cantidad}
                    min={0}
                    onChange={value => console.log({value, row})}
                    {...rest}
                    disabled={cantidad <= 0}
                />
            },
        }
    ];

    render() {
        const { loading, dataSource, editableKeys } = this.state;

        return (
            <Form
                onFinish={this.onFinish}
                ref={this.formModalOrdenCompra}
                layout="vertical"
            >
                <Spin spinning={loading}>
                    <Row justify="center">
                        <Col xs={24}>
                            <Title level={3} className="text-center">Nueva {this.props.recepcion ? "Recepción" : "Entrega"}</Title>
                        </Col>
                        <Col xs={24}>
                        <EditableProTable
                            headerTitle=""
                            columns={this.columns}
                            rowKey="_id"
                            value={dataSource}
                            recordCreatorProps={{
                                style: { display: "none" },
                            }}
                            editable={{
                                //form,
                                type: "multiple",
                                onValuesChange: this.onRowChange,
                                editableKeys: editableKeys,
                            }}
                        />
                        </Col>
                        <Col xs={21} className="mt-1">
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingresa el nombre"
                                    }
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        {
                            !this.props.recepcion ? (
                                <>
                                <Col xs={21} lg={9}>
                                    <Form.Item
                                        label="Archivos"
                                        name="archivos"
                                        getValueFromEvent={this.normFile}
                                        valuePropName="fileList"
                                    >
                                        <Upload
                                            action={axios.defaults.baseURL + "/voucher/add"}
                                            style={{
                                                width: "100%"
                                            }}
                                            multiple={true}
                                        >
                                            <Button>
                                                <UploadOutlined /> Subir Archivo
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                                <Col xs={0} lg={3}></Col>
                                </>
                            ) : null
                        }
                        <Col xs={21} lg={this.props.recepcion ? 21 : 9}>
                            <Form.Item
                                label="Firma"
                                name="firma"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                rules={[
                                    {
                                        required: true,
                                        message: "Sube la firma"
                                    }
                                ]}
                            >
                                <Upload
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    style={{
                                        width: "100%"
                                    }}
                                    maxCount={1}
                                >
                                    <Button>
                                        <UploadOutlined /> Subir Archivo
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        
                        <Col xs={4}>
                            <Form.Item>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={this.state.loading || this.state.loadingFile}
                                >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <ModalOrdenCompra {...props} />
        </Modal>
    )
}
