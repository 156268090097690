import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Typography, Select, Avatar, InputNumber, AutoComplete, List } from 'antd';
import PropTypes from "prop-types";
const axios = require('axios').default;


const { Title, Text } = Typography;
const { Option } = Select;




/**
 * @export
 * @class FormAlmacen
 * @extends {Component}
 */
class FormAlmacen extends Component {

    /**
     * @static
     * @memberof FormAlmacen
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            cantidad: 0,
            info: undefined,

            almacenes: [],

            form: {
                cantidad: 0
            }
        }
    }



    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.id !== undefined)
            this.get()

        this.getAlmacenes();
    }


    /**
     * @method getAlmacenes
     * @description Obtener almacenes que tienen el id del producto y el cliente dado
     */

    getAlmacenes = () => {

        axios.get('/vmi/list', {
            params: {
                page: 1,
                limit: 1000,
                almacenes: true,
                cliente_id: this.props.cliente_id
            }
        })
            .then(({ data }) => {
                const almacenes = data.data.itemsList.filter(almacen => !this.props.almacen_ids.includes(almacen._id))

                this.setState({
                    almacenes 
                })
            })
            .catch(error => {
                message.error("No se obtuvieron los Almacenes")
                console.log(error)
            })
    }



    /**
     * @memberof FormAlmacen
     * 
     * @method get
     * @description Obtenemos el producto
     */
    get = () => {
        var alm = this.props.almacen;

        if (alm !== undefined) {

            this.formModal.current.setFieldsValue({
                direccion: alm.direccion,
                cantidad: alm.cantidad,
                ubicacion: alm.ubicacion
            })
        }

    }


    /**
     * @memberof FormAlmacen
     * 
     * @method save
     * @description Guardamos el producto
     */

    add = (values) =>  this.setState({ loading: true }, () => {
            axios.post('/almacenes/productos/add', {
                almacen_id: this.props.id,
                inventario_id: this.props.inventario_id,
                inventario_producto_id: this.props.inventario_producto_id,
    
                cantidad: values.cantidad,
                ubicacion: values.ubicacion,
                direccion: values.direccion
    
            })
                .then(() => {
                    message.success("¡Se ha guardado correctamente el registro!")
                    this.props.onClose();
                })
                .catch((e) => {
                    message.error('No se pudo actualizar el registro')
                })
                .finally(() => this.setState({
                    loading: false,
                    loadingImage: false,
                    image: undefined
                }))
        })

    update = (values) =>  this.setState({ loading: true }, () => {
            axios.post('/almacenes/productos/update', {
                almacen_id: this.props.id,
                inventario_id: this.props.inventario_id,
                inventario_producto_id: this.props.inventario_producto_id,
    
                cantidad: values.cantidad,
                ubicacion: values.ubicacion,
                direccion: values.direccion
    
            })
                .then(() => {
                    message.success("¡Se ha guardado correctamente el registro!")
                    this.props.onClose();
                })
                .catch((e) => {
                    message.error('No se pudo actualizar el registro')
                })
                .finally(() => this.setState({
                    loading: false,
                    loadingImage: false,
                    image: undefined
                }))
        })


    finish = (...values) => {
        if(this.state.loading) return

        return (this.props.id) ? this.update(...values) : this.add(...values)
    }

    formModal = React.createRef();


    render() {
        const { formModal, } = this;
        const { form, loading, } = this.state;



        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.id ? "Editar Almacén" : "Nuevo Almacén"}</Title>
                <Form layout="vertical" ref={formModal} initialValues={form} onFinish={this.finish} >
                    <Row gutter={[16, 16]}>

                        <Col xs={24}>
                            <Form.Item
                                label="Almacén"
                                name="direccion"
                                rules={[{ required: true, message: "Ingrese almacén" }]}
                            >

                                <AutoComplete
                                    options={this.state.almacenes.map(alm => ({
                                        key: alm._id,
                                        value: alm.direccion,
                                        label: <List.Item.Meta
                                            title={<>
                                                <Text strong>{alm.direccion}</Text>
                                            </>}
                                        />
                                    }))}

                                    onSearch={search => this.getAlmacenes({ search })}
                                    className="width-100">
                                    <Input allowClear placeholder="Almacén" enterButton={false} />
                                </AutoComplete>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"
                                rules={[{ required: true, message: "Ingrese la cantidad" }]}
                            >
                                <InputNumber placeholder={(this.props.inv_max) ? ("Inventario máximo : " + this.props.inv_max) : "100"}
                                    className="width-100" min={0} defaultValue={0}
                                    max={(this.props.inv_max) ? this.props.inv_max : null} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Ubicación"
                                name="ubicacion"
                                rules={[{ required: true, message: "Ingrese la ubicación" }]}
                            >
                                <Input placeholder="Ubicación" className="width-100" />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onCancel, onClose } = props;

    return (
        <Modal
            visible={visible}
            title={null}
            footer={null}
            closable={true}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            onCancel={onClose}
        >
            <FormAlmacen {...props} />
        </Modal>
    )
}


