import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Checkbox, Form, Input, message, Spin, Upload, InputNumber, Select } from 'antd';
import { StarOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';

const { Title } = Typography;

const axios = require('axios').default;
const { Option } = Select;


/**
 *
 *
 * @class ModalInventarios
 * @extends {Component}
 */
class ModalInventarios extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            clientes: [],
        }
    }

    ModalInventarios = React.createRef();

    componentDidMount() {
        this.getClientes()

        if(this.props.inventario_id){
            this.getInventario()
        }
    }

    /**
     * @memberof ModalInventarios
     * @method getClientes
     * @description Obtiene un cliente de la DB
     */
    getClientes = (search) => {
        axios.get('/clientes/list', {
            params: {
                inventarios: true,
                limit: 35,
                search
            }
        }).then(response => {
            console.log(response.data.data)
            this.setState({clientes: response.data.data.itemsList})

        }).catch(error => {
            message.error('Error al traer cliente')
            console.log(error)
        })
    }

    /**
     * @memberof ModalInventarios
     * @method getInventario
     * @description Obtiene un cliente de la DB
     */
    getInventario = () => {
        axios.get('/inventarios/get', {
            params: {
                id: this.props.inventario_id
            }
        }).then(response => {
            console.log('labelIn', response.data.data)
            this.ModalInventarios.current.setFieldsValue({
                cliente_id: {
                    value: response.data.data.cliente_id?._id,
                    key: response.data.data.cliente_id?._id,
                    label: response.data.data.cliente_id?.razon_social || response.data.data.cliente_id?.nombre,
                },
                descripcion: response.data.data.descripcion,
            })

        }).catch(error => {
            message.error('Error al traer cliente')
        })
    }


    /**
     * @memberof ModalInventarios
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {

        values.cliente_id = (values.cliente_id?.value) ? values.cliente_id?.value : values.cliente_id
        if (this.props.inventario_id) {
            this.updateInventario(values)
        } else {
            this.addInventario(values)
        }
    }


    /**
     * @memberof ModalInventarios
     * @method addInventario
     * @description Añade un cliente a la BD
     */
    addInventario = (values) => {
        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            
            axios.post('/inventarios/add', {
                ...values
            }).then(response => {
                message.success('Inventario Añadido')
                this.props.onClose()
            }).catch(error => {
                message.error('Error al crear el Inventario.')
            }).finally(() => this.setState({ loading: false }))
        })
        
    }


    



    /**
     * @memberof ModalInventarios
     * @method updateInventario
     * @description Actualiza la información de un cliente
     */
    updateInventario = (values) => {

        this.setState({ loading: true })
        axios.post('/inventarios/update', {
            id: this.props.inventario_id,
            ...values
        }).then(response => {

           message.success("Inventario Actualizado")
           this.props.onClose()

        }).catch(error => {
            message.error('Error al actualizar Inventario')
        }).finally(() => this.setState({ loading: false }))
    }



    render() {


        return (
            <Form layout="vertical" ref={this.ModalInventarios} onFinish={this.onFinish} >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                            >
                                <Select
                                    labelInValue
                                    showSearch
                                    className="witdh-100"
                                    placeholder="Seleccione un Cliente"
                                    onSearch={(value)=>this.getClientes(value)}
                                    filterOption={false}
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione un cliente"
                                    }]}
                                >
                                    {this.state.clientes.map(cliente => {
                                        return <Option value={cliente._id} key={cliente._id} >{cliente.razon_social || cliente.nombre}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Descripción"></Input.TextArea>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Inventarios </Title>
        </div>
        <ModalInventarios {...props} />
    </Modal>

}