import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Button, Col, Row, Space, Typography, List, message, Card, Statistic, Popconfirm } from "antd";
import { CheckCircleFilled, WarningFilled, CloseCircleFilled } from '@ant-design/icons'

import { IconEye, IconDelete } from '../../Widgets/Iconos';
import ModalVentaDetalle from './Ventas/ModalVentaDetalle'

import '../../../Styles/Modules/ManagerInventarios/ManagerInventarios.css'

const { Content } = Layout;
const { Text } = Typography
const moment = require('moment')
const axios = require('axios')

/**
 * @export
 * @class PedidosList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class PedidosList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            ventas: {
                data: [],
                page: 1,
                limit: 10,


                total: 0,
                pages: 0,
            },
            // ingresos: [],
            // page: 1,
            // limit: 10,
            // total: 0,

            filtroSearch: '',
            searching: true,

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getVentas();
    }



    componentDidUpdate = () => {


        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getVentas();
            this.state.searching = false;
        }
    }

    /**
    *
    *
    * @memberof SolicitudesList
    * @method getVentas
    * @description Obtener las solicitudes del id de una inventario dado
    */
    getVentas = ({
        page = this.state.ventas.page,
        limit = this.state.ventas.limit,

    } = this.state.ventas) => {

        let search = this.props.search;
        this.setState({loading: true,ventas: {...this.state.ventas, page, limit}})

        axios.get('/vmi/ventas-v2', {
            params: {
                cliente_id: this.props.cliente_id,
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            console.log("data", data)
            this.setState({
                ventas: {...data},
                searching: true,
                filtroSearch: search
            })
        }).catch(error => {
            message.error('Error al obtener solicitudes')
            console.log(error)
        }).finally(()=>this.setState({loading: false}))
    }


    /**
    * @memberof IngresosList
    * @method   renderMonto
    * @description  Retorna el monto con el color correspondinte dependiendo del tipo de transaccion
    **/
    renderMonto = (tipo, monto) => {
        let colors = ['#000000', '#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    /**
   * @memberof IngresosList
   * @method   renderStatusDate
   * @description  Retorna el status dependiedo de la fecha limite
   **/
    renderStatusDate = (orden) => {

        let a = moment()
        let b = moment(orden.fecha_limite)
        let dias = b.diff(a, 'days')

        let icons = {
            4: <CloseCircleFilled style={{ fontSize: '35px' }} className="text-red" />,
            3: <WarningFilled style={{ fontSize: '35px' }} className="text-yellow" />,
            2: '',
            1: <CheckCircleFilled style={{ fontSize: '35px' }} className="text-green" />,
        }

        return <Row className="width-100">
            <Col span={9} className="center">
                {orden.fecha_status ? icons[orden.fecha_status] : 'N/A'}
            </Col>
            <Col span={15} className="flex-column">
                <Text className="text-purple">{orden.total === orden.pagado ? '-' : `${dias} Días`}</Text>
                <Text className="text-gray">{moment(orden.fecha_limite).format('DD-MM-YYYY')}</Text>
            </Col>
        </Row>
    }

    getProjectManagerLink = ({ _id, estatus, tipo_orden, orden_detalles }) => {

        let section = ""
        let faltante = orden_detalles?.cantidad_faltante > 0 ? true : false

        if (tipo_orden === 1) {
            switch (estatus) {
                case 1:
                    section = "supplychain"
                    break
                case 2:
                case 3:
                case 4:
                    section = "matriz-costos"
                    break
                case 5:
                    section = "supplychain"
                    break;
                case 6:
                case 7:
                    section = "cuenta-ventas"
                    break
                case 8:
                case 9:
                case 10:
                case 11:
                    section = "logistica"
                    break
                default:
                    section = "supplychain"
                    break
            }
        }

        if (tipo_orden === 2) {
            if (faltante && estatus <= 5) {
                section = "matriz-costos"
            }

            if (estatus <= 7) {
                section = "cuenta-ventas"
            }

            if (estatus > 7) {
                section = "logistica"
            }
        }

        return `/admin/project-manager/${section}/${_id}`
    }

    render() {

        return (
            <>
                <Content>
                    <Row>
                        <Col span={24}>
                            <List
                                loading={this.state.loading}
                                className="component-list lista-vmi"
                                locale={{ emptyText: "Sin Ventas" }}
                                itemLayout="horizontal"
                                dataSource={this.state.ventas.data}
                                pagination={{
                                    current: this.state.ventas.page,
                                    pageSize: this.state.ventas.limit,
                                    total: this.state.ventas.total,
                                    hideOnSinglePage: false,
                                    showSizeChanger: true,
                                    position: 'bottom',
                                    className: "flex-left",
                                    onChange: (page, limit) => this.getVentas({ page, limit })
                                }}
                                header={<Row className="header-list width-100 pl-1 pr-1 ">
                                    <Col span={2} className="center">
                                        <Text strong>Folio</Text>
                                    </Col>
                                    <Col span={2} className=" center">
                                        <Text strong>fecha</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>Producto</Text>
                                    </Col>
                                    <Col span={2} className="center">
                                        <Text strong>Cantidad</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>Total</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>Almacén</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>Cliente</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>Días</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>Acciones</Text>
                                    </Col>
                                </Row>}
                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <div className={`badge-card tipo-${item?.tipo}`}>
                                            </div>
                                                <Row className="width-100">
                                                    <Col span={2} className="center">
                                                        <Text strong>{item?.folio}</Text>
                                                    </Col>
                                                    <Col span={2} className="center">
                                                        <Text strong>{moment(item?.createdAt).format('DD/MM/YYYY')}</Text>
                                                    </Col>
                                                    <Col xs={3} className="center">
                                                        <Text className="text-gray">{item?.producto_id?.np_fabricante}</Text>
                                                    </Col>
                                                    <Col xs={2} className="center">
                                                        <Text className="text-gray">{item?.orden_detalles?.cantidad}</Text>
                                                    </Col>
                                                    <Col xs={3} className="center">
                                                        <Text className="text-gray-dark">$ {item?.total?.toMoney(true)} MXN</Text>
                                                    </Col>
                                                    <Col xs={3} className="center">
                                                        <Text className="text-gray">{item?.almacen_id?.direccion}</Text>
                                                    </Col>
                                                    <Col xs={3} className="center">
                                                        <Text className="text-gray">{item?.usuario?.nombre} {item?.usuario?.apellido}<br /><small>{item?.usuario?.email}</small></Text>
                                                    </Col>
                                                    <Col xs={3} className="center">
                                                        {this.renderStatusDate(item)}
                                                    </Col>
                                                    <Col xs={3} className="center">
                                                        <Space>
                                                            <Link to={this.getProjectManagerLink(item)}>
                                                                <Button
                                                                    icon={<IconEye />}
                                                                    type="primary"
                                                                    title="Editar"
                                                                    onClick={() => this.setState({ modalVisible: true, orden: item })}
                                                                />
                                                                </Link>
                                                            <Popconfirm
                                                                placement="topRight"
                                                                title="¿Deseas eliminar esta Orden?"
                                                                onConfirm={() => axios.post('/ordenes/delete', { id: item._id })
                                                                    .then((response) => {
                                                                        message.success("Orden eliminada correctamente")
                                                                        this.getVentas()
                                                                    })
                                                                    .catch((error) => {
                                                                        console.log()
                                                                        message.error(error.response?.data?.message ? error.response.data.message : "No es posible eliminar")
                                                                        this.getVentas();
                                                                    })
                                                                }
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <Button type="normal" danger icon={<IconDelete />} title="Eliminar" />
                                                            </Popconfirm>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            
                                        </Card>
                                    </List.Item>
                                )}
                            />

                        </Col>
                    </Row>
                </Content>
                <ModalVentaDetalle
                    visible={this.state.modalVisible}
                    onClose={() => this.setState({ modalVisible: false, orden: undefined })}
                    orden={this.state.orden}
                />
            </>
        )
    }
}