import React, { Component } from "react";
import { Button, Col, Form, message, Modal, Row, Spin, Typography, Select, InputNumber, Alert, } from 'antd';

import PropTypes from "prop-types";

import InputProducto from "../../../Widgets/Inputs/InputProducto"

const axios = require('axios').default;

const { Title } = Typography;
const { Option } = Select;


/**
 *
 *
 * @export
 * @class FormProveedor
 * @extends {Component}
 */
class FormProveedor extends Component {


    /**
     *
     *
     * @static
     * @memberof FormProducto
     * 
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.InputProductoRef = React.createRef()
        this.state = {
            id: undefined,
            loading: false,
            proveedor_id: undefined,

            proveedores: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
            },
        }
    }

    formModalNegocios = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProveedores()
    }

    /**
     *
     *
     * @param {*} [{
     *         page = this.state.proveedores.page, Pagina actual del proveedor
     *         limit = this.state.proveedores.limit, Limite de proveedores
     *         search = this.state.proveedores.search Busqueda
     *     }=this.state.proveedores]
     * @memberof FormProveedor
     * 
     * @description Obtenemos los proveedores
     */
    getProveedores = ({
        page = this.state.proveedores.page,
        limit = this.state.proveedores.limit,
        search = this.state.proveedores.search
    } = this.state.proveedores) => {
        this.setState(state => {
            state.loading = true
            state.proveedores.page = page;
            state.proveedores.limit = limit;
            return state;
        })

        axios.get('/proveedores/list', {
            params: {
                page, limit, search, nin_proveedores: this.props.proveedores_usados
            }
        })
            .then(response => {
                console.log('response ', response)
                this.setState(state => {
                    if (page === 1)
                        state.proveedores.data = response.data.data.itemsList
                    else
                        state.proveedores.data = [...state.proveedores.data, ...response.data.data.itemsList]

                    state.proveedores.total = response.data.data.paginator.itemCount
                    state.proveedores.pages = response.data.data.paginator.pageCount
                    state.loading = false
                    return state;
                })
            })
            .catch(error => {
                message.error('Error al traer la información de los proveedores')
            })
    }

    /**
     *
     *
     * @param {*} [{
     *         page = this.state.proveedores.page, Pagina actual del proveedor
     *         limit = this.state.proveedores.limit, Limite de proveedores
     *         search = this.state.proveedores.search Busqueda
     *     }=this.state.proveedores]
     * @memberof FormProveedor
     * 
     * @description Obtenemos los proveedores al scrollear
     */
    onPopupScrollProveedores = event => {
        const { page, pages, } = this.state.proveedores
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.loading
        )
            this.getProveedores({ page: page + 1 })
    }




    /**
     *
     *
     * @memberof FormProveedor
     * 
     * @method onFinish
     * @description Cuando se guarda el producto
     */
    onFinish = values => {
        values.proveedor_id = ((values.proveedor_id?.value) ? values.proveedor_id?.value : values.proveedor_id)
        this.save(values)
    }

    /**
     *
     *
     * @memberof FormProveedor
     * 
     * @method save
     * @description Guardamos el proveedor
     */
    save = (values) => {

        axios.post('/project-manager/update/matriz/change-proveedor', {
            id: this.props.orden_detalles_id,
            ...values
        })
            .then(() => {
                // console.log('FormProducto')
                message.success("¡Se ha actializado el proveedor!")
                this.props.onClose();
            })
            .catch((e) => {
                message.error('No se pudo actualizar el proveedor')
                console.log('e', e)
            })
            .finally(() => this.setState({
                loading: false,
                loadingImage: false,
                image: undefined
            }))
    }


    render() {
        const { loading, } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">Cambiar Proveedor</Title>
                <Form layout="vertical" ref={this.formModalNegocios} onFinish={this.onFinish} >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                        <Alert
                            message="Antes de continuar"
                            description="Si cambias al proveedor tendrás que ingresar en la tabla el nuevo costo por unidad que este te ofrece y el precio de venta por unidad para evitar pérdidas"
                            type="warning"
                            showIcon
                            closable
                        />
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                                rules={[
                                    { required: true, message: "Seleccione un proveedor" }
                                ]} >
                                <Select
                                    labelInValue
                                    showSearch
                                    placeholder="Buscar"
                                    optionFilterProp="children"
                                    loading={loading}
                                    onChange={({ value }) => {
                                        this.setState({ proveedor_id: value })
                                    }}
                                    onSearch={search => this.getProveedores({ page_: 1, search })}
                                    onPopupScroll={this.onPopupScrollProveedores}
                                >
                                    {this.state.proveedores.data.map(({ _id, nombre, nombre_comercial, rfc }) => <Option key={_id} value={_id} title={nombre}>
                                        {nombre}
                                        <br />
                                        <small>{nombre_comercial}</small><br />
                                        <small>{rfc}</small>
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onClose } = props

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={null}
            footer={null}
            closable={true}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormProveedor {...props} />
        </Modal>
    )
}
