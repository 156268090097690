import React, { Component } from "react";
import { Layout, Button, Col, Row, Space, Popconfirm, Collapse, Typography, List, message, Pagination } from "antd";
import { SwapOutlined, WarningFilled, MinusOutlined, PlusOutlined, CloseCircleFilled, CheckCircleFilled, AuditOutlined } from '@ant-design/icons'

//componentes
import FloatingButton from './../../../Widgets/Floating Button/FloatingButton';
import { IconDelete, IconEdit } from './../../../Widgets/Iconos';
import DrawerFacturacionMultiple from './DrawerFacturacionMultiple'
//modales
import ModalTraspasoProductos from './../ModalTraspasoProductos';
import ModalSolicitudProductos from './../ModalSolicitudProductos';
import ModalAlmacenProducto from './ModalAlmacenProducto';
import ModalRegistroProducto from './../ModalRegistroProducto';

//css
import '../../../../Styles/Modules/ManagerInventarios/ManagerInventarios.css';

import ModalAlmacen from "./ModalAlmacen";

const { Content } = Layout;
const { Text } = Typography;
const { Panel } = Collapse;
const axios = require('axios');

var tabActiva = "";

/**
 * @export
 * @class AlmacenesList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class AlmacenesList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            almacenes: [],
            page: 1,
            limit: 10,
            total: 0,
            actualizarTabla: false,
            modalVisibleRegistro: false,
            tmp_inventario_id: undefined,
            tmp_registro_id: undefined,
            tmp_cantidad: 0,
            filtroSearch: '',
            searching: true,
            
            almacen_producto_id: null,
            producto_id: null
        }
    }

    componentDidMount = () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        this.getAlmacenes(1)
        this.getInventario()
    }

    componentDidUpdate = () => {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getAlmacenes();
            this.state.searching = false;
        }


        if (this.props.actualizarTabla !== tabActiva) {
            tabActiva = this.props.actualizarTabla;
        }
        if (tabActiva === "Almacenes" && this.state.actualizarTabla === true) {
            this.state.actualizarTabla = false;
            this.setState({ actualizarTabla: false })
            this.getAlmacenes(1);
            this.getInventario();
        }
        else if (tabActiva !== "Almacenes" && !(this.state.actualizarTabla)) {
            this.state.actualizarTabla = true;
            this.setState({ actualizarTabla: true })
        }



    }


    /**
     * @memberof AlmacenesList
     * @method getAlmacenes
     * @description Obtener almacenes que tienen el id del producto y el cliente dado
     */
    getAlmacenes = (pag) => {

        let search = this.props.search;
        const { page, limit } = this.state
        this.setState({loading: true})
        axios.get('/vmi/list', {
            params: {
                page: (pag === undefined) ? page : pag,
                limit: 10,
                almacenes: true,
                cliente_id: this.props.cliente_id,
                search
            }
        })
            .then(({ data }) => {

                this.setState({
                    almacenes: data.data.itemsList,
                    page: data.data.currentPage,
                    pageSize: 10,
                    total: data.data.itemCount,
                    searching: true,
                    filtroSearch: search
                })


            })
            .catch(error => {
                message.error("No se obtuvieron los Almacenes")
                console.log(error)
            }).finally(()=>this.setState({loading: false}))
    }

    /**
    *
    *
    * @memberof SolicitudesList
    * @method getInventario
    * @description Obtener inventario del id de un cliente dado por props
    */
    getInventario = () => {
        axios.get('/inventarios/get', {
            params: {
                cliente_id: this.props.cliente_id
            }
        })
            .then(({ data }) => {
                this.setState({
                    inventario: data.data
                })
            })
            .catch(error => {
                message.error("No se obtuvo el Inventario")
                console.log(error)
            })
    }


    render() {

        return (
            <>
                <Content>
                    <Row className="">
                        <Col span={24}>
                            <List
                                className="component-list lista-vmi"
                                dataSource={this.state.almacenes}
                                locale={{ emptyText: "Sin Almacenes" }}
                                loading={this.state.loading}

                                renderItem={almacen => {

                                    let { _id, direccion, status, productos = [], ubicacion, total_productos } = almacen;
                                    let cntTotal = 0

                                    for (let item of productos) {
                                        cntTotal += item.cantidad
                                    }

                                    return <Collapse
                                        bordered={false}
                                        defaultActiveKey={['1']}
                                        expandIcon={({ isActive }) => isActive ? <MinusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} /> :
                                            <PlusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} />}
                                        className="collapse-almacenes"
                                    >
                                        <Panel
                                            header={
                                                <Row className="width-100 header-collapse ">
                                                    {/* total_actual */}
                                                    <Col xs={24} md={20} xl={8} className="flex-left">
                                                        {
                                                            status?._id === 1 ? (
                                                                <CloseCircleFilled className="text-red collapse-text-big collapse-icon-big" />
                                                            ) : status?._id === 2 ? (
                                                                <WarningFilled className="text-yellow collapse-text-big collapse-icon-big" />
                                                            ) : (
                                                                <CheckCircleFilled className="text-green collapse-text-big collapse-icon-big" />
                                                            )
                                                        }
                                                        <Text className="collapse-text-big" ellipsis>{direccion}</Text>
                                                    </Col>

                                                    <Col xs={24} md={12} xl={4} className="flex-left">
                                                        <Text className="collapse-text-big">{ubicacion}</Text>
                                                    </Col>

                                                    <Col xs={24} md={12} xl={4} className="flex-column">
                                                        <Text>Productos</Text>
                                                        <Text>{productos.length || "0"}</Text>
                                                    </Col>
                                                    <Col xs={24} md={12} xl={4} className="flex-column">
                                                        <Text>Total Actual</Text>
                                                        <Text strong>$ {total_productos.toMoney(true)}</Text>
                                                    </Col>
                                                    <Col xs={24} md={24} xl={4} className="center">
                                                        <Space>

                                                            <Button
                                                                type="primary"
                                                                icon={<AuditOutlined style={{ fontSize: 16 }} />}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    this.setState({
                                                                        drawerFacturas: true,
                                                                        almacen_id: _id
                                                                    })
                                                                }}
                                                                title="Corte de facturación"
                                                            />

                                                            <Button
                                                                type="primary"
                                                                icon={<PlusOutlined style={{ fontSize: 16 }} />}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modalVisibleAlmacenProducto: true,
                                                                        almacen_id: _id
                                                                    })
                                                                }}
                                                                title="Agregar"
                                                            />

                                                            <Button
                                                                type="primary"
                                                                icon={<IconEdit />}
                                                                title="Editar"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modalVisible: true,
                                                                        almacen_id: _id
                                                                    })
                                                                }}
                                                            />

                                                            {cntTotal === 0 ?
                                                                <Popconfirm
                                                                    placement="topRight"
                                                                    title="¿Deseas eliminar este Almacén?"
                                                                    okText="Si"
                                                                    cancelText="No"
                                                                    onConfirm={() => {
                                                                        axios.post('/almacenes/delete', { id: _id })
                                                                            .then(response => {
                                                                                message.success('Almacén eliminado!')
                                                                                this.getAlmacenes()
                                                                            })
                                                                            .catch(error => {
                                                                                message.error('No se pudo eliminar el Almacén!')
                                                                                console.log(error)
                                                                            })
                                                                    }}
                                                                >
                                                                    <Button
                                                                        type="primary"
                                                                        icon={<IconDelete />}
                                                                        title="Eliminar"
                                                                        danger
                                                                    />
                                                                </Popconfirm>
                                                                :

                                                                <Button
                                                                    type="primary"
                                                                    icon={<IconDelete />}
                                                                    title="Eliminar"
                                                                    disabled
                                                                    danger
                                                                />
                                                            }
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            }
                                        >

                                            <Row>

                                                <Col span={4} className="center">
                                                    <Text>N/P Fabricante</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text>N/P Cliente</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Text className="text-gray">Ubicación</Text>
                                                </Col>
                                                <Col span={2} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                    <Text>Cantidad</Text>
                                                </Col>
                                                <Col span={3} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                    <Text>Precio</Text>
                                                </Col>
                                                <Col span={3} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                    <Text>Total</Text>
                                                </Col>
                                                <Col span={4} className="flex-right" style={{ paddingRight: '1.5rem' }}>

                                                </Col>

                                            </Row>

                                            {
                                                productos.map((producto) => {

                                                    const { ubicacion, cantidad, precio, total_actual, producto_id, inventario_id, inventario_producto_id } = producto;

                                                    return (
                                                        <Row>
                                                            <Col span={4} className="center">
                                                                <Text ellipsis>{producto_id?.np_fabricante}    </Text>
                                                            </Col>
                                                            <Col span={4} className="center">
                                                                <Text ellipsis>  {inventario_producto_id?.np_cliente} </Text>
                                                            </Col>
                                                            <Col span={4} className="center">
                                                                <Text className="text-gray">{ubicacion}</Text>
                                                            </Col>
                                                            <Col span={2} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                                <Text className="text-gray">{cantidad}</Text>
                                                            </Col>
                                                            <Col span={3} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                                <Text className="text-gray">$ {precio.toMoney(true)}</Text>
                                                            </Col>
                                                            <Col span={3} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                                <Text className="text-gray">$ {total_actual.toMoney(true)}</Text>
                                                            </Col>
                                                            <Col span={4} className="flex-right" style={{ paddingRight: '1.5rem' }}>
                                                                <Button
                                                                    type="primary"
                                                                    style={{ marginRight: "8px" }}
                                                                    icon={<IconEdit />}
                                                                    title="Editar"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            modalVisibleRegistro: true,
                                                                            tmp_inventario_id: inventario_id,
                                                                            tmp_registro_id: inventario_producto_id._id,
                                                                            tmp_cantidad: cantidad
                                                                        })
                                                                    }}
                                                                />


                                                                <Button
                                                                    title="Traspaso"
                                                                    //<SwapOutlined />
                                                                    icon={<SwapOutlined className="text-white" />}
                                                                    type="primary"
                                                                    className="btn-traspaso"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            modalVisibleTraspaso: true,

                                                                            almacen_producto_id: producto._id,
                                                                            producto_id: producto.producto_id._id,

                                                                        })
                                                                    }}
                                                                >
                                                                </Button>
                                                                <Button
                                                                    title="Solicitud"
                                                                    style={{ marginRight: '0.5rem' }}
                                                                    icon={<WarningFilled className="text-white" />}
                                                                    type="primary"
                                                                    onClick={() => this.setState({
                                                                        modalVisibleSolicitud: true,
                                                                        almacen: almacen,
                                                                        producto: producto.producto_id,
                                                                    })}
                                                                >
                                                                </Button>

                                                                {cantidad === 0 ?
                                                                    <Popconfirm
                                                                        placement="topRight"
                                                                        title="¿Deseas eliminar este producto del almacen?"
                                                                        okText="Si"
                                                                        cancelText="No"
                                                                        onConfirm={() => {
                                                                            axios.post('/almacenes/productos/delete', { id: producto._id })
                                                                                .then(response => {
                                                                                    message.success('¡Producto de Almacén eliminado!')
                                                                                    this.getAlmacenes()
                                                                                })
                                                                                .catch(error => {
                                                                                    message.error('¡No se pudo eliminar el Producto de Almacén!')
                                                                                    console.log(error)
                                                                                })
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            type="primary"
                                                                            icon={<IconDelete />}
                                                                            title="Eliminar"
                                                                            danger
                                                                        />
                                                                    </Popconfirm>
                                                                    :
                                                                    <Button
                                                                        type="primary"
                                                                        icon={<IconDelete />}
                                                                        title="Eliminar"
                                                                        disabled
                                                                        danger
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </Panel>
                                    </Collapse>

                                }}
                            />

                        </Col>

                        <Col span={24}>

                            <Pagination
                                current={this.state.page}
                                total={this.state.total}
                                pageSize={10}
                                showSizeChanger={false}
                                onChange={(page) => this.getAlmacenes(page)}

                            />
                        </Col>
                    </Row>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />
                </Content>

                <ModalAlmacen
                    visible={this.state.modalVisible}
                    inventario_id={this.state.inventario?._id}
                    almacen_id={this.state.almacen_id}
                    onSuccess={() => {
                        this.getAlmacenes(1)
                    }}
                    onClose={() => {
                        this.setState({
                            modalVisible: false,
                            almacen_id: undefined
                        })
                    }}
                />

                <ModalTraspasoProductos
                    visible={this.state.modalVisibleTraspaso}
                    almacen_producto_id={this.state.almacen_producto_id}
                    producto_id={this.state.producto_id}

                    onSuccess={() => {
                        this.getAlmacenes(1)
                    }}
                    onClose={() => {
                        this.setState({
                            modalVisibleTraspaso: false,
                            almacen: undefined,
                        });
                        this.getAlmacenes(1);
                    }}
                />

                <ModalSolicitudProductos
                    visible={this.state.modalVisibleSolicitud}
                    cliente_id={this.props.cliente_id}
                    almacen={this.state.almacen}
                    producto={this.state.producto}
                    onClose={() => {
                        this.setState({
                            modalVisibleSolicitud: false,
                            almacen: undefined,
                            producto: undefined
                        });
                        this.getAlmacenes(1);
                    }}
                    almacenesList={true}
                    
                />
                <ModalAlmacenProducto
                    visible={this.state.modalVisibleAlmacenProducto}
                    almacen_id={this.state.almacen_id}
                    inventario_id={this.state.inventario?._id}
                    onSuccess={() => {
                        this.getAlmacenes(1)
                    }}
                    onClose={() => {
                        this.setState({
                            modalVisibleAlmacenProducto: false,
                            almacen_id: undefined
                        });
                        this.getAlmacenes(1);
                        this.getInventario();

                    }}
                />
                <ModalRegistroProducto
                    cliente_id={this.props.cliente_id}
                    id={this.state.tmp_registro_id}
                    inventario_id={this.state.tmp_inventario_id}
                    cantidad={this.state.tmp_cantidad}

                    visible={this.state.modalVisibleRegistro}
                    onSuccess={() => {
                        this.setState({ modalVisibleRegistro: false })

                    }}
                    onClose={() => {
                        this.setState({ modalVisibleRegistro: false })
                        this.getAlmacenes()
                    }}
                />
                <DrawerFacturacionMultiple
                    visible={this.state.drawerFacturas}
                    cliente_id={this.props.cliente_id}
                    almacen_id={this.state.almacen_id}
                    onClose={() => {
                        this.setState({ drawerFacturas: false, almacen_id: undefined })
                    }}
                />
            </>
        )
    }
}